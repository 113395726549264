const config = {
    path: '/process',
    name: 'Process',
    component: () => import('@/views/Process/index.vue'),
    meta: {
      title: '天津物流集团-论点结果',
      keepAlive:true
    },

  }
  const data={
    token:'',
    queryStr:'',
    structid:'',
    carId:'',
    resultStateFlag:'',
    availableflag:'',
    urgentflag:'',
    issuestateflag:'',
    processflag:'',
    startDate:'',
    endDate:''
  }

  export {config,data}