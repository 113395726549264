const config = {
    path: '/gps',
    name: 'GPS',
    component: () => import('@/views/GPS/index.vue'),
    meta: {
      title: '天津物流集团-获取GPS信息',
      keepAlive:true
    }
  }
  
  export {config}
