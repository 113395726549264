const config = {
  path: '/lessuel',
  name: 'Lessuel',
  component: () => import('@/views/Lessuel/index.vue'),
  meta: {
    title: '天津物流集团-论点登记'
  },

}
const data = {
  token: ''
}
const date = {
  Info_IssueName: '',
  Id: '',
  RefId: '',
  Name: '',
  StructId: '',
  StructName: '',
  CarId: '',
  CarNo: '',
  CompleteFlag: '',
  CompleteDate: '',
  CurrentFlag: true,
  Sort: 0,
  Remark: '',
  CreateBy: '',
  CreateTime: '',
  ModifyBy: '',
  ModifyTime: '',
  ActiveFlag: ''
}

export {
  config,
  data,
  date
}