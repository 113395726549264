const config = {
    path: '/shaomiao',
    name: 'shaomiao',
    component: () => import('@/views/shaomiao/index.vue'),
    meta: {
      title: '天津物流集团-扫描',
      keepAlive:true
    }
  }
  
  export  {config}