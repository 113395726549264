const config = {
    path: '/Jieguoxiangqing',
    name: 'Jieguoxiangqing',
    component: () => import('@/views/Jieguoxiangqing/index.vue'),
    meta: {
      title: '天津物流集团-结果详情',
      keepAlive:true
    }
  }
  const data={
  }
  export {config,data}