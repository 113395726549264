const config = {
    path: '/three',
    name: 'Three',
    component: () => import('@/views/Three/index.vue'),
    meta: {
      title: '天津物流集团-车辆操作',
      keepAlive:true
    }
  }

  export {config}