export default {
    message: {
        title:'大信物流集团',
        keywords:'大信物流集团,国际海运进出口,国际国内空运,国内道路运输,仓储物流,项目物流,危险品运输,海运,空运,进出口,进口,出口',
        description:'Daeshin构建全球物流网络、为客户提供一站式物流解决方案、通过建设海外分支机构、强化的业务力量及扩大义务支援、努力成长为全球综合物流企业',
        data: [{
            label: '首页',
            route: 'Home',
            id: 0,
            xia: [{
                label: '关于我们'
            }, {
                label: '物流服务'
            }, {
                label: '我们的优势'
            }, {
                label: '发展历程'
            }, {
                label: '报价咨询'
            }, {
                label: '我们的承诺'
            }, {
                label: '经营理念'
            }, {
                label: '业务领域'
            }, {
                label: '大信物流资源'
            }, {
                label: '公司历程'
            }, {
                label: '报价咨询'
            }, {
                label: '我们的承诺'
            }]
        }, {
            label: '公司信息',
            route: 'Information',
            id: 1,
            xia: [{
                label: '理念'
            }, {
                label: '5S战略'
            }, {
                label: '诚信'
            }, {
                label: '顾客'
            }, {
                label: '认证企业证书'
            }, {
                label: '感谢牌'
            }]
        }, {
            label: '业务领域',
            route: 'Outlets',
            id: 2,
            xia: [{
                label: '国际国内海运业务'
            }, {
                label: '国际国内空运业务'
            }, {
                label: '仓储管理'
            }, {
                label: '陆运业务'
            }, {
                label: '危险品业务'
            }, {
                label: '项目运输业务'
            }]
        }, {
            label: '世界网络',
            route: 'Network',
            id: 3,
            xia: [{
                label: '中国'
            }, {
                label: '美国'
            }, {
                label: '日本'
            }, {
                label: '韩国'
            }]
        }, {
            label: '宣传中心',
            route: 'Advocacy',
            id: 4,
        }, {
            label: '当前位置',
            id: 5,
        }, {
            label: '登录',
            id: 6,
        }, {
            label: '地址'
        }, {
            label: '天津市河西区围堤道146号华盛广场B座22层'
        }, {
            label: '+86-022-8823-9781/9785'
        }, {
            label: 'sales_china@i-daeshin.com'
        }, {
            label: '页',
        }, {
            xia: [{
                label: '中国'
            }, {
                label: '美国'
            }, {
                label: '韩国'
            }, {
                label: '日本'
            }, ]
        }],
        swiper: [{
            titlebta: '运输及物流解决方案',
            url: require("@/assets/image/sl1.jpg"),
            textp: '<span style="color: red;">海运/空运物流</span>解决方案',
            title: '大信物流连接全世界,世界综合物流企业',
            text: '选择大信物流，选择迅捷安全,无论何时何地,完善的服务体系提供更好的物流体验,门到门服务，一站式物流服务，以及电子商务服务'
        }, {
            url: 'require("@/assets/image/sl2.jpg") ',
            textp: '<span style="color: red;">国际物流</span>解决方案',
            title: '国际空运,快速连通整个世界',
            text: '“高效、快捷、安全”,各类航空运输，航空快递，时效急件，行李托运，合理的价格，诚心的服务，良好的信誉，满足不同层次的需求'
        }, {
            url: require("@/assets/image/sl3.jpg"),
            textp: '<span style="color: red;">仓储管理</span>解决方案',
            title: '分布广泛,综合物流仓库',
            text: '总面积超过77,500㎡物流仓库,宽阔的车道，便利的卸货平台，全天安保巡逻，较高级别消防设施,现代化管理理念，先进的电子设备，提供全天候在线服务'
        }, {
            url: require("@/assets/image/sl4.jpg"),
            textp: '<span style="color: red;">内陆运输</span>解决方案',
            title: '种类齐全,数量众多的车辆',
            text: '厢式车、槽罐车、展翼车、冷藏车、冷冻车,在中国的韩国物流企业,遍布全国的运输网络为客户提供完美的运输服务'
        }, {
            textp: '<span style="color: red;">危险品运送</span>解决方案',
            title: '危险品运输,安全、及时、准确的送达',
            text: '专业团队，内陆和进出口危险品运输，保证零风险抵达目的地，齐全的保护装备，熟练的操作工人，高额的货物保险，精细的线路管理，可靠的流程设计，全面的应急机制'
        }, {
            title: '阅读更多',
            txt: '报价咨询',
        }],
        swiper2: [{
            title: '业务范围',
            subheading: '物流解决方案',
            swiper: [{
                url: require("@/assets/image/banner-1.jpg"),
                image: require('@/assets/image/海运4web.jpg'),
                p: '海运出口',
                title: '国际海运进出口',
                subheading: '根据货物特点，',
                subheading1: '提供优秀的港口装卸与运输服务，',
                text: '依托全球各个港口的物流基础设施和符合客户货件特性的直营设备，为客户提供量身定制的港口装卸和海运服务。同时，将业务范围扩大到报关、报检、清关等事业领域，力争实现协同效应优质化',
            }, {
                url: require("@/assets/image/banner-2.jpg"),
                image: require('@/assets/image/空运2web.jpg'),
                p: '国际空运',
                title: '国际国内空运',
                subheading: '为顾客设计更优化、更快捷的运输方案，减少转机环节',
                text: '通过货件的货盘化运输，实现迅速的通关及运输服务。除了进出口货件的空运、通关等服务外，还提供与全球电子商务相匹配的专业化国际快递服务',
            }, {
                url: require("@/assets/image/banner-3.jpg"),
                image: require('@/assets/image/仓库3web.jpg'),
                p: '仓储物流',
                title: '仓储物流',
                subheading: '大面积的综合仓库，',
                subheading1: '提供优化的运营支援和物流服务，',
                text: '总面积达77,500㎡的物流仓库，遍布国内主要城市。根据货物类别特征，提供定制型的储存方案。卓越的专业人力，提供高效、安全稳定的服务支持',
            }, {
                url: require("@/assets/image/banner-4.jpg"),
                image: require('@/assets/image/陆运2web.jpg'),
                p: '内陆运输',
                title: '国内道路运输',
                subheading: '拥有380辆运输车辆,',
                subheading1: '韩国的物流企业',
                text: '拥有箱式车、槽罐车、展翼车、冷藏车、冷冻车等运输车辆，可以迅速运输各个产业群所需的多种货物。遍布全国的物流网络，对物流配送提供365天全方位支持',

            }, {
                url: require("@/assets/image/banner-5.jpg"),
                // image: require('@/assets/image/危险2web.jpg'),
                p: '危险品运输',
                title: '危险品运输',
                subheading: '超过1年危险品运输经验,',
                subheading1: '一家韩国的物流企业，',
                text: '危险品货物可以按照客户要求添加各种药剂，全程视频监控，提供货物的实时位置，运输状态随时反馈给客户。从原材料供应物流到逆向物流，保证货物安全到达目的地',
            }],
        }],
         swiper3: [{
            name: ' Tae Kyung Lee',
            gongs: 'DSL KOR - 代理 ',
            title: '开始是成功的一半！',
            text: '一个良好的开端，就是对成功的展望。成功的路很长，但在开始就拥有一颗坚定的心，必然会令人拥有无穷的斗志！',
         }, {
            name: 'Younghoon Lee',
            gongs: 'DSL USA - 法人长',
            title: '不到最后还不算结束。',
            text: '生活中没有所谓的终点，每一个阶段都是一个新的开始。',
         }, {
            name: '李振旭',
            gongs: 'DSL CHN - 天津 国际部 ',
            title: '商即人、人即商',
            text: '做生意不是为了盈利，而是为了留下人。',
         }, {
            name: '藤井知己',
            gongs: 'DSL JPN - 苏州 国际业务部 ',
            title: '深淘滩、底作堰',
            text: '不断地挖掘内部潜力，降低运作成本。提高工作效率。节制自己的贪欲，合作共赢',
         }, {
            name: '蔡宰薰',
            gongs: 'DSL CHN - 上海 常务 ',
            title: '努力绝对不会被背叛。',
            text: '努力是不会背叛自己的。只有通过不断地努力,我们才能够实现自己的目标。无论是学习、工作还是生活,都需要我们付出不懈的努力。',
         }, {
            name: '韩颖',
            gongs: 'DSL CHN - 香港 国际部 ',
            title: '日日行、不怕千万里，常常做、不怕千万事',
            text: '凡事要有坚韧不拔的毅力和坚持不懈的定力，坚持下去必成大器。',
         }, {
            name: '车映周',
            gongs: '大信物流集团 社长',
            title: '君志所向、一往无前。愈战愈勇、再接再厉。',
            text: '人若有志，遇到困难或挫折，就不会在半坡停止，会一直坚持下去。',
         }, {
            name: '邵啸群',
            gongs: 'DSL CHN - 苏州 3PL部 ',
            title: '每天进步一点点，成功就在不远处。',
            text: '用每进步一点，都是成功的积累；每跨过一个跳栏，都是到达终点的目标。',
         }, {
            name: ' Yusil Kang',
            gongs: 'DSL USA - 国际部',
            title: '人生就是快乐',
            text: ' 生活是非常美好的，乐观一点就会有惊喜；人生是快乐的，留心一点就会有欢笑；日子是美妙的，知足一点就会有幸福！',
         }, {
            name: '李文静',
            gongs: 'DSL CHN - 西安 国际部 ',
            title: '有志者事竟成',
            text: '放弃很容易，可坚持一定很酷。星星未必光芒万丈，但却始终有光。',
         }, {
            name: ' Mark Meredith',
            gongs: 'DSL USA  - 安全管理',
            title: '安全第一、始终保持安全',
            text: '宁愿多加小心，也不要出现意外后悔的情况。遵守法律，减少风险。',
         }, {
            name: '黄丽君',
            gongs: '广州 国际部',
            title: '厚德载物、尽心服务',
            text: '君子的品德应如大地般厚实可以承载万物，用心去服务客户。',
         }],
        baojia: [{
            title: '联络',
            title2: '报价咨询',
            subheading: '电话咨询和询问',
            subheading2: '+86 22 8823 9781',
            text: '在海运、空运、仓库管理、内陆货运和危险品方面需要帮助吗？<br>不要犹豫，请联系我们。<br> 我会尽快答复您。',
            input: '公司名称',
            input1: '您的姓名',
            input2: '您的邮箱',
            input3: '您的电话',
            input4: '选择服务类型',
            input5: '航空运输',
            input6: '海上运输',
            input7: '仓库管理',
            input8: '内陆运输',
            input9: '危险品运输',
            input10: '重要事项',
            button: '提交',
            sj: '你的邮箱不正确',
            yjan: '你的手机号不正确',
        }],
        slide: [{
            title: '公司历程',
            title2: '大信物流集团',
            icondiqu1: '中国,',
            icondiqu2: '美国',
            icondiqu3: '韩国',
            icondiqu4: '日本',
            zhongbu: '总部',
            fengongs: '分公司',
            fengongs1: '法人',
            huwulexing1: '危险品/内陆',
            huwulexing2: '国际物流',
            huwulexing3: '危险品',
            huwulexing4: '国际物流/内陆/危险品',
            huwulexing5: '二次电池铜箔与铜箔设备生产',
            diqu1: '天津',
            diqu2: '西安',
            diqu3: '仓州',
            diqu4: '陕西',
            diqu5: '威海',
            diqu6: '重庆',
            diqu7: '香港',
            diqu8: '广州',
            diqu9: '苏州',
            diqu10: '上海',
            diqu11: '首尔',
            diqu12: '奥斯汀',
            diqu13: ' 洛杉矶',
            diqu14: '东京',

            swiper: [{
                    text: '天津大信物流公司',
                    text2: '天津总部成立',
                },
                {
                    text: '天津大信国际货运代理有限公司',
                    text2: '天津总部成立',
                },
                {
                    text: '天津易大信运输有限公司',
                    text2: '天津总部成立',
                },
                {
                    text: '天津大信通运输有限公司',
                    text2: '天津总部成立',
                },
                {
                    text: '天津大信国际货运代理有限公司',
                    text2: '西安分公司成立',
                },
                {
                    text: '天津大信通运输有限公司',
                    text2: '沧州分公司成立',
                },
                {
                    text: '陕西大信通运输有限公司',
                    text2: '总部成立',
                },
                {
                    text: '天津大信通运输有限公司',
                    text2: '威海分公司成立',
                },
                {
                    text: '天津大信通运输有限公司',
                    text2: '重庆分公司成立',
                },
                {
                    text: '香港大信物流有限公司',
                    text2: '香港总部成立',
                },
                {
                    text: '天津大信通运输有限公司',
                    text2: '广州分公司成立',
                },
                {
                    text: '天津大信通运输有限公司',
                    text2: '苏州分公司成立',
                },
                {
                    text: '天津大信通运输有限公司',
                    text2: '上海分公司成立',
                },
                {
                    text: '韩国大信物流',
                    text2: '首尔总部成立',
                },
                {
                    text: '美国大信物流',
                    text2: '奥斯汀(TX)总部成立',
                },
                {
                    text: '美国大信物流',
                    text2: '洛杉矶(CA)分公司成立',
                },
                {
                    text: '日本大信物流',
                    text2: '东京总部成立',
                }, {
                    text: 'P&D 西安 ',
                    text2: 'PNT&大信物流合资企业',
                }
            ]
        }],
        about: [{
            tetlet: '17年专门危险品物流公司',
            tetlyt: '您的物流伙伴',
            title: '关于我们',
            subheading: '经营理念',
            text: '秉承<span style="color: red;">“顾客至上，服务第一”</span>的经营理念，竭诚为客户提供<span style="color:#198754;">“安全，高效，快捷，准确，节省”</span>的服务！',
            image: [{
                url: '@/assets/image/关于我们小图3.jpg'
            }, {
                url: '@/assets/image/关于我们小图4.jpg'
            }, {
                url: '@/assets/image/关于我们小图5.jpg'
            }, {
                url: '@/assets/image/关于我们小图6.jpg'
            }],
            description: [{
                text: '大信物流成立于2007年，总部位于中国直辖市之一的“天津”'
            }, {
                text: '十七年的发展，大信物流运用先进的国际运输管理体系、凭借全体员工不断的拼搏、创新以及确定正常的发展方向，规模不断扩大'
            }, {
                text: '在中国的北京、上海、香港、重庆、广州、西安、苏州等大中型城市设立了分公司，随着公司发展，业务扩展到韩国以及美国。'
            }, {
                text: '我们在中国地区拥有超过380辆自有车辆以及77、500㎡的综合仓库。业务范围从开始的国际海运进出口，已扩展至国际空运、仓储物流、危险品运输、起重装卸、多式联运进出口贸易等业务，能为客户提供完善的一站式服务！'
            }, {
                text: '经过奋斗和发展，我们不仅积累了大量的行内经验，锻炼了一大批优秀、专业的企业人才、和国内外客户保持了长期、友好的合作关系！凭借与韩国、日本企业的密切合作整合各种优势资源，我们已经发展成为涉及陆、海、空、进出口的专业化物流企业！未来，我们也将不断努力，利用自身优势和外部环境，继续提高我们的价格优势，提升我们的服务质量！'
            }]
        }],
        advantage: [{
            title: '我们的优势',
            subheading: '大信物流集团资源',
            text: 'Daeshin构建全球物流网络，为客户提供一站式物流解决方案',
            text2: '通过建设海外分支机构，强化的业务力量及扩大义务支援，努力成长为全球综合物流企业',
            description: [{
                url: '@/assets/image/homeyewu1.webp',
                text: '国家'
            }, {
                url: '@/assets/image/homeyewu2.webp',
                text: '公司城市'
            }, {
                url: '@/assets/image/homeyewu3.webp',
                text: '正式员工'
            }, {
                url: '@/assets/image/homeyewu4.webp',
                text: '仓库面积'
            }, {
                url: '@/assets/image/homeyewu5.webp',
                text: '危险品车辆'
            }, {
                url: '@/assets/image/homeyewu6.webp',
                text: '普货车辆'
            }, {
                url: '@/assets/image/homeyewu7.webp',
                text: '冷链车辆'
            }, {
                url: '@/assets/image/homeyewu8.webp',
                text: '装箱设备'
            }]
        }],
        image: [{
            title: '大信物流的目标舞台是整个世界。',
            subheading: '细节决定成败，我们将为客户提供细致的全球服务。',
            url: '@/assets/image/home-background2.webp'
        }],
        spin: [{
            text: '细节决定成败,态度决定一切',
        }, {
            text: '客户',
        }, {
            text: '认证企业证书',
        }],
        chengn: [{
            text: '承诺',
        }, {
            text: '我们的承诺',
        }]
    },
    information: {
        data: [{
            'val': '概要',
        }, {
            'val': '理念',
        }, {
            'val': '管理',
        }, {
            'val': '愿景',
        }, {
            'val': '诚信',
        }, {
            'val': '资格',
        }, {
            'val': '荣誉',
        }, {
            'val': '客户',
        }, {
            'val': '了解公司的愿景、企业文化、精英理念及未来发展',
        }],
        image: [{
            text1: '天津大信国际货运代理有限公司',
            text2: '中国海关AEO高级认证',
        }, {
            text1: '天津大信运输有限公司',
            text2: '质量管理体系ISO9001认证',
        }, {
            text1: '天津大信运输有限公司',
            text2: '质量管理体系ISO14001认证',
        }, {
            text1: '天津大信国际货运代理有限公司',
            text2: '无船承运业务NVOCC',
        }, {
            text1: '天津易大信运输有限公司',
            text2: '信誉等级AAA',
        }, {
            text1: '陕西大信通运输有限公司',
            text2: '信誉等级AAA',
        }, {
            text1: '三星 SDS',
            text2: '业务支持',
        }, {
            text1: '摩拓乐液压机械',
            text2: '物流优质服务商',
        }, {
            text1: '住化电子材料科技(常州)有限公司',
            text2: '物流优质服务商',
        }, {
            text1: '园益（西安）半导体',
            text2: '支持贡献',
        }, {
            text1: '陕西大信通运输有限公司',
            text2: '2018年危险品运输',
        }, {
            text1: '陕西大信通运输有限公司',
            text2: '2017年危险品运输',
        }, {
            text1: '日进汽车系统有限公司',
            text2: '支持贡献感谢',
        }, {
            text1: '住化电子材料科技(西安)有限公司',
            text2: '物流优质服务商 ',
        }, ],
        bejing: [{
            title: '关于我们',
            text: '了解公司的愿景、企业文化、精英理念及未来发展',
        }],
        idea: [{
            url: '@/assets/image/linian1.jpg',
            title: '态度决定一切、细节决定成败',
            title3: '大信物流集团 代表 车映周',
            text: '大信物流时刻将“责任”摆在第一位，基于"事业报国"的服务理念，为全面履行企业应尽的社会责任，使所有成员能够为社会作出贡献。在物流领域，客户责任堪称关乎企业生存的必需战略。大信物流正以可持续发展的超一流物流企业为目标，加强与客户的沟通，加强服务质量，赢得客户的信赖。凭借更低廉的价格，广布的网点和尖端物流网络系统，为客户提供更优质服务。未来凭借全球物流网点和专业人才，努力成为“创造实现客户满意的超一流服务”的全球性综合物流公司。'
        }],
        manage: [{
            title: 'CSV管理',
            subheading: 'CSV(Creating Shared Value，创造共享价值)经营目的在于以合法经营、诚信经营和安全经营为基础，实现创造经营、共赢经营和共享经营，为回报社会而发挥企业应尽的义务，不断创造社会性价值。大信物流集团基于创造共享价值经营理念，积极运用本司的业务力量，通过各方面努力贡献于社会发展。',
            li: [{
                text: '创造经营',
                textx: '实现世界较新型的产业模式，贡献于国家和社会，促进企业发展。'
            }, {
                text: '共赢经营',
                textx: '营造共赢产业生态系统，积极实现共赢，确保企业的可持续发展。'
            }, {
                text: '共享经营',
                textx: '基于核心业务力量，关注社会弱势领域，开展系统的社会奉献活动'
            }],
            url: '@/assets/image/xiangqing2.jpg',
            bootom: [{
                url: '@/assets/image/xiangqing3.jpg',
                text: '合法经营',
                tceit: '법률을 준수하다'
            }, {
                url: '@/assets/image/xiangqing4.jpg',
                text: '诚信经营',
                tceit: '약속을 중시하다'
            }, {
                url: '@/assets/image/xiangqing5.jpg',
                text: '安全经营',
                tceit: '안전을 보증하다'
            }]
        }],
        vision: [{
            box: [{
                title: '战略方向',
                subheading: '（전략 방향）',
                strong: '发掘新事业机会',
                strongzong: '确保Cost Leadership',
                strongxia: '构建Network/Partnership',
                strongxiaz: '培养专业物流人才',
                textp1: '寻找可能的客户',
                textp2: '积极推进新事业和海外物流事业',
                textp3: '以加强客户的物流竞争力为目的',
                textp4: '构建更优化物流体系并提供物流服务',
                textp5: '通过与优秀的企业的战略合作，构建合作网络',
                textp6: '构建与客户的Win-Win合作体制',
                textp8: '核心人才（物流顾问、先驱）的系统性培养',
            }, {
                title: '核心力量',
                subheading: '（핵심 역량）',
                strong: '全球网点（Global Network）能力',
                strongzong: 'IT系统构建/运用能力',
                strongxia: 'Consulting能力',
                textp1: '满足客户的个性化需要',
                textp2: '任何角落都能迅速低廉地运输货物地广泛网点',
                textp3: '构建可以实时（Real-time）提供数据地网络',
                textp4: '满足客户需要的物流信息、决策的IT系统',
                textp5: '给客户提供更有竞争力的系统性综合方案',
                textp6: '沟通商讨最可行的运输计划',
            }, {
                title: '共享价值',
                subheading: '（공유 가치）',
                strong: '信赖（Trust）:Partner Spirit',
                strongzong: '开放心态(Openness):Family Spirit',
                strongxia: '热情(Passion):Frontier Spirit',
                textp1: '相信客户与公司、公司与员工',
                textp2: '相信员工与员工相互间实现Win-Win关系',
                textp3: '跨越自身或部门的利害，站在全公司的角度',
                textp4: '以开放的心态，以超前意识思考及处理业务',
                textp5: '成为先进企业，努力打造杰出公司精神',
                textp6: '明确的愿景、渴望、自信',
            }],
            url: '@/assets/image/yuanjing1.jpg'
        }],

        opnert: [{
            opttbe: '企业的使命',
            title: '“诚信经营”：“诚”真诚也，“信”信用也，两者结合起来就是诚实守信，遵守信用，是企业发展的根本。',
            text1: '员工，股东，客户，社会和环保的承诺。展望是企业的发展方向和未来的面貌。',
            text2: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">企业要履行与职员的约定，尊重职员的价值，职员才会对企业忠诚，这才有助于企业的长期发展。</p>',
            text3: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">企业只有不断提高价值，股东才能对公司产生自豪感，并持续进行投资，从而获得企业发展所需的支援。</p>',
            text4: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">企业只有向顾客提供信任、信任、服务等，才能创造更多的机会，吸引新的顾客，获得增加利润的机会。</p>',
            text5: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">企业只有向顾客提供信任、信任、服务等，才能创造更多的机会，吸引新的顾客，获得增加利润的机会。</p>',
            text6: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">企业关心社会，关心环境保护，社会才能给企业更多的反馈，企业才能创造良好的发展环境。</p>',
            text7: '只有这样，才能实现企业对未来的发展和成长;只有为未来诚实经营，才能实现企业的长期发展。',
            url: '@/assets/image/chengxi1.jpg'

        }],
        avthend: [{
            url: '@/assets/image/zizhi1.jpg',
            text: '天津大信国际货运代理有限公司',
            textp: '中国海关AEO高级认证'
        }, {
            url: '@/assets/image/zizhi2.jpg',
            text: '天津大信运输有限公司',
            textp: '质量管理体系ISO9001认证'
        }, {
            url: '@/assets/image/zizhi3.jpg',
            text: '天津大信运输有限公司',
            textp: '质量管理体系ISO14001认证'
        }, {
            url: '@/assets/image/zizhi4.jpg',
            text: '天津大信国际货运代理有限公司',
            textp: '无船承运业务NVOCC'
        }, {
            url: '@/assets/image/zizhi5.jpg',
            text: '天津易大信运输有限公司',
            textp: '信誉等级AAA'
        }, {
            url: '@/assets/image/zizhi6.jpg',
            text: '陕西大信通运输有限公司',
            textp: '信誉等级AAA'
        }],
        medal: [{
            url: '@/assets/image/zizhi7.jpg',
            text: 'SAMSUNG SDS',
            textp: '业务支持感谢'
        }, {
            url: '@/assets/image/zizhi8.jpg',
            text: '摩拓乐液压机械',
            textp: '物流优质服务商'
        }, {
            url: '@/assets/image/zizhi9.jpg',
            text: '摩拓乐液压机械',
            textp: '物流优质服务商'
        }, {
            url: '@/assets/image/zizhi10.jpg',
            text: '园益（西安）半导体',
            textp: '支持贡献感谢'
        }, {
            url: '@/assets/image/zizhi11.jpg',
            text: '陕西大信通运输有限公司',
            textp: '2018年危险品运输先进企业'
        }, {
            url: '@/assets/image/zizhi12.jpg',
            text: '陕西大信通运输有限公司',
            textp: '2017年危险品运输先进企业'
        }],
        zhanluei: [{
            tltle: '5S战略',
            s1: {
                text: '<span style="margin-right: 5px;">✓</span> 根据中国业务种类，创立了5个不同类型的公司',
                text1: '<span style="margin-right: 5px;">✓</span> 利用韩国、日本、香港、美国的分公司，构建全球网络',
                text2: '<span style="margin-right: 5px;">✓</span> ISO 9001, 14001 认证及AEO高级认证',
                text3: '<span style="margin-right: 5px;">✓</span> 安全管理系统运用',
            },
            s2: {
                text: '<span style="margin-right: 5px;">✓</span>中国国内第一家拥有危险品运输许可证的韩资公司',
                text1: '<span style="margin-right: 5px;">✓</span>拥有中国地区355辆自有车辆的资产型物流公司',
                text2: '<span style="margin-right: 5px;">✓</span>通过合作物流公司可以调配8000多辆各种类型的运输车辆',
                text3: '<span style="margin-right: 5px;">✓</span>仓库面积',
                text4: '<span style="margin-right: 5px;">✓</span>铺货车辆',
                text5: '<span style="margin-right: 5px;">✓</span>危险品车辆',
                text6: '<span style="margin-right: 5px;">✓</span>冷链车辆',
            },
            s3: {
                text: '<span style="margin-right: 5px;">✓</span>建立车辆跟踪系统(Gps,cctv)',
                text1: '<span style="margin-right: 5px;">✓</span>交通局安全教育(系统)每月进行一次',
                text2: '<span style="margin-right: 5px;">✓</span>交通局安全评价AAA获得最高评分',
                text3: '<span style="margin-right: 5px;">✓</span>指定先进企业',
                text4: '<span style="margin-right: 5px;">✓</span>每日司机健康检查(体温、血压、饮酒)',
                text5: '<span style="margin-right: 5px;">✓</span>进行司机职业病及MMPI(明尼苏达多项人格测验)检查',
                text6: '<span style="margin-right: 5px;">✓</span>检查车辆及每月进行2次安全Patrol进行',
                text7: '<span style="margin-right: 5px;">✓</span>每年进行2次消防安全紧急演练',
            },
            s4: {
                text: '<span style="margin-right: 5px;">✓</span>中国全境拥有自己的车辆,可以及时投入',
                text1: '<span style="margin-right: 5px;">✓</span>新购买符合客户要求的车辆并快速投入(Customize)',
                text2: '<span style="margin-right: 5px;">✓</span>24小时Gps管理,快速准确定位,与客户共享',
                text3: '<span style="margin-right: 5px;">✓</span>快速船舶Booking',
                text4: '<span style="margin-right: 5px;">✓</span>快速通关和交货',
            },
            s5: {
                text: '<span style="margin-right: 5px;">✓</span>大家喊”NO“的时候,大信物流却喊”YES“',
                text1: '<span style="margin-right: 5px;">✓</span>让不可能成为可能',
                text2: '<span style="margin-right: 5px;">✓</span>全体员工的工作思想”细节决定成败“',
            },
        }],
        image1: require('@/assets/image/yuanjing1.jpg'),
        image2: require('@/assets/image/chengxi1.jpg'),
        image3: require('@/assets/image/kehuo.png'),
        image4: require('@/assets/image/xiangqing2.jpg'),
        image6: require('@/assets/image/中国.jpg'),
        image7: require('@/assets/image/美国.jpg'),
        image8: require('@/assets/image/韩国.jpg'),
        image9: require('@/assets/image/日本.jpg'),
        image5: require('@/assets/image/货运.jpg'),
        image10: require('@/assets/image/sl5.jpg'),
        image11: require('@/assets/image/top1.jpg'),
        image12: require('@/assets/image/top3.jpg'),
        image13: require('@/assets/image/top2.jpg'),
        'title6': '“诚信经营”：“诚”真诚也，“信”信用也，两者结合起来就是诚实守信，遵守信用，是企业发展的根本。',
        'operate-text1': '企业的使命包括：对员工、对股东、对客户、对社会以及对环境保护等的承诺。',
        'operate-text2': '愿景是企业未来的发展方向和未来的景象。',
        'operate-text3': '企业只有对员工进行承诺，并实现员工的价值，员工才会有忠诚，才会真正的为企业的长期发展着想。',
        'operate-text4': '企业只有不断的增加股东的价值，股东才会对公司充满信心，才会不断的进行投资，支持企业的发展。',
        'operate-text5': '企业只有不断的提供给客户以增值的机会，才能够培育客户的忠诚度，才能够不断的吸引新的客户，同时给企业带来增加收益的机会。',
        'operate-text6': '企业只有不断的为社会创造，关心社会的发展，关心环境保护，社会和政府才能够给企业以更多的回馈，为企业创造更良好的发展环境。',
        'operate-text7': '只有这样企业对未来的美好的预期才可能实现，企业发展成长的使命愿景要求企业一定要诚信经营，才可能实现企业的长远发展。',
    },
    outlets: {
        datalist: [{
            'wedic': '国际海运进出口',
        }, {
            'wedic': '国际国内空运',
        }, {
            'wedic': '国内道路运输',
        }, {
            'wedic': '仓储物流',
        }, {
            'wedic': '项目物流',
        }, {
            'wedic': '危险品运输',
        }, {
            'wedic': '查看详情',
        }],
        mari: [{
            card: [{
                text1: '海上运输',
                text2: '高素质的专业队伍和完善的全球网络服务，遍布世界的代理网络',
                text3: '全面的提供清关、派送到门服务，从物流方案设计到货物跟踪，满足各种进出口货物的托运要求',
                text4: '承接散杂货物和大宗货物的租船业务，提供班轮和非班轮国际船舶代理服务',
                text5: '承接项目货、工程货、特种货等多元化业务，为客户提供专业的供应链管理服务及其他的全方位优质服务',
                text6: '与多家船公司建立长期合作伙伴关系，可以提供非常优惠的海运价格',
                url: '@/assets/image/hiashangyshu1.jpg'
            }, {
                title1: '根据货物特点，提供优秀的港口装卸与运输服务。',
                title2: '为顾客设计更优化、更快捷的运输方案，减少转机环节。',
                title3: '拥有380辆运输车辆,韩国的物流企业。',
                title4: '大面积的综合仓库，提供优化的运营支援和物流服务。',
                title5: '多元化的国际贸易需要更加全面且专业的物流服务。',
                title6: '超过17年危险品运输经验,一家韩国的物流企业。',
            }],
            text: [{
                title: '服务介绍',
                text: [{
                    url: '@/assets/image/hiashangyshu2.jpg',
                    titlex: '集装箱服务（Container Service）',
                    textp: '速度更快、价格更低，包装、运输、卸货、保管等海上运输过程中，进行系统的管理。',
                }, {
                    url: '@/assets/image/hiashangyshu3.jpg',
                    titlex: '散货服务（Bulk Service）',
                    textp: '以优惠运价、优质服务及较少装卸次数赢得更多的海运货量，从而获取更多价值。委托可靠服务与集中托运业务满足客户的运输要求。',

                }, {
                    url: '@/assets/image/hiashangyshu4.jpg',
                    titlex: '服务优势（Service Advantage）',
                    textp: '大信物流与世界优秀船公司有紧密的合作关系，利用各船务公司的专业化服务，提供更安全、更迅速的客户定制型运输服务。',
                    textp2: '同各大船公司缔结战略性优待服务合同（Service Contract），在节约物流费用方面也占据强大优势。',
                    textp3: '确保海内外多元化船务公司渠道和多数货主公司网点，实现以大货量为基础的运费竞争力，向客户提供实质性的价值。'
                }]
            }]
        }],
        avintion: [{
            card: [{
                text1: '航空运输',
                text2: '专业从事国内航空货物运输及航空快递、时效急件、航空行李托运、港到港、门到门服务',
                text3: '联合各大航空公司的优势，服务网络遍及全国各大中小城市',
                text4: '航空货物运输业务齐全，承接各大航空公司空运货物进出港业务',
                text5: '与多家航空货运公司合作组建航空运输网络，为客户提供优质、满意的全方位的航空运输服务',
                text6: '凭借合理的价格，诚信的服务，良好的信誉，满足客户不同层次的各种需求',
                url: '@/assets/image/air-1.jpg'
            }],
            text: [{
                title: '服务介绍',
                text: [{
                    url: '@/assets/image/air-2.jpg',
                    titlex: '一站式运输服务（One-Stop Transporting Logistics Service）',
                    textp: '与优秀航空公司缔结战略性合作关系，确保令客户满意的合理运费和全年稳定的货物运输速度。自己的运输车辆，确保提高航空运输服务的便捷。',
                }, {
                    url: '@/assets/image/air-3.jpg',
                    titlex: '续运服务（Continued Transport Service）',
                    textp: '续运是在航空运输过程中，将已经卸货的货物重新装上飞机，运输至目的地或需要地点的服务。在续运服务中，广泛的中转网点和丰富的转机经验，决定着服务的质量。',

                }, {
                    url: '@/assets/image/air-4.jpg',
                    titlex: '海空联运服务（Joint Services Of Sea And Air）',
                    textp: '海空联运服务是结合海上运输的费用优廉和航空运输的迅速性等两种运输手段优点的服务。从韩国到中国沿海港口采用海运后、到内陆地区采用空运；或从韩国到中国北方',
                    textp2: '港口采用空运后、到南方港口采用海运等。与仅采用海上运输相比，缩短了运输时间，与仅采用航空运输相比，节约了运费。 ',
                }, {
                    titlex: '服务优势（Service Advantage）',
                    textp: '大信物流提供航空出口、航空进口、国际特快专递、海空联运（Seaand and Air）复合运输、通关、报关等优质的定制型物流服务。',
                    textp2: ' 作为综合物流企业，拥有丰富的信息、知识和经验，可以向客户实时提供航空货物信息，在飞机出发前确认货物运输信息。',
                    textp3: '综合考虑全航空条件、航空运费、运输时间和距离、航空公司航运路线、转运（Transshipment）花费的时间等，向客户提供适合的续运服务。',
                    textp4: '全球各地拥有海外代理，即使在急剧变化的运输环境中，也可以迅速地收集出发地、经由地、抵达地的信息并应对，提供安全值得信赖的海空联运服务。'
                }]
            }]
        }],
        landlocked: [{
            card: [{
                text1: '内陆运输',
                text2: '大信物流凭借中国地区广泛围的网点，提供各种公路运输服务。',
                text3: '凭借以多年积累的经验，拥有全方位的服务和有竞争力的价格，提供更高层次的服务。',
                url: '@/assets/image/land-1.jpg'
            }],
            text: [{
                title: '服务介绍',
                text: [{
                    url: '@/assets/image/land-2.jpg',
                    img: '@/assets/image/land-3.jpg',
                    titlex: '内陆公路运输',
                    textp: '独自拥有超过300辆的运输汽车，车型包含有箱式车、槽罐车、展翼车、冷藏车、冷冻车。',
                    textp2: '中国大陆运输企业中的韩国物流公司，积累有丰富的第三方物流服务经验，培养了大批专业的人才，拥有一支高效的管理团队。'
                }, {
                    url: '@/assets/image/land-4.jpg',
                    titlex: '服务优势（Service Advantage）',
                    textp: ' 车辆实时的监管，遍布中国的网络',
                }]
            }]
        }],
        warehousing: [{
            card: [{
                text1: '仓储物流',
                text2: '总面积超过77,500㎡，拥有10,000㎡的货物装卸站台。',
                text3: '仓库配备有宽阔的车道、便利的卸货平台、邻接库房的办公室。',
                text4: '24小时的监控和巡逻、高级的消防设施，确保货物的安全存放。',
                text5: '提供货物储存、分拣、重新包装、丈量尺码、称重、条形码扫描、贴标签、打托、缠膜、等综合物流操作服务。',
                url: '@/assets/image/warehouse-3 (2).jpg'
            }],
            text: [{
                title: '服务介绍',
                text: [{
                    url: '@/assets/image/warehouse-3 (3).jpg',
                    titlex: '仓储分布',
                    textp: '物流仓库遍布北京顺义、北京通州、天津普洛斯物流园、重庆两江新区工业开发区、沧州经济开发区、威海临港经济技术开发区、苏州等地区。',
                }, {
                    titlex: '服务优势（Service Advantage）',
                    url: '@/assets/image/warehouse-3 (1).jpg',
                    img: '@/assets/image/warehouse-4.jpg',
                    textp: ' 大信物流通过坚持不懈的挑战实现更高的客户满意度，以尖端设施、完善的设备、尖端IT系统等为基础，为客户的货物保管提供更优化方案。 ',
                    textp2: ' 高素质的人员、稳定的团队、完善的人员保护措施和设备，体现了企业以人为本、长久发展的理念。',
                }]
            }]
        }],
        project: [{
            card: [{
                text1: '项目物流',
                text2: '多元化的国际贸易需要更加全面且专业的物流服务。',
                text3: '大信物流凭借雄厚的实力和强大的代理网络，提供专业的多种国际多式联运服务。',
                text4: '为客户设计更佳联运路线，节省运输费用与时间',
                text5: '为客户提供“门到门”、“门到港”、“场、站到门”等多种联运服务；签发提单或多式联运提单。',
                text6: '客户只要一次委托，货物便可一票直达全程。',
                url: '@/assets/image/Project-1.jpg'
            }],
            text: [{
                title: '服务介绍',
                text: [{
                    url: '@/assets/image/Project-2.jpg',
                    textp: '项目物流服务（Project Cargo Service）',
                }, {
                    titlex: '服务优势（Service Advantage）',
                    url: '@/assets/image/Project-3.jpg',
                    textp: '选择项目运输中的各种机械、设备、材料等，需要采用的运输技术',
                    textp2: '根据货物类型，选择更好的船舶舱位和飞机型号，保证按时/安全地运输工具',
                    textp3: '事前环境调查、勘探路线、障碍物调查、运输模拟、安排特殊车辆等涉及到内陆运输的情况',
                    textp4: '过事先调查目的地国家的通关、法律和惯例，提出安全的运输方案',
                    textp5: '执行本地免税和减税通关业务和提供L/C、C/O、大使馆认证等贸易/物流服务统筹咨询服务',
                    textp6: '通过海外代理，从各国发货时起至货物目的地现场到达地（Job Site）为止，提供从运输计划到后续管理的系统化综合物流服务',
                }]
            }]
        }],
        international: [{
            card: [{
                text1: '危险货物运输',
                text2: '凡具有腐蚀性、自然性、易燃性、毒害性、爆炸性等性质，',
                text3: '在运输、装卸和储存保管过程中容易造成人身伤亡和财产损毁而需要特别防护的物品，均属危险品。',
                text4: '危险品运输是特种运输的一种，专门组织或技术人员对非常规物品使用特殊车辆进行的运输。',
                text5: '经过国家相关职能部门严格审核，拥有能保证安全运输危险货物的相应设施设备，才能有资格进行危险品运输。',
                url: '@/assets/image/weixianxiao.jpg"'
            }],
            text: [{
                title: '服务介绍',
                text: [{
                    url: '@/assets/image/weixianpintext1.jpg',
                    titlex: '危险品公路运输',
                    textp: ' 专业的危险品运输团队进行内陆运输及进出口危险品业务，及时准确的信息反馈，跟踪运输，全程监控，随时将运输情况准确反馈给每一位客户，保证客户零风险及货物安全到达目的地。',
                }, {
                    url: '@/assets/image/weixianpintext2.jpg',
                    img: '@/assets/image/weixianpintext3.jpg',
                    titlex: '服务优势（Service Advantage）',
                    textp: '齐全的保护装备，熟练的操作工人，可以按照客户需要，添加各种药剂。',
                    textp2: '精细的线路管理、可靠的流程设计、网络化的应急机制、多样化的通讯手段、客服呼叫中心的集中跟踪，保证了货物安全、及时、准确地送达。'
                }]
            }]
        }],
        imaget: require('@/assets/image/warehouse-3 (3).jpg'),
        imaget1: require('@/assets/image/warehouse-3 (1).jpg'),
    },
    advocacy: {
        title: [{
            'title1': '新闻公告',
            'title2': '行业知识',
        }],
        box: [{
            title: '2022年物流行业发展趋势与工业物流崛起',
            url: '@/assets/image/宣传中心1.webp',
            text: '在过去两年中、物流行业呈加速发展态势。2022年、这一趋势将随新技术的应用而进一步发展、并将在操作流程、运输、仓储、安全性',
            time: '2022-10-10'
        }, {
            title: '2022年世界国际海运公司',
            url: '@/assets/image/宣传中心2.webp',
            text: '据Alphainer最新运力数据显示,截至2022年7月10日,全球班轮公司运力前10名与上月比保持不变,目前全球在运营集装箱船总数为6406艘,总运力增加到2579.3291万TEU',
            time: '2022-09-13'
        }, {
            title: '物流机械设备的发展趋势',
            url: '@/assets/image/宣传中心3.webp',
            text: '随着生产和物流规模的扩大,以及物流自动化程度的提高,物流机械设备在现代化生产和物流中应用越来越广,作用也越来越大;而现代化生产和现代化物流又...',
            time: '2019-03-12'
        }, {
            title: '物流快递行业年度期盼的新机遇',
            url: '@/assets/image/宣传中心4.webp',
            text: '26日,京广高铁正式开通运营。羊城晚报记者获悉,武广高铁运营后已有广东快递企业试水广州长沙间的...',
            time: '2019-03-12'
        }, {
            title: '进军国际市场开拓物流发展新机遇',
            url: '@/assets/image/宣传中心5.webp',
            text: '5月11日,全国首列“南亚国际货运列车”在兰启程,10天后,这列满载着“中国制造”的货运...',
            time: '2019-03-12'
        }, {
            title: '物流从业必备知识：货车分类',
            url: '@/assets/image/货车分类.jpg',
            text: '目前从交通运输行业统计来讲，吧运营货车分成四类，普通货车、专用货车、牵引车和挂车，2020年共有营运载货汽车1110.28万辆，其普通货车414.14万辆，专用货车50.67万辆，牵引车310.8万辆',
            time: '2022-10-10'
        }, {
            title: '易燃易爆危险品存放原则',
            url: '@/assets/image/危险品储存.jpg',
            text: '易燃易爆化学危险品的整装存储，往往存放的品种多，性质危险而复杂，比较难于管理。而散装存储量比较大，设备多，技术条件复杂，一旦发生事故难以施救。',
            time: '2022-09-13'
        }, {
            title: ' 集装箱知识',
            url: '@/assets/image/集装箱种类.jpg',
            text: '集装箱运输是国际贸易货物多试联运过程中的重要运输方式，实现全球范围内的船舶、港口、航线、公路、中转站、桥梁、隧道多式联运相配套的物流系统。',
            time: '2022-09-13'
        }, {
            title: ' 危险品分类',
            url: '@/assets/image/危险品种类.jpg',
            text: '凡具有爆炸、易燃、毒害、腐蚀、放射性等物质、在运输、装卸和贮存保管过程中，容易造成人事伤亡和财产损毁而需要特别防护的货物，均属危险品货物。',
            time: '2019-03-12'
        }, ],
        'title1': '新闻公告',
        'title2': '行业知识',
        'title3': '2022年物流行业发展趋势与工业物流崛起',
        'title4': '2022年世界国际海运公司',
        'title5': '物流机械设备的发展趋势',
        'title6': '物流快递行业年度期盼的新机遇',
        'title7': '进军国际市场开拓物流发展新机遇',
        'title8': '物流从业必备知识：货车分类',
        'title9': '易燃易爆危险品存放原则',
        'title10': '集装箱知识',
        'title11': '危险品分类',
        'title12': '新闻公告',
        'title13': '行业知识',
        'title14': '海运公司排行前十：',
        'textp1': '在过去两年中、物流行业呈加速发展态势。2022年、这一趋势将随新技术的应用而进一步发展、并将在操作流程、运输、仓储、安全性',
        'textp2': '据Alphainer最新运力数据显示,截至2022年7月10日,全球班轮公司运力前10名与上月比保持不变,目前全球在运营集装箱船总数为6406艘,总运力增加到2579.3291万TEU',
        'textp3': '随着生产和物流规模的扩大,以及物流自动化程度的提高,物流机械设备在现代化生产和物流中应用越来越广,作用也越来越大;而现代化生产和现代化物流又...',
        'textp4': '26日,京广高铁正式开通运营。羊城晚报记者获悉,武广高铁运营后已有广东快递企业试水广州长沙间的...',
        'textp5': '5月11日,全国首列“南亚国际货运列车”在兰启程,10天后,这列满载着“中国制造”的货运...',
        'textp6': '目前从交通运输行业统计来讲，吧运营货车分成四类，普通货车、专用货车、牵引车和挂车，2020年共有营运载货汽车1110.28万辆，其普通货车414.14万辆，专用货车50.67万辆，牵引车310.8万辆',
        'textp7': '易燃易爆化学危险品的整装存储，往往存放的品种多，性质危险而复杂，比较难于管理。而散装存储量比较大，设备多，技术条件复杂，一旦发生事故难以施救。',
        'textp8': '集装箱运输是国际贸易货物多试联运过程中的重要运输方式，实现全球范围内的船舶、港口、航线、公路、中转站、桥梁、隧道多式联运相配套的物流系统。',
        'textp9': '凡具有爆炸、易燃、毒害、腐蚀、放射性等物质、在运输、装卸和贮存保管过程中，容易造成人事伤亡和财产损毁而需要特别防护的货物，均属危险品货物。',
        'time1': '2022-10-10',
        'time2': '2022-09-13',
        'time3': '2019-03-12',
        'time4': '2019-03-12',
        'time5': '2019-03-12',
        'time6': '2022-10-10',
        'time7': '2022-09-13',
        'time8': '2022-09-13',
        'time9': '2019-03-12',
        'textp1': '在过去两年中，物流行业呈加速发展态势。2022年，这一趋势将随这新技术的应用而进一步发展，并将在操作流程、运输、仓储、安全性，以及供应链稳定性等诸多方面产生持续而广泛的影响。数字化和可持续性将深刻改变物流行业的工作方式。',
        'textp2': '物流4.0开启物流数字化时代新篇章',
        'textp3': '一段时间以来，我们越来越多地谈到物流4.0，这一概念源自工业4.0。数字化将成为新时代物流发展的根基，更准确地说，将基于流程自动化、设备网络化和企业间的联合。物流4.0旨在简化流程、提升效率，同时维护全球供应链的稳定，尤其是在充满挑战的时期，及时发现并消除供应链风险至关重要。',
        'textp4': '与物流4.0息息相关的大数据、机器使用和自动化，以及网络化将对物流的未来发展产生重要影响。数字化在道路交通中也将发挥巨大作用。一方面，自动驾驶技术的发展将有助于提高道路安全，另一方便也有助于缓解交通拥堵。',
        'textp5': '物流自动化的大数据和网络',
        'textp6': '数据是物流的关键组成部分，随着数据量的不断提升，其作用愈加重要。智能设备的使用使越来越多的数据可以被收集和交换。物流企业可根据不同的数据流进行分析、预测或判断发展趋势。',
        'textp7': '有效部署大数据和市场参与者之间的智能网络，有利于维护整个供应链的稳定，预测及避免潜在“断链”风险，并及时制定替代计划，以保证物流时效。同时，大数据也可用于优化运输路径，提升效率。此外，网络化不仅有助于优化风险管理，还能够提高供应链的透明度，帮助相关企业了解运输过程中的具体信息。',
        'textp8': '机器人化加速物流行业发展',
        'textp9': '在很多科幻电影中都出现了人类与机器人协同的场景。虽然目前机器人还没有那么全能，但已经可以执行简单的任务，并在工作中发挥作用。机器人被广泛试运行使用，特别是在电子商务和消费品仓储领域，主要用来减少人工工作量。机器人可为新订单拣选货物，并在库区内运输重货，通过预先计算规划最优路径，高效把货物送到交接点，再由另外的机器人继续完成后面的流程。传感器的使用可有效避免机器人在行进过程中的碰撞。',
        'textp10': '得益于电子商务的蓬勃发展，以及由此带来物流需求的快速增长，更多机器人将会投入试运营，其数字化能力也将得到进一步提升。',
        'textp11': '多式联运物流运输将成主流‍',
        'textp12': '为了防范和避免供应链中断的风险，多样化的物流运输方式可有效防范、降低供应链中断的风险。去年苏伊士运河堵塞给全球海运带来很大影响，凸显出单一运输方式的局限性。多式联运覆盖路运、汽运、海运和空运，一旦一种运输方式出现问题，可迅速切换至另一种方式，从而有效避免运输延误，维持供应链稳定。多元化物流渠道是实现多式联运的先决条件。',
        'textp13': '可持续物流进一步发展',
        'textp14': '可持续发展是未来几年乃至几十年的大趋势。物流行业将为温室气体减排做出重大贡献。据世界经济论坛研究统计，物流运输占全球碳排放总量的5.5%。因此，为未来的物流发展提供环保解决方案势在必行。',
        'textp15': '过去，速度和成本是选择运输方式的决定性因素。如今，可持续性变得越来越重要。除了车辆电气化，清洁燃料的使用和相关技术的研发对于减少二氧化碳和其他排放至关重要。此外，仓储物流也是物流可持续发展的重要领域，如包材的选择，以及物流公司如何获取能源途径等方面。',
        'textp16': '碳排放报告也是进一步减排的有效工具，未来也将更加普及。报告可提供供应链透明度，供企业深入了解其二氧化碳和污染物的排放情况。此外，可持续发展也将成为企业形象和竞争力的重要组成部分，促使企业设法减少排放量，将对环境的影响降到最低。',
        'textp17': '总结与展望',
        'textp18': '今年，数字化将在物流行业进一步发力，将有更多技术创新应用于简化流程，提高全球供应链稳定性。在可持续发展的大趋势下，整个物流行业将在2022年开启发展新格局，在注重环保的同时，保持盈利能力。我们已处于数字化和气候变化的大变局之中，及早适应并积极引入新技术，顺势而为的市场参与者将乘势而上。',
        'li-p1': '1、马士基航运有限公司（全球最大的航运公司，总部设于丹麦歌本哈根）',
        'li-p2': '2、地中海航运公司（世界第二大航运公司）',
        'li-p3': '3、法国达飞海运集团（世界10大航运公司第三名）',
        'li-p4': '4、中国远洋运输（集团）公司',
        'li-p5': '5、赫伯罗特船舶公司',
        'li-p6': '6、中国长荣海运',
        'li-p7': '7、中国香港东方海外货柜航运公司',
        'li-p8': '8、中国台湾阳明海运集团',
        'li-p9': '9、日本商船三井MOL',
        'li-p10': '10、日本邮船株式会社（NYK）',
        'h3-p1': '第十名、日本邮船株式会社（NYK）',
        'h3-p2': '第九名、日本商船三井MOL',
        'h3-p3': '第八名、中国台湾阳明海运集团',
        'h3-p4': '第七名、中国香港东方海外货柜航运公司',
        'h3-p5': '第六名、中国长荣海运',
        'h3-p6': '第五名、赫伯罗特船舶公司',
        'h3-p7': '第四名、中国远洋运输（集团）公司',
        'h3-p8': '第三名、法国达飞海运集团',
        'h3-p9': '第二名、地中海航运公司',
        'h3-p10': '第一名、马士基航运有限公司',
        'p-text1': '日本三大船务公司之一，也是世界上历史最悠久和规模最大的航运公司之一，由日本在早期的三菱财阀所创立。',
        'p-text2': '该公司总部位于日本千代地区，拥有大约800艘船只，包含集装箱船、邮轮、游轮等等。',
        'p-text3': '世界10大航运公司中第二个日本船运公司，是日本经连的一部分，它们家的鳄鱼标志可以在世界各地大港口的集装箱上看到',
        'p-text4': '位于中国台湾基隆的元阳运输公司，成立于1972年，如果追溯历史还可以清朝期间。',
        'p-text5': '该公司拥有85艘船舶，拥有420万载重吨位34.6万标准箱吞吐量，该公司服务于全球70多个国家',
        'p-text6': '世界最大的综合国际集装箱运输公司之一，在全球70多个国家设立有320个办事处。该公司拥有57艘不同级别的船只，包括极端天气下的冰级船。',
        'p-text7': '是一家集装箱运输公司，总部设立于中国台湾桃园市，其主要线路为远东至北美洲、中美洲以及加勒比地区。该公司在全球80个国家拥有240个港口。',
        'p-text8': '德国跨国船运公司，成立与1970年，由德国两家运输公司合并而成，拥有175艘集装箱船',
        'p-text9': '中国最大的航运企业，截至2011年就覆盖了全球160多个国家1600多个港口。截止2016年总船队规模稳居全球第一',
        'p-text10': '成立于1978年，总部位于马赛，是法国第一，世界10大航运公司第三名。该公司在全球150多个国家420个港口拥有200多条航线',
        'p-text11': '成立于1970年的瑞士航运公司，是世界第二大航运公司，在全球拥有350个机构，员工超过2.8万人，拥有471艘集装箱船。',
        'p-text12': '丹麦最大的航运商业集团，也是世界最大的航运公司，总部设立于丹麦歌本哈根，在全球130个国家设有办事处，员工超过8',
        'infor1': '　　随着生产和物流规模的扩大，以及物流自动化程度的提高，物流机械设备在现代化生产和物流中应用越来越广，作用也越来越大',
        'infor2': '纵观物流机械设备发展现状可以看出，为适应现代物流的需要，物流机械设备有如下发展趋势',
        'infor3': '1.大型化和高速化',
        'infor4': '　　大型化是指设备的容量、规模、能力越来越大，高速化是指设备的运转速度、运行速度、识别速度、运算速度大大加快。现代社会经济快速发展，使得生产和物流规模不断扩大，为了提高作业效率和规模效益，大型、高速的物流机械需求量不断增长。物流机械设备的起重量、载重量、生产率、作业能力越来越大，工作速度越来越快',
        'infor5': '2.实用化和轻型化',
        'infor6': '　　物流机械设备是现代化、自动化物流的重要物质技术基础。物流机械设备要求使用方便，容易维护、操作，具有优异的耐久性、无故障性和良好的经济性，以及较高的安全性、可靠性。因此，今后会更加注重开发使用性能好、成本低、可靠性高的物流机械设备。',
        'infor7': '3.专用化和通用化',
        'infor8': '　　物流是社会经济发展的产物，必然随着社会经济的发展而呈现多样化的特征。多样化的特征反映了对物流机械设备需求的多样化，从而使物流机械设备具有多种多样的品种且',
        'infor9': '4.自动化和智能化',
        'infor10': '　　将机械技术和电子技术相结合，将先进的微电子技术、电力电子技术、光缆技术、液压技术、模糊控制技术应用到机械的驱动和控制系统中，实现物流机械设备的自动化和智能化将是今后的发展方向。例如，大型起重机的新一代电气控制装置已发展为全电子数字化控制系统，由全数字化控制驱动装置、可编程序控制器、故障诊断及数据管理系统、数字化操纵给定检测等装置组成。先进技术的应用使起重机具有更高的柔性，从而提高了单机综合自动化水平。运输设备的操作更多的转向依靠仪表、信号和辅助驾驶系统智能化程度越来越高。海运、空运中的自动驾驶系统、自动化立体仓库中的送取货小车、公路运输智能交通系系统',
        'infor11': '　　智能式搬运车AHV( AutonomousHandlingVehicle)也将会得到广泛应用。AHV的形状类似于现在使用的AGV，但装有两只通用的机械手，在工作时依靠起视觉作用的工业摄像机，对货物的位置和大小进行判断，如同人一样可以用机械手自由地搬运重达200--300千克的货物。AHV具有协同作业的功能，搬运的货物过长、过重时，可以由两台以上的ARV协同作业进行搬运，这样会大大减少ARV的规格型号，便于管理。数台同一规格AHV的联合运作，其作业能力可提高很多。',
        'infor12': '5.成套化和系统化',
        'infor13': '　　在物流机械设备单机自动化的基础上，通过计算机把各种物流机械设备组成一个物流机械设备集成系统，通过控制室的控制，与物流系统协调配合。这类物流机械设备自动化程度较高，具有信息处理功能，可将传感器检测出来的各种信息实施存储、运算、逻辑判断、变换等处理加工，进而向执行机构发出控制指令。这类物流机械设备还具有较好的信息输人输出接口，实现信息全部、准确、可靠地在整个物流机械集成系统中的传输。物流机械设备通过系统集成，能形成不同机种的优先匹配和组合，取长补短，发挥出优先功效。以后将发展的有工厂生产搬运自动化系统、货物配送集散系统、集装箱装卸搬运系统、货物的自动分拣与搬运系统等。',
        'infoe-text1': '26日，京广高铁正式开通运营。羊城晚报记者获悉，武广高铁运营后已有广东快递企业试水广州长沙间的高铁快件运输，如今京广高铁的开通更将给我国物流、快递业带来新机遇。',
        'infoe-text2': '“此前武广高铁开通，广州长沙间就有高铁快件运输。”广州一家知名民营快递企业负责人告诉记者，目前快递走公路运输的占了80%左右，走航空的约占15%。其实无论从时效和成本来看，高铁比公路都有优势。京广高铁开通运营后，中间很多节点城市都是快递服务需求旺盛的地区，空间很大。',
        'infoe-text3': '据了解，12月中旬，国家邮政局、国家发改委综合运输研究所、中国快递协会就组成联合调研组，赴广东省对快递企业利用高铁运输快件等开展实地调研。',
        'infoe-text4': '除了快递业，大宗商品运输等传统物流业预计也将从京广高铁开通、京广线货运能力提升中受益。数据显示，仅武广高铁开通后，京广线货运能力就提升了10.9%，使长期运能受限制的煤炭、石油、粮食等物资及港澳外贸货运的运输需求得到改善。',
        'infoe-text5': '5月11日，全国首列“南亚国际货运列车”在兰启程，10天后，这列满载着“中国制造”的货运列车，经过铁路、公路运输，将到达尼泊尔首都加德满都。这标志着兰州在“一带一路”建设中，在开启“兰州号”中亚、欧洲货运专列之后，又一次迈出了向西向南阿开放的重要一步',
        'infoe-text6': ' 　　“一带一路”重大战略构想提出两年多来，沿线国家纷纷响应。去年，中国跟30多个国家签订了共建“一带一路”谅解备忘录，一些主要的“一带一路”骨架已经开始搭建，比如中蒙俄经济走廊、中国—中南半岛的经济走廊、新亚欧大陆桥经济走廊、中国—中亚—西亚走廊、中巴经济走廊、孟中印缅经济走廊等，有的已取得喜人成果。先就中欧来说，以往，中欧之间的货运主要靠空运、海运以及公路运输，空运和公路运输成本高，海运耗时长。中欧班列以其运距短、速度快、安全性高的特征，以及安全快捷、绿色环保、受自然环境影响小的优势，已成为国际物流中陆路运输的骨干方式。',
        'infoe-text7': ' 　　国际货运的前景十分广阔，具有极高的性价比。众所周知，过去我们面向南亚和东南亚的物流贸易主要通过海运完成，此次开通的铁路货运专列可谓开拓了陆路运输的途径，将充分发挥铁路运输的优势。可以想象，今后由铁路带动各个国家和城市之间的经济贸易、货物往来、人员流动、文化交流与技术支持，必将强化各国间的“城市”效应，“一带一路”的发展格局也必将在互帮、互助、互进中建立起世界各国共同发展的合作体系。随着科技的互补、资源的互助，铁路也会在发展中迈开步伐，终铺就一条世界铁路运输线路图。 ',
        'infoe-text8': ' 　　现在“一带一路”已成为一种趋势，每一条国际铁路线的开通都对沿线国家与地区带来新一轮的发展机遇，各产业应以全新的发展思路来迎接这一崭新的时代，携手创造共赢。随着和南亚铁路携手合作，我们的铁路也在不断“升级”提速，我们相信，“南亚国际货运列车”必将成为“一带一路”大战略上国际货运的“明星产品”，也将拉动共同的经济发展从而惠及人民。',
        'textd-p1': '目前从交通运输行业统计来讲，把营运货车分成四类，普通货车、专用货车、牵引车和挂车，2020年共有营运载货汽车1110.28万辆，其中普通货车414.14万辆，专用货车50.67万辆，牵引车310.84万辆，挂车334.63万辆。其中的专用货车，包括了集装箱车辆、冷藏车辆、罐式车辆以及大件和危货运输车。',
        'textd-p2': '就一般货车而言，可以从载重、厢体结构、驾驶室、驱动方式、车长、用能结构、排放标准等去进行划分。专用车辆也可以放到厢体结构中，属于特殊厢体结构。挂车本身也是一个系列，根据《货运挂车系列型谱》（GB/T 6420-2017），挂车包括了栏板式挂车、仓栅式挂车、厢式、低平板式、车辆运输车挂车（又分为中置轴和半挂）、液体运输车、颗粒物料运输半挂车、集装箱半挂车、冷藏和保温运输半挂车。与一般货车按照厢体结构分类基本相同。因此，我们可以从载重、厢体结构、驾驶室形态、车辆驱动轴、车长、用能结构以及车辆排放7个维度，对货车类型进行细分',
        'textd-p3': '一、按载重分',
        'textd-p4': '目前常用的，一般分为微卡、轻卡、中卡、重卡四种：',
        'textd-p5': '微卡：总质量＜1.8吨。',
        'textd-p6': '轻卡：1.8吨＜总质量≤6吨。',
        'textd-p7': '中卡：6吨＜总质量≤14吨 。',
        'textd-p8': '重卡：总质量＞14吨。',
        'textd-p9': '2019年轻型民用载货汽车拥有量为1901万辆，占民用载货汽车的68.3%；重型民用载货汽车拥有量为762万辆，占民用载货汽车的27.37%；中型民用载货汽车拥有量为116万辆，占民用载货汽车的4.18%；微型民用载货汽车拥有量为4万辆，占民用载货汽车的0.15%。以轻、重型货车为主。',
        'textd-p10': '对于车长小于 6000mm 且总质量小于 4500kg 的轻型载货汽车，上蓝牌，允许在城市内行驶，就是我们常听到的蓝牌轻卡。根据新的《收费公路车辆通行费车型分类》，4.2米蓝牌轻卡成为1、2类车型的分界标，降低了蓝牌轻卡的收费。',
        'textd-p11': '二、按车厢结构分',
        'textd-p12': '由于运输货物的特性不同，货车（挂车）车箱厢体差异较大，一般来讲，可以分为仓栅式、栏板式、（低）平板式、自卸车、厢式、集装箱车、罐式以及中置轴。',
        'textd-p13': '其中，平板式包括普通平板和低平板专用车。普通平板半挂车，车长最长13米。新出台的《低平板半挂车技术规范》（JT/T 1264-2020），明确无论是低平板专用半挂车还是低平板增强半挂车，车长都不能超过13.75米。',
        'textd-p14': '低平板车也是轿运车的主要车型。2018年7月1日起开始只允许以下三种车运输商品车：平头铰接列车车长不超过17.1m、长头不超过18.1米，车宽不超过2.55m，载货后车高不超过4.2m；中置轴整车车长不超22米，车宽不超过2.55m，载货后车高不超过4.2m。',
        'textd-p15': '三种车型的转载情况如下图所示：',
        'textd-p16': '中置轴列车',
        'textd-p17': '此外，对于17.5米大板车，发改委、工信部曾于2007年12月21日发布公告，对17.5米长低平板半挂车等车型予以撤销，并自2007年12月31日起不再作为办理车辆注册登记的依据。而低平板半挂车的使用年限最长是15年，因此目前业内有了2022年17.5米大板将全面淘汰的说法。',
        'textd-p18': '中置轴属于挂车的一种特殊类型（挂车分为全挂、半挂、中置轴）。2016年颁布的《GT/T 1589-2016汽车、挂车及汽车列车外廓尺寸、轴荷及质量限值》标准中新增了中置轴货运列车的选项，中置轴货运列车的宽度为2.55米，最大长度达到了史无前例的22米，也就是前面看到的轿运车车型。部分品牌的中置轴挂车货箱容积可以达到130立方米，对于未来的快递甩挂运输绝对是一大优势。',
        'textd-p19': '三、按驾驶室分',
        'textd-p20': '可以分为长头、短头、平头和偏置四类。平头车发动机就在驾驶室下方，短头车发动机有一半在驾驶室下方，长头车发动机就位于驾驶室前方。',
        'textd-p21': '在我国，目前大家在路面上看到的绝大部分都是平头车，零星可能会看到几辆长头车。但是时间倒退几十年的话，结果是反着的。新中国生产的第一辆卡车，解放CA10就是长头车。随着汽车保有量的提升和提高道路安全的考虑，国家出台相关标准对汽车的外廓界限进行了明确要求，车头越长、货箱就越短，拉的货就越少，由此促使平头车开始盛行。',
        'textd-p22': '平头卡车',
        'textd-p23': '偏置式卡车',
        'textd-p24': '四、按驱动轴分',
        'textd-p25': '我们经常听见货车有4x2、6x2、6x4之类的说法，这就是按照驱动方式对卡车进行分类。前一个数字是车轮总数，后一个数字是驱动轮数。例如4x2，表示货车有4个车轮（两根轮轴即双桥），其中有2个驱动车轮（一根驱动轴即单桥）。同理，6*4的货车有3根轮轴，其中有2个是驱动轴。',
        'textd-p26': '我们常常听到前四后八、前四后四。所谓“前四后八”就是四轴车，前四是双桥4个轮，后八也是双桥，每个桥的左右各2个轮，每个桥4个轮，就是8个轮子驱动。“前四后四”是三轴车，前桥是双桥4轮，后面是单桥，每个桥的左右各2个轮，一共4轮。',
        'textd-p27': '五、按车厢长分',
        'textd-p28': '我们常听到的几款车型，如4米2、9米6、13米5等，都指的是车厢长度，不是《GT/T 1589-2016汽车、挂车及汽车列车外廓尺寸、轴荷及质量限值》标准中车长的概念。',
        'textd-p29': '常见几款车型规格及载重如下：',
        'textd-p30': '六、按用能及排放分',
        'textd-p31': '目前货车仍以柴油引擎作为动力来源，同时有部分轻型货车使用汽油、石油气、天然气以及电力等新能源。根据《中国机动车环境管理年报》，2017年货车保有量2341万辆，其中柴油车保有量1690.9万辆，占比72.2%。按排放标准阶段划分，国Ⅱ及之前排放标准的柴油货车35万辆，Ⅲ标准的柴油货车841.2万辆，国Ⅳ标准的柴油货车716.8万辆，国Ⅴ及以上排放标准的柴油货车97.9万辆。',
        'textd-p32': '2019年生态环境部等11部委联合印发《柴油货车污染治理攻坚战行动计划》，明确提出自2019年7月1日起，重点区域、珠三角地区、成渝地区提前实施机动车国六排放标准。2020年年底前，京津冀及周边地区、汾渭平原加快淘汰国三及以下排放标准营运柴油货车100万辆以上。各地也相继出台了国Ⅲ货车限行和淘汰补贴的相关政策，加速国Ⅲ货车淘汰。',
        'tretal1': '（八）腐蚀性物品',
        'tretal2': '腐蚀性物品，一般与其他种类的物品之间和腐蚀性物品中的有机与无机腐蚀品之间，酸性与碱性物品之间，可燃体固体之间，都应单独仓间存放，不可混储。',
        'tretal3': '1．腐蚀性物品之间',
        'tretal4': '（1）溴与硝酸、硫酸等混合，能加强其腐蚀性或燃烧，应隔离后存放。',
        'tretal5': '（2）过氧化氢易与硝酸起反应放出大量气体，遇三氯化磷等会起脱水作用，产生高温，甚至发生爆炸。',
        'tretal6': '2．腐蚀性物品与可燃液体之间',
        'tretal7': '有机酸性腐蚀品与乙类可燃液体之间要隔离后储存，有机碱性腐蚀品与可燃液体之间可同库储存，但堆垛须间隔2米以上。',
        'tretal8': '3．腐蚀性物品与可燃固体之间，无机碱性腐蚀品与乙类可燃固体之间可隔离存放。',
        'tretal9': '4．其他碱性腐蚀品均应单独仓间存放，不可混储。',
        'tretal10': '（七）毒害品',
        'tretal11': '1．无机毒害品与无机氧化剂之间和有机毒害品的固体与硝酸的有机衍生物之间应隔离存放',
        'tretal12': '2．无机毒害品与氧化（助燃）气体，应隔离存放，与不燃气体可同库存放；有机毒害品与不燃气体应隔离存放；',
        'tretal13': '3．液体的有机毒害品与可燃液体可隔离后存放；',
        'tretal14': '4．有机毒害品的固体与乙类燃烧固体可同储，但与甲类易燃固体应隔离后存放，无机毒害品与乙类易燃固体可隔离后存放。',
        'tretal15': '5．有机毒害品的固体与液体之间，以及与无机毒害品之间，均应隔离后存放；有机毒害品的液体与液体，固体与固体之间，无机的剧毒品与有毒品之间均可同库储存。。',
        'tretal16': '6．其他种类物品均不可同库存放',
        'tretal17': '（六）氧化剂和有机过氧化物',
        'tretal18': '1．氧化剂和有机过氧化物之间',
        'tretal19': '甲类无机氧化剂与有机氧化剂特别是有机过氧化物不能同库储存。',
        'tretal20': '2．氧化剂与压缩气体和液化气体',
        'tretal21': '甲类氧化剂与易燃或剧毒气体不可同库储存，因为甲类氧化剂的氧化能力强，与剧毒气体或易燃气体接触容易引起燃烧或钢瓶爆炸。',
        'tretal22': '乙类氧化剂与压缩和液化气体可隔离储存，即保持2米以上的间距，与惰性气体可同库存放。',
        'tretal23': '3．氧化剂与自燃、易燃，遇湿易燃物品，一般不同库储存。',
        'tretal24': '4．氧化剂与毒害品',
        'tretal25': '无机氧化剂与毒害品应隔离储存，有机氧化剂毒害品可以同库隔离储存，但与有可燃性的毒害品不可同库储存。',
        'tretal26': '5．氧化剂与腐蚀性物品',
        'tretal27': '有机过氧化物不得与溴和硫酸等氧化性腐蚀品同库储存。',
        'tretal28': '6．氧化剂（指无机氧化剂）不得与松软的粉状物同库储存。',
        'tretal29': '（五）遇湿易燃物品',
        'tretal30': '1．遇湿易燃品不得与自燃物品同库存放。因为自燃物品危险性大，见空气即着火，且黄磷、651除氧催化剂等，包装用水作稳定剂，一旦包装破损或渗透都有引起着火的危险。',
        'tretal31': '2．遇湿易燃物品与氧化剂不可同库存放。因为遇湿易燃物品是还原剂，遇氧化剂会剧烈反应，发生着火和爆炸。',
        'tretal32': '3．遇湿易燃物品与腐蚀性物品之间：因为溴、过氧化氢、硝酸、硫酸等强酸，都具有较强的氧化性，与遇水燃烧物品接触会立即着火或爆炸。且过氧化氢还含有水，也会引起着火爆炸，所以不得同库存放。与盐酸、甲酸、醋酸和含水碱性腐蚀品如液碱等，亦应隔离存放。',
        'tretal33': '4．遇湿易燃物品与含水的易燃液体和稳定剂是水的易燃液体，如己酸、二硫化碳等，均不得同库存放。',
        'tretal34': '5．遇湿易燃物品之间；活泼金属及其氢化物可同库存放；电石受潮后产生大量乙炔气，其包装易发生爆破，应单独存放。磷化钙、硫化钠、硅化镁等受潮后能产生大量易燃的毒气和易自燃的毒气，因此，亦应单独存放。',
        'tretal35': '（四）自燃物品',
        'tretal36': '1．甲类自燃物品，不得与爆炸品、氧化剂、氧化性气体（助燃）、易燃液体、易燃固体同库存放。',
        'tretal37': '2．黄磷、651除氧催化剂，不得与遇湿易燃物品同库存放。硼、锌、铝、锑、碳氢化合物类自燃物品与遇湿易燃物品可隔离储存。',
        'tretal38': '3．自燃物品之间：甲类自燃物品与乙类自燃物品与黄磷、651除氧催化剂不得同库放。',
        'tretal39': '4．腐蚀性物品，溴、硝酸、过氧化氢（40%的双氧水）具有较强的氧化性，自燃物品与之不可同库存放。与盐酸、甲酸、醋酸和碱性腐蚀品亦不准同库存放或隔离存放。',
        'tretal40': '（三）易燃固体',
        'tretal40': '1．与自燃物品：因为甲类自燃物品性质不稳定，可以自行氧化燃烧，会引起易燃固体的燃烧，所以不能同库储存。与乙类自燃物品亦应隔离储存。 ',
        'tretal42': '2．与遇湿易燃物品：因和遇湿易燃物品灭火方法不同，且有的性质相互抵触，不能同库储存。',
        'tretal43': '3．与氧化剂：因为易燃固体都有很强的还原性，与氧化剂接触或混合有引起着火爆炸的危险，所以都不能同库存放。',
        'tretal44': '4．与腐蚀性物品：与具有氧化性的腐蚀性物品如：溴、过氧化氢、硝酸等不可同库储存，与其他酸性腐蚀性物品可同库隔离存放，但发孔剂H（71011）与某些酸作用能引起燃烧，所以不宜同库存放。',
        'tretal45': '5．易燃固体之间：金属氨基化合物类、金属粉末、磷的化合物类等与其他易燃固体不宜同库储存。因为他们灭火方法和储存保养措施不同，硝化棉、赤磷、赛璐珞、火柴等均宜专库储存。樟脑、萘、赛璐珞制品，虽属乙类易燃固体，但挥发出来的蒸气和空气可形成爆炸性的混合气体，遇着火源容易引起燃烧爆炸，储存条件要求高，也宜专库储存。',
        'tretal46': '（二）易燃液体',
        'tretal47': '易燃液体，不仅本身易燃，而且大都具有一定的毒性。如甲醇、苯、二硫化碳等，原则上应单独存放。但因各种条件的限制，不得不与其他种类危险品同储时，应遵守如下原则：',
        'tretal48': '1．与甲类自燃物品不能同储，与乙类自燃物品也应隔离存放。因为自燃物品可自行燃烧。',
        'tretal49': '2．与腐蚀性物品溴、过氧化氢，硝酸等强酸不可同储，如量甚少时，也应隔离存放，并保持2米以上的间距。因为溴、过氧化氢、硝酸等强酸都有较强的氧化性。',
        'tretal50': '3．含水的易燃液体和需要加水存放或运输的易燃液体、不得与遇湿易燃物品同储',
        'tretal51': '易燃易爆化学危险品的整装储存，往往存放的品种多，性质危险而复杂，比较难于管理。而散装储存量比较大，设备多，技术条件复杂，一旦发生事故难以施救。故无论何种储存方式都潜在有很大的火灾危险，所以必须用科学的态度从严管理，万万不可马虎从事',
        'tretal52': '分类存放的一般原则',
        'tretal53': '（一）压缩、液化气体',
        'tretal54': '1．压缩、液化气体之间：可燃气体与氧化性（助燃）气体混合，遇着火源易着火甚至爆炸，应隔离存放。',
        'tretal55': '2．压缩、液化气体与自然、遇湿易燃等易燃物品之间：剧毒、可燃、氧化性（助燃）气体均不得与甲类自然物品物品同储和配装；与乙类自燃物品，遇水易燃物品（灭火方法不同）应隔离存放和配装；可燃液体、固体与剧毒、氧化性气体不得同储配装。',
        'tretal56': '3．压缩、液化气体与腐性物品：剧毒气体、可燃气体不得与硝酸、硫酸等强酸配装和同储，与氧化性（助燃）气体不燃气体应隔离储存放和配装。',
        'tretal57': '4．氧气瓶及氧气空瓶不得与油脂及含油物质、易燃物同储和配装。',
        'text1': '1.集装箱（container）所谓集装箱，是指具有一定强度、刚度和规格专供周转使用的大型装货容器。使用集装箱转运货物，可直接在发货人的仓库装货，运到收货人的仓库卸货，中途更换车、船时，无须将货物从箱内取出换装。按所装货物种类分，有杂货集装箱、散货集装箱、液体货集装箱、冷藏箱集装箱等；按制造材料分，有木集装箱、钢集装箱、铝合金集装箱、玻璃钢集装箱、不锈钢集装箱等；按结构分，有折叠式集装箱、固定式集装箱等，在固定式集装箱中还可分密闭集装箱、开顶集装箱、板架集装箱等；按总重分，有30吨集装箱、20吨集装箱、10吨集装箱、5吨集装箱、2.5吨集装箱等。',
        'text2': '2.集装箱外尺寸（containers overall external dimensions） 包括集装箱永久性附件在内的集装箱外部最大的长、宽、高尺寸。它是确定集装箱能否在船舶、底盘车、货车、铁路车辆之间进行换装的主要参数。是各运输部门必须掌握的一项重要技术资料。',
        'text3': '3.集装箱内尺寸（containers internal dimensions）集装箱内部的最大长、宽、高尺寸。高度为箱底板面至箱顶板最下面的距离，宽度为两内侧衬板之间的距离，长度为箱门内侧板量至端壁内衬板之间的距离。它决定集装箱内容积和箱内货物的最大尺寸。',
        'text4': '4.集装箱内容积（containers unobstructed capacity）按集装箱内尺寸计算的装货容积。同一规格的集装箱，由于结构和制造材料的不同，其内容积略有差异。集装箱内容积是物资部门或其它装箱人必须掌握的重要技术资料。',
        'text5': '5.集装箱计算单位（twenty-feet equivalent units 简称：TEU) 又称20英尺换算单位，是计算集装箱箱数的换算单位。目前各国大部分集装箱运输，都采用20英尺和40英尺长的两种集装箱。为使集装箱箱数计算统一化，把20英尺集装箱作为一个计算单位，40尺集装箱作为两个计算单位，以利统一计算集装箱的营运量。',
        'text6': '6.集装箱租赁 （container leasing) 即所有人将空箱租给使用人的一项业务。集装箱所有人为出租的一方集装箱，与使用人，一般是船公司或货主，为承租的一方，双方签订租赁合同。由出租人提供合格的集装箱交由承租人在约定范围内使用。装箱，每航次编制配载图；办理有关货运单证的编签； 编制并签验集装箱运用运载工具的出入及流转的有关单证； 办理集装箱及运载工具、装卸工具的情况检查、维修，以及空箱的清扫、熏蒸等工作； 空箱的收发、存贮和保管；安排空箱和重箱在堆场的堆码，及编制场地分配计划； 其它有关业务工作。 集装箱装卸区一般由专用码头、前沿、堆场、货运站、指挥塔、修理部门、大门和办公室组成。有时堆场或货运站等可延伸到市区内部事5-15公里的中转站。',
        'text7': '7.集装箱装卸区（container terminal) 是集装箱运输中，箱或货装卸交换保管的具体经办部门。它受承运人或其代理人的委托，进行下列各项业务：对整箱货运的交换、保管； 设有集装箱货运站者，办理拼箱货的交接； 安排集装箱船的靠泊，装卸集装箱。',
        'text8': '8.集装箱前方堆场（marshalling yard） 是指在集装箱码头前方，为加速船舶装卸作业，暂时堆放集装箱的场地。其作用是：当集装箱船到港前，有计划有次序地按积载要求将出口集装箱整齐地集中堆放，卸船时将进口集装箱暂时堆放在码头前方，以加速船舶装卸作业。',
        'text9': '9.集装箱后方堆场（container yard） 集装箱重箱或空箱进行交接、保管和堆存的场所。有些国家对集装箱堆场并不分前方堆场或后方堆场，统称为堆场。集装箱后方堆场是集装箱装卸区的组成部分。是集装箱运输“场到场”交接方式的整箱货办理交接的场所（实际上是在集装箱卸区“大门口”进行交接的）。',
        'text10': '10.空箱堆场（van pool） 专门办理空箱收集、保管、堆存或交接的场地。它是专为集装箱装卸区或转运站堆场不足时才予设立。这种堆场不办理重箱或货物交接。它可以单独经营，也可以由集装箱装卸区在区外另设。有些国家，经营这种空箱堆场，须向航运公会声明。',
        'text11': '11.中转站或内路站（container depot or inland depot）海港以外的集装箱运输的中转站或集散地。它的作用除了没有集装箱专用船的装卸作业外，其余均与集装箱装卸区业务相同。中转站或内陆站的度量，包括集装箱装卸港的市区中转站、内陆城市、内河港口的内陆站均在内。',
        'text12': '12.集装箱货运站（container freight station 简称：CFS） 为拼箱货装箱和拆箱的船、货双方办理交接的场所。承运人在一个港口或内陆城市只能委托一个集装箱货运站的经营者。由它代表承运人办理下列主要业务：拼箱货的理货和交接； 对货物外表检验如有异状时，就办理批注； 拼箱货的配箱积载和装箱； 进口拆箱货的拆箱和保管； 代承运人加铅封并签发站收据； 办理各项单证和编制等。',
        'text13': '13.托运人责任（shippers liabilities） 托运人在集装箱运输中应有的责任，这种责任是不完全同于传统海运方面的。拼箱货托运人的责任与传统海运相同。整箱货托运人的责任不同于传统运输的有：应保证所报货运资料的正确和完整； 承运人有权核对箱内所装货物，因核对而发生的费用，有托运人承担； 海关或其它权力机关开箱检查，其费用和由此发生货损货差，由托运人承担；如集装箱货不满，或是垫衬不良，积载不当，或是装了不适于集装箱运输的货物，因而引起货损、货差，概由托运人负责； 如使用了托运人自有的不适航的集装箱，所引起的货损事故，应由托运人负责；在使用承运人集装箱及设备其间造成第三者财产或生命的损害，应由托运人负责赔偿。集装箱的租赁，国际上有多种不同的方式，总括起来有：程租、期租、活期租用和航区内阻赁等。',
        'text14': '14.责任限制（limits of liability） 集装箱运输中发生货损货差，承运人应承担的最高赔偿额。拼箱货的责任限制与传统运输相同。整箱货的赔偿按照目前国际上的一些判例：如果提单上没有列明箱内所装货物的件数，每箱作为一个理赔计算单位；如提单上列明箱内载货件数的，仍按件数计算；如果货物的损坏和灭失，不属海运，而是在内陆运输中发生的，则按陆上运输最高赔偿额办理；如集装箱是由托运人所有或提供时，遇有灭失或损坏，其责任确属承运人应承担者，亦应视作一个理赔计算单位。',
        'goods1': '1.集装箱（container）所谓集装箱，是指具有一定强度、刚度和规格专供周转使用的大型装货容器。使用集装箱转运货物，可直接在发货人的仓库装货，运到收货人的仓库卸货，中途更换车、船时，无须将货物从箱内取出换装。按所装货物种类分，有杂货集装箱、散货集装箱、液体货集装箱、冷藏箱集装箱等；按制造材料分，有木集装箱、钢集装箱、铝合金集装箱、玻璃钢集装箱、不锈钢集装箱等；按结构分，有折叠式集装箱、固定式集装箱等，在固定式集装箱中还可分密闭集装箱、开顶集装箱、板架集装箱等；按总重分，有30吨集装箱、20吨集装箱、10吨集装箱、5吨集装箱、2.5吨集装箱等。',
        'goods2': '2.集装箱外尺寸（containers overall external dimensions） 包括集装箱永久性附件在内的集装箱外部最大的长、宽、高尺寸。它是确定集装箱能否在船舶、底盘车、货车、铁路车辆之间进行换装的主要参数。是各运输部门必须掌握的一项重要技术资料。',
        'goods3': '3.集装箱内尺寸（containers internal dimensions）集装箱内部的最大长、宽、高尺寸。高度为箱底板面至箱顶板最下面的距离，宽度为两内侧衬板之间的距离，长度为箱门内侧板量至端壁内衬板之间的距离。它决定集装箱内容积和箱内货物的最大尺寸。',
        'goods4': '4.集装箱内容积（containers unobstructed capacity）按集装箱内尺寸计算的装货容积。同一规格的集装箱，由于结构和制造材料的不同，其内容积略有差异。集装箱内容积是物资部门或其它装箱人必须掌握的重要技术资料。',
        'goods5': '5.集装箱计算单位（twenty-feet equivalent units 简称：TEU) 又称20英尺换算单位，是计算集装箱箱数的换算单位。目前各国大部分集装箱运输，都采用20英尺和40英尺长的两种集装箱。为使集装箱箱数计算统一化，把20英尺集装箱作为一个计算单位，40尺集装箱作为两个计算单位，以利统一计算集装箱的营运量。',
        'goods6': '6.集装箱租赁 （container leasing) 即所有人将空箱租给使用人的一项业务。集装箱所有人为出租的一方集装箱，与使用人，一般是船公司或货主，为承租的一方，双方签订租赁合同。由出租人提供合格的集装箱交由承租人在约定范围内使用。装箱，每航次编制配载图；办理有关货运单证的编签； 编制并签验集装箱运用运载工具的出入及流转的有关单证； 办理集装箱及运载工具、装卸工具的情况检查、维修，以及空箱的清扫、熏蒸等工作； 空箱的收发、存贮和保管；安排空箱和重箱在堆场的堆码，及编制场地分配计划； 其它有关业务工作。 集装箱装卸区一般由专用码头、前沿、堆场、货运站、指挥塔、修理部门、大门和办公室组成。有时堆场或货运站等可延伸到市区内部事5-15公里的中转站。',
        'goods7': '7.集装箱装卸区（container terminal) 是集装箱运输中，箱或货装卸交换保管的具体经办部门。它受承运人或其代理人的委托，进行下列各项业务：对整箱货运的交换、保管； 设有集装箱货运站者，办理拼箱货的交接； 安排集装箱船的靠泊，装卸集装箱。',
        'goods8': '8.集装箱前方堆场（marshalling yard） 是指在集装箱码头前方，为加速船舶装卸作业，暂时堆放集装箱的场地。其作用是：当集装箱船到港前，有计划有次序地按积载要求将出口集装箱整齐地集中堆放，卸船时将进口集装箱暂时堆放在码头前方，以加速船舶装卸作业。',
        'goods9': '9.集装箱后方堆场（container yard） 集装箱重箱或空箱进行交接、保管和堆存的场所。有些国家对集装箱堆场并不分前方堆场或后方堆场，统称为堆场。集装箱后方堆场是集装箱装卸区的组成部分。是集装箱运输“场到场”交接方式的整箱货办理交接的场所（实际上是在集装箱卸区“大门口”进行交接的）。',
        'goods10': '10.空箱堆场（van pool） 专门办理空箱收集、保管、堆存或交接的场地。它是专为集装箱装卸区或转运站堆场不足时才予设立。这种堆场不办理重箱或货物交接。它可以单独经营，也可以由集装箱装卸区在区外另设。有些国家，经营这种空箱堆场，须向航运公会声明。',
        'goods11': '11.中转站或内路站（container depot or inland depot）海港以外的集装箱运输的中转站或集散地。它的作用除了没有集装箱专用船的装卸作业外，其余均与集装箱装卸区业务相同。中转站或内陆站的度量，包括集装箱装卸港的市区中转站、内陆城市、内河港口的内陆站均在内。',
        'goods12': '12.集装箱货运站（container freight station 简称：CFS） 为拼箱货装箱和拆箱的船、货双方办理交接的场所。承运人在一个港口或内陆城市只能委托一个集装箱货运站的经营者。由它代表承运人办理下列主要业务：拼箱货的理货和交接； 对货物外表检验如有异状时，就办理批注； 拼箱货的配箱积载和装箱； 进口拆箱货的拆箱和保管； 代承运人加铅封并签发站收据； 办理各项单证和编制等。',
        'goods13': '13.托运人责任（shippers liabilities） 托运人在集装箱运输中应有的责任，这种责任是不完全同于传统海运方面的。拼箱货托运人的责任与传统海运相同。整箱货托运人的责任不同于传统运输的有：应保证所报货运资料的正确和完整； 承运人有权核对箱内所装货物，因核对而发生的费用，有托运人承担； 海关或其它权力机关开箱检查，其费用和由此发生货损货差，由托运人承担；如集装箱货不满，或是垫衬不良，积载不当，或是装了不适于集装箱运输的货物，因而引起货损、货差，概由托运人负责； 如使用了托运人自有的不适航的集装箱，所引起的货损事故，应由托运人负责；在使用承运人集装箱及设备其间造成第三者财产或生命的损害，应由托运人负责赔偿。集装箱的租赁，国际上有多种不同的方式，总括起来有：程租、期租、活期租用和航区内阻赁等。',
        'goods14': '14.责任限制（limits of liability） 集装箱运输中发生货损货差，承运人应承担的最高赔偿额。拼箱货的责任限制与传统运输相同。整箱货的赔偿按照目前国际上的一些判例：如果提单上没有列明箱内所装货物的件数，每箱作为一个理赔计算单位；如提单上列明箱内载货件数的，仍按件数计算；如果货物的损坏和灭失，不属海运，而是在内陆运输中发生的，则按陆上运输最高赔偿额办理；如集装箱是由托运人所有或提供时，遇有灭失或损坏，其责任确属承运人应承担者，亦应视作一个理赔计算单位。',
    },
    foot: {
        xiazhaispin: '公司宣转视频',
        tbat:'下载',
        textdiz: ' 津公网安备 12010302001429号',
        // require("@/assets/image/banner-4.jpg")
        tupian:require('@/assets/image/huei.png'),
        bananhap:'津ICP备16008346号-2',
        title: '联系我们 ',
        subheading: '联系我们，选择我们就是选择放心、选择安心',
        data: [{
            text: '天津总部热线'
        }, {
            text: '空运业务合作'
        }, {
            text: '空运业务'
        }, {
            text: '陆运仓储'
        }, {
            text: '投诉建议'
        }]
    },
    network: [{
        title: '延伸至全球的"大信"物流神经网络',
        subheading: '大信物流网点'
    }, {
        title: '天津总公司',
        subheading: ' 天津市河西区围堤道146号华盛广场B座22层',
        sjhaoma: ' 022-88239781',
        dianhuahaoma: '022-88239785',
        emoe: 'sales_china@i-daeshin.com',
    }, {
        title: '天津大信通北京分公司',
        subheading: '北京市顺义区南彩镇彩祥西路1号'
    }, {
        title: '天津大信通上海分公司',
        subheading: '上海市长宁区仙霞路317号远东国际大厦B座2109室'
    }, {
        title: '天津大信通威海分公司',
        subheading: '山东省威海市临港经济技术开发区苘山镇正气路-6-2号'
    }, {
        title: '天津大信通沧州分公司',
        subheading: '沧州经济开发区东海路35号5幢'
    }, {
        title: '陕西大信通物流有限公司',
        subheading: '陕西省铜川市印台区柳湾村 一组'
    }, {
        title: '天津大信国际西安分公司',
        subheading: '西安市高新区锦业二路逸翠尚府北区8号楼2单元2301/2302'
    }, {
        title: '天津大信通重庆分公司',
        subheading: '重庆渝北区龙兴镇重庆市渝北区龙兴镇 迎龙大道19号 丰树物流园'
    }, {
        title: '成都办事处',
        subheading: '四川省眉山市彭山区滨江翡翠城1期（南大门）'
    }, {
        title: '惠州办事处',
        subheading: '广东省惠州市惠阳区大亚湾区霞涌街道浅水湾2号楼2单元3108'
    }, {
        title: '福州办事处',
        subheading: '福建省福清市新厝镇盘昇花园小区6号楼2座705'
    }, {
        title: '衢州办事处',
        subheading: '浙江省衢州市柯城区高新园区晓星大道晓星新材料有限公司(12号门）'
    }, {
        title: '天津大信通苏州分公司',
        subheading: '苏州市相城区高铁新城南天成路77号高融大厦1404室'
    }, {
        title: '常州办事处',
        subheading: '江苏省常州市新北区港区中路1号住化电子'
    }, {
        title: '大信物流 洛杉矶(CA)分公司',
        subheading: '西米棕榈路11号，布卢明代尔，佐治亚州31302',
        emoe: 'yhlee1019@i-daeshin.com',
    }, {
        title: '大信物流 奥斯汀(TX)总部',
        subheading: '(78759)德克萨斯州奥斯汀 Research 12710路380号',
        sjhaoma: '+1 512 638 0799',
        emoe: 'mhsung@i-daeshin.com',
    }, {
        title: '大信物流有限公司-首尔总部',
        subheading: '首尔市永登浦区仙遊路70,1102-1号{br}(文来洞三街, WOORI 创投之城)',
        sjhaoma: ' +82-02-2088-0681',
        dianhuahaoma: ' +82-2-2088-0685',
        emoe: ' op@i-daeshin.com',
    }, {
        title: '大信物流有限公司-东京总部',
        subheading: '东京市 荒川区 西日暮里 5-33-3 约科奇维尔 101室',
        sjhaoma: ' +81-3-6806-7927',
        emoe: ' kazunobu_iwamoto@i-daeshin.com',
    }, {
        title: '香港大信物流有限公司',
        subheading: '',
    }, {
        title: '天津大信通广州分公司',
        subheading: '广州市黄埔区开创大道2395号至泰商业广场A2栋1302单元 ',
    }]
}