const config = {
    path: '/long',
    name: 'Long',
    component: () => import('@/views/Long/index.vue'),
    meta: {
      title: '天津物流集团-登录',
      keepAlive:true
    }
  }
  const data={
    userName:'',
    password:'',
  }
  
  export {config,data} 