export default {
    message: {
        title:'DSL',
        keywords:'DSL,DSL JAPAN,国際海運輸出入,国際国内空運,国内陸路運輸,在庫管理,プロジェクト物流,危険品運輸,海上運送,航空運送,輸出入,輸入,輸出',
        description:'グローバルな物流ネットワークを構築し、顧客にワンストップ方式で物流サービスを提供可能。海外支店の建設、事業力の強化、支援の拡充により、グローバルな総合物流企業への成長を目指しています。',
        data: [{
            label: 'ホーム',
            route: 'Home',
            id: 0,
            xia: [{
                label: '会社紹介'
            }, {
                label: 'サービス'
            }, {
                label: '我々の強み'
            }, {
                label: '会社発展'
            }, {
                label: '見積もり質問'
            }, {
                label: '私たちの約束'
            }, {
                label: '経営理念'
            }, {
                label: '事業範囲'
            }, {
                label: '大信物流グループ資源'
            }, {
                label: '会社の歴史'
            }, {
                label: 'お問い合わせ'
            }, {
                label: '我々の約束'
            }]
        }, {
            label: '会社概要',
            route: 'Information',
            id: 1,
            xia: [{
                label: '管理'
            }, {
                label: '5S戦略'
            }, {
                label: '誠実'
            }, {
                label: '顧客'
            }, {
                label: '認定エンタープライズ証明書'
            }, {
                label: '感謝のしるし'
            }]
        }, {
            label: '事業内容',
            route: 'Outlets',
            id: 2,
            xia: [{
                label: '国際国内海運'
            }, {
                label: '国際国内航空運送'
            }, {
                label: '倉庫管理'
            }, {
                label: '内陸輸送'
            }, {
                label: '危険品運送'
            }, {
                label: 'プロジェクト運送'
            }]
        }, {
            label: '国内・海外拠点',
            route: 'Network',
            id: 3,
            xia: [{
                label: '中国'
            }, {
                label: '米国'
            }, {
                label: '日本'
            }, {
                label: '韓国'
            }]
        }, {
            label: 'コミュニティ',
            route: 'Advocacy',
            id: 4,
        }, {
            label: '現在の場所',
            id: 5,
        }, {
            label: '登录',
            id: 6,
        }, {
            label: 'アドレス'
        }, {
            label: '東京都 荒川区 西日暮里5-33-3 ヨコチビル 101'
        }, {
            label: '+86-166-2235-5720'
        }, {
            label: 'sales_japan@i-daeshin.com'
        }, {
            label: 'ページ',
        }, {
            xia: [{
                label: '中国'
            }, {
                label: 'アメリカ合衆国'
            }, {
                label: '韓国'
            }, {
                label: '日本'
            },]
        }],
        swiper: [{
            titlebta: '輸送および物流ソリューション',
            url: require("@/assets/image/sl1.jpg"),
            textp: '<span style="color: red;">航空および海運運送</span>ソリューション',
            title: '世界をつなぐ総合物流企業大信物流',
            text: '大信物流はスピードや安全性はもちろん、いつでもどこでも完璧なサービスシステムでより良い物流体験を提供します。ドアツードア、ワンストップ物流サービス、Eコマースサービス。'
        }, {
            url: 'require("@/assets/image/sl2.jpg") ',
            textp: '<span style="color: red;">国際運送</span>ソリューション',
            title: '国際空運で、全世界にスピードアクセス',
            text: '“効率的で迅速かつ安全に航空輸送、航空速達、至急送達、荷物委託、合理的な価格、誠心誠意なサービス、高い評価で顧客のニーズにお応えします。'
        }, {
            url: require("@/assets/image/sl3.jpg"),
            textp: '<span style="color: red;">倉庫管理</span>ソリューション',
            title: '広域分散型総合物流倉庫',
            text: '総面積77,500㎡以上の物流倉庫、広い車道、便利な搬入口、24時間体制のセキュリティパトロール、高度な防火対策、現代的な管理コンセプト、先進的な電子設備、24時間365日対応のオンラインサービス。',
        }, {
            url: require("@/assets/image/sl4.jpg"),
            textp: '<span style="color: red;">内陸輸送</span>ソリューション',
            title: '幅広い車種と車両',
            text: 'コンテナトラック、タンクローリー、ハンググライダー、冷蔵車両、冷凍車両を保有しており、当社は中国内の韓国物流会社で、全国に輸送網を持っており、顧客にご満足頂ける運送サービスを提供しています。',
        }, {
            // url: require("@/assets/image/sl5.jpg"),
            textp: '<span style="color: red;">危険物配達</span>ソリューション',
            title: '危険品運輸、安全、スピード、正確な納品',
            text: '専門団体、内陸および輸出入危険物輸送、目的地への到着リスクゼロ、多様な保護設備、熟練したオペレーター、高額な貨物保険、綿密なルート管理、信頼できる工程設計、緊急メカニズム。',
        }, {
            title: 'もっと読む',
            txt: 'お問い合わせ',
        }],
        baojia: [{
            title: 'ご連絡',
            title2: 'お問い合わせ',
            subheading: '電話相談·問い合わせ',
            subheading2: '+86-166-2235-5720',
            text: '海上貨物、航空貨物、倉庫管理、内陸貨物、危険物について何か助けが必要ですか？<br> 遠慮なくご連絡ください。<br> すぐにお答えします',
            input: '会社',
            input1: 'あなたの名前',
            input2: 'あなたのメールボックス',
            input3: 'あなたの電話番号',
            input4: 'サービスタイプの選択',
            input5: '航空輸送',
            input6: '海上輸送',
            input7: '倉庫管理',
            input8: '内陸輸送',
            input9: '危険物輸送',
            input10: '重要事項',
            button: '送信',
            sj: 'あなたのメールアドレスは正しくありません。',
            yjan: 'あなたの携帯番号は正しくありません。',

        }],
        swiper2: [{
            title: '事業範囲',
            subheading: '物流ソリューション',
            swiper: [{
                url: require("@/assets/image/banner-1.jpg"),
                image: require('@/assets/image/海运4web.jpg'),
                p: '海运出口',
                title: '国際海運輸出入',
                subheading: '商品の特性により',
                subheading1: '優れた港湾荷役・輸送サービスを提供しています。',
                text: '世界各地の港の物流インフラや、お客さまの貨物の特性に合わせた直営施設を活用し、お客さまにオーダーメイドの港湾荷役・海上輸送サービスを提供しています。 同時に、通関、検査、通関業務へと事業領域を拡大し、品質シナジー効果の実現に努めます。',
            }, {
                url: require("@/assets/image/banner-2.jpg"),
                image: require('@/assets/image/空运2web.jpg'),
                p: '国际空运',
                title: '国際国内空運 ',
                subheading: 'お客様にとってより最適で迅速な運輸ソリューションの設計と運輸時間の短縮。',
                text: 'パレット運輸による迅速な通関・輸送サービス 輸出入貨物の航空輸送や通関に加え、グローバルな電子商取引に対応した国際エクスプレスサービスも提供しております',
            }, {
                url: require("@/assets/image/banner-3.jpg"),
                image: require('@/assets/image/仓库3web.jpg'),
                p: '仓储物流',
                title: '在庫管理',
                subheading: '大規模な総合倉庫',
                subheading1: '最適な運用サポートとロジスティクスサービスを提供。',
                text: '中国の主要都市にある総面積約77,500m²の物流倉庫。 貨物の特性に応じたカスタマイズ収納ソリューション、効率的で安全かつ安定したサービスを提供するための専門スタッフ。',
            }, {
                url: require("@/assets/image/banner-4.jpg"),
                image: require('@/assets/image/陆运2web.jpg'),
                p: '内陆运输',
                title: '国内道路運輸',
                subheading: '380台の輸送車両を保有',
                subheading1: '韓国系物流企業',
                text: 'コンテナトラック、タンクローリー、ハングライダー、冷蔵車両、冷凍車両等様々な車種を保有しており、さまざまな産業分野で必要とされる幅広い品物を、迅速に輸送することができます。 365日物流をオールラウンドサポートする全国規模の物流ネットワーク。',
            }, {
                url: require("@/assets/image/banner-5.jpg"),
                // image: require('@/assets/image/危险2web.jpg'),
                p: '危险品运输',
                title: '危険品運輸 ',
                subheading: '1年以上の危険品運輸経験',
                subheading1: '韓国系物流企業',
                text: '危険品貨物は、顧客のご要望に応じて様々な薬剤を追加することができ、プロセス全体を通し監視カメラを設置し、貨物の位置と輸送状況のフィードバックをいつでも顧客に提供することができます。 原材料の供給物流からリバースロジスティクスまで安全に貨物を届けることを保証。',
            }],
        }],
        swiper3: [{
            name: ' Tae Kyung Lee',
            gongs: 'DSL KOR - 代理',
            title: '始まりは半分です！',
            text: '良いスタートは成功に対するビジョンです。成功の道は長いが、最初から確固たる心を持つようになれば、闘志は尽きないです!!',
        }, {
            name: 'Younghoon Lee',
            gongs: 'DSL USA - 法人長',
            title: '最後までではありません。',
            text: '人生には終わりというものはなく、すべての段階が新たな始まりです。',
        }, {
            name: '李振旭',
            gongs: 'DSL CHN - 天津国際部',
            title: '商は人であり、人は商である',
            text: '商売をするのは利益を得るためではなく、人を残すためです。',
        }, {
            name: '藤井知己',
            gongs: 'DSL JPN -蘇州国際業務部',
            title: 'ビジネスは利益を得るためではなく、人を残すためである',
            text: '継続的に内部の潜在力を発掘し、運用コストを削減します。生産性を高めます。自分の欲を節制して、自分が残した利潤は低く、利益はもっとたくさん与えることです。',
        }, {
            name: '蔡宰薰',
            gongs: 'DSL CHN - 上海常務',
            title: '努力は決して裏切りません。',
            text: '努力は自分を裏切らないです。絶えず努力してこそ、私たちは自分の目標を達成することができます。勉強も仕事も生活も私たちのたゆまぬ努力が必要です。',
        }, {
            name: '韩颖',
            gongs: 'DSL CHN -  香港国際部',
            title: '毎日千万里を恐れない、いつもすること、千万事を恐れないです。',
            text: '毎日千万里を恐れない、いつもすること、千百事を恐れないすべてのことに強靭な粘り強さと粘り強い定力が必要であり、耐えていくと必ず大きな器になります。',
        }, {
            name: '车映周',
            gongs: '大信物流グループ社長',
            title: '君の志向は、一途である。闘うほどに勇気を増し、再三再四挑戦していく。',
            text: '意志がある人は、困難や挫折に遭遇しても、途中で踏ん張ることはせず、ぶれずに最後まで粘り強く戦い続けます。',
        }, {
            name: '邵啸群',
            gongs: 'DSL CHN - 蘇州 3PL部',
            title: '毎日少しずつ進歩し続ければ、成功はすぐそこにあります。',
            text: '少しずつ前進することは成功への蓄積であり、一つ一つハードルを越えることはゴールに到達する目標を達成することです。',
        }, {
            name: ' Yusil Kang',
            gongs: 'DSL USA - 国際部',
            title: '人生は幸福そのものです。',
            text: '生活は非常に素晴らしいものです。少しの楽観主義で驚きがあるでしょう。人生は幸せです、少し注意すると笑顔があります。日々は素晴らしいものです、少し満足すれば幸福があります！',
        }, {
            name: '李文静',
            gongs: 'DSL CHN -  西安国際部 ',
            title: '意思あるところに道がある',
            text: '諦めは簡単ですが、持続することは確かにかっこいいです。星は必ずしも輝かしい訳ではありませんが、常に輝きがあります。',
        }, {
            name: ' Mark Meredith',
            gongs: 'DSL USA  - あんぜんかんり',
            title: '安全第一、常に安全を保つ',
            text: 'くれぐれも慎重に行動し、後悔するような事態は避けたい。法律を守り、リスクを減らす。',
        }, {
            name: '黄丽君',
            gongs: '広州 国際部',
            title: '人徳を高く保ち物事を成し遂げる。心を尽くしてサービスする',
            text: '君子の人徳は大地のように厚く、あらゆるものを受け入れ、心を込めて顧客にサービスするべきです',
        }],
        slide: [{
            title: '会社の歴史',
            title2: '大信物流集团',
            icondiqu1: '中国,',
            icondiqu2: '米国',
            icondiqu3: '韩国',
            icondiqu4: '日本',
            zhongbu: '本部',
            fengongs: ' 支社',
            fengongs1: '法人',
            huwulexing1: '危険物/内陸',
            huwulexing2: '国際物流',
            huwulexing3: '危険物',
            huwulexing4: '国際物流/内陸/危険物',
            huwulexing5: '二次電池銅箔·銅箔設備生産',
            diqu1: '天津',
            diqu2: '西安',
            diqu3: '滄州',
            diqu4: '陝西',
            diqu5: '威海',
            diqu6: '重慶',
            diqu7: '香港',
            diqu8: '広州',
            diqu9: '蘇州',
            diqu10: '上海',
            diqu11: 'ソウル',
            diqu12: 'オースティン',
            diqu13: 'ロサンゼルス',
            diqu14: '東京',
            swiper: [{
                text: '天津大信物流天津法人設立',
                text2: '天津本社設立',
            },
            {
                text: '天津大信国際貨物運送代理店',
                text2: '天津本社設立',
            },
            {
                text: '天津易大信運輸有限公司',
                text2: '天津本社設立',
            },
            {
                text: '天津大信通運輸有限公司',
                text2: '天津本社設立',
            },
            {
                text: '天津国際貨物運送代理店',
                text2: '西安支社設立',
            },
            {
                text: '天津大信通運輸有限公司',
                text2: '滄州支社設立',
            },
            {
                text: '陝西大信通運輸有限公司',
                text2: '本社設立',
            },
            {
                text: '天津大信通運輸有限公司',
                text2: '威海支社設立',
            },
            {
                text: '天津大信通運輸有限公司',
                text2: '重慶支社設立',
            },
            {
                text: '香港大信物流有限公司',
                text2: '香港本社設立',
            },
            {
                text: '天津大信通運輸有限公司',
                text2: '広州支社設立',
            },
            {
                text: '天津大信通運輸有限公司',
                text2: '蘇州支社設立',
            },
            {
                text: '天津大信通運輸有限公司',
                text2: '上海支社設立',
            },
            {
                text: '韓国大信物流',
                text2: 'ソウル本社設立',
            },
            {
                text: 'アメリカン·ダイシン·ロジスティクス',
                text2: 'オースティン本社設立',
            },
            {
                text: 'アメリカン·ダイシン·ロジスティクス',
                text2: 'ロサンゼルス(CA)支社設立',
            },
            {
                text: '日本大信物流',
                text2: '東京本社設立',
            }, {
                text: 'P&D西安',
                text2: 'PNT&大信物流合弁企業',
            }
            ]
        }],
        about: [{
            tetlet: '17年危険物専門物流会社',
            tetlyt: '物流パートナー',
            title: '私たちについて',
            subheading: '経営理念 ',
            text: '<span style="color: red;">「顧客至上、サービス第一」</span>という経営理念のもと、<span style="color: red;">「安全、効率、迅速、正確、経済的」</span>なサービスをお客様に提供することに専念しています。',
            image: [{
                url: '@/assets/image/关于我们小图3.jpg'
            }, {
                url: '@/assets/image/关于我们小图4.jpg'
            }, {
                url: '@/assets/image/关于我们小图5.jpg'
            }, {
                url: '@/assets/image/关于我们小图6.jpg'
            }],
            description: [{
                text: '大信物流は2007年に設立され、中国の中央政府直轄市の一つである「天津市」に本社を構えております。',
            }, {
                text: '大信物流は17年の発展を経て、先進的な国際輸送管理システム、全スタッフの絶え間ない努力と革新、正確な発展方向により、その規模を拡大して参りました。',
            }, {
                text: '北京、上海、香港、重慶、広州、西安、蘇州など中国発展都市に支社を構え、会社の成長とともに韓国やアメリカにも事業を展開しております。',
            }, {
                text: '中国国内に380台以上の車両と77,500m2の総合倉庫を保有しており、 事業範囲は、国際海上輸出入から国際航空運輸、在庫管理、危険品運輸、リフト・積卸、複合一貫輸出入貿易などに広がっており、顧客のために完璧なワンストップサービスを提供しております。',
            }, {
                text: '闘争と発展を通し、業界で多くの経験を蓄積するだけでなく、優秀な人材を育成し、国内外の顧客と長期的かつ友好的な協力関係を維持し、韓国と日本の企業との緊密な協力と、様々な資源の利点を統合し、陸・海・空・輸出入を取り扱う専門的な物流企業に発展しました。 今後も、自社の強みと外部環境を生かし、価格性やサービス品質の向上を継続的に実現するために努力を続けていく所存です。',
            }]
        }],
        advantage: [{
            title: '私たちの強み',
            subheading: '大信物流グループ資源 ',
            text: 'グローバルな物流ネットワークを構築し、顧客にワンストップ方式で物流サービスを提供可能。',
            text2: '海外支店の建設、事業力の強化、支援の拡充により、グローバルな総合物流企業への成長を目指しています。',
            description: [{
                url: '@/assets/image/homeyewu1.webp',
                text: '国 ',
            }, {
                url: '@/assets/image/homeyewu2.webp',
                text: '支社',
            }, {
                url: '@/assets/image/homeyewu3.webp',
                text: '社員',
            }, {
                url: '@/assets/image/homeyewu4.webp',
                text: '倉庫面積',
            }, {
                url: '@/assets/image/homeyewu5.webp',
                text: '危険品車両',
            }, {
                url: '@/assets/image/homeyewu6.webp',
                text: '一般貨物車両',
            }, {
                url: '@/assets/image/homeyewu7.webp',
                text: '冷蔵車両',
            }, {
                url: '@/assets/image/homeyewu8.webp',
                text: '梱包設備',
            }]
        }],
        image: [{
            title: '大信物流が目指すのは全世界 ',
            subheading: '細部が成否を决定づける、繊細なグローバルサービスを顧客に提供します。',
            url: '@/assets/image/home-background2.webp'
        }],
        spin: [{
            text: '誠実さとコンプライアンスをしっかり守り事業展開をして参ります',
        }, {
            text: '顧客',
        }, {
            text: '認定エンタープライズ証明書',
        }],
        chengn: [{
            text: '確約',
        }, {
            text: '我々の約束',
        }]
    },
    information: {
        data: [{
           'val': '概要',
        },{
           'val': '理念',
        },{
           'val': '管理',
        },{
           'val': 'ビジョン',
        },{
           'val': '信用',
        },{
           'val': '認定エンタープライズ証明書',
        },{
           'val': '感謝のしるし',
        },{
           'val': '顧客',
        },{
            'val': '了解公司的愿景、企业文化、精英理念及未来发展',
        }],
        image: [{
            text1: '天津大信国際貨物運送代理有限公司',
            text2: '中国税関AEO高級認証',
        }, {
            text1: '天津大信運輸有限公司',
            text2: '品質管理システムISO9001認証',
        }, {
            text1: '天津大信運輸有限公司',
            text2: '品質管理システムISO14001認証',
        }, {
            text1: '天津大信国際貨物運送代理有限公司',
            text2: '無船荷運送事業NVOCC',
        }, {
            text1: '天津イデシン運輸有限公司',
            text2: '信用格付けAAA',
        }, {
            text1: '陝西大信通運輸有限公司',
            text2: '信用格付けAAA',
        }, {
            text2: 'ビジネスサポートの感謝',
            text1: '三星SDS',
        }, {
            text2: '物流優良サービス業者',
            text1: 'モテロ油圧機械',
        }, {
            text2: '物流優良サービス業者',
            text1: '住化電子材料科学技術(常州)有限公司',
        }, {
            text2: '貢献感謝を支持',
            text1: '園益(西安)半導体',
        }, {
            text2: '2018年危険物運送先進企業',
            text1: '陝西大信通運輸有限公司',
        }, {
            text2: '2017年危険物運送先進企業',
            text1: '陝西大信通運輸有限公司',
        }, {
            text1: '日進自動車システムズ',
            text2: '貢献感謝を支持',
        }, {
            text1: '住化電子材料科学技術(西安)有限公司',
            text2: '物流優良サービス業者',
        },],
        bejing: [{
            title: '会社概要',
            text: '会社のビジョン、企業文化、エリート理念、将来の発展を理解する',
        }],
        idea: [{
            url: '@/assets/image/linian1.jpg',
            title: '態度が全てを決定し、細部が成功を決定する',
            title3: '大信物流グループ 代表 車映周',
            text: '大信物流は常に“責任”を第一と考え，「国家のための事業」というサービス概念に基づき、企業の社会的責任を十分に果たし、すべての社員が社会に貢献できるよう心がけております。物流分野では、企業存続のために顧客の信頼を保つことは必要な戦略であり、大信物流は、優良物流企業を目指し、お客様とのコミュニケーションの強化とサービス品質を向上させ、お客様の信頼を獲得していく所存です。 より安い価格、幅広い拠点網、高度な物流ネットワークシステムにより、お客様により良いサービスを提供いたします。今後、グローバルな物流ネットワークとプロフェッショナルな対応により、「お客様のニーズを実現し最高のサービスを提供するグローバル総合物流企業」を目指していきます。',
        }],
        manage: [{
            title: 'CSV管理',
            subheading: 'SV(Creating Shared Value，共有価値の創造) 法に従い、誠実かつ安全な経営を基本にWin-Winな関係を築き、社会に還元したいと考えております。经营目的在于以合法经营、诚信经营和安全经营为基础，实现创造经营、共赢经营和共享经营，为回报社会而发挥企业应尽的义务，不断创造社会性价值。大信物流グループは、「共通価値の創造」という経営理念のもと、事業の強みを活かし、あらゆる面で社会の発展に貢献してまいります。',
            li: [{
                text: '事業創出',
                textx: '世界の新しい産業モデルを実現し、国や社会に貢献し、事業の発展を促す。',
            }, {
                text: ' Win-win関係の構築',
                textx: 'Win-Winの産業エコシステムを構築し、積極的に実現することで、持続可能なビジネスの発展を確保。',
            }, {
                text: 'シェアリングエコノミー',
                textx: '本業の強みを生かし、社会的弱者に焦点を当てた社会貢献活動を実施。',
            }],
            url: '@/assets/image/xiangqing2.jpg',
            bootom: [{
                url: '@/assets/image/xiangqing3.jpg',
                text: '合法経営',
                tceit: '법률을 준수하다'
            }, {
                url: '@/assets/image/xiangqing4.jpg',
                text: '誠実な運営',
                tceit: '약속을 중시하다'
            }, {
                url: '@/assets/image/xiangqing5.jpg',
                text: '安全な経営',
                tceit: '안전을 보증하다'
            }]
        }],
        vision: [{
            box: [{
                title: '戦略方向',
                subheading: '（전략 방향）',
                strong: '新規開拓',
                strongzong: 'Cost Leadershipの確保',
                strongxia: 'Network/Partnershipの構築',
                strongxiaz: '専門人材の育成',
                textp1: '取引可能な顧客探し',
                textp2: '新事業や海外事業の推進 ',
                textp3: '顧客の物流競争力の強化を目標に',
                textp4: 'より最適な物流システムの構築と物流サービスを提供',
                textp5: '優秀な企業との戦略的パートナーシップによる協力関係の構築',
                textp6: '顧客とのWin-Winな協力関係の構築',
                textp8: 'コア人材（物流コンサルタント、パイオニア）の育成',
            }, {
                title: '我々の強み',
                subheading: '（Core Strength）',
                strong: 'グローバルネットワーク',
                strongzong: 'ITシステム構築 /運用能力',
                strongxia: 'コンサルティング力',
                textp1: '顧客一人ひとりのニーズに応える',
                textp2: 'どこまでも迅速かつ安価に輸送できる幅広いネットワーク',
                textp3: 'データをリアルタイムに届けるネットワークの構築',
                textp4: '顧客ニーズに対応した物流情報・意思決定のためのITシステム',
                textp5: 'より競争力のある統合ソリューションの提供',
                textp6: '実現性の高い輸送計画の伝達と協議',
            }, {
                title: '共有価値',
                subheading: '（shared value）',
                strong: '信頼：パートナー精神',
                strongzong: 'オープンマインド ',
                strongxia: '情熱：開拓者精神',
                textp1: '顧客と会社、会社と社員間の信頼関係',
                textp2: 'Win-Win関係の信頼',
                textp3: '自分自身や部門の利益だけでなく、会社全体に目を向ける',
                textp4: '先を見据え、オープンマインドで業務に取り組む',
                textp5: '先進的な企業となり、卓越したカンパニースピリッツを追求',
                textp6: '明確なビジョン、意欲、自信',
            }],
            url: '@/assets/image/yuanjing1.jpg'
        }],
        opnert: [{
            opttbe: '企業の使命です',
            title: '誠実さとコンプライアンスをしっかり守り事業展開をして参ります',
            text1: '企業の使命とは、従業員、株主、顧客、社会、自然環境に対する保護義務',
            text2: 'ビジョンとは、企業の将来的な方向性、将来像のこと',
            text3: '企業が社員に安心を与え、その価値を実感させてこそ、社員は忠誠心を持ち、会社の長期的な発展を考えることができる',
            text4: '企業が株主に価値を与え続けてこそ、株主は企業に信頼を寄せ、投資を続け、事業の成長を支えることができる',
            text5: '顧客に付加価値を提供し続けることで、顧客の信頼を得て、新規顧客を獲得し続けることができ、企業は収益を上げる機会を得ることができる',
            text6: '企業が社会のために創造し、社会の発展や環境保護に配慮し続けてこそ、社会も政府も企業に対してより多くの還元をし、より良い発展環境を整えることができる',
            text7: '企業の長期的な発展・成長を実現するためには、誠実な企業運営を行うことが必要。',
            url: '@/assets/image/chengxi1.jpg'
        }],
        zhanluei: [{
            tltle: '5S戦略',
            s1: {
                text: '<span style="margin-right: 5px;">✓</span>中国国内における業務特性に応じた５つの法人経営',
                text1: '<span style="margin-right: 5px;">✓</span>韓国、香港、米国に拠点を確保しグローバル企業に跳躍',
                text2: '<span style="margin-right: 5px;">✓</span>ISO 9001, 14001 および AEO 取得',
                text3: '<span style="margin-right: 5px;">✓</span>IT 安全管理システム運用',
            },
            s2: {
                text: '<span style="margin-right: 5px;">✓</span>韓国系企業において危険物ライセンスの初取得',
                text1: '<span style="margin-right: 5px;">✓</span>中国全土に355台の自社トラックを保有するアセット型物流会社',
                text2: '<span style="margin-right: 5px;">✓</span>協力物流会社ネットワークによる約8,000台の車輛運営が可能',
                text3: '<span style="margin-right: 5px;">✓</span>倉庫面積',
                text4: '<span style="margin-right: 5px;">✓</span>一般貨車両',
                text5: '<span style="margin-right: 5px;">✓</span>危険品車両',
                text6: '<span style="margin-right: 5px;">✓</span>冷蔵車両',
            },
            s3: {
                text: '<span style="margin-right: 5px;">✓</span>車輛追跡システムの装備 (GPS, CCTV)',
                text1: '<span style="margin-right: 5px;">✓</span>交通局安全教育月1回実施',
                text2: '<span style="margin-right: 5px;">✓</span>交通局安全評価 AAAで 最高得点を取得',
                text3: '<span style="margin-right: 5px;">✓</span>中国全域の自社保有車輛で適時適所に投入可能',
                text4: '<span style="margin-right: 5px;">✓</span>顧客企業の要求に合った車輛を新規購入し敏速に投入(カスタマイズ)',
                text5: '<span style="margin-right: 5px;">✓</span>24時間 GPS管理で敏速・正確に位置追跡し顧客企業と共有',
                text6: '<span style="margin-right: 5px;">✓</span>敏速な船舶予約',
                text7: '<span style="margin-right: 5px;">✓</span>敏速な通関及び納品',
            },
            s4: {
                text: '<span style="margin-right: 5px;">✓</span>中国全域の自社保有車輛で適時適所に投入可能',
                text1: '<span style="margin-right: 5px;">✓</span>顧客企業の要求に合った車輛を新規購入し敏速に投入(カスタマイズ)',
                text2: '<span style="margin-right: 5px;">✓</span>24小时GPS管理，快速准确定位，与客户共享',
                text3: '<span style="margin-right: 5px;">✓</span>敏速な船舶予約',
                text4: '<span style="margin-right: 5px;">✓</span>敏速な通関及び納品',
            },
            s5: {
                text: '<span style="margin-right: 5px;">✓</span>皆が ‘NO’を言う時、代わりに物流は ‘YES’と言う',
                text1: '<span style="margin-right: 5px;">✓</span>不可能を可能に',
                text2: '<span style="margin-right: 5px;">✓</span>全職員の業務マインド 公正成立」',
            },
        }],
        image1: require('@/assets/image/yuanjingja1.jpg'),
        image2: require('@/assets/image/chengxija1.jpg'),
        image3: require('@/assets/image/kehuoja.png'),
        image4: require('@/assets/image/xiangqingja2.png'),
        image5: require('@/assets/image/货运ko.jpg'),
        image6: require('@/assets/image/中国ja.jpg'),
        image7: require('@/assets/image/美国ja.jpg'),
        image8: require('@/assets/image/韩国ja.jpg'),
        image9: require('@/assets/image/日本ja.jpg'),
        image10: require('@/assets/image/sl5ja.jpg'),
        image11: require('@/assets/image/top1ko.jpg'),
        image12: require('@/assets/image/top3ko.jpg'),
        image13: require('@/assets/image/top2ko.jpg'),
    },
    outlets: {
        datalist: [{
            'wedic': '国際海運輸出入',
        }, {
            'wedic': '国際国内空運',
        }, {
            'wedic': '国内陸路運輸',
        }, {
            'wedic': '在庫管理',
        }, {
            'wedic': 'プロジェクト物流',
        }, {
            'wedic': '危険品運輸',
        }, {
            wedic: '詳細内容',
        }],
        mari: [{
            card: [{
                text1: '海上運輸',
                text2: '高い能力を持つ専門チームと、世界中に広がる支社との完璧なグローバルネットワークサービス。',
                text3: '物流ソリューションの設計から貨物追跡まで、さまざまな輸出入貨物のニーズに応えた総合的な通関・配送サービス。',
                text4: 'バルク品の用船業務、定期船および不定期船の国際海運代理店業務。',
                text5: '多様なビジネスを展開し、サプライチェーン・マネジメント・サービスやその他の総合的な高品質なサービスを顧客に提供。',
                text6: '多くの船会社と長期的な契約を結び、低い価格で海上運輸を提供可能。',
                url: '@/assets/image/hiashangyshu1.jpg'
            }, {
                title1: '商品の特性により,優れた港湾積降・輸送サービスを提供しています。',
                title2: 'お客様にとってより最適で迅速な運輸ソリューションの設計と運輸時間の短縮。',
                title3: '380台の輸送車両を保有韓国系物流企業',
                title4: '大規模な総合倉庫。最適な運用サポートとロジスティクスサービスを提供。',
                title5: '輸送に使用する各種機械、装置、材料等の選定と輸送技術の検討。',
                title6: '17年以上の危険品運輸経験を持つ韓国系物流企業',
            }],
            text: [{
                title: 'サービス紹介',
                text: [{
                    url: '@/assets/image/hiashangyshu2.jpg',
                    titlex: 'コンテナサービス（Container Service）',
                    textp: '速度が速く、価格が低く、包装、輸送、荷下ろし、保管などの海上輸送過程で、システムの管理を行う。',
                }, {
                    url: '@/assets/image/hiashangyshu3.jpg',
                    titlex: 'バルクサービス（Bulk Service）',
                    textp: 'より安価なレート、より良いサービス、より少ないハンドリングで、さらに海上運輸業務を取得し、 顧客のニーズに応え、信頼性の高いサービスと委託業務。',

                }, {
                    url: '@/assets/image/hiashangyshu4.jpg',
                    titlex: 'サービス利点（Service Advantage）',
                    textp: '大信物流は、世界の優良な海運会社と密接な関係を持ち、各海運会社のサービスを活用することで、より安全で迅速な運輸サービスを提供しています。',
                    textp2: 'また、大手海運会社との戦略的なサービス契約は、物流コストの削減という点でも大きなアドバンテージとなります。',
                    textp3: '多様な船会社と国内外のほとんどの荷主企業のネットワーク確保し、競争力のある運賃を実現して顧客に提供します。',
                }]
            }]
        }],
        avintion: [{
            card: [{
                text1: '航空運輸',
                text2: '国内航空貨物運輸、エアエクスプレス、緊急輸送、航空手荷物受託、ポート・トゥ・ポート、ドア・ツー・ドアサービスに特化。',
                text3: '大手航空会社の利点を生かし、中国の主要・中堅都市を網羅するサービス網の構築。',
                text4: '大手航空会社の航空貨物輸出入業務を請け負う総合航空貨物輸送事業。',
                text5: '多くの航空貨物会社と協力し、航空運輸ネットワークを構築し、顧客に高品質で満足度の高い総合的な航空輸送サービスを提供します。',
                text6: 'リーズナブルな価格、誠実なサービス、高い評価で、さまざまな顧客の多様なニーズにお応えします。',
                url: '@/assets/image/air-1.jpg'
            }],
            text: [{
                title: 'サービス紹介',
                text: [{
                    url: '@/assets/image/air-2.jpg',
                    titlex: 'ワンストップ輸送物流サービス（One-Stop Transporting Logistics Service）',
                    textp: '優良航空会社とのパートナーシップにより、顧客にご満足いただける適正な価格と年間を通じて安定した貨物輸送のスピードを実現。 航空輸送サービスの効率をよりよくするために、自社車両を使用。',
                }, {
                    url: '@/assets/image/air-3.jpg',
                    titlex: '継続輸送サービス（Continued Transport Service）',
                    textp: '継続輸送とは、一度降ろした貨物をもう一度積み、目的地や必要となる地点まで輸送するサービスです。 継続輸送では、広範囲なトランジット地点と豊富なトランジット経験がサービスの質を左右します。',

                }, {
                    url: '@/assets/image/air-4.jpg',
                    titlex: '海空共同運航便（Joint Services Of Sea And Air）',
                    textp: '海空共同運航便サービスとは、海上輸送の低コストと航空輸送のスピードという、両輸送手段の長所を組み合わせたサービスです。 韓国から中国沿岸部の港へ海上輸送し、内陸部へ空輸、または韓国から中国北部へ空輸。',
                    textp2: '港湾での航空輸送、南方港湾への海上輸送などを運用し、海上輸送のみと比較すると、輸送時間の短縮、航空輸送のみと比較すると運賃の節約になります。',
                }, {
                    titlex: 'サービス利点（Service Advantage）',
                    textp: '大信物流は航空輸出、航空輸入、国際速達、海空共同運航便複合輸送、通関、申告など、高品質でカスタマイズされた物流サービスを提供します。',
                    textp2: '豊富な情報・知識・経験を持つ総合物流企業として、航空貨物に関するリアルタイムな情報をお客様に提供し、航空機出発前の貨物輸送情報をチェックすることが可能。',
                    textp3: '全空域の状況、航空運賃、輸送時間・距離、航空会社の輸送ルート、積み替え（Transshipment）にかかる時間などを考慮し、顧客に適切なサービスを提供します。',
                    textp4: '世界各地に海外代理店を持ち、変化の激しい輸送環境においても、出発地、経由地、到着地の情報を迅速に収集・対応し、安全で信頼できる海・空の複合輸送サービスを提供しております。',
                }]
            }]
        }],
        landlocked: [{
            card: [{
                text1: '内陸運輸',
                text2: '大信物流は、中国国内の広範囲な物流ネットワークで、幅広い道路輸送サービスを提供します。',
                text3: '長年の経験、充実したサービス、競争力のある価格で、ワンランク上のサービスを提供します。',
                url: '@/assets/image/land-1.jpg'
            }],
            text: [{
                title: 'サービス紹介',
                text: [{
                    url: '@/assets/image/land-2.jpg',
                    img: '@/assets/image/land-3.jpg',
                    titlex: '内陸道路運輸',
                    textp: 'ボックス車、タンクローリー、ショーウィングカー、冷蔵車、冷凍車など、300台以上の輸送車両を保有。',
                    textp2: '中国大陸の輸送会社の中で韓国系物流会社は、第三者物流サービスにおいて豊富な経験を蓄積し、多くの専門人材を育成し、効率的な管理チームを構成しています。',
                }, {
                    url: '@/assets/image/land-4.jpg',
                    titlex: 'サービス利点（Service Advantage）',
                    textp: '中国全土の車両、ネットワークをリアルタイムで管理',
                }]
            }]
        }],
        warehousing: [{
            card: [{
                text1: '在庫管理物流',
                text2: '倉庫総面積は77,500m²を超え、10,000m²の荷役台が設置されています',
                text3: '倉庫には広い車道と便利な荷下ろし台、そして倉庫に隣接した事務所も備えられています。',
                text4: '24時間体制の監視・巡回、高度な消火設備により、貨物の安全な保管を実現。',
                text5: '在庫管理、仕分け、再梱包、計量、バーコード読み取り、ラベリング、パレット積み、フィルム包装など、総合的な物流業務を提供します。',
                url: '@/assets/image/warehouse-3 (2).jpg'
            }],
            text: [{
                title: 'サービス紹介',
                text: [{
                    url: '@/assets/image/warehouse-3 (3).jpg',
                    titlex: '倉庫の分布',
                    textp: '物流倉庫はそれぞれ、北京順義、北京通州、天津普洛斯物流園、重慶両江新区工業開発区、滄州経済開発区、威海臨港経済技術開発区、蘇州などに展開しています。',
                }, {
                    titlex: 'サービス利点（Service Advantage）',
                    url: '@/assets/image/warehouse-3 (1).jpg',
                    img: '@/assets/image/warehouse-4.jpg',
                    textp: '大信物流は、顧客に適したサービスを提供するために最新鋭の設備、完璧な機器、高度なITシステムをベースに、たゆまぬ挑戦を続け、高い顧客満足度を実現して参りました。',
                    textp2: '優秀な人材、安定したチーム、完璧な人材保護対策と設備は、『社員を大切に、長期的な発展に臨む』という会社理念を反映しています。',
                }]
            }]
        }],
        project: [{
            card: [{
                text1: 'プロジェクト物流',
                text2: '国際貿易の多様化に伴い、より総合的で専門的な物流サービスが求められています。',
                text3: '強い実力と強力な代理店網を持つ大信物流は、プロフェッショナルな国際複合一貫輸送サービスを提供しています。',
                text4: '顧客のために、より良い輸送ルートを設計し、輸送コストと時間を節約。',
                text5: '「ドアツードア」「ドアツーポート」「ヤード、ステーションツードア」など、さまざまな複合輸送サービスをお客様に提供し、船荷証券やマルチモーダル船荷証券を発行しています。',
                text6: '顧客は貨物を委託するのみで、直接配達が可能。',
                url: '@/assets/image/Project-1.jpg'
            }],
            text: [{
                title: 'サービス紹介',
                text: [{
                    url: '@/assets/image/Project-2.jpg',
                    textp: 'プロジェクトカーゴサービス（Project Cargo Service）',
                }, {
                    titlex: 'サービス利点（Service Advantage）',
                    url: '@/assets/image/Project-3.jpg',
                    textp: '輸送に使用する各種機械、装置、材料等の選定と輸送技術の検討。',
                    textp2: '貨物の種類に応じて最適な船腹と航空機を選択し、定刻・安全な輸送を実現',
                    textp3: '内陸輸送に関わる事前環境調査、探査ルート、障害物調査、輸送シミュレーション、特殊車両手配など',
                    textp4: '目的地までの通関、法律、慣習を事前に調査し、安全な輸送を提案',
                    textp5: '現地での免税・関税軽減通関業務の実施、L/C、C/O、大使館証明など貿易・物流サービスに関する総合的なアドバイザリーサービスの提供。',
                    textp6: '海外代理店を通じ、各国での出荷から目的地到着まで、輸送計画から追跡管理まで一貫した物流サービスを提供。',
                }]
            }]
        }],
        international: [{
            card: [{
                text1: '危険品運輸について',
                text2: '腐食性、自燃性、可燃性、毒性、爆発性などの性質を持つもの、',
                text3: '輸送、積み降ろし、保管中に人に対し傷害や命に係わる被害、財産の損害を引き起こす可能性があるもの、特別な保護が必要なものは危険物として分類されています。',
                text4: '危険品運輸とは、特殊な車両を使用し、規格外品の輸送のために編成された、特別な運輸の一種である。',
                text5: '危険品の輸送ができるのは、国の関係当局による厳しい審査を受け、危険物の輸送を安全に行うために適した施設・設備を有する者に限られています。',
                url: '@/assets/image/weixianxiao.jpg"'
            }],
            text: [{
                title: 'サービス紹介',
                text: [{
                    url: '@/assets/image/weixianpintext1.jpg',
                    titlex: '危険物道路輸送について',
                    textp: '内陸輸送と輸出入危険品業務の専門チームが、正確な情報のフィードバック、追跡輸送、完全な監視システム、各顧客にいつでも正確な輸送状況、顧客ゼロリスクと目的地への安全な到着を確保。',
                }, {
                    url: '@/assets/image/weixianpintext2.jpg',
                    img: '@/assets/image/weixianpintext3.jpg',
                    titlex: 'サービス利点（Service Advantage）',
                    textp: '完全な安全装備や熟練したオペレータ、お客様のニーズに合わせて様々な薬剤も追加可能。',
                    textp2: '正確な路線管理、信頼できるプロセス設計、ネットワーク化された緊急メカニズム、多様な通信手段、カスタマーサービスコールセンターによる集中追跡により、安全かつ迅速な貨物の配送を実現。',
                }]
            }]
        }],
        imaget: require('@/assets/image/warehouse-3 (3)ja.jpg'),
        imaget1: require('@/assets/image/warehouse-3 (1)ja.jpg'),
        'wedic1': '海運業務（詳細はこちら）',
        'wedic2': '空運業務（詳細はこちら）',
        'wedic3': '内陸運輸（詳細はこちら）',
        'wedic4': '在库管理業務（詳細はこちら）',
        'wedic5': 'プロジェクトロジスティクス（詳細はこちら）',
        'wedic6': '危険品運輸（詳細はこちら）',
        'title': '海上運輸',
        'li-p1': '高い能力を持つ専門チームと、世界中に広がる支社との完璧なグローバルネットワークサービス。',
        'li-p2': '物流ソリューションの設計から貨物追跡まで、さまざまな輸出入貨物のニーズに応えた総合的な通関・配送サービス。',
        'li-p3': 'バルク品の用船業務、定期船および不定期船の国際海運代理店業務。',
        'li-p4': '多様なビジネスを展開し、サプライチェーン・マネジメント・サービスやその他の総合的な高品質なサービスを顧客に提供。',
        'li-p5': '多くの船会社と長期的な契約を結び、低い価格で海上運輸を提供可能。',
        'title2': 'サービス紹介',
        'subheading': '危険品の陸上運輸',
        'subheading2': 'バルクサービス（Bulk Service）',
        'subheading3': 'サービス利点（Service Advantage）',
        'text-p1': '内陸輸送と危険品輸出入業務の専門チーム、リアルタイムかつ正確な情報のフィードバック、追跡輸送、監視カメラ、各顧客にいつでも正確な輸送状況、顧客ゼロリスクと目的地への安全な到着を確保。',
        'text-p2': 'より安価なレート、より良いサービス、より少ないハンドリングで、さらに海上運輸業務を取得し、 顧客のニーズに応え、信頼性の高いサービスと委託業務。',
        'text-p3': '大信物流は、世界の優良な海運会社と密接な関係を持ち、各海運会社のサービスを活用することで、より安全で迅速な運輸サービスを提供しています。',
        'text-p4': 'また、大手海運会社との戦略的なサービス契約は、物流コストの削減という点でも大きなアドバンテージとなります。',
        'text-p5': '多様な船会社と国内外のほとんどの荷主企業のネットワーク確保し、競争力のある運賃を実現して顧客に提供します。',
        'title3': '航空運輸',
        'li-p6': '国内航空貨物運輸、エアエクスプレス、緊急輸送、航空手荷物受託、ポート・トゥ・ポート、ドア・ツー・ドアサービスに特化。',
        'li-p7': '大手航空会社の利点を生かし、中国の主要・中堅都市を網羅するサービス網の構築。',
        'li-p8': '大手航空会社の航空貨物輸出入業務を請け負う総合航空貨物輸送事業。',
        'li-p9': '多くの航空貨物会社と協力し、航空運輸ネットワークを構築し、顧客に高品質で満足度の高い総合的な航空輸送サービスを提供します。',
        'il-p10': 'リーズナブルな価格、誠実なサービス、高い評価で、さまざまな顧客の多様なニーズにお応えします。',
        'subheading4': 'ワンストップ輸送物流サービス（One-Stop Transporting Logistics Service）',
        'subheading5': '継続輸送サービス（Continued Transport Service）',
        'subheading6': '海空共同運航便（Joint Services Of Sea And Air）',
        'text-p6': '優良航空会社とのパートナーシップにより、顧客にご満足いただける適正な価格と年間を通じて安定した貨物輸送のスピードを実現。 航空輸送サービスの効率をよりよくするために、自社車両を使用。',
        'text-p7': '継続輸送とは、一度降ろした貨物をもう一度積み、目的地や必要となる地点まで輸送するサービスです。 継続輸送では、広範囲なトランジット地点と豊富なトランジット経験がサービスの質を左右します。',
        'text-p8': '海空共同運航便サービスとは、海上輸送の低コストと航空輸送のスピードという、両輸送手段の長所を組み合わせたサービスです。 韓国から中国沿岸部の港へ海上輸送し、内陸部へ空輸、または韓国から中国北部へ空輸。',
        'text-p9': '港湾での航空輸送、南方港湾への海上輸送などを運用し、海上輸送のみと比較すると、輸送時間の短縮、航空輸送のみと比較すると運賃の節約になります。',
        'text-p10': '大信物流は航空輸出、航空輸入、国際速達、海空共同運航便複合輸送、通関、申告など、高品質でカスタマイズされた物流サービスを提供します。',
        'text-p11': '豊富な情報・知識・経験を持つ総合物流企業として、航空貨物に関するリアルタイムな情報をお客様に提供し、航空機出発前の貨物輸送情報をチェックすることが可能。',
        'text-p12': '全空域の状況、航空運賃、輸送時間・距離、航空会社の輸送ルート、積み替え（Transshipment）にかかる時間などを考慮し、顧客に適切なサービスを提供します。',
        'text-p13': '世界各地に海外代理店を持ち、変化の激しい輸送環境においても、出発地、経由地、到着地の情報を迅速に収集・対応し、安全で信頼できる海・空の複合輸送サービスを提供しております。',
        'title4': '内陸運輸',
        'subheading7': '内陸道路運輸',
        'text-p14': '大信物流は、中国国内の広範囲な物流ネットワークで、幅広い道路輸送サービスを提供します。',
        'text-p15': '長年の経験、充実したサービス、競争力のある価格で、ワンランク上のサービスを提供します。',
        'text-p16': 'ボックス車、タンクローリー、ショーウィングカー、冷蔵車、冷凍車など、300台以上の輸送車両を保有。',
        'text-p17': '中国大陸の輸送会社の中で韓国系物流会社は、第三者物流サービスにおいて豊富な経験を蓄積し、多くの専門人材を育成し、効率的な管理チームを構成しています。',
        'text-p18': '中国全土の車両、ネットワークをリアルタイムで管理',
        'title5': '倉庫の分布',
        'title6': '在庫管理物流',
        'li-p11': '倉庫総面積は77,500m²を超え、10,000m²の荷役台が設置されています',
        'li-p12': '倉庫には広い車道と便利な荷下ろし台、そして倉庫に隣接した事務所も備えられています。',
        'li-p13': '24時間体制の監視・巡回、高度な消火設備により、貨物の安全な保管を実現。',
        'li-p14': '在庫管理、仕分け、再梱包、計量、バーコード読み取り、ラベリング、パレット積み、フィルム包装など、総合的な物流業務を提供します。',
        'text-p19': '物流倉庫はそれぞれ、北京順義、北京通州、天津普洛斯物流園、重慶両江新区工業開発区、滄州経済開発区、威海臨港経済技術開発区、蘇州などに展開しています。',
        'text-p20': '大信物流は、顧客に適したサービスを提供するために最新鋭の設備、完璧な機器、高度なITシステムをベースに、たゆまぬ挑戦を続け、高い顧客満足度を実現して参りました。',
        'text-p21': '優秀な人材、安定したチーム、完璧な人材保護対策と設備は、『社員を大切に、長期的な発展に臨む』という会社理念を反映しています。',
        'subheading8': 'プロジェクトカーゴサービス（Project Cargo Service）',
        'title7': 'プロジェクト物流',
        'li-p19': '国際貿易の多様化に伴い、より総合的で専門的な物流サービスが求められています。',
        'li-p20': '強い実力と強力な代理店網を持つ大信物流は、プロフェッショナルな国際複合一貫輸送サービスを提供しています。',
        'li-p21': '顧客のために、より良い輸送ルートを設計し、輸送コストと時間を節約。',
        'li-p22': '「ドアツードア」「ドアツーポート」「ヤード、ステーションツードア」など、さまざまな複合輸送サービスをお客様に提供し、船荷証券やマルチモーダル船荷証券を発行しています。',
        'li-p23': '顧客は貨物を委託するのみで、直接配達が可能。',
        'text-p22': '輸送に使用する各種機械、装置、材料等の選定と輸送技術の検討。',
        'text-p23': '貨物の種類に応じて最適な船腹と航空機を選択し、定刻・安全な輸送を実現',
        'text-p24': '内陸輸送に関わる事前環境調査、探査ルート、障害物調査、輸送シミュレーション、特殊車両手配など',
        'text-p25': '目的地までの通関、法律、慣習を事前に調査し、安全な輸送を提案',
        'text-p26': '現地での免税・関税軽減通関業務の実施、L/C、C/O、大使館証明など貿易・物流サービスに関する総合的なアドバイザリーサービスの提供。',
        'text-p27': '海外代理店を通じ、各国での出荷から目的地到着まで、輸送計画から追跡管理まで一貫した物流サービスを提供。',
        'title8': '危険品運輸について',
        'title9': '危険物道路輸送について',
        'li-p24': '腐食性、自燃性、可燃性、毒性、爆発性などの性質を持つもの、',
        'li-p25': '輸送、積み降ろし、保管中に人に対し傷害や命に係わる被害、財産の損害を引き起こす可能性があるもの、特別な保護が必要なものは危険物として分類されています。',
        'li-p26': '危険品運輸とは、特殊な車両を使用し、規格外品の輸送のために編成された、特別な運輸の一種である。',
        'li-p27': '危険品の輸送ができるのは、国の関係当局による厳しい審査を受け、危険物の輸送を安全に行うために適した施設・設備を有する者に限られています。',
        'text-p28': '内陸輸送と輸出入危険品業務の専門チームが、正確な情報のフィードバック、追跡輸送、完全な監視システム、各顧客にいつでも正確な輸送状況、顧客ゼロリスクと目的地への安全な到着を確保。',
        'text-p29': '完全な安全装備や熟練したオペレータ、お客様のニーズに合わせて様々な薬剤も追加可能。',
        'text-p30': '正確な路線管理、信頼できるプロセス設計、ネットワーク化された緊急メカニズム、多様な通信手段、カスタマーサービスコールセンターによる集中追跡により、安全かつ迅速な貨物の配送を実現。',
    },
    advocacy: {
        'title1': 'ニュース',
        'title2': '業界知識',
        'title3': '2022年物流行业发展趋势与工业物流崛起',
        'title4': '2022年世界国际海运公司',
        'title5': '物流机械设备的发展趋势',
        'title6': '物流快递行业年度期盼的新机遇',
        'title7': '进军国际市场开拓物流发展新机遇',
        'title8': '物流从业必备知识：货车分类',
        'title9': '易燃易爆危险品存放原则',
        'title10': '集装箱知识',
        'title11': '危险品分类',
        'title12': '新闻公告',
        'title13': '行业知识',
        'title14': '海运公司排行前十：',
        'textp1': '在过去两年中、物流行业呈加速发展态势。2022年、这一趋势将随新技术的应用而进一步发展、并将在操作流程、运输、仓储、安全性',
        'textp2': '据Alphainer最新运力数据显示,截至2022年7月10日,全球班轮公司运力前10名与上月比保持不变,目前全球在运营集装箱船总数为6406艘,总运力增加到2579.3291万TEU',
        'textp3': '随着生产和物流规模的扩大,以及物流自动化程度的提高,物流机械设备在现代化生产和物流中应用越来越广,作用也越来越大;而现代化生产和现代化物流又...',
        'textp4': '26日,京广高铁正式开通运营。羊城晚报记者获悉,武广高铁运营后已有广东快递企业试水广州长沙间的...',
        'textp5': '5月11日,全国首列“南亚国际货运列车”在兰启程,10天后,这列满载着“中国制造”的货运...',
        'textp6': '目前从交通运输行业统计来讲，吧运营货车分成四类，普通货车、专用货车、牵引车和挂车，2020年共有营运载货汽车1110.28万辆，其普通货车414.14万辆，专用货车50.67万辆，牵引车310.8万辆',
        'textp7': '易燃易爆化学危险品的整装存储，往往存放的品种多，性质危险而复杂，比较难于管理。而散装存储量比较大，设备多，技术条件复杂，一旦发生事故难以施救。',
        'textp8': '集装箱运输是国际贸易货物多试联运过程中的重要运输方式，实现全球范围内的船舶、港口、航线、公路、中转站、桥梁、隧道多式联运相配套的物流系统。',
        'textp9': '凡具有爆炸、易燃、毒害、腐蚀、放射性等物质、在运输、装卸和贮存保管过程中，容易造成人事伤亡和财产损毁而需要特别防护的货物，均属危险品货物。',
        'time1': '2022-10-10',
        'time2': '2022-09-13',
        'time3': '2019-03-12',
        'time4': '2019-03-12',
        'time5': '2019-03-12',
        'time6': '2022-10-10',
        'time7': '2022-09-13',
        'time8': '2022-09-13',
        'time9': '2019-03-12',
        'textp1': '在过去两年中，物流行业呈加速发展态势。2022年，这一趋势将随这新技术的应用而进一步发展，并将在操作流程、运输、仓储、安全性，以及供应链稳定性等诸多方面产生持续而广泛的影响。数字化和可持续性将深刻改变物流行业的工作方式。',
        'textp2': '物流4.0开启物流数字化时代新篇章',
        'textp3': '一段时间以来，我们越来越多地谈到物流4.0，这一概念源自工业4.0。数字化将成为新时代物流发展的根基，更准确地说，将基于流程自动化、设备网络化和企业间的联合。物流4.0旨在简化流程、提升效率，同时维护全球供应链的稳定，尤其是在充满挑战的时期，及时发现并消除供应链风险至关重要。',
        'textp4': '与物流4.0息息相关的大数据、机器使用和自动化，以及网络化将对物流的未来发展产生重要影响。数字化在道路交通中也将发挥巨大作用。一方面，自动驾驶技术的发展将有助于提高道路安全，另一方便也有助于缓解交通拥堵。',
        'textp5': '物流自动化的大数据和网络',
        'textp6': '数据是物流的关键组成部分，随着数据量的不断提升，其作用愈加重要。智能设备的使用使越来越多的数据可以被收集和交换。物流企业可根据不同的数据流进行分析、预测或判断发展趋势。',
        'textp7': '有效部署大数据和市场参与者之间的智能网络，有利于维护整个供应链的稳定，预测及避免潜在“断链”风险，并及时制定替代计划，以保证物流时效。同时，大数据也可用于优化运输路径，提升效率。此外，网络化不仅有助于优化风险管理，还能够提高供应链的透明度，帮助相关企业了解运输过程中的具体信息。',
        'textp8': '机器人化加速物流行业发展',
        'textp9': '在很多科幻电影中都出现了人类与机器人协同的场景。虽然目前机器人还没有那么全能，但已经可以执行简单的任务，并在工作中发挥作用。机器人被广泛试运行使用，特别是在电子商务和消费品仓储领域，主要用来减少人工工作量。机器人可为新订单拣选货物，并在库区内运输重货，通过预先计算规划最优路径，高效把货物送到交接点，再由另外的机器人继续完成后面的流程。传感器的使用可有效避免机器人在行进过程中的碰撞。',
        'textp10': '得益于电子商务的蓬勃发展，以及由此带来物流需求的快速增长，更多机器人将会投入试运营，其数字化能力也将得到进一步提升。',
        'textp11': '多式联运物流运输将成主流‍',
        'textp12': '为了防范和避免供应链中断的风险，多样化的物流运输方式可有效防范、降低供应链中断的风险。去年苏伊士运河堵塞给全球海运带来很大影响，凸显出单一运输方式的局限性。多式联运覆盖路运、汽运、海运和空运，一旦一种运输方式出现问题，可迅速切换至另一种方式，从而有效避免运输延误，维持供应链稳定。多元化物流渠道是实现多式联运的先决条件。',
        'textp13': '可持续物流进一步发展',
        'textp14': '可持续发展是未来几年乃至几十年的大趋势。物流行业将为温室气体减排做出重大贡献。据世界经济论坛研究统计，物流运输占全球碳排放总量的5.5%。因此，为未来的物流发展提供环保解决方案势在必行。',
        'textp15': '过去，速度和成本是选择运输方式的决定性因素。如今，可持续性变得越来越重要。除了车辆电气化，清洁燃料的使用和相关技术的研发对于减少二氧化碳和其他排放至关重要。此外，仓储物流也是物流可持续发展的重要领域，如包材的选择，以及物流公司如何获取能源途径等方面。',
        'textp16': '碳排放报告也是进一步减排的有效工具，未来也将更加普及。报告可提供供应链透明度，供企业深入了解其二氧化碳和污染物的排放情况。此外，可持续发展也将成为企业形象和竞争力的重要组成部分，促使企业设法减少排放量，将对环境的影响降到最低。',
        'textp17': '总结与展望',
        'textp18': '今年，数字化将在物流行业进一步发力，将有更多技术创新应用于简化流程，提高全球供应链稳定性。在可持续发展的大趋势下，整个物流行业将在2022年开启发展新格局，在注重环保的同时，保持盈利能力。我们已处于数字化和气候变化的大变局之中，及早适应并积极引入新技术，顺势而为的市场参与者将乘势而上。',
        'li-p1': '1、马士基航运有限公司（全球最大的航运公司，总部设于丹麦歌本哈根）',
        'li-p2': '2、地中海航运公司（世界第二大航运公司）',
        'li-p3': '3、法国达飞海运集团（世界10大航运公司第三名）',
        'li-p4': '4、中国远洋运输（集团）公司',
        'li-p5': '5、赫伯罗特船舶公司',
        'li-p6': '6、中国长荣海运',
        'li-p7': '7、中国香港东方海外货柜航运公司',
        'li-p8': '8、中国台湾阳明海运集团',
        'li-p9': '9、日本商船三井MOL',
        'li-p10': '10、日本邮船株式会社（NYK）',
        'h3-p1': '第十名、日本邮船株式会社（NYK）',
        'h3-p2': '第九名、日本商船三井MOL',
        'h3-p3': '第八名、中国台湾阳明海运集团',
        'h3-p4': '第七名、中国香港东方海外货柜航运公司',
        'h3-p5': '第六名、中国长荣海运',
        'h3-p6': '第五名、赫伯罗特船舶公司',
        'h3-p7': '第四名、中国远洋运输（集团）公司',
        'h3-p8': '第三名、法国达飞海运集团',
        'h3-p9': '第二名、地中海航运公司',
        'h3-p10': '第一名、马士基航运有限公司',
        'p-text1': '日本三大船务公司之一，也是世界上历史最悠久和规模最大的航运公司之一，由日本在早期的三菱财阀所创立。',
        'p-text2': '该公司总部位于日本千代地区，拥有大约800艘船只，包含集装箱船、邮轮、游轮等等。',
        'p-text3': '世界10大航运公司中第二个日本船运公司，是日本经连的一部分，它们家的鳄鱼标志可以在世界各地大港口的集装箱上看到',
        'p-text4': '位于中国台湾基隆的元阳运输公司，成立于1972年，如果追溯历史还可以清朝期间。',
        'p-text5': '该公司拥有85艘船舶，拥有420万载重吨位34.6万标准箱吞吐量，该公司服务于全球70多个国家',
        'p-text6': '世界最大的综合国际集装箱运输公司之一，在全球70多个国家设立有320个办事处。该公司拥有57艘不同级别的船只，包括极端天气下的冰级船。',
        'p-text7': '是一家集装箱运输公司，总部设立于中国台湾桃园市，其主要线路为远东至北美洲、中美洲以及加勒比地区。该公司在全球80个国家拥有240个港口。',
        'p-text8': '德国跨国船运公司，成立与1970年，由德国两家运输公司合并而成，拥有175艘集装箱船',
        'p-text9': '中国最大的航运企业，截至2011年就覆盖了全球160多个国家1600多个港口。截止2016年总船队规模稳居全球第一',
        'p-text10': '成立于1978年，总部位于马赛，是法国第一，世界10大航运公司第三名。该公司在全球150多个国家420个港口拥有200多条航线',
        'p-text11': '成立于1970年的瑞士航运公司，是世界第二大航运公司，在全球拥有350个机构，员工超过2.8万人，拥有471艘集装箱船。',
        'p-text12': '丹麦最大的航运商业集团，也是世界最大的航运公司，总部设立于丹麦歌本哈根，在全球130个国家设有办事处，员工超过8',
        'infor1': '　　随着生产和物流规模的扩大，以及物流自动化程度的提高，物流机械设备在现代化生产和物流中应用越来越广，作用也越来越大',
        'infor2': '纵观物流机械设备发展现状可以看出，为适应现代物流的需要，物流机械设备有如下发展趋势',
        'infor3': '1.大型化和高速化',
        'infor4': '　　大型化是指设备的容量、规模、能力越来越大，高速化是指设备的运转速度、运行速度、识别速度、运算速度大大加快。现代社会经济快速发展，使得生产和物流规模不断扩大，为了提高作业效率和规模效益，大型、高速的物流机械需求量不断增长。物流机械设备的起重量、载重量、生产率、作业能力越来越大，工作速度越来越快',
        'infor5': '2.实用化和轻型化',
        'infor6': '　　物流机械设备是现代化、自动化物流的重要物质技术基础。物流机械设备要求使用方便，容易维护、操作，具有优异的耐久性、无故障性和良好的经济性，以及较高的安全性、可靠性。因此，今后会更加注重开发使用性能好、成本低、可靠性高的物流机械设备。',
        'infor7': '3.专用化和通用化',
        'infor8': '　　物流是社会经济发展的产物，必然随着社会经济的发展而呈现多样化的特征。多样化的特征反映了对物流机械设备需求的多样化，从而使物流机械设备具有多种多样的品种且',
        'infor9': '4.自动化和智能化',
        'infor10': '　　将机械技术和电子技术相结合，将先进的微电子技术、电力电子技术、光缆技术、液压技术、模糊控制技术应用到机械的驱动和控制系统中，实现物流机械设备的自动化和智能化将是今后的发展方向。例如，大型起重机的新一代电气控制装置已发展为全电子数字化控制系统，由全数字化控制驱动装置、可编程序控制器、故障诊断及数据管理系统、数字化操纵给定检测等装置组成。先进技术的应用使起重机具有更高的柔性，从而提高了单机综合自动化水平。运输设备的操作更多的转向依靠仪表、信号和辅助驾驶系统智能化程度越来越高。海运、空运中的自动驾驶系统、自动化立体仓库中的送取货小车、公路运输智能交通系系统',
        'infor11': '　　智能式搬运车AHV( AutonomousHandlingVehicle)也将会得到广泛应用。AHV的形状类似于现在使用的AGV，但装有两只通用的机械手，在工作时依靠起视觉作用的工业摄像机，对货物的位置和大小进行判断，如同人一样可以用机械手自由地搬运重达200--300千克的货物。AHV具有协同作业的功能，搬运的货物过长、过重时，可以由两台以上的ARV协同作业进行搬运，这样会大大减少ARV的规格型号，便于管理。数台同一规格AHV的联合运作，其作业能力可提高很多。',
        'infor12': '5.成套化和系统化',
        'infor13': '　　在物流机械设备单机自动化的基础上，通过计算机把各种物流机械设备组成一个物流机械设备集成系统，通过控制室的控制，与物流系统协调配合。这类物流机械设备自动化程度较高，具有信息处理功能，可将传感器检测出来的各种信息实施存储、运算、逻辑判断、变换等处理加工，进而向执行机构发出控制指令。这类物流机械设备还具有较好的信息输人输出接口，实现信息全部、准确、可靠地在整个物流机械集成系统中的传输。物流机械设备通过系统集成，能形成不同机种的优先匹配和组合，取长补短，发挥出优先功效。以后将发展的有工厂生产搬运自动化系统、货物配送集散系统、集装箱装卸搬运系统、货物的自动分拣与搬运系统等。',
        'infoe-text1': '26日，京广高铁正式开通运营。羊城晚报记者获悉，武广高铁运营后已有广东快递企业试水广州长沙间的高铁快件运输，如今京广高铁的开通更将给我国物流、快递业带来新机遇。',
        'infoe-text2': '“此前武广高铁开通，广州长沙间就有高铁快件运输。”广州一家知名民营快递企业负责人告诉记者，目前快递走公路运输的占了80%左右，走航空的约占15%。其实无论从时效和成本来看，高铁比公路都有优势。京广高铁开通运营后，中间很多节点城市都是快递服务需求旺盛的地区，空间很大。',
        'infoe-text3': '据了解，12月中旬，国家邮政局、国家发改委综合运输研究所、中国快递协会就组成联合调研组，赴广东省对快递企业利用高铁运输快件等开展实地调研。',
        'infoe-text4': '除了快递业，大宗商品运输等传统物流业预计也将从京广高铁开通、京广线货运能力提升中受益。数据显示，仅武广高铁开通后，京广线货运能力就提升了10.9%，使长期运能受限制的煤炭、石油、粮食等物资及港澳外贸货运的运输需求得到改善。',
        'infoe-text5': '5月11日，全国首列“南亚国际货运列车”在兰启程，10天后，这列满载着“中国制造”的货运列车，经过铁路、公路运输，将到达尼泊尔首都加德满都。这标志着兰州在“一带一路”建设中，在开启“兰州号”中亚、欧洲货运专列之后，又一次迈出了向西向南阿开放的重要一步',
        'infoe-text6': ' 　　“一带一路”重大战略构想提出两年多来，沿线国家纷纷响应。去年，中国跟30多个国家签订了共建“一带一路”谅解备忘录，一些主要的“一带一路”骨架已经开始搭建，比如中蒙俄经济走廊、中国—中南半岛的经济走廊、新亚欧大陆桥经济走廊、中国—中亚—西亚走廊、中巴经济走廊、孟中印缅经济走廊等，有的已取得喜人成果。先就中欧来说，以往，中欧之间的货运主要靠空运、海运以及公路运输，空运和公路运输成本高，海运耗时长。中欧班列以其运距短、速度快、安全性高的特征，以及安全快捷、绿色环保、受自然环境影响小的优势，已成为国际物流中陆路运输的骨干方式。',
        'infoe-text7': ' 　　国际货运的前景十分广阔，具有极高的性价比。众所周知，过去我们面向南亚和东南亚的物流贸易主要通过海运完成，此次开通的铁路货运专列可谓开拓了陆路运输的途径，将充分发挥铁路运输的优势。可以想象，今后由铁路带动各个国家和城市之间的经济贸易、货物往来、人员流动、文化交流与技术支持，必将强化各国间的“城市”效应，“一带一路”的发展格局也必将在互帮、互助、互进中建立起世界各国共同发展的合作体系。随着科技的互补、资源的互助，铁路也会在发展中迈开步伐，终铺就一条世界铁路运输线路图。 ',
        'infoe-text8': ' 　　现在“一带一路”已成为一种趋势，每一条国际铁路线的开通都对沿线国家与地区带来新一轮的发展机遇，各产业应以全新的发展思路来迎接这一崭新的时代，携手创造共赢。随着和南亚铁路携手合作，我们的铁路也在不断“升级”提速，我们相信，“南亚国际货运列车”必将成为“一带一路”大战略上国际货运的“明星产品”，也将拉动共同的经济发展从而惠及人民。',
        'textd-p1': '目前从交通运输行业统计来讲，把营运货车分成四类，普通货车、专用货车、牵引车和挂车，2020年共有营运载货汽车1110.28万辆，其中普通货车414.14万辆，专用货车50.67万辆，牵引车310.84万辆，挂车334.63万辆。其中的专用货车，包括了集装箱车辆、冷藏车辆、罐式车辆以及大件和危货运输车。',
        'textd-p2': '就一般货车而言，可以从载重、厢体结构、驾驶室、驱动方式、车长、用能结构、排放标准等去进行划分。专用车辆也可以放到厢体结构中，属于特殊厢体结构。挂车本身也是一个系列，根据《货运挂车系列型谱》（GB/T 6420-2017），挂车包括了栏板式挂车、仓栅式挂车、厢式、低平板式、车辆运输车挂车（又分为中置轴和半挂）、液体运输车、颗粒物料运输半挂车、集装箱半挂车、冷藏和保温运输半挂车。与一般货车按照厢体结构分类基本相同。因此，我们可以从载重、厢体结构、驾驶室形态、车辆驱动轴、车长、用能结构以及车辆排放7个维度，对货车类型进行细分',
        'textd-p3': '一、按载重分',
        'textd-p4': '目前常用的，一般分为微卡、轻卡、中卡、重卡四种：',
        'textd-p5': '微卡：总质量＜1.8吨。',
        'textd-p6': '轻卡：1.8吨＜总质量≤6吨。',
        'textd-p7': '中卡：6吨＜总质量≤14吨 。',
        'textd-p8': '重卡：总质量＞14吨。',
        'textd-p9': '2019年轻型民用载货汽车拥有量为1901万辆，占民用载货汽车的68.3%；重型民用载货汽车拥有量为762万辆，占民用载货汽车的27.37%；中型民用载货汽车拥有量为116万辆，占民用载货汽车的4.18%；微型民用载货汽车拥有量为4万辆，占民用载货汽车的0.15%。以轻、重型货车为主。',
        'textd-p10': '对于车长小于 6000mm 且总质量小于 4500kg 的轻型载货汽车，上蓝牌，允许在城市内行驶，就是我们常听到的蓝牌轻卡。根据新的《收费公路车辆通行费车型分类》，4.2米蓝牌轻卡成为1、2类车型的分界标，降低了蓝牌轻卡的收费。',
        'textd-p11': '二、按车厢结构分',
        'textd-p12': '由于运输货物的特性不同，货车（挂车）车箱厢体差异较大，一般来讲，可以分为仓栅式、栏板式、（低）平板式、自卸车、厢式、集装箱车、罐式以及中置轴。',
        'textd-p13': '其中，平板式包括普通平板和低平板专用车。普通平板半挂车，车长最长13米。新出台的《低平板半挂车技术规范》（JT/T 1264-2020），明确无论是低平板专用半挂车还是低平板增强半挂车，车长都不能超过13.75米。',
        'textd-p14': '低平板车也是轿运车的主要车型。2018年7月1日起开始只允许以下三种车运输商品车：平头铰接列车车长不超过17.1m、长头不超过18.1米，车宽不超过2.55m，载货后车高不超过4.2m；中置轴整车车长不超22米，车宽不超过2.55m，载货后车高不超过4.2m。',
        'textd-p15': '三种车型的转载情况如下图所示：',
        'textd-p16': '中置轴列车',
        'textd-p17': '此外，对于17.5米大板车，发改委、工信部曾于2007年12月21日发布公告，对17.5米长低平板半挂车等车型予以撤销，并自2007年12月31日起不再作为办理车辆注册登记的依据。而低平板半挂车的使用年限最长是15年，因此目前业内有了2022年17.5米大板将全面淘汰的说法。',
        'textd-p18': '中置轴属于挂车的一种特殊类型（挂车分为全挂、半挂、中置轴）。2016年颁布的《GT/T 1589-2016汽车、挂车及汽车列车外廓尺寸、轴荷及质量限值》标准中新增了中置轴货运列车的选项，中置轴货运列车的宽度为2.55米，最大长度达到了史无前例的22米，也就是前面看到的轿运车车型。部分品牌的中置轴挂车货箱容积可以达到130立方米，对于未来的快递甩挂运输绝对是一大优势。',
        'textd-p19': '三、按驾驶室分',
        'textd-p20': '可以分为长头、短头、平头和偏置四类。平头车发动机就在驾驶室下方，短头车发动机有一半在驾驶室下方，长头车发动机就位于驾驶室前方。',
        'textd-p21': '在我国，目前大家在路面上看到的绝大部分都是平头车，零星可能会看到几辆长头车。但是时间倒退几十年的话，结果是反着的。新中国生产的第一辆卡车，解放CA10就是长头车。随着汽车保有量的提升和提高道路安全的考虑，国家出台相关标准对汽车的外廓界限进行了明确要求，车头越长、货箱就越短，拉的货就越少，由此促使平头车开始盛行。',
        'textd-p22': '平头卡车',
        'textd-p23': '偏置式卡车',
        'textd-p24': '四、按驱动轴分',
        'textd-p25': '我们经常听见货车有4x2、6x2、6x4之类的说法，这就是按照驱动方式对卡车进行分类。前一个数字是车轮总数，后一个数字是驱动轮数。例如4x2，表示货车有4个车轮（两根轮轴即双桥），其中有2个驱动车轮（一根驱动轴即单桥）。同理，6*4的货车有3根轮轴，其中有2个是驱动轴。',
        'textd-p26': '我们常常听到前四后八、前四后四。所谓“前四后八”就是四轴车，前四是双桥4个轮，后八也是双桥，每个桥的左右各2个轮，每个桥4个轮，就是8个轮子驱动。“前四后四”是三轴车，前桥是双桥4轮，后面是单桥，每个桥的左右各2个轮，一共4轮。',
        'textd-p27': '五、按车厢长分',
        'textd-p28': '我们常听到的几款车型，如4米2、9米6、13米5等，都指的是车厢长度，不是《GT/T 1589-2016汽车、挂车及汽车列车外廓尺寸、轴荷及质量限值》标准中车长的概念。',
        'textd-p29': '常见几款车型规格及载重如下：',
        'textd-p30': '六、按用能及排放分',
        'textd-p31': '目前货车仍以柴油引擎作为动力来源，同时有部分轻型货车使用汽油、石油气、天然气以及电力等新能源。根据《中国机动车环境管理年报》，2017年货车保有量2341万辆，其中柴油车保有量1690.9万辆，占比72.2%。按排放标准阶段划分，国Ⅱ及之前排放标准的柴油货车35万辆，Ⅲ标准的柴油货车841.2万辆，国Ⅳ标准的柴油货车716.8万辆，国Ⅴ及以上排放标准的柴油货车97.9万辆。',
        'textd-p32': '2019年生态环境部等11部委联合印发《柴油货车污染治理攻坚战行动计划》，明确提出自2019年7月1日起，重点区域、珠三角地区、成渝地区提前实施机动车国六排放标准。2020年年底前，京津冀及周边地区、汾渭平原加快淘汰国三及以下排放标准营运柴油货车100万辆以上。各地也相继出台了国Ⅲ货车限行和淘汰补贴的相关政策，加速国Ⅲ货车淘汰。',
        'tretal1': '（八）腐蚀性物品',
        'tretal2': '腐蚀性物品，一般与其他种类的物品之间和腐蚀性物品中的有机与无机腐蚀品之间，酸性与碱性物品之间，可燃体固体之间，都应单独仓间存放，不可混储。',
        'tretal3': '1．腐蚀性物品之间',
        'tretal4': '（1）溴与硝酸、硫酸等混合，能加强其腐蚀性或燃烧，应隔离后存放。',
        'tretal5': '（2）过氧化氢易与硝酸起反应放出大量气体，遇三氯化磷等会起脱水作用，产生高温，甚至发生爆炸。',
        'tretal6': '2．腐蚀性物品与可燃液体之间',
        'tretal7': '有机酸性腐蚀品与乙类可燃液体之间要隔离后储存，有机碱性腐蚀品与可燃液体之间可同库储存，但堆垛须间隔2米以上。',
        'tretal8': '3．腐蚀性物品与可燃固体之间，无机碱性腐蚀品与乙类可燃固体之间可隔离存放。',
        'tretal9': '4．其他碱性腐蚀品均应单独仓间存放，不可混储。',
        'tretal10': '（七）毒害品',
        'tretal11': '1．无机毒害品与无机氧化剂之间和有机毒害品的固体与硝酸的有机衍生物之间应隔离存放',
        'tretal12': '2．无机毒害品与氧化（助燃）气体，应隔离存放，与不燃气体可同库存放；有机毒害品与不燃气体应隔离存放；',
        'tretal13': '3．液体的有机毒害品与可燃液体可隔离后存放；',
        'tretal14': '4．有机毒害品的固体与乙类燃烧固体可同储，但与甲类易燃固体应隔离后存放，无机毒害品与乙类易燃固体可隔离后存放。',
        'tretal15': '5．有机毒害品的固体与液体之间，以及与无机毒害品之间，均应隔离后存放；有机毒害品的液体与液体，固体与固体之间，无机的剧毒品与有毒品之间均可同库储存。。',
        'tretal16': '6．其他种类物品均不可同库存放',
        'tretal17': '（六）氧化剂和有机过氧化物',
        'tretal18': '1．氧化剂和有机过氧化物之间',
        'tretal19': '甲类无机氧化剂与有机氧化剂特别是有机过氧化物不能同库储存。',
        'tretal20': '2．氧化剂与压缩气体和液化气体',
        'tretal21': '甲类氧化剂与易燃或剧毒气体不可同库储存，因为甲类氧化剂的氧化能力强，与剧毒气体或易燃气体接触容易引起燃烧或钢瓶爆炸。',
        'tretal22': '乙类氧化剂与压缩和液化气体可隔离储存，即保持2米以上的间距，与惰性气体可同库存放。',
        'tretal23': '3．氧化剂与自燃、易燃，遇湿易燃物品，一般不同库储存。',
        'tretal24': '4．氧化剂与毒害品',
        'tretal25': '无机氧化剂与毒害品应隔离储存，有机氧化剂毒害品可以同库隔离储存，但与有可燃性的毒害品不可同库储存。',
        'tretal26': '5．氧化剂与腐蚀性物品',
        'tretal27': '有机过氧化物不得与溴和硫酸等氧化性腐蚀品同库储存。',
        'tretal28': '6．氧化剂（指无机氧化剂）不得与松软的粉状物同库储存。',
        'tretal29': '（五）遇湿易燃物品',
        'tretal30': '1．遇湿易燃品不得与自燃物品同库存放。因为自燃物品危险性大，见空气即着火，且黄磷、651除氧催化剂等，包装用水作稳定剂，一旦包装破损或渗透都有引起着火的危险。',
        'tretal31': '2．遇湿易燃物品与氧化剂不可同库存放。因为遇湿易燃物品是还原剂，遇氧化剂会剧烈反应，发生着火和爆炸。',
        'tretal32': '3．遇湿易燃物品与腐蚀性物品之间：因为溴、过氧化氢、硝酸、硫酸等强酸，都具有较强的氧化性，与遇水燃烧物品接触会立即着火或爆炸。且过氧化氢还含有水，也会引起着火爆炸，所以不得同库存放。与盐酸、甲酸、醋酸和含水碱性腐蚀品如液碱等，亦应隔离存放。',
        'tretal33': '4．遇湿易燃物品与含水的易燃液体和稳定剂是水的易燃液体，如己酸、二硫化碳等，均不得同库存放。',
        'tretal34': '5．遇湿易燃物品之间；活泼金属及其氢化物可同库存放；电石受潮后产生大量乙炔气，其包装易发生爆破，应单独存放。磷化钙、硫化钠、硅化镁等受潮后能产生大量易燃的毒气和易自燃的毒气，因此，亦应单独存放。',
        'tretal35': '（四）自燃物品',
        'tretal36': '1．甲类自燃物品，不得与爆炸品、氧化剂、氧化性气体（助燃）、易燃液体、易燃固体同库存放。',
        'tretal37': '2．黄磷、651除氧催化剂，不得与遇湿易燃物品同库存放。硼、锌、铝、锑、碳氢化合物类自燃物品与遇湿易燃物品可隔离储存。',
        'tretal38': '3．自燃物品之间：甲类自燃物品与乙类自燃物品与黄磷、651除氧催化剂不得同库放。',
        'tretal39': '4．腐蚀性物品，溴、硝酸、过氧化氢（40%的双氧水）具有较强的氧化性，自燃物品与之不可同库存放。与盐酸、甲酸、醋酸和碱性腐蚀品亦不准同库存放或隔离存放。',
        'tretal40': '（三）易燃固体',
        'tretal40': '1．与自燃物品：因为甲类自燃物品性质不稳定，可以自行氧化燃烧，会引起易燃固体的燃烧，所以不能同库储存。与乙类自燃物品亦应隔离储存。 ',
        'tretal42': '2．与遇湿易燃物品：因和遇湿易燃物品灭火方法不同，且有的性质相互抵触，不能同库储存。',
        'tretal43': '3．与氧化剂：因为易燃固体都有很强的还原性，与氧化剂接触或混合有引起着火爆炸的危险，所以都不能同库存放。',
        'tretal44': '4．与腐蚀性物品：与具有氧化性的腐蚀性物品如：溴、过氧化氢、硝酸等不可同库储存，与其他酸性腐蚀性物品可同库隔离存放，但发孔剂H（71011）与某些酸作用能引起燃烧，所以不宜同库存放。',
        'tretal45': '5．易燃固体之间：金属氨基化合物类、金属粉末、磷的化合物类等与其他易燃固体不宜同库储存。因为他们灭火方法和储存保养措施不同，硝化棉、赤磷、赛璐珞、火柴等均宜专库储存。樟脑、萘、赛璐珞制品，虽属乙类易燃固体，但挥发出来的蒸气和空气可形成爆炸性的混合气体，遇着火源容易引起燃烧爆炸，储存条件要求高，也宜专库储存。',
        'tretal46': '（二）易燃液体',
        'tretal47': '易燃液体，不仅本身易燃，而且大都具有一定的毒性。如甲醇、苯、二硫化碳等，原则上应单独存放。但因各种条件的限制，不得不与其他种类危险品同储时，应遵守如下原则：',
        'tretal48': '1．与甲类自燃物品不能同储，与乙类自燃物品也应隔离存放。因为自燃物品可自行燃烧。',
        'tretal49': '2．与腐蚀性物品溴、过氧化氢，硝酸等强酸不可同储，如量甚少时，也应隔离存放，并保持2米以上的间距。因为溴、过氧化氢、硝酸等强酸都有较强的氧化性。',
        'tretal50': '3．含水的易燃液体和需要加水存放或运输的易燃液体、不得与遇湿易燃物品同储',
        'tretal51': '易燃易爆化学危险品的整装储存，往往存放的品种多，性质危险而复杂，比较难于管理。而散装储存量比较大，设备多，技术条件复杂，一旦发生事故难以施救。故无论何种储存方式都潜在有很大的火灾危险，所以必须用科学的态度从严管理，万万不可马虎从事',
        'tretal52': '分类存放的一般原则',
        'tretal53': '（一）压缩、液化气体',
        'tretal54': '1．压缩、液化气体之间：可燃气体与氧化性（助燃）气体混合，遇着火源易着火甚至爆炸，应隔离存放。',
        'tretal55': '2．压缩、液化气体与自然、遇湿易燃等易燃物品之间：剧毒、可燃、氧化性（助燃）气体均不得与甲类自然物品物品同储和配装；与乙类自燃物品，遇水易燃物品（灭火方法不同）应隔离存放和配装；可燃液体、固体与剧毒、氧化性气体不得同储配装。',
        'tretal56': '3．压缩、液化气体与腐性物品：剧毒气体、可燃气体不得与硝酸、硫酸等强酸配装和同储，与氧化性（助燃）气体不燃气体应隔离储存放和配装。',
        'tretal57': '4．氧气瓶及氧气空瓶不得与油脂及含油物质、易燃物同储和配装。',
        'text1': '1.集装箱（container）所谓集装箱，是指具有一定强度、刚度和规格专供周转使用的大型装货容器。使用集装箱转运货物，可直接在发货人的仓库装货，运到收货人的仓库卸货，中途更换车、船时，无须将货物从箱内取出换装。按所装货物种类分，有杂货集装箱、散货集装箱、液体货集装箱、冷藏箱集装箱等；按制造材料分，有木集装箱、钢集装箱、铝合金集装箱、玻璃钢集装箱、不锈钢集装箱等；按结构分，有折叠式集装箱、固定式集装箱等，在固定式集装箱中还可分密闭集装箱、开顶集装箱、板架集装箱等；按总重分，有30吨集装箱、20吨集装箱、10吨集装箱、5吨集装箱、2.5吨集装箱等。',
        'text2': '2.集装箱外尺寸（containers overall external dimensions） 包括集装箱永久性附件在内的集装箱外部最大的长、宽、高尺寸。它是确定集装箱能否在船舶、底盘车、货车、铁路车辆之间进行换装的主要参数。是各运输部门必须掌握的一项重要技术资料。',
        'text3': '3.集装箱内尺寸（containers internal dimensions）集装箱内部的最大长、宽、高尺寸。高度为箱底板面至箱顶板最下面的距离，宽度为两内侧衬板之间的距离，长度为箱门内侧板量至端壁内衬板之间的距离。它决定集装箱内容积和箱内货物的最大尺寸。',
        'text4': '4.集装箱内容积（containers unobstructed capacity）按集装箱内尺寸计算的装货容积。同一规格的集装箱，由于结构和制造材料的不同，其内容积略有差异。集装箱内容积是物资部门或其它装箱人必须掌握的重要技术资料。',
        'text5': '5.集装箱计算单位（twenty-feet equivalent units 简称：TEU) 又称20英尺换算单位，是计算集装箱箱数的换算单位。目前各国大部分集装箱运输，都采用20英尺和40英尺长的两种集装箱。为使集装箱箱数计算统一化，把20英尺集装箱作为一个计算单位，40尺集装箱作为两个计算单位，以利统一计算集装箱的营运量。',
        'text6': '6.集装箱租赁 （container leasing) 即所有人将空箱租给使用人的一项业务。集装箱所有人为出租的一方集装箱，与使用人，一般是船公司或货主，为承租的一方，双方签订租赁合同。由出租人提供合格的集装箱交由承租人在约定范围内使用。装箱，每航次编制配载图；办理有关货运单证的编签； 编制并签验集装箱运用运载工具的出入及流转的有关单证； 办理集装箱及运载工具、装卸工具的情况检查、维修，以及空箱的清扫、熏蒸等工作； 空箱的收发、存贮和保管；安排空箱和重箱在堆场的堆码，及编制场地分配计划； 其它有关业务工作。 集装箱装卸区一般由专用码头、前沿、堆场、货运站、指挥塔、修理部门、大门和办公室组成。有时堆场或货运站等可延伸到市区内部事5-15公里的中转站。',
        'text7': '7.集装箱装卸区（container terminal) 是集装箱运输中，箱或货装卸交换保管的具体经办部门。它受承运人或其代理人的委托，进行下列各项业务：对整箱货运的交换、保管； 设有集装箱货运站者，办理拼箱货的交接； 安排集装箱船的靠泊，装卸集装箱。',
        'text8': '8.集装箱前方堆场（marshalling yard） 是指在集装箱码头前方，为加速船舶装卸作业，暂时堆放集装箱的场地。其作用是：当集装箱船到港前，有计划有次序地按积载要求将出口集装箱整齐地集中堆放，卸船时将进口集装箱暂时堆放在码头前方，以加速船舶装卸作业。',
        'text9': '9.集装箱后方堆场（container yard） 集装箱重箱或空箱进行交接、保管和堆存的场所。有些国家对集装箱堆场并不分前方堆场或后方堆场，统称为堆场。集装箱后方堆场是集装箱装卸区的组成部分。是集装箱运输“场到场”交接方式的整箱货办理交接的场所（实际上是在集装箱卸区“大门口”进行交接的）。',
        'text10': '10.空箱堆场（van pool） 专门办理空箱收集、保管、堆存或交接的场地。它是专为集装箱装卸区或转运站堆场不足时才予设立。这种堆场不办理重箱或货物交接。它可以单独经营，也可以由集装箱装卸区在区外另设。有些国家，经营这种空箱堆场，须向航运公会声明。',
        'text11': '11.中转站或内路站（container depot or inland depot）海港以外的集装箱运输的中转站或集散地。它的作用除了没有集装箱专用船的装卸作业外，其余均与集装箱装卸区业务相同。中转站或内陆站的度量，包括集装箱装卸港的市区中转站、内陆城市、内河港口的内陆站均在内。',
        'text12': '12.集装箱货运站（container freight station 简称：CFS） 为拼箱货装箱和拆箱的船、货双方办理交接的场所。承运人在一个港口或内陆城市只能委托一个集装箱货运站的经营者。由它代表承运人办理下列主要业务：拼箱货的理货和交接； 对货物外表检验如有异状时，就办理批注； 拼箱货的配箱积载和装箱； 进口拆箱货的拆箱和保管； 代承运人加铅封并签发站收据； 办理各项单证和编制等。',
        'text13': '13.托运人责任（shippers liabilities） 托运人在集装箱运输中应有的责任，这种责任是不完全同于传统海运方面的。拼箱货托运人的责任与传统海运相同。整箱货托运人的责任不同于传统运输的有：应保证所报货运资料的正确和完整； 承运人有权核对箱内所装货物，因核对而发生的费用，有托运人承担； 海关或其它权力机关开箱检查，其费用和由此发生货损货差，由托运人承担；如集装箱货不满，或是垫衬不良，积载不当，或是装了不适于集装箱运输的货物，因而引起货损、货差，概由托运人负责； 如使用了托运人自有的不适航的集装箱，所引起的货损事故，应由托运人负责；在使用承运人集装箱及设备其间造成第三者财产或生命的损害，应由托运人负责赔偿。集装箱的租赁，国际上有多种不同的方式，总括起来有：程租、期租、活期租用和航区内阻赁等。',
        'text14': '14.责任限制（limits of liability） 集装箱运输中发生货损货差，承运人应承担的最高赔偿额。拼箱货的责任限制与传统运输相同。整箱货的赔偿按照目前国际上的一些判例：如果提单上没有列明箱内所装货物的件数，每箱作为一个理赔计算单位；如提单上列明箱内载货件数的，仍按件数计算；如果货物的损坏和灭失，不属海运，而是在内陆运输中发生的，则按陆上运输最高赔偿额办理；如集装箱是由托运人所有或提供时，遇有灭失或损坏，其责任确属承运人应承担者，亦应视作一个理赔计算单位。',
        'goods1': '1.集装箱（container）所谓集装箱，是指具有一定强度、刚度和规格专供周转使用的大型装货容器。使用集装箱转运货物，可直接在发货人的仓库装货，运到收货人的仓库卸货，中途更换车、船时，无须将货物从箱内取出换装。按所装货物种类分，有杂货集装箱、散货集装箱、液体货集装箱、冷藏箱集装箱等；按制造材料分，有木集装箱、钢集装箱、铝合金集装箱、玻璃钢集装箱、不锈钢集装箱等；按结构分，有折叠式集装箱、固定式集装箱等，在固定式集装箱中还可分密闭集装箱、开顶集装箱、板架集装箱等；按总重分，有30吨集装箱、20吨集装箱、10吨集装箱、5吨集装箱、2.5吨集装箱等。',
        'goods2': '2.集装箱外尺寸（containers overall external dimensions） 包括集装箱永久性附件在内的集装箱外部最大的长、宽、高尺寸。它是确定集装箱能否在船舶、底盘车、货车、铁路车辆之间进行换装的主要参数。是各运输部门必须掌握的一项重要技术资料。',
        'goods3': '3.集装箱内尺寸（containers internal dimensions）集装箱内部的最大长、宽、高尺寸。高度为箱底板面至箱顶板最下面的距离，宽度为两内侧衬板之间的距离，长度为箱门内侧板量至端壁内衬板之间的距离。它决定集装箱内容积和箱内货物的最大尺寸。',
        'goods4': '4.集装箱内容积（containers unobstructed capacity）按集装箱内尺寸计算的装货容积。同一规格的集装箱，由于结构和制造材料的不同，其内容积略有差异。集装箱内容积是物资部门或其它装箱人必须掌握的重要技术资料。',
        'goods5': '5.集装箱计算单位（twenty-feet equivalent units 简称：TEU) 又称20英尺换算单位，是计算集装箱箱数的换算单位。目前各国大部分集装箱运输，都采用20英尺和40英尺长的两种集装箱。为使集装箱箱数计算统一化，把20英尺集装箱作为一个计算单位，40尺集装箱作为两个计算单位，以利统一计算集装箱的营运量。',
        'goods6': '6.集装箱租赁 （container leasing) 即所有人将空箱租给使用人的一项业务。集装箱所有人为出租的一方集装箱，与使用人，一般是船公司或货主，为承租的一方，双方签订租赁合同。由出租人提供合格的集装箱交由承租人在约定范围内使用。装箱，每航次编制配载图；办理有关货运单证的编签； 编制并签验集装箱运用运载工具的出入及流转的有关单证； 办理集装箱及运载工具、装卸工具的情况检查、维修，以及空箱的清扫、熏蒸等工作； 空箱的收发、存贮和保管；安排空箱和重箱在堆场的堆码，及编制场地分配计划； 其它有关业务工作。 集装箱装卸区一般由专用码头、前沿、堆场、货运站、指挥塔、修理部门、大门和办公室组成。有时堆场或货运站等可延伸到市区内部事5-15公里的中转站。',
        'goods7': '7.集装箱装卸区（container terminal) 是集装箱运输中，箱或货装卸交换保管的具体经办部门。它受承运人或其代理人的委托，进行下列各项业务：对整箱货运的交换、保管； 设有集装箱货运站者，办理拼箱货的交接； 安排集装箱船的靠泊，装卸集装箱。',
        'goods8': '8.集装箱前方堆场（marshalling yard） 是指在集装箱码头前方，为加速船舶装卸作业，暂时堆放集装箱的场地。其作用是：当集装箱船到港前，有计划有次序地按积载要求将出口集装箱整齐地集中堆放，卸船时将进口集装箱暂时堆放在码头前方，以加速船舶装卸作业。',
        'goods9': '9.集装箱后方堆场（container yard） 集装箱重箱或空箱进行交接、保管和堆存的场所。有些国家对集装箱堆场并不分前方堆场或后方堆场，统称为堆场。集装箱后方堆场是集装箱装卸区的组成部分。是集装箱运输“场到场”交接方式的整箱货办理交接的场所（实际上是在集装箱卸区“大门口”进行交接的）。',
        'goods10': '10.空箱堆场（van pool） 专门办理空箱收集、保管、堆存或交接的场地。它是专为集装箱装卸区或转运站堆场不足时才予设立。这种堆场不办理重箱或货物交接。它可以单独经营，也可以由集装箱装卸区在区外另设。有些国家，经营这种空箱堆场，须向航运公会声明。',
        'goods11': '11.中转站或内路站（container depot or inland depot）海港以外的集装箱运输的中转站或集散地。它的作用除了没有集装箱专用船的装卸作业外，其余均与集装箱装卸区业务相同。中转站或内陆站的度量，包括集装箱装卸港的市区中转站、内陆城市、内河港口的内陆站均在内。',
        'goods12': '12.集装箱货运站（container freight station 简称：CFS） 为拼箱货装箱和拆箱的船、货双方办理交接的场所。承运人在一个港口或内陆城市只能委托一个集装箱货运站的经营者。由它代表承运人办理下列主要业务：拼箱货的理货和交接； 对货物外表检验如有异状时，就办理批注； 拼箱货的配箱积载和装箱； 进口拆箱货的拆箱和保管； 代承运人加铅封并签发站收据； 办理各项单证和编制等。',
    },
    foot: {
        xiazhaispin: '会社広報映像',
        tbat: 'ダウンロード',
        textdiz: '',
        tupian:'',
        bananhap:'',
        title: '連絡先',
        subheading: '私たちに連絡して、私たちを選ぶのは安心、安心を選ぶことです',
        data: [{
            text: '天津本部ホットライン'
        }, {
            text: '空輸業務提携 '
        }, {
            text: '空輸業務'
        }, {
            text: '陸運倉庫'
        }, {
            text: '苦情の提案'
        }]
    },
    network: [{
        title: '大信物流のニューラルネットワークを世界中に広げます',
        subheading: '大信物流ネットワーク'
    }, {
        title: '天津本社',
        subheading: '天津市河西区围堤道146号华盛广场B座22层天津市河西区囲堤道146号ワシントン広場B座22階',
        sjhaoma: ' 022-88239781',
        dianhuahaoma: '022-88239785',
        emoe: 'sales_china@i-daeshin.com',
    }, {
        title: '天津大信通北京支社',
        subheading: '北京市順義区ナムチェジンチェサンソに1号'
    }, {
        title: '天津大信通上海支社',
        subheading: '上海市長寧区仙霞路317号極東国際ビルB座2109号室'
    }, {
        title: '天津大信通威海支社',
        subheading: '山東省威海市臨港経済技術開発区山山鎮正気路-6-2号'
    }, {
        title: '天津大信通滄州支社',
        subheading: '滄州経済開発区東海35号5棟'
    }, {
        title: '陝西大信通物流有限公司',
        subheading: '陝西省銅川市印台区柳湾村一組'
    }, {
        title: '天津大信国際西安支社',
        subheading: '西安市ハイテク区錦業2で逸翠尚府北区8号楼2単元2301/2302'
    }, {
        title: '天津大信通重慶支社',
        subheading: '重慶ウィブククヨンフンジン重慶市ウィブククヨンフンジンヨンリョン通り19号豊樹物流団地'
    }, {
        title: '成都事務所',
        subheading: '四川省眉山市彭山区浜江エメラルドシティ1期(南大門)'
    }, {
        title: '恵州事務所',
        subheading: '広東省恵州市恵陽区大亜湾区霞涌通り浅水湾2号楼2単元3108'
    }, {
        title: '福州事務所',
        subheading: '福建省福清市新築鎮盤昇花園団地6号棟2棟705'
    }, {
        title: 'ククジュ事務所',
        subheading: '浙江省クジュシコソン区ハイテク団地暁星大道暁星新素材有限公司(12号門)'
    }, {
        title: '天津大信通蘇州支社',
        subheading: '蘇州市相城区高速鉄道新城南チョンソンロ77号高融ビル1404号室'
    }, {
        title: '常州事務所',
        subheading: '江蘇省常州市新北区港区ジュンロ1号住化電子'
    }, {
        title: '大信物流ロサンゼルス(CA)支社',
        subheading: 'ウェストミパームロード11番地,ブルーミングデール,ジョージア州31302です',
        emoe: 'yhlee1019@i-daeshin.com',
    }, {
        title: '大信物流オースティン(TX)本部',
        subheading: '(78759)テキサス州オースティン·リサーチ12710道380号',
        sjhaoma: '+1 512 638 0799',
        emoe: 'mhsung@i-daeshin.com',
    }, {
        title: '大信物流有限公司-ソウル本社',
        subheading: 'ソウル市永登浦区仙遊路70,1102-1番{br}(文来洞3街、ウリ創投の都市)',
        sjhaoma: ' +82-02-2088-0681',
        dianhuahaoma: ' +82-2-2088-0685',
        emoe: ' op@i-daeshin.com',
    }, {
        title: '大信物流有限公司-東京本社',
        subheading: '(116-0013)東京都 荒川区 西日暮里5-33-3 ヨコチビル 101',
        sjhaoma: ' +81-3-6806-7927',
        emoe: ' kazunobu_iwamoto@i-daeshin.com',
    }, {
        title: '香港大信有限公司',
        subheading: '',
    }, {
        title: '天津大信通広州支社',
        subheading: '広州市黄埔区開創大道2395号至泰商業広場A2棟1302単元',
    }]
}