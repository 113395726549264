const config = {
    path: '/catalog',
    name: 'Catalog',
    component: () => import('@/views/Catalog/index.vue'),
meta:{
    title:'天津物流集团-业务选择',
    keepAlive:true
}
}
                   
export{config}   