const config = {
  path: '/', // 这里是相对根路径
  name: 'Home',
  // redirect: '/en', // 默认重定向到英文版本
  component: () => import('@/views/Home/index.vue'),
  meta: {
    keepAlive: false,
    header: true,
  }
};

export { config };