const config = {
    path: '/guanli',
    name: 'guanli',
    component: () => import('@/views/guanli/index.vue'),
    meta: {
      title: '天津物流集团-登录',
      keepAlive:true
    }
  }
  const data={
    token:'',
    structid:'',
    carNo:'',
    carType:'',
    cargoType:'',
    AreasId:'',
  }
  export {config,data}