export default {
    message: {
        title:'디에스엘코리아',
        keywords:'디에스엘,디에스엘코리아,DSL Korea,해상운송,항공운송,내륙운송,창고물류,프로젝트물류,위험품운송,항공,해운,종합물류,DSL,DAESHIN,수출입,위험품수입,위험품수출,위험품수출입,해상,항공수출,항공수입,해상수출,해상수입',
        description:'고객에게 원스톱 물류 서비스를 제공드립니다. 지속적인 해외 투자로 글로벌 인프라 구축한 종합 물류 회사로 성장하고 있습니다.',
         data: [{
            label: '홈',
            route: 'Home',
            id: 0,
            xia: [{
                label: '회사소개'
            }, {
                label: '물류 솔루션'
            }, {
                label: '우리의 강점'
            }, {
                label: '연혁'
            }, {
                label: '견적문의'
            }, {
                label: '임직원의 약속'
            }, {
                label: '경영이념'
            }, {
                label: '서비스'
            }, {
                label: '대신그룹 자원'
            }, {
                label: '연혁'
            }, {
                label: '견적문의'
            }, {
                label: '임직원의 약속'
            }]
        }, {
            label: '회사정보',
            route: 'Information',
            id: 1,
            xia: [{
                label: '경영철학'
            }, {
                label: '5S 전략'
            }, {
                label: '성실&신용' //删除
            }, {
                label: '고객사'
            }, {
                label: '인증서' //删除
            }, {
                label: '감사패'
            }]
        }, {
            label: '서비스',
            route: 'Outlets',
            id: 2,
            xia: [{
                label: '국제국내해운'
            }, {
                label: '국제국내항공운송'
            }, {
                label: '창고관리'
            }, {
                label: '내륙운송'
            }, {
                label: '위험품운송'
            }, {
                label: '프로젝트운송'
            }]
        }, {
            label: '네트워크',
            route: 'Network',
            id: 3,
            xia: [{
                label: '중국'
            }, {
                label: '미국'
            }, {
                label: '일본'
            }, {
                label: '한국'
            }]
        }, {
            label: '물류소식',
            route: 'Advocacy',
            id: 4,
        }, {
            label: '현 위치 ',
            id: 5,
        }, {
            label: '로그인',
            id: 6,
        }, {
            label: '주소'
        }, {
            label: '서울시 영등포구 선유로 70, 1102-1호(문래동3가, 우리벤처타운2)'
        }, {
            label: '+82-02-2088-0681'
        }, {
            label: 'sales_korea@i-daeshin.com'
        }, {
            label: '페이지',
        }, {
            xia: [{
                label: '중국'
            }, {
                label: '미국'
            }, {
                label: '한국'
            }, {
                label: '일본'
            }, ]
         }],
         swiper: [{
            titlebta: '운송 및 물류 솔루션',
            textp: '<span style="color: red;">항공 & 해운물류</span> 솔루션',
            url: require("@/assets/image/sl1.jpg"),
            title: '세계로 뻗어 나가는 글로벌 종합물류회사인 대신물류를 선택하시면 언제 어디서나 안전하고 신속한 최상의 One-stop ',
            text: '운송, Door to Door 서비스를 경험하실 수 있습니다.'
        }, {
            url: 'require("@/assets/image/sl2.jpg") ',
            textp: '<span style="color: red;">국제물류</span>  솔루션',
            title: '글로벌 네트워크를 통해 항공 운송으로 빠르게 이어집니다.',
            text: '"고효율, 신속, 안전"을 모토로, 각종 항공 운송, 항공 택배, 긴급 화물, 수하물 탁송 서비스를 합리적인 가격, 신뢰도, 차별화 된 서비스로 고객만족을 실현하겠습니다.'
        }, {
            url: require("@/assets/image/sl3.jpg"),
            textp: '<span style="color: red;">창고관리</span> 솔루션',
            title: '중국 전역에 총면적 77,500㎡ 이상의 종합 물류 창고를 운영하고 있습니다.<br>상하차에 적합한 도크와 넓은 공간, 완벽한 소방 인프라를 가지고 있으며, 365일 관리를 통해 제품 안전을 보장합니다.',
            text: '창고의 모든 관리는 고객에게 24시간 실시간 서비스를 제공합니다.'
        }, {
            url: require("@/assets/image/sl4.jpg"),
            textp: '<span style="color: red;">내륙 운송</span>  솔루션',
            title: '업무와 화물 특성에 최적화 된 차량을 제공합니다.',
            text: '탑차, ISO탱크, 윙바디, 냉장/냉동차를 보유하고 있으며, 중국 전역과 전 세계 운송 네트워크로 고객에게 고품질의 운송서비스를 제공합니다.'
        }, {
            textp: '<span style="color: red;">위험품 운송</span> 솔루션',
            title: '안전한 위험품 운송, 정확한 배송 시간을 준수합니다.',
            text: '위험품 전문가의 ZERO-RISK 관리로 내륙 및 수출입 위험물의 안전 도착을 보장합니다.<br>작업은 숙련된 작업자가 안전한 보호 장비 착용 후 진행됩니다.<br>세분화된 운송 노선 관리와 안전 예방 메커니즘, 전문 노하우를 보유하고 있어 고가의 화물도 신뢰하고 맡길 수 있습니다. '
        }, {
            title: '상세정보',
            txt: '견적 문의',
         }],
         swiper3: [{
            name: '이태경',
            gongs: 'DSL KOR - 대리',
            title: '시작이 반이다.',
            text: '일단 시작하고 추진해야, 앞으로 나아갈 수 있습니다.작은 실천이라도 해보려는 노력이 중요합니다.',
         }, {
            name: '이용훈',
            gongs: 'DSL USA - 법인장',
            title: '끝날 때까지 끝난게 아니다.',
            text: '인생에 끝은 없습니다. 모든 단계에서 새로운 시작이 있을 뿐 입니다.',
         }, {
            name: '이진욱',
            gongs: 'DSL CHN - 천진 국제부',
            title: '상즉인,인즉상',
            text: '사업은 이윤을 남기기 보다 사람을 남기기 위해서 입니다.',
         }, {
            name: '藤井知己',
            gongs: 'DSL JPN - 소주 국제부',
            title: '하상을 깊게 파고 제방은 낮게 축조하라.',
            text: '원가 절감을 위하여 끊임없이 노력하겠습니다. 업무 효율을 높이도록 하겠습니다. 우리의 이윤보다는 고객의 이윤을 높이도록 노력하겠습니다.',
         }, {
            name: '채재훈',
            gongs: 'DSL CHN -상해 상무',
            title: '노력은 결코 배신하지 않는다.',
            text: '노력은 자신을 배신하지 않습니다.끊임없는 노력을 통해서만 우리는 자신의 목표를 달성할 수 있습니다. 일과 생활에는 끊임없는 노력이 필요합니다.',
         }, {
            name: '한영',
            gongs: 'DSL CHN -상해 국제부',
            title: '작은 일을 착실히 행하면, 큰 일도 두렵지 않다.',
            text: '모든 일은 열정과 영혼을 담은 결정이 필요합니다.  열정을 가지고 꾸준히 한다면 반드시 성공합니다.',
         }, {
            name: '차영주',
            gongs: '대신물류그룹 사장',
            title: '뜻을 향하여 용감히 돌진하면, 난관 극복하고 더욱 강해진다.',
            text: '사람은 뜻을 가진다면, 어려움이나 좌절이 있더라도 전진을 멈추지 않고 난관을 이겨 낼 수 있습니다.',
         }, {
            name: '샤오샤오쥔',
            gongs: 'DSL CHN -소주 3PL부',
            title: '매일 조금씩 발전하면 성공은 멀지 않은 곳에 있다.',
            text: '한 발짝 나갈 때 성공이 축적되고, 한 걸음 나갈 때 목표라는 결승점을 가질 수 있습니다.',
         }, {
            name: '강유실',
            gongs: 'DSL USA -국제부',
            title: '인생은 즐거움',
            text: '인생은 아름답습니다.긍정적 마음을 가진다면 생활은 놀라움을 가져 줍니다.인생은 즐겁습니다.인생에 주의를 기울이면 조그만 일에도 웃음이 나옵니다.인생은 행복합니다.작은 일에 만족을 느낀다면, 행복을 가질 수 있습니다.',
         }, {
            name: '리원징',
            gongs: 'DSL CHN -서안 국제부',
            title: '뜻이 있는 곳에 길이 있다.',
            text: '포기는 쉽지만, 끈기 있는 자는 멋집니다.밤 하늘에 별이 보이지 않을 때도 별은 항상 같은 자리에서 빛을 내고 있습니다.',
         }, {
            name: ' Mark Meredith',
            gongs: 'DSL USA  -안전관리',
            title: '안전 제일, 안전은 업무의 시작과 끝.',
            text: '사고 발생 후 자기 자신에게 때 늦은 후회를 하지 않기 위해 안전 점검, 안전 운전의 기본을 준수 합니다.법을 준수하는 것은 위험을 줄이는 길 입니다.',
         }, {
            name: '황리쥔',
            gongs: 'DSL CHN - 광주 국제부',
            title: '덕이 있는 사람만이 중대한 업무를 수행 할 수 있다.',
            text: '마음을 다해 고객을 섬기겠습니다.',
         }],
         baojia: [{
            title: '연락처',
            title2: '견적 문의',
            subheading: '전화 상담 및 문의',
            subheading2: '+82-02-2088-0681',
            text: '해상 운임, 항공 운임, 창고 관리, 내륙 운임, 위험품 운임 어떤 도움이 필요하신가요?<br> 신속히 답해 드리겠습니다.',
            input: '회사 이름',
            input1: '이름',
            input2: '사서함',
            input3: '전화 번호',
            input4: '서비스 선택',
            input5: '항공 운송',
            input6: '해상수송',
            input7: '창고 관리',
            input8: '내륙수송',
            input9: '위험물운송',
            input10: '중요사항',
            button: '제출',
            sj: '이메일이 올바르지 않습니다',
            yjan: '당신의 휴대전화 번호가 정확하지 않습니다',
         }],
         about: [{
            tetlet: '17년 위험품 전문 물류 회사',
            tetlyt: '당신의 물류 동반자',
            title: '회사 소개',
            subheading: '경영 이념',
            text: '<span style="color: red;">고객 최우선</span>의 경영 이념으로 <br/>  고객에게 <span style="color: red;">안전</span>하고 <span style="color: red;">효율적</span>이며 <span style="color: red;">신속</span>하고 <span style="color: red;">정확</span>한 서비스를 제공합니다.',
            image: [{
                url: '@/assets/image/关于我们小图3.jpg'
            }, {
                url: '@/assets/image/关于我们小图4.jpg'
            }, {
                url: '@/assets/image/关于我们小图5.jpg'
            }, {
                url: '@/assets/image/关于我们小图6.jpg'
            }],
            description: [{
                text: '대신물류는 2007년 중국의 4대 직할시인 천진에 설립되었습니다.'
            }, {
                text: '17년간의 지속적인 성장은 국제 운송 관리 시스템 운영과 전 임직원의 끊임없는 노력과 혁신, 그리고 고객 최우선의 경영이념을 바탕으로 지속적으로 성장하고 있습니다.'
            }, {
                text: '중국 천진에 3개 법인을 설립하였고 서안, 창주, 위해, 중경, 북경, 상해, 광주, 서안, 소주 등의 중국 주요 도시에 분공사를 소유하고 있습니다. 홍콩, 한국, 미국, 일본법인을 설립하여 글로벌 성장을 거듭하고 있습니다.'
            }, {
                text: '중국 내 380대 이상의 자차를 보유하고 있으며, 77,500㎡의 종합 물류창고를 운영하고 있습니다. 국제 항공 & 해운 수출입, 창고 물류, 콜드체인 물류, 위험품 운송, 도비, 프로젝트 운송 등 고객에게 원스톱 서비스를 제공하고 있습니다.'
            }, {
                text: '17년 이상의 물류 경험을 바탕으로 노하우를 축적했을 뿐 아니라 많은 전문인력을 보유하여 국내외 고객과 장기적이고 우호적인 협력 관계를 유지하고 있습니다. 한국, 일본 대기업에 안정적이고 경쟁력있는 서비스를 제공하며 육로, 해운, 항공 수출입 및 위험품 운송 관련 전문 물류 기업으로 발전하였습니다.대신물류는 끊임없는 노력을 통해 대신물류만의 강점과 인프라를 이용하여 고객의 운임 경쟁력을 높이고, 양질의 서비스를 제공하겠습니다.'
            }, {
                text: ''
            }]
         }],
         image: [{
            title: '대신물류는 글로벌 물류기업을 목표로 하고 있습니다.',
            subheading: '디테일이 승패를 결정한다.를 모토로 고객님들께 더 정교한 글로벌 서비스를 제공하겠습니다.',
            url: '@/assets/image/home-background2.webp'
         }],
         advantage: [{
            title: '우리의 강졈',
            subheading: '대신물류그룹 자원',
            text: '글로벌 물류 네트워크 인프라로 고객에게 원스톱 물류 서비스를 제공합니다.',
            text2: '지속적인 해외 투자와 강화된 글로벌 네트워크를 바탕으로 종합물류회사로 성장하고 있습니다.',
            description: [{
                url: '@/assets/image/homeyewu1.webp',
                text: '국가'
            }, {
                url: '@/assets/image/homeyewu2.webp',
                text: '회사분포'
            }, {
                url: '@/assets/image/homeyewu3.webp',
                text: '전문인력'
            }, {
                url: '@/assets/image/homeyewu4.webp',
                text: '창고면적'
            }, {
                url: '@/assets/image/homeyewu5.webp',
                text: '위험품차량'
            }, {
                url: '@/assets/image/homeyewu6.webp',
                text: '일반차량'
            }, {
                url: '@/assets/image/homeyewu7.webp',
                text: '콜드체인차량'
            }, {
                url: '@/assets/image/homeyewu8.webp',
                text: '포장설비'
            }]
         }],
         swiper2: [{
            title: '서비스',
            subheading: '물류 솔루션',
            swiper: [{
                url: require("@/assets/image/banner-1.jpg"),
                image: require('@/assets/image/海运4web.jpg'),
                p: '海运出口',
                title: '국제해운수출입대리',
                subheading: '화물의 특성에 따라，',
                subheading1: '우수한 항구하역관리와 운송서비스를 제공합니다，',
                text: '전 세계 항구의 물류 인프라와 고객 화물 특성에 맞는 운송장비를 통해 고객에게 맞춤형 항만 하역 및 해상 서비스를 제공합니다. 아울러 화물검사 및·통관업무까지 사업영역을 확대해 시너지 효과를 높여가고 있습니다.',
            }, {
                url: require("@/assets/image/banner-2.jpg"),
                image: require('@/assets/image/空运2web.jpg'),
                p: '国际空运',
                title: '국제, 국내 항공운송',
                subheading: '고객에게 최적의 운송방안을 설계/제안하며 최단거리, 최단시간의 서비스를 제공해 드리겠습니다.',
                text: '화물은 팔레트 운송을 통해 신속한 통관 및 운송 서비스를 실현해 드립니다. 수출입 통관 및 항공운송, 내륙운송 등 토탈 서비스 제공 외에 글로벌 전자상거래에 특화된 국제택배 서비스까지 제공할 수 있습니다.',
            }, {
                url: require("@/assets/image/banner-3.jpg"),
                image: require('@/assets/image/仓库3web.jpg'),
                p: '仓储物流',
                title: '창고물류',
                subheading: '대면적 종합창고를 운영하며，',
                subheading1: '양질의 창고 운영지원서비스 및 사내물류, 간선물류, 통합물류 등 창고물류 서비스를 제공합니다.',
                text: '중국 전국 주요도시에 총면적77,500㎡의 물류창고를 운영하며 우수한 전문 관리인력을 통해 최적의 창고관리 시스템을 제안하고 고효율의 안정된 서비스를 제공합니다.',
            }, {
                url: require("@/assets/image/banner-4.jpg"),
                image: require('@/assets/image/陆运2web.jpg'),
                p: '内陆运输',
                title: '국내 도로운송',
                subheading: '중국내 380대 이상의 자기차량을 보유하고 운송하는,',
                subheading1: '한국 물류회사입니다.',
                text: '다양한 탑차, 탱크차량, 윙바디차량, 냉장/냉동 차량 및 기타 특종 운송 차량을 보유하고 다양한 업종의 고객님께서 요구하는 화물을 신속하게  운송할 수 있습니다. 중국내 20여개 지사를 보유하고 전국적 물류 네트워크를 구축하고 365일 운송 서비스를 제공합니다.',

            }, {
                url: require("@/assets/image/banner-5.jpg"),
                // image: require('@/assets/image/危险2web.jpg'),
                p: '危险品运输',
                title: '위험품 운송',
                subheading: '중국에서 5년 이상 위험품 직접운송 경험을 보유한',
                subheading1: '위험품운송에 특화된 한국 물류회사입니다.',
                text: '위험품 화물은 고객의 요구에 따라 충진업무까지 가능하며 전 노선 CCTV와 GPS 관리로 화물의 실시간 위치를 파악할수 있으며 운송정보를 실시간으로 고객님께 제공해 드립니다. 원재료 납품부터 위험품 포장물 회수까지 화물을 안전하고 신속하게 목적지까지 배송하겠습니다.',
            }],
         }],
         slide: [{
            title: '연혁',
            title2: '대신물류그룹',
            icondiqu1: '중국,',
            icondiqu2: ' 미국',
            icondiqu3: '한국',
            icondiqu4: '일본,',
            zhongbu: '본사',
            fengongs: '분공사',
            fengongs1: '법인',
            huwulexing1: '위험품/내륙',
            huwulexing2: '국제물류',
            huwulexing3: '위험품',
            huwulexing4: '국제물류/내륙/위험품',
            huwulexing5: '중국, 서안, 2차 전지 동박 생산 및 동박 설비제작',
            diqu1: '천진',
            diqu2: '서안',
            diqu3: '창주',
            diqu4: '섬서',
            diqu5: '위해',
            diqu6: '중경',
            diqu7: '홍공',
            diqu8: '광주',
            diqu9: '소주',
            diqu10: '상해',
            diqu11: '서울',
            diqu12: '오스틴',
            diqu13: ' 로스엔젤레스',
            diqu14: ' 도쿄',
            swiper: [{
                    text: '대신물류그룹',
                    text2: '천진 본사 설립',
                },
                {
                    text: '천진대신국제화운대리유한회사',
                    text2: '천진 본사 설립',
                },
                {
                    text: ' 천진역대신운수유한공사',
                    text2: '천진 본사 설립',
                },
                {
                    text: ' 천진대신통운수유한공사',
                    text2: '천진 본사 설립',
                },
                {
                    text: '천진대신국제화운대리유한회사',
                    text2: '서안 분공사 설립',
                },
                {
                    text: ' 천진대신통운수유한공사',
                    text2: '창주 분공사 설립',
                },
                {
                    text: '섬서대신통운수유한공사',
                    text2: '섬서 본사 설립',
                },
                {
                    text: ' 천진대신통운수유한공사',
                    text2: '위해 분공사 설립',
                },
                {
                    text: ' 천진대신통운수유한공사',
                    text2: '중경 분공사 설립',
                },
                {
                    text: '홍콩대신물류유한회사',
                    text2: '홍콩 본사 설립',
                },
                {
                    text: ' 천진대신통운수유한공사',
                    text2: '광주 분공사 설립',
                },
                {
                    text: ' 천진대신통운수유한공사',
                    text2: '소주 분공사 설립',
                },
                {
                    text: ' 천진대신통운수유한공사',
                    text2: '상해 분공사 설립',
                },
                {
                    text: '㈜디에스엘코리아',
                    text2: '서울 본사 설립',
                },
                {
                    text: '㈜디에스엘USA',
                    text2: '오스틴(TX) 본사 설립',
                },
                {
                    text: '㈜디에스엘USA',
                    text2: '로스앤젤레스(CA) 분공사 설립',
                },
                {
                    text: '㈜디에스엘JAPAN',
                    text2: '동경 본사 설립',
                }, {
                    text: 'P&D 서안',
                    text2: 'PND&대신물류합작법인',
                }
            ]
         }],
         spin: [{
            text: '승패를 결정하는 것은 디테일이고, 태도가 모든 것을 변화하게 한다.',
        }, {
            text: '주요거래처',
        }, {
            text: '인증서',
         }],
         chengn: [{
            text: '약속',
        }, {
            text: '임직원의 약속',
         }]
         },
    information: {
        data: [{
            'val': '개요',
        }, {
            'val': '경영철학',
        }, {
            'val': '관리',
        }, {
            'val': '비전',
        }, {
            'val': '신용',
        }, {
            'val': '인증서',
        }, {
            'val': '감사패',
        }, {
            'val': '고객',
        }, {
            val: '회사의 비전과 기업문화',
        }],
        image: [{
            text1: '천진 대신 국제 화운 대리 유한 회사',
            text2: '중국 해관 AEO 고급 인증',
        }, {
            text1: '천진 대신 운송 유한 회사',
            text2: '품질 경영 시스템 ISO9001 인증',
        }, {
            text1: '천진 대신 운송 유한 회사',
            text2: '품질 경영 시스템 ISO14001 인증',
        }, {
            text1: '천진 대신 국제 화운 대리 유한 회사',
            text2: 'NVOCC',
        }, {
            text1: '천진 역 대신 운송 유한회사',
            text2: '신용 등급 AAA',
        }, {
            text1: '섬서 대신통 운송 유한회사',
            text2: '신용 등급 AAA',
        }, {
            text1: 'SAMSUNG SDS',
            text2: '업무지원 감사패',
        }, {
            text1: 'MOTTROL',
            text2: '우수물류서비스업체 감사패',
        }, {
            text1: '住友전자재료과학(상주)유한회사',
            text2: '우수물류회사 감사패',
        }, {
            text1: '원익(서안) 반도체',
            text2: '물류공헌 감사패',
        }, {
            text1: '섬서대신통운수유한공사',
            text2: '2018년위험품운송선진기업',
        }, {
            text1: '섬서대신통운수유한공사',
            text2: '2017년위험품운송선진기업',
        }, {
            text1: '일진모토시스템유한회사',
            text2: '물류공헌 감사패',
        }, {
            text1: '住友전자재료과학(서안)유한회사',
            text2: '우수물류회사 감사패',
        }],
        bejing: [{
            title: '우리에 관하여',
            text: '회사의 소망, 기업 문화, 엘리트 이념과 미래 발전을 이해하다',
        }],
        idea: [{
            url: '@/assets/image/linian1.jpg',
            title: '태도가 모든 것을 결정하고, 세부 사항이 성공을 결정한다',
            title3: '대신물류그룹 대표 차영주',
            text: '대신물류는 “사회 책임”을 최우선으로 삼고, “사회 공헌”의 이념에 따라 기업의 사회적 책임을 다하기 위해 모든 구성원이 노력하고 있습니다.대신물류는 초일류 물류 기업을 목표로 고객사와 공생하기 위한 필수 전략으로 고객사와 소통하고 서비스 품질, 저렴한 물류 비용, 글로벌 네트워크 및 첨단 물류 시스템을 바탕으로 고객의 신뢰를 얻고 있습니다. 앞으로도 글로벌 네트워크 및 전문 인력을 바탕으로 “고객 만족 초일류 서비스”를 목표로 글로벌 종합 물류 기업으로서 지속적으로 성장하겠습니다..'
        }],
        manage: [{
            title: 'CSV관리',
            subheading: 'CSV(Creating Shared Value，공유가치 창출) 경영 목표는 준법경영, 신용경영, 안전경영을 바탕으로 나아가 창조경영, 상생경영, 공유경영을 실현하고 사회 환원을 위해 기업이 해야 할 의무를 다하며 사회적 가치를 지속적으로 창출하는 것입니다.대신물류그룹은 CSV 경영이념에 입각하여 당사의 업무역량을 적극 활용하여 다방면의 노력을 통해 사회발전에 기여하고 있습니다.',
            li: [{
                text: '창조경영',
                textx: '세계의 새로운 산업 모델을 실현하여, 국가와 사회에 공헌하고, 기업의 발전에이바지하겠습니다.'
            }, {
                text: '상생경영',
                textx: '상생의 산업 생태계를 조성하고, 적극적으로 상생을 실현하며, 고객님들의 지속적인 발전을 보장하겠습니다.'
            }, {
                text: '공유경영',
                textx: '핵심업무역량을 바탕으로 사회적 약자에 대한 관심을 가지고 체계적인 사회봉사 활동을 전개한다.'
            }],
            url: '@/assets/image/xiangqing2.jpg',
            bootom: [{
                url: '@/assets/image/xiangqing3.jpg',
                text: '준법경영',
                tceit: '법률을 준수하다'
            }, {
                url: '@/assets/image/xiangqing4.jpg',
                text: '신용경영',
                tceit: '약속을 중시하다'
            }, {
                url: '@/assets/image/xiangqing5.jpg',
                text: '안전경영',
                tceit: '안전을 보증하다'
            }]
        }],
        vision: [{
            box: [{
                title: '전략 방향',
                subheading: '（전략 방향）',
                strong: '신사업 기회 발굴',
                strongzong: 'Cost Leadership 확보',
                strongxia: '협력Network/Partnership',
                strongxiaz: '전문 물류 인재 양성',
                textp1: '가능한 고객을 찾기',
                textp2: '신사업과 해외 물류 사업 적극 추진',
                textp3: '고객의 물류 경쟁력 강화를 목표.',
                textp4: '물류 시스템을 최적화하여 양질의 물류 서비스를 제공.',
                textp5: '우수 기업과의 전략적 제휴를 통한 협력 네트워크 구축',
                textp6: '고객과의 Win-Win협력체제 구축',
                textp8: '핵심인재（물류컨설턴트, 프로세스）의 체계적인 육성',
            }, {
                title: '핵심 역량',
                subheading: '（핵심 역량）',
                strong: '글로벌 네트워크（Global Network）실현',
                strongzong: 'IT시스템 구축/운용 능력',
                strongxia: 'Consulting능력',
                textp1: '고객 맞춤형 서비스',
                textp2: '전 지역 분공사를 통한 신속하고 저렴한 화물 운송',
                textp3: '실시간（Real-time）데이터 제공이 가능한 네트워크 구축',
                textp4: '다양한 물류 정보 공유 IT시스템',
                textp5: '고객에게 보다 경쟁력 있는 체계적이고 종합적인 방안 제시',
                textp6: '실현 가능한 운송 계획 협의',
            }, {
                title: '공유 가치',
                subheading: '（공유 가치）',
                strong: '신뢰（Trust）:Partner Spirit',
                strongzong: '적극적 마인드(Openness):Family Spirit',
                strongxia: '열정(Passion):Frontier Spirit',
                textp1: '고객과 회사와의 신뢰、회사는 직원과의 신뢰',
                textp2: '직원과 직원 상호간에Win-Win관계 형성',
                textp3: '자신이나 부서의 이해를 뛰어넘어 전사적 시각에 서다',
                textp4: '앞서가는 마인드로 업무 처리',
                textp5: '자부심을 갖고 선진 기업을 만드는데 힘쓰다',
                textp6: '명확한 비전、열망, 자신감',
            }],
            url: '@/assets/image/yuanjing1.jpg'
        }],
        opnert: [{
            opttbe: '기업의 사명',
            title: '"诚"진솔함과 "信"신용으로 결합된 두 글자는 진솔하게 신용을 지킨다는 의미로 당사 기업 발전의 근본입니다.',
            text1: '직원, 주주, 고객, 사회 및 환경 보호의 약속. 비전은 기업의 발전 방향과 미래의 모습이다.',
            text2: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">기업은 직원과의 약속을 이행하고 직원의 가치를 존중해야 직원이 기업에 충성하고 이는 기업의 장기적 발전에 도움이 된다.</p>',
            text3: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">기업은 가치를 끊임없이 높여야 주주가 회사에 대한 자부심을 가지고 지속적 투자로 연결되어 기업 발전에 필요한 지원을 받을 수 있다.</p>',
            text4: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">기업은 고객에게 믿음, 신뢰, 서비스 등을 제공해야 더 많은 기회를 창출할 수 있고, 새로운 고객을 유치할 수 있으며, 이윤을 증가시킬 기회를 가질 수 있다.</p>',
            text5: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">기업은 고객에게 믿음, 신뢰, 서비스 등을 제공해야 더 많은 기회를 얻을 수 있으며, 새로운 고객을 유치할수 있으며, 동시에 기업에게는이윤을 증대 시킬 기회를 획득할수 있다.</p>',
            text6: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">기업은 사회에 관심을 가지고 환경 보호에 관심을 가져야 사회는 기업에 더 많은 피드백을 줄수 있고 기업은 좋은 발전 환경을 만들 수 있다.</p>',
            text7: '이렇게 해야만 기업의 미래에 대한 발전 및 성장을 실현할수 있으며, 미래를 위해 성실하게 경영해야만 기업이 장기적인 발전을 실현시킬수 있다.',
            url: '@/assets/image/chengxi1.jpg'
        }],
        avthend: [{
            url: '@/assets/image/zizhi1.jpg',
            text: '천진대신국제화운대리유한회사',
            textp: '중국 해관 AEO 고급 인증'
        }, {
            url: '@/assets/image/zizhi2.jpg',
            text: '천진대신운송유한회사',
            textp: '품질 경영 시스템 ISO9001 인증'
        }, {
            url: '@/assets/image/zizhi3.jpg',
            text: '천진대신운송유한회사',
            textp: '품질 경영 시스템 ISO14001 인증'
        }, {
            url: '@/assets/image/zizhi4.jpg',
            text: '천진대신국제화운대리유한회사',
            textp: '무선박 운송 업무 NVOCC'
        }, {
            url: '@/assets/image/zizhi5.jpg',
            text: '천진역대신운송유한회사',
            textp: '신용 등급 AAA'
        }, {
            url: '@/assets/image/zizhi6.jpg',
            text: '섬서대신통운수유한공사',
            textp: '신용 등급 AAA'
        }],
        medal: [{
            url: '@/assets/image/zizhi7.jpg',
            text: '업무지원 감사패',
            textp: 'SAMSUNG SDS'
        }, {
            url: '@/assets/image/zizhi8.jpg',
            text: '우수물류서비스업체 감사패',
            textp: 'MOTTROL'
        }, {
            url: '@/assets/image/zizhi9.jpg',
            text: '우수물류회사 감사패',
            textp: '住友전자재료과학(서안)유한회사'
        }, {
            url: '@/assets/image/zizhi10.jpg',
            text: '물류공헌 감사패',
            textp: '원익(서안) 반도체'
        }, {
            url: '@/assets/image/zizhi11.jpg',
            text: '2018년위험품운송선진기업',
            textp: '섬서대신통운수유한공사'
        }, {
            url: '@/assets/image/zizhi12.jpg',
            text: '2017년위험품운송선진기업',
            textp: '섬서대신통운수유한공사'
        }, {
            url: '@/assets/image/zizhi12.jpg',
            text: '물류공헌 감사패',
            textp: '일진모토시스템유한회사'
        }, {
            url: '@/assets/image/zizhi12.jpg',
            text: '우수물류회사 감사패',
            textp: '住友전자재료과학(서안)유한회사'
        }],
        zhanluei: [{
            tltle: '5S전략',
            s1: {
                text: '<span style="margin-right: 5px;">✓</span>중국내 업무특성에 따른 5개 법인 운영',
                text1: '<span style="margin-right: 5px;">✓</span>  한국, 홍콩, 미국 거점 확보하여 Global 기업으로 도약',
                text2: '<span style="margin-right: 5px;">✓</span>  ISO 9001, 14001 인증 및 AEO 고급인증 ',
                text3: '<span style="margin-right: 5px;">✓</span>  IT 안전관리시스템 운용',
            },
            s2: {
                text: '<span style="margin-right: 5px;">✓</span>한국계 위험물회사 라이센스 최초 보유',
                text1: '<span style="margin-right: 5px;">✓</span>중국 전역 355대 자차 보유한 Asset형 물류회사',
                text2: '<span style="margin-right: 5px;">✓</span>협력물류사 네트워크를 통한 8,000여대 차량운영 가능',
                text3: '<span style="margin-right: 5px;">✓</span>창고면적',
                text4: '<span style="margin-right: 5px;">✓</span>일반차량',
                text5: '<span style="margin-right: 5px;">✓</span>위험품차량',
                text6: '<span style="margin-right: 5px;">✓</span>콜드체인',
            },
            s3: {
                text: '<span style="margin-right: 5px;">✓</span>차량추적시스템 구축 (GPS, CCTV)',
                text1: '<span style="margin-right: 5px;">✓</span>교통국 안전교육(시스템) 월 1회 진행',
                text2: '<span style="margin-right: 5px;">✓</span>교통국 안전평가 AAA로 최고평점 획득',
                text3: '<span style="margin-right: 5px;">✓</span>Daily 기사 건강 Check (체온, 혈압, 음주)',
                text4: '<span style="margin-right: 5px;">✓</span>기사 직업병 및 MMPI(미네소타 다면적인성) 검사 진행',
                text5: '<span style="margin-right: 5px;">✓</span>차량점검 및 매월 2회 안전 Patrol 진행',
                text6: '<span style="margin-right: 5px;">✓</span>매년 2회 소방안전 긴급훈련 진행',
                text7: '<span style="margin-right: 5px;">✓</span>S社 물류회사 대표로 선정되어 社내 긴급훈련 진행',
            },
            s4: {
                text: '<span style="margin-right: 5px;">✓</span>중국 전역 자기차량 보유로 적시적소 투입가능',
                text1: '<span style="margin-right: 5px;">✓</span>고객사 요구사항에 맞는 차량 신규구매하여 신속 투입(Customize)',
                text2: '<span style="margin-right: 5px;">✓</span>24시간 GPS관리로 신속〮정확하게 위치추적하여 고객사와 공유',
                text3: '<span style="margin-right: 5px;">✓</span>신속한 선박 Booking',
                text4: '<span style="margin-right: 5px;">✓</span>신속한 통관 및 납품',
            },
            s5: {
                text: '<span style="margin-right: 5px;">✓</span>모두 ‘NO’라고 외칠 때 대신물류는 ‘YES’라고 외친다',
                text1: '<span style="margin-right: 5px;">✓</span>불가능을 가능하게 하라',
                text2: '<span style="margin-right: 5px;">✓</span>전직원의 업무 마인드 “细节决定成败”',
            },
        }],
        image1: require('@/assets/image/yuanjingko1.jpg'),
        image2: require('@/assets/image/chengxiko1.jpg'),
        image3: require('@/assets/image/kehuoko.png'),
        image4: require('@/assets/image/xiangqingko2.png'),
        image5: require('@/assets/image/货运ko.jpg'),
        image6: require('@/assets/image/中国ko.jpg'),
        image7: require('@/assets/image/美国ko.jpg'),
        image8: require('@/assets/image/韩国ko.jpg'),
        image9: require('@/assets/image/日本ko.jpg'),
        image10: require('@/assets/image/sl5.jpg'),
        image11: require('@/assets/image/top1ko.jpg'),
        image12: require('@/assets/image/top3ko.jpg'),
        image13: require('@/assets/image/top2ko.jpg'),

    },
    outlets: {
        datalist: [{
            'wedic': '해상운송',
        }, {
            'wedic': '항공운송',
        }, {
            'wedic': '내륙 운송',
        }, {
            'wedic': '창고 물류',
        }, {
            'wedic': '프로젝트 물류',
        }, {
            'wedic': '위험품 운송',
        }, {
            'wedic': '상세정보',
        }],
        mari: [{
            card: [{
                text1: '해상운송',
                text2: '고품질 전문 팀과 글로벌 네트워크 서비스, 전세계에 널리 퍼져 있는 에이전트 네트워크',
                text3: '물류 방안 설계부터 화물 추적까지 통관 및 배송 서비스를 전면적으로 제공하여 각종 수출입 화물의 탁송 요구 사항 만족',
                text4: '혼재화물 및 대량화물의 용선 업무를 맡으며 정기선과 비정기선의 국제 선박 대리 서비스를 제공',
                text5: '프로젝트 화물, 엔지니어링 화물, 특수 화물 등의 다양화된 업무를 책임지며 고객에게 전문적인 공급망 관리 관리 서비스와 양질의 서비스 제공',
                text6: '여러 선사와 장기적인 협력 관계를 맺으므로써 낮은 해상 운임 가격 제공 가능',
                url: '@/assets/image/hiashangyshu1.jpg'
            }, {
                title1: '화물 특성에 따라 항구, 항만을 선별하여 하역 관리와 운송 서비스를 제공합니다.',
                title2: '고객에게 최적의 운송 프로세스를 제공합니다.',
                title3: '380대 이상의 자차를 운영하는 중국 내 유일한 한국 물류 회사입니다.',
                title4: '고객 NEEDS에 부합되는 최적의 종합 창고를 운영하여 양질의 서비스를 제공합니다.',
                title5: '"Door to Door", "Door to Port & Place"등 다양한 복합 운송 서비스를 제공하고 있습니다.',
                title6: '중국에서 17년 이상 위험품 직접운송 경험을 보유한 위험품 운송에 특화된 한국 물류 회사입니다.',
            }],
            text: [{
                title: '업무 서비스 소개',
                text: [{
                    url: '@/assets/image/hiashangyshu2.jpg',
                    titlex: '컨테이너 서비스（Container Service）',
                    textp: '더 빠르고 저렴하며 포장, 운송, 하역, 보관 및 기타 해상 운송 과정에서 체계적인 관리가 수행됩니다.',
                }, {
                    url: '@/assets/image/hiashangyshu3.jpg',
                    titlex: '크 운송 서비스 （Bulk Service）',
                    textp: '벌크선 서비스는, 컨테이너 선적이 어려운 다양한 형태의 화물을 보다 빠르고 가격 경쟁력이 있는 서비스이며, 컨테이너 화물보다는 많은 화물을 경쟁력 있는 운임으로 신속하게 진행 가능합니다.',

                }, {
                    url: '@/assets/image/hiashangyshu4.jpg',
                    titlex: '서비스 이점（Service Advantage）',
                    textp: '대신물류는 한국, 중국, 미국, 동남아시아 등 국가별 선사와의 유기적인 협력을 통해 고객이 만족할 수 있는 안전하고 빠른 최상의 서비스를 제공합니다.',
                    textp2: '대형 선사들과의 강력한 파트너십을 통해 유연하고 신속한 해상 화물 서비스를 제공함과 동시에 가격적 혜택에 대한 서비스도 함께 제공합니다.',
                    textp3: '글로벌 네크워크가 확보됨으로써 국가별 지역별 선사들과 유연한 소통이 이뤄지고 있으며, 대량 화물에 대한 가격 경쟁력 확보 및 최상의 서비스를 제공합니다.'
                }]
            }]
        }],
        avintion: [{
            card: [{
                text1: '항공운송 서비스',
                text2: '빠르고 정확한 항공 운송 전부서비스를 보유함으로써 고객사의 요구를 충족시키기 위해 항공운송 서비스를 제공하고 있습니다.',
                text3: '항공사와 전략적 제휴를 통한 全중국 지역별 복합운송 서비스 제공합니다.',
                text4: '항공 화물 운송 서비스 제공, 대형 항공사 화물 위탁 서비스 제공합니다.',
                text5: '다국적 항공사와의 유기적인 협력을 통한 글로벌 네트워크 형성으로 고품격 서비스 제공합니다.',
                text6: '합리적인 비용, 고품격 서비스, 최상의 신용도, 다양한 고객사 요구를 충족시키기 위한 서비스 제공합니다.',
                url: '@/assets/image/air-1.jpg'
            }],
            text: [{
                title: '업무 서비스 소개',
                text: [{
                    url: '@/assets/image/air-2.jpg',
                    titlex: 'One-Stop 운송 서비스（One-Stop Transporting Logistics Service）',
                    textp: '대형 항공사와 전략적 제휴를 체결하여 고객이 만족할 수 있는 합리적인 운임과 안정적인 화물 운송 속도를 보장합니다. 또한 자차（自车）를 다수 보유하고 있으며, 항공 운송 서비스의 품질을 보장합니다.',
                }, {
                    url: '@/assets/image/air-3.jpg',
                    titlex: 'T/S 운송 서비스（Continued Transport Service）',
                    textp: 'T/S운송은 이미 항공기에서 양하 후 다른 항공기로 이관하는 운송 방법으로서 선적지부터 도착지까지의 운송서비스를 보장하며, 풍부한 업무 노하우로 고품격 서비스를 제공합니다.',

                }, {
                    url: '@/assets/image/air-4.jpg',
                    titlex: '항공〮해상 복합운송 서비스（Joint Services Of Sea And Air）',
                    textp: '해상·항공 연계 운송 서비스는 해상 운송에서 합리적인 가격과 항공운송의 신속성 등 두 가지 운송수단의 장점을 결합한 서비스로서 한국발 중국 항구 도착 후 항공으로 운행하는 연계 운송 서비스로서 한국에서 중국 全지역 서비스를 제공합니다.',
                    textp2: '해상 운송으로 항구 도착 후 항공으로 연계 운송하거나, 추가 남방지역으로 연계운송 하는 등 복합운송 서비스로 하여금 운송비와 리드타임 절약의 노하우 보유하고 있습니다. ',
                }, {
                    titlex: '서비스 이점（Service Advantage）',
                    textp: '대신물류는 항공 수출입, 국제 운송 및 해상/항공 복합 연계운송 서비스, 통관 등 복합적인 물류 전문 서비스 기업입니다.',
                    textp2: ' 대신물류는 종합 국제물류 기업으로서 풍부한 정보, 지식, 경험을 보유하고 있으며, 고객에게 항공화물정보를 실시간으로 제공하고, 항공기 이착륙 등 화물운송정보를 확인할 수 있습니다 ',
                    textp3: '항공 운송 조건, 항공운임, 리드타임 및 운송거리(운송방법), 환적 Transshipment) 등을 종합적으로 고려하여 고객사 니즈에 적합한 운송에 대한 컨설팅 서비스를 제공합니다.',
                    textp4: '전 세계 지역별 에이전트를 보유하고 있어 급변하는 물류 환경에서도 신속하게 출발지, 경유지, 도착지에 대한 정보를 수집하고 대응하여 안전하고 신뢰할 수 있는 해상/항공 연계 운송 서비스를 제공 드립니다.'
                }]
            }]
        }],
        landlocked: [{
            card: [{
                text1: '내륙운송 서비스 ',
                text2: '대신물류는 전 중국 지역별 네크워크가 형성 되어있어 고객사 니즈에 따른 각종 내륙 운송 서비스를 제공합니다.',
                text3: '풍부한 운송 노하우를 바탕으로 경쟁력 있는 운임과 고품격 서비스를 제공합니다.',
                url: '@/assets/image/land-1.jpg'
            }],
            text: [{
                title: '업무 서비스 소개',
                text: [{
                    url: '@/assets/image/land-2.jpg',
                    img: '@/assets/image/land-3.jpg',
                    titlex: '내륙 도로 운송',
                    textp: '약 300대의 자차를 보유하고 있으며, 윙-바디 차량, ISO TANK 차량, 컨테이너 차량, 냉동 위험품 차량, 냉장 위험품 차량 등 고객사 니즈에 맞는 차량 서비스를 제공합니다. ',
                    textp2: '중국의 운송 기업중 한국 물류회사로써 풍부한 제3자 물류 서비스 경험을 충족하고, 우수한 전문 인재를 양성했으며 효율적인 관리 팀을 보유하고 있습니다. '
                }, {
                    url: '@/assets/image/land-4.jpg',
                    titlex: '서비스 이점（Service Advantage）',
                    textp: ' 중국 전역 네트워크에 차량 실시간 관리 감독 서비스 가능',
                }]
            }]
        }],
        warehousing: [{
            card: [{
                text1: '창고 물류',
                text2: '총면적 77,500㎡ 이상，10,000㎡의 화물 하역 플랫폼 보유',
                text3: '넓은 차선, 편리한 하역을 위한 플랫폼 및 창고와 인접한 사무실 보유',
                text4: '화물의 안전한 보관을 위한 24시간 모니터링 및 고급 소방 시설 서비스 보유',
                text5: '보관, 분류, 재포장, 치수 측정, 바코드 스캔, 라벨 부착, 팔렛 작업, 랩핑 작업 등 종합 물류 운영 서비스 제공',
                url: '@/assets/image/warehouse-3 (2).jpg'
            }],
            text: [{
                title: '업무 서비스 소개',
                text: [{
                    url: '@/assets/image/warehouse-3 (3).jpg',
                    titlex: '창고 분포도',
                    textp: '베이징 순이, 베이징 퉁저우, 톈진 플러스 물류센터, 중경 양강신구 물류창고, 창주 경제 개발구, 위해 임항 경제 개발구, 소주 등 여러 지역에 분포되어 있습니다.',
                }, {
                    titlex: '서비스 이점（Service Advantage）',
                    url: '@/assets/image/warehouse-3 (1).jpg',
                    img: '@/assets/image/warehouse-4.jpg',
                    textp: '대신물류는 꾸준한 도전을 통하여 보다 높은 고객 만족도를 실현하고 있으며, 첨단 시설, 완벽한 설비 , 첨단 IT시스템 등을 바탕으로 고객의 화물 보관에 더욱 최적화된 솔루션을 제공하고 있습니다.',
                    textp2: '고품질 인력, 안정적인 팀, 완벽한 인력 보호 대책 및 장비로 인간 중심의 기업 및 장기적인 발전의 개념을 반영합니다.',
                }]
            }]
        }],
        project: [{
            card: [{
                text1: '프로젝트 물류',
                text2: '다양한 국제 무역은 보다 포괄적이며 전문적인 물류 서비스를 필요로 하기에',
                text3: '대신물류는 탄탄한 실력과 강력한 대리인 네트워크를 바탕으로 전문적인 다양한 국제 복합운송 서비스를 제공하고 있습니다.',
                text4: '고객을 위해 더 나은 복합 운송 경로를 설계하여 운송 비용과 시간을 절약하고',
                text5: '고객에게 ‘Door to Door’, ‘Door to Port & Place’ 등 다양한 복합 운송 서비스를 제공하고 선하증권 혹은 복합 운송 선하증권을 발행하고 있습니다.',
                text6: '위탁 한번이면 한 건으로 전 과정 진행 가능합니다.',
                url: '@/assets/image/Project-1.jpg'
            }],
            text: [{
                title: '업무 서비스 소개',
                text: [{
                    url: '@/assets/image/Project-2.jpg',
                    textp: '프로젝트 물류 서비스（Project Cargo Service）',
                }, {
                    titlex: '서비스 이점（Service Advantage）',
                    url: '@/assets/image/Project-3.jpg',
                    textp: '프로젝트 운송중 각종 기계, 설비, 재료 등 채택해야 하는 운송 기술 선택',
                    textp2: '화물 유형에 따른 더 나은 모선 및 항공기 모델을 선택하여 안전과 시간 준수 보장',
                    textp3: '국내 운송 관련 상황에 따른 사전 환경조사, 탐사경로, 장애물조사, 운송 시뮬레이션, 특수차량 배치 등',
                    textp4: '목적지 국가의 통관, 법률 및 관례 등 사전조사를 통한 안전한 운송방안 제시',
                    textp5: '현지 면세 및 감세 통관 업무 수행과 L/C, C/O, 대사관 인증 등 무역&물류 서비스 총괄 컨설팅 서비스 제공',
                    textp6: '해외 에이전시를 통한 국가별 출하시부터 도착시까지 운송계획부터 사후관리까지의 체계화된 종합 물류 서비스 제공',
                }]
            }]
        }],
        international: [{
            card: [{
                text1: '위험물 운송',
                text2: '부식성, 자연성, 인화성, 독성, 폭발성 등의 성질을 가지고 있으며',
                text3: '운송, 하역, 저장 및 보관 과정에서 인명 및 재산 손상을 일으키기 쉽고 특별한 보호가 필요한 물품은 위험물로 분류됩니다.',
                text4: '위험물 운송은 특수 운송의 일종으로 전문 기술 조직 또는 인력이 특수 차량을 이용하여 운송을 진행합니다.',
                text5: '관련 국가 기능 기구의 엄격한 검토와 심사를 거쳐야 하며 위험물의 안전한 운송을 보장할 수 있는 해당 시설 및 장비가 있어야 위험물 운송에 대한 자격 보유가 가능합니다.',
                url: '@/assets/image/weixianxiao.jpg"'
            }],
            text: [{
                title: '服务介绍',
                text: [{
                    url: '@/assets/image/weixianpintext1.jpg',
                    titlex: '위험물 도로운송',
                    textp: '전문 위험물 운송팀은 내륙 운송 및 수출입 위험물 업무를 수행하고 적시에 정확한 정보 피드백, 추적 운송, 전 과정 모니터링, 운송상황을 모든 고객에게 정확하게 피드백하여 고객의 화물이 리스크없이 안전하게 목적지에 도착할 수 있도록 보장합니다.',
                }, {
                    url: '@/assets/image/weixianpintext2.jpg',
                    img: '@/assets/image/weixianpintext3.jpg',
                    titlex: '服务优势（Service Advantage）',
                    textp: '완전한 보호장비 착용 및 숙련된 작업자의 경우 고객의 요구에 따라 추가 작업이 가능합니다.',
                    textp2: '정교한 노선 관리, 신뢰할 수 있는 프로세스 설계, 네트워크화된 비상 메커니즘, 다양한 통신 수단 및 고객 콜 센터의 중앙 집중식 추적은 화물이 안전하고 정확하며 시간을 준수하는 배송을 서비스를 보장합니다.'
                }]
            }]
        }],
        imaget: require('@/assets/image/warehouse-3 (3)ko.jpg'),
        imaget1: require('@/assets/image/warehouse-3 (1)ko.jpg'),
    },
    advocacy: {
        'title1': '신문공고',
        'title2': '업계 지식',
        'title3': '2022年物流行业发展趋势与工业物流崛起',
        'title4': '2022年世界国际海运公司',
        'title5': '物流机械设备的发展趋势',
        'title6': '物流快递行业年度期盼的新机遇',
        'title7': '进军国际市场开拓物流发展新机遇',
        'title8': '物流从业必备知识：货车分类',
        'title9': '易燃易爆危险品存放原则',
        'title10': '集装箱知识',
        'title11': '危险品分类',
        'title12': '新闻公告',
        'title13': '行业知识',
        'title14': '海运公司排行前十：',
        'textp1': '在过去两年中、物流行业呈加速发展态势。2022年、这一趋势将随新技术的应用而进一步发展、并将在操作流程、运输、仓储、安全性',
        'textp2': '据Alphainer最新运力数据显示,截至2022年7月10日,全球班轮公司运力前10名与上月比保持不变,目前全球在运营集装箱船总数为6406艘,总运力增加到2579.3291万TEU',
        'textp3': '随着生产和物流规模的扩大,以及物流自动化程度的提高,物流机械设备在现代化生产和物流中应用越来越广,作用也越来越大;而现代化生产和现代化物流又...',
        'textp4': '26日,京广高铁正式开通运营。羊城晚报记者获悉,武广高铁运营后已有广东快递企业试水广州长沙间的...',
        'textp5': '5月11日,全国首列“南亚国际货运列车”在兰启程,10天后,这列满载着“中国制造”的货运...',
        'textp6': '目前从交通运输行业统计来讲，吧运营货车分成四类，普通货车、专用货车、牵引车和挂车，2020年共有营运载货汽车1110.28万辆，其普通货车414.14万辆，专用货车50.67万辆，牵引车310.8万辆',
        'textp7': '易燃易爆化学危险品的整装存储，往往存放的品种多，性质危险而复杂，比较难于管理。而散装存储量比较大，设备多，技术条件复杂，一旦发生事故难以施救。',
        'textp8': '集装箱运输是国际贸易货物多试联运过程中的重要运输方式，实现全球范围内的船舶、港口、航线、公路、中转站、桥梁、隧道多式联运相配套的物流系统。',
        'textp9': '凡具有爆炸、易燃、毒害、腐蚀、放射性等物质、在运输、装卸和贮存保管过程中，容易造成人事伤亡和财产损毁而需要特别防护的货物，均属危险品货物。',
        'time1': '2022-10-10',
        'time2': '2022-09-13',
        'time3': '2019-03-12',
        'time4': '2019-03-12',
        'time5': '2019-03-12',
        'time6': '2022-10-10',
        'time7': '2022-09-13',
        'time8': '2022-09-13',
        'time9': '2019-03-12',
        'textp1': '在过去两年中，物流行业呈加速发展态势。2022年，这一趋势将随这新技术的应用而进一步发展，并将在操作流程、运输、仓储、安全性，以及供应链稳定性等诸多方面产生持续而广泛的影响。数字化和可持续性将深刻改变物流行业的工作方式。',
        'textp2': '物流4.0开启物流数字化时代新篇章',
        'textp3': '一段时间以来，我们越来越多地谈到物流4.0，这一概念源自工业4.0。数字化将成为新时代物流发展的根基，更准确地说，将基于流程自动化、设备网络化和企业间的联合。物流4.0旨在简化流程、提升效率，同时维护全球供应链的稳定，尤其是在充满挑战的时期，及时发现并消除供应链风险至关重要。',
        'textp4': '与物流4.0息息相关的大数据、机器使用和自动化，以及网络化将对物流的未来发展产生重要影响。数字化在道路交通中也将发挥巨大作用。一方面，自动驾驶技术的发展将有助于提高道路安全，另一方便也有助于缓解交通拥堵。',
        'textp5': '物流自动化的大数据和网络',
        'textp6': '数据是物流的关键组成部分，随着数据量的不断提升，其作用愈加重要。智能设备的使用使越来越多的数据可以被收集和交换。物流企业可根据不同的数据流进行分析、预测或判断发展趋势。',
        'textp7': '有效部署大数据和市场参与者之间的智能网络，有利于维护整个供应链的稳定，预测及避免潜在“断链”风险，并及时制定替代计划，以保证物流时效。同时，大数据也可用于优化运输路径，提升效率。此外，网络化不仅有助于优化风险管理，还能够提高供应链的透明度，帮助相关企业了解运输过程中的具体信息。',
        'textp8': '机器人化加速物流行业发展',
        'textp9': '在很多科幻电影中都出现了人类与机器人协同的场景。虽然目前机器人还没有那么全能，但已经可以执行简单的任务，并在工作中发挥作用。机器人被广泛试运行使用，特别是在电子商务和消费品仓储领域，主要用来减少人工工作量。机器人可为新订单拣选货物，并在库区内运输重货，通过预先计算规划最优路径，高效把货物送到交接点，再由另外的机器人继续完成后面的流程。传感器的使用可有效避免机器人在行进过程中的碰撞。',
        'textp10': '得益于电子商务的蓬勃发展，以及由此带来物流需求的快速增长，更多机器人将会投入试运营，其数字化能力也将得到进一步提升。',
        'textp11': '多式联运物流运输将成主流',
        'textp12': '为了防范和避免供应链中断的风险，多样化的物流运输方式可有效防范、降低供应链中断的风险。去年苏伊士运河堵塞给全球海运带来很大影响，凸显出单一运输方式的局限性。多式联运覆盖路运、汽运、海运和空运，一旦一种运输方式出现问题，可迅速切换至另一种方式，从而有效避免运输延误，维持供应链稳定。多元化物流渠道是实现多式联运的先决条件。',
        'textp13': '可持续物流进一步发展',
        'textp14': '可持续发展是未来几年乃至几十年的大趋势。物流行业将为温室气体减排做出重大贡献。据世界经济论坛研究统计，物流运输占全球碳排放总量的5.5%。因此，为未来的物流发展提供环保解决方案势在必行。',
        'textp15': '过去，速度和成本是选择运输方式的决定性因素。如今，可持续性变得越来越重要。除了车辆电气化，清洁燃料的使用和相关技术的研发对于减少二氧化碳和其他排放至关重要。此外，仓储物流也是物流可持续发展的重要领域，如包材的选择，以及物流公司如何获取能源途径等方面。',
        'textp16': '碳排放报告也是进一步减排的有效工具，未来也将更加普及。报告可提供供应链透明度，供企业深入了解其二氧化碳和污染物的排放情况。此外，可持续发展也将成为企业形象和竞争力的重要组成部分，促使企业设法减少排放量，将对环境的影响降到最低。',
        'textp17': '总结与展望',
        'textp18': '今年，数字化将在物流行业进一步发力，将有更多技术创新应用于简化流程，提高全球供应链稳定性。在可持续发展的大趋势下，整个物流行业将在2022年开启发展新格局，在注重环保的同时，保持盈利能力。我们已处于数字化和气候变化的大变局之中，及早适应并积极引入新技术，顺势而为的市场参与者将乘势而上。',
        'li-p1': '1、马士基航运有限公司（全球最大的航运公司，总部设于丹麦歌本哈根）',
        'li-p2': '2、地中海航运公司（世界第二大航运公司）',
        'li-p3': '3、法国达飞海运集团（世界10大航运公司第三名）',
        'li-p4': '4、中国远洋运输（集团）公司',
        'li-p5': '5、赫伯罗特船舶公司',
        'li-p6': '6、中国长荣海运',
        'li-p7': '7、中国香港东方海外货柜航运公司',
        'li-p8': '8、中国台湾阳明海运集团',
        'li-p9': '9、日本商船三井MOL',
        'li-p10': '10、日本邮船株式会社（NYK）',
        'h3-p1': '第十名、日本邮船株式会社（NYK）',
        'h3-p2': '第九名、日本商船三井MOL',
        'h3-p3': '第八名、中国台湾阳明海运集团',
        'h3-p4': '第七名、中国香港东方海外货柜航运公司',
        'h3-p5': '第六名、中国长荣海运',
        'h3-p6': '第五名、赫伯罗特船舶公司',
        'h3-p7': '第四名、中国远洋运输（集团）公司',
        'h3-p8': '第三名、法国达飞海运集团',
        'h3-p9': '第二名、地中海航运公司',
        'h3-p10': '第一名、马士基航运有限公司',
        'p-text1': '日本三大船务公司之一，也是世界上历史最悠久和规模最大的航运公司之一，由日本在早期的三菱财阀所创立。',
        'p-text2': '该公司总部位于日本千代地区，拥有大约800艘船只，包含集装箱船、邮轮、游轮等等。',
        'p-text3': '世界10大航运公司中第二个日本船运公司，是日本经连的一部分，它们家的鳄鱼标志可以在世界各地大港口的集装箱上看到',
        'p-text4': '位于中国台湾基隆的元阳运输公司，成立于1972年，如果追溯历史还可以清朝期间。',
        'p-text5': '该公司拥有85艘船舶，拥有420万载重吨位34.6万标准箱吞吐量，该公司服务于全球70多个国家',
        'p-text6': '世界最大的综合国际集装箱运输公司之一，在全球70多个国家设立有320个办事处。该公司拥有57艘不同级别的船只，包括极端天气下的冰级船。',
        'p-text7': '是一家集装箱运输公司，总部设立于中国台湾桃园市，其主要线路为远东至北美洲、中美洲以及加勒比地区。该公司在全球80个国家拥有240个港口。',
        'p-text8': '德国跨国船运公司，成立与1970年，由德国两家运输公司合并而成，拥有175艘集装箱船',
        'p-text9': '中国最大的航运企业，截至2011年就覆盖了全球160多个国家1600多个港口。截止2016年总船队规模稳居全球第一',
        'p-text10': '成立于1978年，总部位于马赛，是法国第一，世界10大航运公司第三名。该公司在全球150多个国家420个港口拥有200多条航线',
        'p-text11': '成立于1970年的瑞士航运公司，是世界第二大航运公司，在全球拥有350个机构，员工超过2.8万人，拥有471艘集装箱船。',
        'p-text12': '丹麦最大的航运商业集团，也是世界最大的航运公司，总部设立于丹麦歌本哈根，在全球130个国家设有办事处，员工超过8',
        'infor1': '　　随着生产和物流规模的扩大，以及物流自动化程度的提高，物流机械设备在现代化生产和物流中应用越来越广，作用也越来越大',
        'infor2': '纵观物流机械设备发展现状可以看出，为适应现代物流的需要，物流机械设备有如下发展趋势',
        'infor3': '1.大型化和高速化',
        'infor4': '　　大型化是指设备的容量、规模、能力越来越大，高速化是指设备的运转速度、运行速度、识别速度、运算速度大大加快。现代社会经济快速发展，使得生产和物流规模不断扩大，为了提高作业效率和规模效益，大型、高速的物流机械需求量不断增长。物流机械设备的起重量、载重量、生产率、作业能力越来越大，工作速度越来越快',
        'infor5': '2.实用化和轻型化',
        'infor6': '　　物流机械设备是现代化、自动化物流的重要物质技术基础。物流机械设备要求使用方便，容易维护、操作，具有优异的耐久性、无故障性和良好的经济性，以及较高的安全性、可靠性。因此，今后会更加注重开发使用性能好、成本低、可靠性高的物流机械设备。',
        'infor7': '3.专用化和通用化',
        'infor8': '　　物流是社会经济发展的产物，必然随着社会经济的发展而呈现多样化的特征。多样化的特征反映了对物流机械设备需求的多样化，从而使物流机械设备具有多种多样的品种且',
        'infor9': '4.自动化和智能化',
        'infor10': '　　将机械技术和电子技术相结合，将先进的微电子技术、电力电子技术、光缆技术、液压技术、模糊控制技术应用到机械的驱动和控制系统中，实现物流机械设备的自动化和智能化将是今后的发展方向。例如，大型起重机的新一代电气控制装置已发展为全电子数字化控制系统，由全数字化控制驱动装置、可编程序控制器、故障诊断及数据管理系统、数字化操纵给定检测等装置组成。先进技术的应用使起重机具有更高的柔性，从而提高了单机综合自动化水平。运输设备的操作更多的转向依靠仪表、信号和辅助驾驶系统智能化程度越来越高。海运、空运中的自动驾驶系统、自动化立体仓库中的送取货小车、公路运输智能交通系系统',
        'infor11': '　　智能式搬运车AHV( AutonomousHandlingVehicle)也将会得到广泛应用。AHV的形状类似于现在使用的AGV，但装有两只通用的机械手，在工作时依靠起视觉作用的工业摄像机，对货物的位置和大小进行判断，如同人一样可以用机械手自由地搬运重达200--300千克的货物。AHV具有协同作业的功能，搬运的货物过长、过重时，可以由两台以上的ARV协同作业进行搬运，这样会大大减少ARV的规格型号，便于管理。数台同一规格AHV的联合运作，其作业能力可提高很多。',
        'infor12': '5.成套化和系统化',
        'infor13': '　　在物流机械设备单机自动化的基础上，通过计算机把各种物流机械设备组成一个物流机械设备集成系统，通过控制室的控制，与物流系统协调配合。这类物流机械设备自动化程度较高，具有信息处理功能，可将传感器检测出来的各种信息实施存储、运算、逻辑判断、变换等处理加工，进而向执行机构发出控制指令。这类物流机械设备还具有较好的信息输人输出接口，实现信息全部、准确、可靠地在整个物流机械集成系统中的传输。物流机械设备通过系统集成，能形成不同机种的优先匹配和组合，取长补短，发挥出优先功效。以后将发展的有工厂生产搬运自动化系统、货物配送集散系统、集装箱装卸搬运系统、货物的自动分拣与搬运系统等。',
        'infoe-text1': '26日，京广高铁正式开通运营。羊城晚报记者获悉，武广高铁运营后已有广东快递企业试水广州长沙间的高铁快件运输，如今京广高铁的开通更将给我国物流、快递业带来新机遇。',
        'infoe-text2': '“此前武广高铁开通，广州长沙间就有高铁快件运输。”广州一家知名民营快递企业负责人告诉记者，目前快递走公路运输的占了80%左右，走航空的约占15%。其实无论从时效和成本来看，高铁比公路都有优势。京广高铁开通运营后，中间很多节点城市都是快递服务需求旺盛的地区，空间很大。',
        'infoe-text3': '据了解，12月中旬，国家邮政局、国家发改委综合运输研究所、中国快递协会就组成联合调研组，赴广东省对快递企业利用高铁运输快件等开展实地调研。',
        'infoe-text4': '除了快递业，大宗商品运输等传统物流业预计也将从京广高铁开通、京广线货运能力提升中受益。数据显示，仅武广高铁开通后，京广线货运能力就提升了10.9%，使长期运能受限制的煤炭、石油、粮食等物资及港澳外贸货运的运输需求得到改善。',
        'infoe-text5': '5月11日，全国首列“南亚国际货运列车”在兰启程，10天后，这列满载着“中国制造”的货运列车，经过铁路、公路运输，将到达尼泊尔首都加德满都。这标志着兰州在“一带一路”建设中，在开启“兰州号”中亚、欧洲货运专列之后，又一次迈出了向西向南阿开放的重要一步',
        'infoe-text6': ' 　　“一带一路”重大战略构想提出两年多来，沿线国家纷纷响应。去年，中国跟30多个国家签订了共建“一带一路”谅解备忘录，一些主要的“一带一路”骨架已经开始搭建，比如中蒙俄经济走廊、中国—中南半岛的经济走廊、新亚欧大陆桥经济走廊、中国—中亚—西亚走廊、中巴经济走廊、孟中印缅经济走廊等，有的已取得喜人成果。先就中欧来说，以往，中欧之间的货运主要靠空运、海运以及公路运输，空运和公路运输成本高，海运耗时长。中欧班列以其运距短、速度快、安全性高的特征，以及安全快捷、绿色环保、受自然环境影响小的优势，已成为国际物流中陆路运输的骨干方式。',
        'infoe-text7': ' 　　国际货运的前景十分广阔，具有极高的性价比。众所周知，过去我们面向南亚和东南亚的物流贸易主要通过海运完成，此次开通的铁路货运专列可谓开拓了陆路运输的途径，将充分发挥铁路运输的优势。可以想象，今后由铁路带动各个国家和城市之间的经济贸易、货物往来、人员流动、文化交流与技术支持，必将强化各国间的“城市”效应，“一带一路”的发展格局也必将在互帮、互助、互进中建立起世界各国共同发展的合作体系。随着科技的互补、资源的互助，铁路也会在发展中迈开步伐，终铺就一条世界铁路运输线路图。 ',
        'infoe-text8': ' 　　现在“一带一路”已成为一种趋势，每一条国际铁路线的开通都对沿线国家与地区带来新一轮的发展机遇，各产业应以全新的发展思路来迎接这一崭新的时代，携手创造共赢。随着和南亚铁路携手合作，我们的铁路也在不断“升级”提速，我们相信，“南亚国际货运列车”必将成为“一带一路”大战略上国际货运的“明星产品”，也将拉动共同的经济发展从而惠及人民。',
        'textd-p1': '目前从交通运输行业统计来讲，把营运货车分成四类，普通货车、专用货车、牵引车和挂车，2020年共有营运载货汽车1110.28万辆，其中普通货车414.14万辆，专用货车50.67万辆，牵引车310.84万辆，挂车334.63万辆。其中的专用货车，包括了集装箱车辆、冷藏车辆、罐式车辆以及大件和危货运输车。',
        'textd-p2': '就一般货车而言，可以从载重、厢体结构、驾驶室、驱动方式、车长、用能结构、排放标准等去进行划分。专用车辆也可以放到厢体结构中，属于特殊厢体结构。挂车本身也是一个系列，根据《货运挂车系列型谱》（GB/T 6420-2017），挂车包括了栏板式挂车、仓栅式挂车、厢式、低平板式、车辆运输车挂车（又分为中置轴和半挂）、液体运输车、颗粒物料运输半挂车、集装箱半挂车、冷藏和保温运输半挂车。与一般货车按照厢体结构分类基本相同。因此，我们可以从载重、厢体结构、驾驶室形态、车辆驱动轴、车长、用能结构以及车辆排放7个维度，对货车类型进行细分',
        'textd-p3': '一、按载重分',
        'textd-p4': '目前常用的，一般分为微卡、轻卡、中卡、重卡四种：',
        'textd-p5': '微卡：总质量＜1.8吨。',
        'textd-p6': '轻卡：1.8吨＜总质量≤6吨。',
        'textd-p7': '中卡：6吨＜总质量≤14吨 。',
        'textd-p8': '重卡：总质量＞14吨。',
        'textd-p9': '2019年轻型民用载货汽车拥有量为1901万辆，占民用载货汽车的68.3%；重型民用载货汽车拥有量为762万辆，占民用载货汽车的27.37%；中型民用载货汽车拥有量为116万辆，占民用载货汽车的4.18%；微型民用载货汽车拥有量为4万辆，占民用载货汽车的0.15%。以轻、重型货车为主。',
        'textd-p10': '对于车长小于 6000mm 且总质量小于 4500kg 的轻型载货汽车，上蓝牌，允许在城市内行驶，就是我们常听到的蓝牌轻卡。根据新的《收费公路车辆通行费车型分类》，4.2米蓝牌轻卡成为1、2类车型的分界标，降低了蓝牌轻卡的收费。',
        'textd-p11': '二、按车厢结构分',
        'textd-p12': '由于运输货物的特性不同，货车（挂车）车箱厢体差异较大，一般来讲，可以分为仓栅式、栏板式、（低）平板式、自卸车、厢式、集装箱车、罐式以及中置轴。',
        'textd-p13': '其中，平板式包括普通平板和低平板专用车。普通平板半挂车，车长最长13米。新出台的《低平板半挂车技术规范》（JT/T 1264-2020），明确无论是低平板专用半挂车还是低平板增强半挂车，车长都不能超过13.75米。',
        'textd-p14': '低平板车也是轿运车的主要车型。2018年7月1日起开始只允许以下三种车运输商品车：平头铰接列车车长不超过17.1m、长头不超过18.1米，车宽不超过2.55m，载货后车高不超过4.2m；中置轴整车车长不超22米，车宽不超过2.55m，载货后车高不超过4.2m。',
        'textd-p15': '三种车型的转载情况如下图所示：',
        'textd-p16': '中置轴列车',
        'textd-p17': '此外，对于17.5米大板车，发改委、工信部曾于2007年12月21日发布公告，对17.5米长低平板半挂车等车型予以撤销，并自2007年12月31日起不再作为办理车辆注册登记的依据。而低平板半挂车的使用年限最长是15年，因此目前业内有了2022年17.5米大板将全面淘汰的说法。',
        'textd-p18': '中置轴属于挂车的一种特殊类型（挂车分为全挂、半挂、中置轴）。2016年颁布的《GT/T 1589-2016汽车、挂车及汽车列车外廓尺寸、轴荷及质量限值》标准中新增了中置轴货运列车的选项，中置轴货运列车的宽度为2.55米，最大长度达到了史无前例的22米，也就是前面看到的轿运车车型。部分品牌的中置轴挂车货箱容积可以达到130立方米，对于未来的快递甩挂运输绝对是一大优势。',
        'textd-p19': '三、按驾驶室分',
        'textd-p20': '可以分为长头、短头、平头和偏置四类。平头车发动机就在驾驶室下方，短头车发动机有一半在驾驶室下方，长头车发动机就位于驾驶室前方。',
        'textd-p21': '在我国，目前大家在路面上看到的绝大部分都是平头车，零星可能会看到几辆长头车。但是时间倒退几十年的话，结果是反着的。新中国生产的第一辆卡车，解放CA10就是长头车。随着汽车保有量的提升和提高道路安全的考虑，国家出台相关标准对汽车的外廓界限进行了明确要求，车头越长、货箱就越短，拉的货就越少，由此促使平头车开始盛行。',
        'textd-p22': '平头卡车',
        'textd-p23': '偏置式卡车',
        'textd-p24': '四、按驱动轴分',
        'textd-p25': '我们经常听见货车有4x2、6x2、6x4之类的说法，这就是按照驱动方式对卡车进行分类。前一个数字是车轮总数，后一个数字是驱动轮数。例如4x2，表示货车有4个车轮（两根轮轴即双桥），其中有2个驱动车轮（一根驱动轴即单桥）。同理，6*4的货车有3根轮轴，其中有2个是驱动轴。',
        'textd-p26': '我们常常听到前四后八、前四后四。所谓“前四后八”就是四轴车，前四是双桥4个轮，后八也是双桥，每个桥的左右各2个轮，每个桥4个轮，就是8个轮子驱动。“前四后四”是三轴车，前桥是双桥4轮，后面是单桥，每个桥的左右各2个轮，一共4轮。',
        'textd-p27': '五、按车厢长分',
        'textd-p28': '我们常听到的几款车型，如4米2、9米6、13米5等，都指的是车厢长度，不是《GT/T 1589-2016汽车、挂车及汽车列车外廓尺寸、轴荷及质量限值》标准中车长的概念。',
        'textd-p29': '常见几款车型规格及载重如下：',
        'textd-p30': '六、按用能及排放分',
        'textd-p31': '目前货车仍以柴油引擎作为动力来源，同时有部分轻型货车使用汽油、石油气、天然气以及电力等新能源。根据《中国机动车环境管理年报》，2017年货车保有量2341万辆，其中柴油车保有量1690.9万辆，占比72.2%。按排放标准阶段划分，国Ⅱ及之前排放标准的柴油货车35万辆，Ⅲ标准的柴油货车841.2万辆，国Ⅳ标准的柴油货车716.8万辆，国Ⅴ及以上排放标准的柴油货车97.9万辆。',
        'textd-p32': '2019年生态环境部等11部委联合印发《柴油货车污染治理攻坚战行动计划》，明确提出自2019年7月1日起，重点区域、珠三角地区、成渝地区提前实施机动车国六排放标准。2020年年底前，京津冀及周边地区、汾渭平原加快淘汰国三及以下排放标准营运柴油货车100万辆以上。各地也相继出台了国Ⅲ货车限行和淘汰补贴的相关政策，加速国Ⅲ货车淘汰。',
        'tretal1': '（八）腐蚀性物品',
        'tretal2': '腐蚀性物品，一般与其他种类的物品之间和腐蚀性物品中的有机与无机腐蚀品之间，酸性与碱性物品之间，可燃体固体之间，都应单独仓间存放，不可混储。',
        'tretal3': '1．腐蚀性物品之间',
        'tretal4': '（1）溴与硝酸、硫酸等混合，能加强其腐蚀性或燃烧，应隔离后存放。',
        'tretal5': '（2）过氧化氢易与硝酸起反应放出大量气体，遇三氯化磷等会起脱水作用，产生高温，甚至发生爆炸。',
        'tretal6': '2．腐蚀性物品与可燃液体之间',
        'tretal7': '有机酸性腐蚀品与乙类可燃液体之间要隔离后储存，有机碱性腐蚀品与可燃液体之间可同库储存，但堆垛须间隔2米以上。',
        'tretal8': '3．腐蚀性物品与可燃固体之间，无机碱性腐蚀品与乙类可燃固体之间可隔离存放。',
        'tretal9': '4．其他碱性腐蚀品均应单独仓间存放，不可混储。',
        'tretal10': '（七）毒害品',
        'tretal11': '1．无机毒害品与无机氧化剂之间和有机毒害品的固体与硝酸的有机衍生物之间应隔离存放',
        'tretal12': '2．无机毒害品与氧化（助燃）气体，应隔离存放，与不燃气体可同库存放；有机毒害品与不燃气体应隔离存放；',
        'tretal13': '3．液体的有机毒害品与可燃液体可隔离后存放；',
        'tretal14': '4．有机毒害品的固体与乙类燃烧固体可同储，但与甲类易燃固体应隔离后存放，无机毒害品与乙类易燃固体可隔离后存放。',
        'tretal15': '5．有机毒害品的固体与液体之间，以及与无机毒害品之间，均应隔离后存放；有机毒害品的液体与液体，固体与固体之间，无机的剧毒品与有毒品之间均可同库储存。。',
        'tretal16': '6．其他种类物品均不可同库存放',
        'tretal17': '（六）氧化剂和有机过氧化物',
        'tretal18': '1．氧化剂和有机过氧化物之间',
        'tretal19': '甲类无机氧化剂与有机氧化剂特别是有机过氧化物不能同库储存。',
        'tretal20': '2．氧化剂与压缩气体和液化气体',
        'tretal21': '甲类氧化剂与易燃或剧毒气体不可同库储存，因为甲类氧化剂的氧化能力强，与剧毒气体或易燃气体接触容易引起燃烧或钢瓶爆炸。',
        'tretal22': '乙类氧化剂与压缩和液化气体可隔离储存，即保持2米以上的间距，与惰性气体可同库存放。',
        'tretal23': '3．氧化剂与自燃、易燃，遇湿易燃物品，一般不同库储存。',
        'tretal24': '4．氧化剂与毒害品',
        'tretal25': '无机氧化剂与毒害品应隔离储存，有机氧化剂毒害品可以同库隔离储存，但与有可燃性的毒害品不可同库储存。',
        'tretal26': '5．氧化剂与腐蚀性物品',
        'tretal27': '有机过氧化物不得与溴和硫酸等氧化性腐蚀品同库储存。',
        'tretal28': '6．氧化剂（指无机氧化剂）不得与松软的粉状物同库储存。',
        'tretal29': '（五）遇湿易燃物品',
        'tretal30': '1．遇湿易燃品不得与自燃物品同库存放。因为自燃物品危险性大，见空气即着火，且黄磷、651除氧催化剂等，包装用水作稳定剂，一旦包装破损或渗透都有引起着火的危险。',
        'tretal31': '2．遇湿易燃物品与氧化剂不可同库存放。因为遇湿易燃物品是还原剂，遇氧化剂会剧烈反应，发生着火和爆炸。',
        'tretal32': '3．遇湿易燃物品与腐蚀性物品之间：因为溴、过氧化氢、硝酸、硫酸等强酸，都具有较强的氧化性，与遇水燃烧物品接触会立即着火或爆炸。且过氧化氢还含有水，也会引起着火爆炸，所以不得同库存放。与盐酸、甲酸、醋酸和含水碱性腐蚀品如液碱等，亦应隔离存放。',
        'tretal33': '4．遇湿易燃物品与含水的易燃液体和稳定剂是水的易燃液体，如己酸、二硫化碳等，均不得同库存放。',
        'tretal34': '5．遇湿易燃物品之间；活泼金属及其氢化物可同库存放；电石受潮后产生大量乙炔气，其包装易发生爆破，应单独存放。磷化钙、硫化钠、硅化镁等受潮后能产生大量易燃的毒气和易自燃的毒气，因此，亦应单独存放。',
        'tretal35': '（四）自燃物品',
        'tretal36': '1．甲类自燃物品，不得与爆炸品、氧化剂、氧化性气体（助燃）、易燃液体、易燃固体同库存放。',
        'tretal37': '2．黄磷、651除氧催化剂，不得与遇湿易燃物品同库存放。硼、锌、铝、锑、碳氢化合物类自燃物品与遇湿易燃物品可隔离储存。',
        'tretal38': '3．自燃物品之间：甲类自燃物品与乙类自燃物品与黄磷、651除氧催化剂不得同库放。',
        'tretal39': '4．腐蚀性物品，溴、硝酸、过氧化氢（40%的双氧水）具有较强的氧化性，自燃物品与之不可同库存放。与盐酸、甲酸、醋酸和碱性腐蚀品亦不准同库存放或隔离存放。',
        'tretal40': '（三）易燃固体',
        'tretal40': '1．与自燃物品：因为甲类自燃物品性质不稳定，可以自行氧化燃烧，会引起易燃固体的燃烧，所以不能同库储存。与乙类自燃物品亦应隔离储存。 ',
        'tretal42': '2．与遇湿易燃物品：因和遇湿易燃物品灭火方法不同，且有的性质相互抵触，不能同库储存。',
        'tretal43': '3．与氧化剂：因为易燃固体都有很强的还原性，与氧化剂接触或混合有引起着火爆炸的危险，所以都不能同库存放。',
        'tretal44': '4．与腐蚀性物品：与具有氧化性的腐蚀性物品如：溴、过氧化氢、硝酸等不可同库储存，与其他酸性腐蚀性物品可同库隔离存放，但发孔剂H（71011）与某些酸作用能引起燃烧，所以不宜同库存放。',
        'tretal45': '5．易燃固体之间：金属氨基化合物类、金属粉末、磷的化合物类等与其他易燃固体不宜同库储存。因为他们灭火方法和储存保养措施不同，硝化棉、赤磷、赛璐珞、火柴等均宜专库储存。樟脑、萘、赛璐珞制品，虽属乙类易燃固体，但挥发出来的蒸气和空气可形成爆炸性的混合气体，遇着火源容易引起燃烧爆炸，储存条件要求高，也宜专库储存。',
        'tretal46': '（二）易燃液体',
        'tretal47': '易燃液体，不仅本身易燃，而且大都具有一定的毒性。如甲醇、苯、二硫化碳等，原则上应单独存放。但因各种条件的限制，不得不与其他种类危险品同储时，应遵守如下原则：',
        'tretal48': '1．与甲类自燃物品不能同储，与乙类自燃物品也应隔离存放。因为自燃物品可自行燃烧。',
        'tretal49': '2．与腐蚀性物品溴、过氧化氢，硝酸等强酸不可同储，如量甚少时，也应隔离存放，并保持2米以上的间距。因为溴、过氧化氢、硝酸等强酸都有较强的氧化性。',
        'tretal50': '3．含水的易燃液体和需要加水存放或运输的易燃液体、不得与遇湿易燃物品同储',
        'tretal51': '易燃易爆化学危险品的整装储存，往往存放的品种多，性质危险而复杂，比较难于管理。而散装储存量比较大，设备多，技术条件复杂，一旦发生事故难以施救。故无论何种储存方式都潜在有很大的火灾危险，所以必须用科学的态度从严管理，万万不可马虎从事',
        'tretal52': '分类存放的一般原则',
        'tretal53': '（一）压缩、液化气体',
        'tretal54': '1．压缩、液化气体之间：可燃气体与氧化性（助燃）气体混合，遇着火源易着火甚至爆炸，应隔离存放。',
        'tretal55': '2．压缩、液化气体与自然、遇湿易燃等易燃物品之间：剧毒、可燃、氧化性（助燃）气体均不得与甲类自然物品物品同储和配装；与乙类自燃物品，遇水易燃物品（灭火方法不同）应隔离存放和配装；可燃液体、固体与剧毒、氧化性气体不得同储配装。',
        'tretal56': '3．压缩、液化气体与腐性物品：剧毒气体、可燃气体不得与硝酸、硫酸等强酸配装和同储，与氧化性（助燃）气体不燃气体应隔离储存放和配装。',
        'tretal57': '4．氧气瓶及氧气空瓶不得与油脂及含油物质、易燃物同储和配装。',
        'text1': '1.集装箱（container）所谓集装箱，是指具有一定强度、刚度和规格专供周转使用的大型装货容器。使用集装箱转运货物，可直接在发货人的仓库装货，运到收货人的仓库卸货，中途更换车、船时，无须将货物从箱内取出换装。按所装货物种类分，有杂货集装箱、散货集装箱、液体货集装箱、冷藏箱集装箱等；按制造材料分，有木集装箱、钢集装箱、铝合金集装箱、玻璃钢集装箱、不锈钢集装箱等；按结构分，有折叠式集装箱、固定式集装箱等，在固定式集装箱中还可分密闭集装箱、开顶集装箱、板架集装箱等；按总重分，有30吨集装箱、20吨集装箱、10吨集装箱、5吨集装箱、2.5吨集装箱等。',
        'text2': '2.集装箱外尺寸（containers overall external dimensions） 包括集装箱永久性附件在内的集装箱外部最大的长、宽、高尺寸。它是确定集装箱能否在船舶、底盘车、货车、铁路车辆之间进行换装的主要参数。是各运输部门必须掌握的一项重要技术资料。',
        'text3': '3.集装箱内尺寸（containers internal dimensions）集装箱内部的最大长、宽、高尺寸。高度为箱底板面至箱顶板最下面的距离，宽度为两内侧衬板之间的距离，长度为箱门内侧板量至端壁内衬板之间的距离。它决定集装箱内容积和箱内货物的最大尺寸。',
        'text4': '4.集装箱内容积（containers unobstructed capacity）按集装箱内尺寸计算的装货容积。同一规格的集装箱，由于结构和制造材料的不同，其内容积略有差异。集装箱内容积是物资部门或其它装箱人必须掌握的重要技术资料。',
        'text5': '5.集装箱计算单位（twenty-feet equivalent units 简称：TEU) 又称20英尺换算单位，是计算集装箱箱数的换算单位。目前各国大部分集装箱运输，都采用20英尺和40英尺长的两种集装箱。为使集装箱箱数计算统一化，把20英尺集装箱作为一个计算单位，40尺集装箱作为两个计算单位，以利统一计算集装箱的营运量。',
        'text6': '6.集装箱租赁 （container leasing) 即所有人将空箱租给使用人的一项业务。集装箱所有人为出租的一方集装箱，与使用人，一般是船公司或货主，为承租的一方，双方签订租赁合同。由出租人提供合格的集装箱交由承租人在约定范围内使用。装箱，每航次编制配载图；办理有关货运单证的编签； 编制并签验集装箱运用运载工具的出入及流转的有关单证； 办理集装箱及运载工具、装卸工具的情况检查、维修，以及空箱的清扫、熏蒸等工作； 空箱的收发、存贮和保管；安排空箱和重箱在堆场的堆码，及编制场地分配计划； 其它有关业务工作。 集装箱装卸区一般由专用码头、前沿、堆场、货运站、指挥塔、修理部门、大门和办公室组成。有时堆场或货运站等可延伸到市区内部事5-15公里的中转站。',
        'text7': '7.集装箱装卸区（container terminal) 是集装箱运输中，箱或货装卸交换保管的具体经办部门。它受承运人或其代理人的委托，进行下列各项业务：对整箱货运的交换、保管； 设有集装箱货运站者，办理拼箱货的交接； 安排集装箱船的靠泊，装卸集装箱。',
        'text8': '8.集装箱前方堆场（marshalling yard） 是指在集装箱码头前方，为加速船舶装卸作业，暂时堆放集装箱的场地。其作用是：当集装箱船到港前，有计划有次序地按积载要求将出口集装箱整齐地集中堆放，卸船时将进口集装箱暂时堆放在码头前方，以加速船舶装卸作业。',
        'text9': '9.集装箱后方堆场（container yard） 集装箱重箱或空箱进行交接、保管和堆存的场所。有些国家对集装箱堆场并不分前方堆场或后方堆场，统称为堆场。集装箱后方堆场是集装箱装卸区的组成部分。是集装箱运输“场到场”交接方式的整箱货办理交接的场所（实际上是在集装箱卸区“大门口”进行交接的）。',
        'text10': '10.空箱堆场（van pool） 专门办理空箱收集、保管、堆存或交接的场地。它是专为集装箱装卸区或转运站堆场不足时才予设立。这种堆场不办理重箱或货物交接。它可以单独经营，也可以由集装箱装卸区在区外另设。有些国家，经营这种空箱堆场，须向航运公会声明。',
        'text11': '11.中转站或内路站（container depot or inland depot）海港以外的集装箱运输的中转站或集散地。它的作用除了没有集装箱专用船的装卸作业外，其余均与集装箱装卸区业务相同。中转站或内陆站的度量，包括集装箱装卸港的市区中转站、内陆城市、内河港口的内陆站均在内。',
        'text12': '12.集装箱货运站（container freight station 简称：CFS） 为拼箱货装箱和拆箱的船、货双方办理交接的场所。承运人在一个港口或内陆城市只能委托一个集装箱货运站的经营者。由它代表承运人办理下列主要业务：拼箱货的理货和交接； 对货物外表检验如有异状时，就办理批注； 拼箱货的配箱积载和装箱； 进口拆箱货的拆箱和保管； 代承运人加铅封并签发站收据； 办理各项单证和编制等。',
        'text13': '13.托运人责任（shippers liabilities） 托运人在集装箱运输中应有的责任，这种责任是不完全同于传统海运方面的。拼箱货托运人的责任与传统海运相同。整箱货托运人的责任不同于传统运输的有：应保证所报货运资料的正确和完整； 承运人有权核对箱内所装货物，因核对而发生的费用，有托运人承担； 海关或其它权力机关开箱检查，其费用和由此发生货损货差，由托运人承担；如集装箱货不满，或是垫衬不良，积载不当，或是装了不适于集装箱运输的货物，因而引起货损、货差，概由托运人负责； 如使用了托运人自有的不适航的集装箱，所引起的货损事故，应由托运人负责；在使用承运人集装箱及设备其间造成第三者财产或生命的损害，应由托运人负责赔偿。集装箱的租赁，国际上有多种不同的方式，总括起来有：程租、期租、活期租用和航区内阻赁等。',
        'text14': '14.责任限制（limits of liability） 集装箱运输中发生货损货差，承运人应承担的最高赔偿额。拼箱货的责任限制与传统运输相同。整箱货的赔偿按照目前国际上的一些判例：如果提单上没有列明箱内所装货物的件数，每箱作为一个理赔计算单位；如提单上列明箱内载货件数的，仍按件数计算；如果货物的损坏和灭失，不属海运，而是在内陆运输中发生的，则按陆上运输最高赔偿额办理；如集装箱是由托运人所有或提供时，遇有灭失或损坏，其责任确属承运人应承担者，亦应视作一个理赔计算单位。',
        'goods1': '1.集装箱（container）所谓集装箱，是指具有一定强度、刚度和规格专供周转使用的大型装货容器。使用集装箱转运货物，可直接在发货人的仓库装货，运到收货人的仓库卸货，中途更换车、船时，无须将货物从箱内取出换装。按所装货物种类分，有杂货集装箱、散货集装箱、液体货集装箱、冷藏箱集装箱等；按制造材料分，有木集装箱、钢集装箱、铝合金集装箱、玻璃钢集装箱、不锈钢集装箱等；按结构分，有折叠式集装箱、固定式集装箱等，在固定式集装箱中还可分密闭集装箱、开顶集装箱、板架集装箱等；按总重分，有30吨集装箱、20吨集装箱、10吨集装箱、5吨集装箱、2.5吨集装箱等。',
        'goods2': '2.集装箱外尺寸（containers overall external dimensions） 包括集装箱永久性附件在内的集装箱外部最大的长、宽、高尺寸。它是确定集装箱能否在船舶、底盘车、货车、铁路车辆之间进行换装的主要参数。是各运输部门必须掌握的一项重要技术资料。',
        'goods3': '3.集装箱内尺寸（containers internal dimensions）集装箱内部的最大长、宽、高尺寸。高度为箱底板面至箱顶板最下面的距离，宽度为两内侧衬板之间的距离，长度为箱门内侧板量至端壁内衬板之间的距离。它决定集装箱内容积和箱内货物的最大尺寸。',
        'goods4': '4.集装箱内容积（containers unobstructed capacity）按集装箱内尺寸计算的装货容积。同一规格的集装箱，由于结构和制造材料的不同，其内容积略有差异。集装箱内容积是物资部门或其它装箱人必须掌握的重要技术资料。',
        'goods5': '5.集装箱计算单位（twenty-feet equivalent units 简称：TEU) 又称20英尺换算单位，是计算集装箱箱数的换算单位。目前各国大部分集装箱运输，都采用20英尺和40英尺长的两种集装箱。为使集装箱箱数计算统一化，把20英尺集装箱作为一个计算单位，40尺集装箱作为两个计算单位，以利统一计算集装箱的营运量。',
        'goods6': '6.集装箱租赁 （container leasing) 即所有人将空箱租给使用人的一项业务。集装箱所有人为出租的一方集装箱，与使用人，一般是船公司或货主，为承租的一方，双方签订租赁合同。由出租人提供合格的集装箱交由承租人在约定范围内使用。装箱，每航次编制配载图；办理有关货运单证的编签； 编制并签验集装箱运用运载工具的出入及流转的有关单证； 办理集装箱及运载工具、装卸工具的情况检查、维修，以及空箱的清扫、熏蒸等工作； 空箱的收发、存贮和保管；安排空箱和重箱在堆场的堆码，及编制场地分配计划； 其它有关业务工作。 集装箱装卸区一般由专用码头、前沿、堆场、货运站、指挥塔、修理部门、大门和办公室组成。有时堆场或货运站等可延伸到市区内部事5-15公里的中转站。',
        'goods7': '7.集装箱装卸区（container terminal) 是集装箱运输中，箱或货装卸交换保管的具体经办部门。它受承运人或其代理人的委托，进行下列各项业务：对整箱货运的交换、保管； 设有集装箱货运站者，办理拼箱货的交接； 安排集装箱船的靠泊，装卸集装箱。',
        'goods8': '8.集装箱前方堆场（marshalling yard） 是指在集装箱码头前方，为加速船舶装卸作业，暂时堆放集装箱的场地。其作用是：当集装箱船到港前，有计划有次序地按积载要求将出口集装箱整齐地集中堆放，卸船时将进口集装箱暂时堆放在码头前方，以加速船舶装卸作业。',
        'goods9': '9.集装箱后方堆场（container yard） 集装箱重箱或空箱进行交接、保管和堆存的场所。有些国家对集装箱堆场并不分前方堆场或后方堆场，统称为堆场。集装箱后方堆场是集装箱装卸区的组成部分。是集装箱运输“场到场”交接方式的整箱货办理交接的场所（实际上是在集装箱卸区“大门口”进行交接的）。',
        'goods10': '10.空箱堆场（van pool） 专门办理空箱收集、保管、堆存或交接的场地。它是专为集装箱装卸区或转运站堆场不足时才予设立。这种堆场不办理重箱或货物交接。它可以单独经营，也可以由集装箱装卸区在区外另设。有些国家，经营这种空箱堆场，须向航运公会声明。',
        'goods11': '11.中转站或内路站（container depot or inland depot）海港以外的集装箱运输的中转站或集散地。它的作用除了没有集装箱专用船的装卸作业外，其余均与集装箱装卸区业务相同。中转站或内陆站的度量，包括集装箱装卸港的市区中转站、内陆城市、内河港口的内陆站均在内。',
        'goods12': '12.集装箱货运站（container freight station 简称：CFS） 为拼箱货装箱和拆箱的船、货双方办理交接的场所。承运人在一个港口或内陆城市只能委托一个集装箱货运站的经营者。由它代表承运人办理下列主要业务：拼箱货的理货和交接； 对货物外表检验如有异状时，就办理批注； 拼箱货的配箱积载和装箱； 进口拆箱货的拆箱和保管； 代承运人加铅封并签发站收据； 办理各项单证和编制等。',
        'goods13': '13.托运人责任（shippers liabilities） 托运人在集装箱运输中应有的责任，这种责任是不完全同于传统海运方面的。拼箱货托运人的责任与传统海运相同。整箱货托运人的责任不同于传统运输的有：应保证所报货运资料的正确和完整； 承运人有权核对箱内所装货物，因核对而发生的费用，有托运人承担； 海关或其它权力机关开箱检查，其费用和由此发生货损货差，由托运人承担；如集装箱货不满，或是垫衬不良，积载不当，或是装了不适于集装箱运输的货物，因而引起货损、货差，概由托运人负责； 如使用了托运人自有的不适航的集装箱，所引起的货损事故，应由托运人负责；在使用承运人集装箱及设备其间造成第三者财产或生命的损害，应由托运人负责赔偿。集装箱的租赁，国际上有多种不同的方式，总括起来有：程租、期租、活期租用和航区内阻赁等。',
        'goods14': '14.责任限制（limits of liability） 集装箱运输中发生货损货差，承运人应承担的最高赔偿额。拼箱货的责任限制与传统运输相同。整箱货的赔偿按照目前国际上的一些判例：如果提单上没有列明箱内所装货物的件数，每箱作为一个理赔计算单位；如提单上列明箱内载货件数的，仍按件数计算；如果货物的损坏和灭失，不属海运，而是在内陆运输中发生的，则按陆上运输最高赔偿额办理；如集装箱是由托运人所有或提供时，遇有灭失或损坏，其责任确属承运人应承担者，亦应视作一个理赔计算单位。',
    },
    foot: {
        xiazhaispin: '회사 소개 영상',
        tbat:'다운로드',
        textdiz: '',
        tupian:'',
        bananhap:'',
        title: '연락처 ',
        subheading: '전문 물류 업체를 자부합니다. 안심하고 저희에게 맡기십시요.',

        data: [{
            text: '천진본사핫라인'
        }, {
            text: '해운 업무'
        }, {
            text: ' 항공 업무'
        }, {
            text: '3PL 업무'
        }, {
            text: '클래임&건의 '
        }]
    },
    network: [{
        title: '세계로 뻗어가는 대신물류 네트워크',
        subheading: '대신물류 네트워크'
    }, {
        title: '천진대신통물류회사 천진본사',
        subheading: ' 천진시 하서구 위제도 146호 화성광장 B좌 22층',
        sjhaoma: ' 022-88239781',
        dianhuahaoma: '022-88239785',
        emoe: 'sales_china@i-daeshin.com',
    }, {
        title: '천진대신통물류회사 북경지사',
        subheading: '  북경시 순이구 남채진 채상서로 1호'
    }, {
        title: '천진대신통물류회사 상해지사',
        subheading: '  상해시 장영구 선하로 317호 원동국제대하 B좌 2109실'
    }, {
        title: '천진대신통물류회사 위해지사',
        subheading: '산동성 위해시 임항 경제 기술 개발구 경산징정기로 6-2호'
    }, {
        title: '천진대신통물류회사 창주지사',
        subheading: '창주 경제 개발구 동해로 35호 5동'
    }, {
        title: '섬서대신통물류회사 동천지사',
        subheading: '섬서성 동천시 인태구 유만촌 1조'
    }, {
        title: '천진대신국제 서안지사',
        subheading: ' 서안시 고신구 금업이로 일취상 부북구 8호로 2단원 2301/2302'
    }, {    
        title: '천진대신통 중경지사',
        subheading: ' 중경시 유북구 용흥진 영용대로 19호 풍수물류원'
    }, {
        title: '천진대신통 미산사무실',
        subheading: '사천성 미산시 견산구 빈강비취성1기(남대문)'
    }, {
        title: '천진대신통 혜주사무실',
        subheading: ' 광동성 혜주시 혜양구 대압만구 하용가도천 수만2호로2단원3108호'
    }, {
        title: '천진대신통 복주사무실',
        subheading: '복건성 복청시 신착진반 승하원소구6호로2좌705호'
    }, {
        title: '천진대신통 취저우사무실',
        subheading: '절강성 취저우시 가성구 고신원구 효성대로 효성 신재료 유한 회사(12호문)'
    }, {
        title: '천진대신통 소주지사',
        subheading: '소주시 상성구 고철신성남천성로77호 고융대하1404실'
    }, {
        title: '천진대신통 상주사무실',
        subheading: '강소성 상주시 신북구 항구중로 1호 住友전자'
    }, {
        title: '㈜디에스엘USA LA(CA)지사',
        subheading: '11시미 팜 로드 브루밍데일 31302',
        emoe: 'yhlee1019@i-daeshin.com',
    }, {
        title: '㈜디에스엘USA Austin(TX)본사',
        subheading: '(78759)텍사스 오스틴 Research 12710로 380호',
        sjhaoma: '+1 512 638 0799',
        emoe: 'mhsung@i-daeshin.com',
    }, {
        title: '㈜디에스엘KOREA 서울본사',
        subheading: '서울시 영등포구 선유로 70, 1102-1호{br}(문래동3가, 우리벤처타운2)',
        sjhaoma: ' +82-02-2088-0681',
        dianhuahaoma: ' +82-2-2088-0685',
        emoe: ' op@i-daeshin.com',
    }, {
        title: '㈜디에스엘JAPAN 도쿄본사',
        subheading: ' 도쿄 아라카와 니시닛포리 5-33-3 요코치빌 101',
        sjhaoma: ' +81-3-6806-7927',
        emoe: ' kazunobu_iwamoto@i-daeshin.com',
    }, {
        title: '홍콩대신물류유한회사',
        subheading: '',
    }, {
        title: '천진대신통물류회사 광저우지사',
        subheading: '광저우시 황푸구 카이펑대로 2395호에서 타이상업광장 A2 동 1302호',
    }]
}