<template>
    <div :id="isMouseOver ? 'headeretad' : 'header'" @click="fetab" v-show="yuyan">
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                    <div class="update">
                        <div @click="Tohome" class="update-l">
                            <img src="@/assets/image/daeshinlogo.png" alt="">
                        </div>
                        <div class="yuyan">
                            <span @click="handoff('cn')">CH</span>/<span @click="handoff('ko')">KO</span>/<span
                                @click="handoff('ja')">JA</span>/<span @click="handoff('en')">US</span>
                        </div>
                        <span class="long" @click="long('Long')" v-text="$t('message.data[6].label')"></span>
                        <div class="tbact">
                            <ul id="menu" v-show="xiangs">
                                <li @mouseover="handleMouseOver('1')" @mouseout="handleMouseOut">
                                    <span v-text="$t('message.data[0].label')" :class="{ 'activeted': 0 === color }"
                                        id="shoye" @click="tate(0, 'Home')"></span>
                                    <ul class="rejichadian-span" v-show="isMouseOver">
                                        <li id="shoye" v-text="$t('message.data[0].xia[6].label')"
                                            @click="navigateTo('guanyu', 0)"></li>
                                        <li id="shoye" v-text="$t('message.data[0].xia[7].label')"
                                            @click="navigateTo('guanyu1', 0)"></li>
                                        <li id="shoye" v-text="$t('message.data[0].xia[8].label')"
                                            @click="navigateTo('guanyu2', 0)"></li>
                                        <li id="shoye" v-text="$t('message.data[0].xia[9].label')"
                                            @click="navigateTo('guanyu3', 0)"></li>
                                        <li id="shoye" v-text="$t('message.data[0].xia[10].label')"
                                            @click="navigateTo('guanyu4', 0)"></li>
                                        <li id="shoye" v-text="$t('message.data[0].xia[11].label')"
                                            @click="navigateTo('guanyu5', 0)"></li>
                                    </ul>
                                </li>
                                <li @mouseover="handleMouseOver('2')" @mouseout="handleMouseOut">
                                    <span v-text="$t('message.data[1].label')" :class="{ 'activeted': 1 === color }"
                                        id="gongsxinixie" @click="tate(1, 'Information')"></span>
                                    <ul class="rejichadian-span" v-show="isMouseOver">
                                        <li id="shoye" v-text="$t('message.data[1].xia[0].label')"
                                            @click="navigatoeinformation('gongsxinxi', 1)"></li>
                                        <li id="shoye" v-text="$t('message.data[1].xia[1].label')"
                                            @click="navigatoeinformation('gongsxinxi2', 1)"></li>
                                        <li id="shoye" v-text="$t('message.data[1].xia[2].label')"
                                            @click="navigatoeinformation('gongsxinxi4', 1)"></li>
                                        <li id="shoye" v-text="$t('message.data[1].xia[3].label')"
                                            @click="navigatoeinformation('section', 1)"></li>
                                        <li id="shoye" v-text="$t('message.data[1].xia[4].label')"
                                            @click="navigatoeinformation('sectioneer', 1)"></li>
                                        <li id="shoye" v-text="$t('message.data[1].xia[5].label')"
                                            @click="navigatoeinformation('gongsxinxi7', 1)"></li>
                                    </ul>
                                </li>
                                <li @mouseover="handleMouseOver('3')" @mouseout="handleMouseOut">
                                    <span v-text="$t('message.data[3].label')" :class="{ 'activeted': 2 === color }"
                                        @click="tate(2, 'Network')"></span>
                                    <ul class="rejichadian-span" v-show="isMouseOver">
                                        <li id="shoye" v-text="$t('message.data[3].xia[0].label')"
                                            @click="sjiewwnagl('guojia', 2)"></li>
                                        <li id="shoye" v-text="$t('message.data[3].xia[1].label')"
                                            @click="sjiewwnagl('guojia1', 2)"></li>
                                        <li id="shoye" v-text="$t('message.data[3].xia[3].label')"
                                            @click="sjiewwnagl('guojia2', 2)"></li>
                                        <li id="shoye" v-text="$t('message.data[3].xia[2].label')"
                                            @click="sjiewwnagl('guojia2', 2)"></li>
                                    </ul>
                                </li>
                            </ul>
                            <i v-show="adtab" class="el-icon-s-unfold" id="tlice" @click="drawer = true" type="primary"
                                style="margin-left: 16px;"> </i>
                            <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleClose"
                                z-index="100">
                                <ul>
                                    <li class="a" @click="tebat('Home')" v-text="$t('message.data[0].label')"></li>
                                    <li class="a" @click="tebat('Information')" v-text="$t('message.data[1].label')">
                                    </li>
                                    <li class="a" @click="tebat('Network')" v-text="$t('message.data[3].label')"></li>
                                </ul>
                            </el-drawer>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div class="rejichadian" v-show="isMouseOver" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
        </div>
    </div>
</template>
<script>
export default {
    metaInfo() {
        return {
            title: this.$t('message.title'), // 使用国际化库来动态设置标题
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: [
                { name: 'description', content:this.$t('message.description') },
                { name: 'keywords', content:this.$t('message.keywords') },
                { property: 'og:title', content:this.$t('message.title') },
                { property: 'og:description', content:this.$t('message.description') }
            ]
        };
    },
    beforeUnmount() {
        localStorage.clear();
    },
    data() {
        return {
            isClick: false, // 初始点击状态
            lang: '',
            showedg: true,
            drawer: false,
            direction: 'rtl',
            islogin: false,
            oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
            scrollTop: 0, // 记录当前的滚动距离
            screenWidth: document.body.clientWidth,
            activeIndex: '1',
            activeIndex2: '1',
            cad: false,
            cdd: false,
            actice: false,
            acde: false,
            adtab: true,
            activeName: '',
            acd: '',
            isActive: 0,
            active: '<li class="sub" :key="index"></li>',
            activet: ' <ul class="two" v-if="add" ></ul>',
            add: false,
            color: '',
            listState: [],
            isMouseOver: false,
            shoye: false,
            gongsxinxie: false,
            yewulingyu: false,
            shjiewangl: false,
            browserLanguage: '',
            data: [{
                label: '首页',
                route: 'Home',
                id: 0,
            }, {
                label: '公司信息',
                route: 'Information',
                id: 1,
            }, {
                label: '业务领域',
                route: 'Outlets',
                id: 2,
            }, {
                label: '世界网络',
                route: 'Network',
                id: 3,
            }, {
                label: '宣传中心',
                route: 'Advocacy',
                id: 4,
                d: require('../assets/image/movie2.mp4')
            }],
            screenWidth: 0,
            xiangs: false,
            yuyan: true,
            lastScrollPosition: 0,

        };
    },
    method: {
    },
    computed: {
        showValue() {
            return this.yuyan;
        },
        isMobile() {
            return this.screenWidth < 768; // 假设宽度小于 768px的设备判定为移动端
        },
    },
    created() {
        this.screenWidth = window.innerWidth; // 更新屏幕宽度
        if (this.screenWidth > 1100) {
            this.xiangs = true
        } else {
            this.xiangs = false
        }
        if (sessionStorage.getItem('lang')) {
            this.$i18n.locale = sessionStorage.getItem('lang') // 设置当前语言为本地存储中的语言
            if (localStorage.getItem('lang') === 'cn') {
                this.showedg = true
                document.body.style.fontFamily = 'STXiHei';
            } else if (sessionStorage.getItem('lang') === 'ko') {
                document.body.style.fontFamily = 'Malgun Gothic';
            } else if (sessionStorage.getItem('lang') === 'ja') {
                document.body.style.fontFamily = 'Yu Gothic';
            } else if (sessionStorage.getItem('lang') === 'en') {
                document.body.style.fontFamily = 'Calibri Light';
            }
        } else {
            const browserLanguage = navigator.language || navigator.userLanguage
            if (browserLanguage == 'zh-CN') {
                this.$i18n.locale = 'cn'
                this.showedg = true
                document.body.style.fontFamily = 'STXiHei';
            } else {
                const lang = sessionStorage.getItem('lang') || browserLanguage
                if (localStorage.getItem('lang')) {
                    this.$i18n.locale = sessionStorage.getItem('lang') // 设置当前语言为本地存储中的语言
                } else {
                    this.$i18n.locale = lang // 设置当前语言为浏览器的语言
                }
                if (lang === 'cn') {
                    this.showedg = true
                    document.body.style.fontFamily = 'STXiHei';
                } else if (lang === 'ko') {
                    document.body.style.fontFamily = 'Malgun Gothic';
                } else if (lang === 'ja') {
                    document.body.style.fontFamily = 'Yu Gothic';
                } else if (lang === 'en') {
                    document.body.style.fontFamily = 'Calibri Light';
                }
            }
        }
    },
    watch: {
        // this.$emit('getVisible', this.yuyan);
        showValue(newVal) {
            this.$emit('showChange', newVal);
        },
        $route(to, from) {
            //监测到路由发生跳转时刷新一次页面
            // console.log(to.path);
            // 检测到路由到达没有点击的时候跳转到页面的时候判断路由来改变字体的颜色
            if (to.path == '/') {
                // console.log(111);
                this.color = 0
            }
            if (to.path == '/information') {
                this.color = 1

            }
            if (to.path == '/network') {
                this.color = 2
            }
            const token = localStorage.getItem('token')
            if (token) {
                this.islogin = true
            }
        },
        scrollTop(newValue, oldValue) {
            setTimeout(() => {
                if (newValue == window.scrollY) { //延时执行后当newValue等于window.scrollY，代表滚动结束
                    this.oldScrollTop = newValue; //每次滚动结束后都要给oldScrollTop赋值
                    this.actice = false;
                    this.acde = false;
                    this.adtab = true;
                    this.$d = function (b, a) {
                    }, (1, 2)
                };
            }, 50); //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时50ms
            if (this.oldScrollTop == oldValue) { //每次滚动开始时oldScrollTop与oldValue相等
            }
        }
    },
    mounted() {
        document.querySelectorAll('meta[data-vue-meta]').forEach(el => el.removeAttribute('data-vue-meta'));
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('scroll', this.handl);
        this.screenWidth = window.innerWidth; // 初始化屏幕宽度
        window.addEventListener('resize', this.handleResize); // 监听 窗口 大小 改变 事件
        this.handleScroll();
        const that = this;
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.documentElement.clientWidth;
                that.screenWidth = window.screenWidth;
            })();
        };
        this.setMetaLanguage();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize); // 移除窗口大小改变事件的监听
        window.removeEventListener('scroll')
    },
    methods: {
        setMetaLanguage() {
            const lang = navigator.language || navigator.userLanguage;
            document.querySelector('meta[name="language"]').setAttribute('content', lang);
        },
        handl() {
            const currentScrollPosition = window.pageYOffset;
            if (currentScrollPosition > this.lastScrollPosition) {
                this.yuyan = false; // 向下 滚动 ， 隐藏 Header
            } else {
                this.yuyan = true; // 向上滚动，显示Header
            }
            this.lastScrollPosition = currentScrollPosition;

        },
        handleResize() {
            this.screenWidth = window.innerWidth; // 更新屏幕宽度
            if (this.screenWidth > 1100) {
                this.xiangs = true
            } else {
                this.xiangs = false
            }
        },
        navigateTo(e, index) {
            this.color = index
            const lang = this.$i18n.locale;
            this.$router.push({ name: 'Home', query: { lang } })
            setTimeout(() => {
                const element = document.getElementById(e);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 500); // 延迟500毫秒
        },
        navigatoeinformation(e, index) {
            this.color = index
            const lang = this.$i18n.locale;
            this.$router.push({ name: 'Information', query: { lang } })
            setTimeout(() => {
                const element = document.getElementById(e);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 500); // 延迟500毫秒
        },
        sjiewwnagl(e, index) {
            this.color = index
            const lang = this.$i18n.locale;
            this.$router.push({ name: 'Network', query: { lang } })
            setTimeout(() => {
                const element = document.getElementById(e);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 500); // 延迟500毫秒
        },
        handleMouseOver(e) {
            this.isMouseOver = true;
            if (e == 1) {
                this.shoye = true
                this.gongsxinxie = false
                this.yewulingyu = false
                this.shjiewangl = false
            } else {
            }
            if (e == 2) {
                this.shoye = false
                this.gongsxinxie = true
                this.yewulingyu = false
                this.shjiewangl = false
            } else {
            }
            if (e == 3) {
                this.gongsxinxie = false
                this.yewulingyu = true
                this.shjiewangl = false
                this.shoye = false
            } else {

            }
            if (e == 4) {
                this.gongsxinxie = false
                this.shoye = false
                this.yewulingyu = false
                this.shjiewangl = true
            } else {
            }
        },
        handleMouseOut(e) {
            this.isMouseOver = false;
        },
        handleClose(done) {
            this.drawer = false
        },
        handleScroll() {
            window.addEventListener('scroll', () => {
                this.scrollTop = window.scrollY;
            })
        },
        handleScroll() {
            // 获取滚动的垂直距离
            const scrollY = window.scrollY || window.pageYOffset;

            // 执行你的逻辑操作，比如输出滚动距离
            // console.log('滚动距离: ', scrollY);
        },
        handoff(e) {

            if (scrollY > 300) {
                this.yuyan = false
            } else {
            }
            sessionStorage.setItem("lang", e)
            const lang = sessionStorage.getItem('lang')
            if (lang === 'cn') {
                document.body.style.fontFamily = 'STXiHei';
            } else if (lang === 'ko') {
                document.body.style.fontFamily = 'Malgun Gothic';
            } else if (lang === 'ja') {
                document.body.style.fontFamily = 'Yu Gothic';
            } else if (lang === 'en') {
                document.body.style.fontFamily = 'Calibri Light';
            }
            if (this.$i18n.locale === lang) {
                this.$i18n.locale = lang;//关键语句
                this.acd = lang
                // this.yuyan=true
            } else {
                this.acd = lang
                this.$i18n.locale = lang;//关键语句
            }
            if (lang === 'cn') {
                this.showedg = true
            } else {
                this.showedg = false
            }
            let newQuery = JSON.parse(JSON.stringify(this.$route.query));
            newQuery.lang=lang;
            this.$router.push({ path:this.$route.path,query:newQuery});
        },
        handleSelect(keyPath) {
            this.$router.push({ name: keyPath })
        },
        Tohome() {
            const lang = this.$i18n.locale;
            this.$router.push({ name: 'Home', query: { lang } })

        },
        Tolong(e) {
            localStorage.removeItem('token')
            this.$router.push({ name: e })
        },
        long(e) {
            this.$router.push({ name: e })
            // window.location.href = 'http://101.200.200.121:60080/long'
        },
        tate(e, item) {
            this.color = e
            const lang = this.$i18n.locale;
            // 使用 this.$router.push 进行路由跳转
            this.$router.push({
                name: item,
                query: { lang }
            })
        },
        tebat(e) {
            this.$router.push({ name: e })
            this.drawer = false
        },
        reji() {
            this.actice = true;
            this.acde = true;
            this.adtab = false;
            let timer = setTimeout(() => {
                this.actice = false;
                this.acde = false;
                this.adtab = true;
            }, 5000)
        },
        handleNodeClick(data) {
            this.$router.push({ name: data.route })
        },
        fetab() {
        },
        guanbi() {
            this.acde = false
            this.actice = false
            this.adtab = true
        }
    }
};
</script>
<style lang="scss">
#header {
    z-index: 100;
    margin: 0 auto;
    width: 100%;
    height: 150px;
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 150;
    color: #fff;
    position: fixed;
    right: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);

    :lang(en) {
        font-family: Arial;
    }

    :lang(zh) {
        font-family: SimSun;
    }

    .rejichadian {
        width: 100%;
        background-color: #fff;
        z-index: 1000;
        margin: 0;
        padding: 0;
        transition: opacity 0.5s;
    }

    .v-modal {
        z-index: 100;
    }

    .el-row {
        color: #fff;
        height: 150px;
        width: 96%;

        .el-col-24 {
            color: #fff;
            height: 150px;
        }

        .grid-content {
            width: 100%;
            height: 150px;
            position: relative;
            color: #fff;

            .caidan {
                width: 1200px;
                height: 70px;
                margin: 0 auto;
                background: #000;
                opacity: 0.5;
                position: absolute;
                top: 110px;
                left: -370px;
                color: #0e98a5;
                z-index: 100;
                margin: 0;
                padding: 0;

                .caidan-b {
                    width: 800px;
                    height: 70px;
                    line-height: 70px;
                    margin: 0 auto;
                    display: flex;

                    p {
                        flex: 1;
                        width: 150px;
                        height: 100%;
                        line-height: 70px;
                        margin: 0;
                        padding: 0;
                        float: left;
                    }
                }
            }

            .update {
                height: 95%;
                margin: 0 auto;
                line-height: 150px;
                height: 150px;

                .long {
                    float: right;
                    cursor: pointer;
                    line-height: 150px;
                    margin-right: 10px;
                    font-size: 38px;
                }

                .long:hover {
                    color: #0d9d0d;
                }

                .a {
                    color: #000;
                    cursor: pointer;
                }

                .a:hover {
                    color: #0d9d0d;
                }

                .activeted {
                    color: #0d9d0d;
                }

                .tbact {
                    .el-drawer__container {
                        .el-drawer {
                            width: 90% !important;

                            ul {
                                padding: 0;
                                margin: 0;

                                li {
                                    padding-left: 60px;
                                    // padding: 0;
                                    margin: 40px auto;
                                    width: 100%;
                                    height: 60px;
                                    line-height: 60px;
                                }

                                :hover {
                                    //color: #09c;
                                    color: #0d9d0d;
                                }
                            }

                            .el-drawer__header {
                                .el-drawer__close-btn {
                                    .el-dialog__close {
                                        font-size: 50px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .update-l {
                    width: 140px;
                    height: 95%;
                    float: left;
                    cursor: pointer;
                    margin-left: 10px;

                    /*鼠标变小手*/
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                #tlice {
                    display: none;
                    cursor: pointer;
                    font-size: 40px;
                    float: right;
                    margin-top: 20px;
                    margin-right: 35px;
                    line-height: 118px;
                    height: 100px;
                }

                .acd {
                    cursor: pointer;
                    font-size: 30px;
                    float: right;
                    margin-top: 20px;
                    margin-right: 35px;
                    line-height: 150px;
                    height: 120px;
                    font-size: 28px;
                    display: none;
                }

                .yuyan {
                    font-size: 38px;
                    line-height: 150px;
                    height: 100%;
                    float: right;

                    span {
                        cursor: pointer;
                    }

                    .xiazai {
                        border: none;
                        background-color: transparent;
                        // color: #fff;
                        font-size: 38px !important;
                    }

                    .xiazai-p {
                        font-size: 38px !important;
                    }

                    .bg-red {
                        background-color: #ccc;
                    }
                }

                #menu {
                    float: right;
                    height: 150px;
                    line-height: 150px;
                    border: none;
                    font-size: 30px;
                    width: 70%;
                    display: flex;

                    li {
                        flex: 1;
                        overflow: hidden;
                        width: 20%;
                        cursor: pointer;
                        float: left;
                        box-sizing: border-box;
                        text-align: center;
                        height: 100%;
                        font-size: 40px;
                        padding: 0px 40px;
                        line-height: 150px;

                        span {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                        }

                        span:hover {
                            color: #0d9d0d;
                        }

                        span {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                        }

                        .rejichadian-span {
                            width: 100% !important;
                            text-align: center;
                            float: left;

                            #shoye {
                                float: left;
                                height: 40px;
                                line-height: 40px;
                                font-size: 30px !important;
                                width: 100% !important;
                            }

                            #shoye:hover {
                                color: #0d9d0d;
                            }
                        }
                    }

                    .active {
                        float: left;
                        box-sizing: border-box;
                        text-align: center;
                        height: 100%;
                        font-size: 40px;
                        padding: 0px 40px;
                        line-height: 150px;
                        background-color: #fff;
                        color: #09c;
                        border-bottom: solid 3px #0e98a5;
                    }
                }

                .sub {
                    float: left;
                    margin: 0 30px;
                    position: relative;
                    display: block;
                    color: #000;
                    font-weight: bold;
                    font-size: 1.1rem;
                    font: System;
                    line-height: 30px;
                    cursor: pointer;
                    height: 40px;
                    line-height: 150px;
                }

                .active {
                    color: brown;
                }

                .two {
                    display: none;
                    margin: 0;
                    padding: 0;
                    width: 600px;
                    height: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    display: -webkit-flex;
                    background-color: #ccc;
                    position: absolute;
                    bottom: 33px;
                    left: 60px;

                    .tow-item {
                        height: 30px;
                        line-height: 30px;
                        margin: 0;
                        padding: 0;
                        float: left;
                        flex: 1;
                    }
                }
            }
        }
    }

    .el-tree {
        height: 100%;
        font-size: 25px;
        position: relative;
        display: none;

        .el-tree-node {
            margin-bottom: 12px;
            text-align: center;
        }
    }
}

#headeretad {
    z-index: 100;
    margin: 0 auto;
    width: 100%;
    height: 150px;
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 150;
    color: #000;
    position: fixed;
    right: 0;
    left: 0;
    background-color: #fff;

    .rejichadian {
        width: 100%;
        height: 500px;
        border-top: solid 2px #ccc;
        background-color: #fff;
        z-index: 1000;
        margin: 0;
        padding: 50px;
    }

    .v-modal {
        z-index: 100;
    }

    .el-row {
        color: #000;
        height: 150px;
        width: 96%;

        .el-col-24 {
            color: #000;
            height: 150px;
        }

        .grid-content {
            width: 100%;
            height: 150px;
            position: relative;
            color: #000;

            .caidan {
                width: 1200px;
                height: 70px;
                margin: 0 auto;
                background: #fff;
                opacity: 0.5;
                position: absolute;
                top: 110px;
                left: -370px;
                color: #0e98a5;
                z-index: 100;
                margin: 0;
                padding: 0;

                .caidan-b {
                    width: 800px;
                    height: 70px;
                    line-height: 70px;
                    margin: 0 auto;
                    display: flex;

                    p {
                        flex: 1;
                        width: 150px;
                        height: 100%;
                        line-height: 70px;
                        margin: 0;
                        padding: 0;
                        float: left;
                    }
                }
            }

            .update {
                height: 95%;
                margin: 0 auto;
                line-height: 150px;
                height: 150px;

                .long {
                    float: right;
                    cursor: pointer;
                    line-height: 150px;
                    margin-right: 10px;
                    font-size: 38px;
                }

                .long:hover {
                    color: #0d9d0d;
                }

                .a {
                    color: #000;
                    cursor: pointer;
                }

                .a:hover {
                    color: #0d9d0d;
                }

                .activeted {
                    color: #0d9d0d;
                }

                .tbact {
                    .el-drawer__container {
                        .el-drawer {
                            width: 90% !important;

                            ul {
                                padding: 0;
                                margin: 0;

                                li {
                                    padding-left: 60px;
                                    margin: 40px auto;
                                    width: 100%;
                                    height: 60px;
                                    line-height: 60px;
                                }

                                :hover {
                                    color: #0d9d0d;
                                }
                            }

                            .el-drawer__header {
                                .el-drawer__close-btn {
                                    .el-dialog__close {
                                        font-size: 50px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .update-l {
                    width: 140px;
                    height: 95%;
                    float: left;
                    cursor: pointer;
                    margin-left: 10px;

                    /*鼠标变小手*/
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                #tlice {
                    display: none;
                    cursor: pointer;
                    font-size: 40px;
                    float: right;
                    margin-top: 20px;
                    margin-right: 35px;
                    line-height: 118px;
                    height: 100px;
                }

                .acd {
                    cursor: pointer;
                    font-size: 30px;
                    float: right;
                    margin-top: 20px;
                    margin-right: 35px;
                    line-height: 150px;
                    height: 120px;
                    font-size: 28px;
                    display: none;
                }

                .yuyan {
                    font-size: 38px;
                    line-height: 150px;
                    height: 100%;
                    float: right;

                    span {
                        cursor: pointer;
                    }

                    .xiazai {
                        border: none;
                        background-color: transparent;
                        // color: #000;
                        font-size: 38px !important;
                    }

                    .xiazai-p {
                        font-size: 38px !important;
                    }

                    .bg-red {
                        background-color: #ccc;
                    }

                    a {
                        color: #000 !important;
                    }
                }

                #menu {
                    float: right;
                    height: 150px;
                    line-height: 150px;
                    border: none;
                    font-size: 30px;
                    width: 70%;
                    display: flex;

                    li {
                        flex: 1;
                        width: 20%;
                        cursor: pointer;
                        float: left;
                        box-sizing: border-box;
                        text-align: center;
                        height: 100%;
                        font-size: 40px;
                        padding: 0px 40px;
                        line-height: 150px;

                        span:hover {
                            color: #0d9d0d;
                        }

                        span {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                        }

                        .rejichadian-span {
                            width: 100% !important;
                            text-align: center;
                            float: left;

                            #shoye {
                                color: #000;
                                float: left;
                                height: 50px;
                                line-height: 50px;
                                font-size: 30px !important;
                                width: 100% !important;
                                overflow: hidden !important;
                            }

                            #shoye:hover {
                                color: #0d9d0d;
                            }
                        }
                    }

                    .active {
                        float: left;
                        box-sizing: border-box;
                        text-align: center;
                        height: 100%;
                        font-size: 40px;
                        padding: 0px 40px;
                        line-height: 150px;
                        background-color: #fff;
                        color: #09c;
                        border-bottom: solid 3px #0e98a5;
                    }
                }

                .sub {
                    float: left;
                    margin: 0 30px;
                    position: relative;
                    display: block;
                    color: #000;
                    font-weight: bold;
                    font-size: 1.1rem;
                    font: System;
                    line-height: 30px;
                    cursor: pointer;
                    height: 40px;
                    line-height: 150px;
                }

                .active {
                    color: brown;
                }

                .two {
                    display: none;
                    margin: 0;
                    padding: 0;
                    width: 600px;
                    height: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    display: -webkit-flex;
                    background-color: #fff;
                    position: absolute;
                    bottom: 33px;
                    left: 60px;

                    .tow-item {
                        height: 30px;
                        line-height: 30px;
                        margin: 0;
                        padding: 0;
                        float: left;
                        flex: 1;
                    }
                }
            }
        }
    }

    .el-tree {
        height: 100%;
        font-size: 25px;
        position: relative;
        display: none;

        .el-tree-node {
            margin-bottom: 12px;
            text-align: center;
        }
    }
}

.actice {
    position: absolute;
    left: 0px;
    top: 145px;
    z-index: 100;
    width: 100%;
    font-size: 25px;

    .el-tree-node__label {
        font-size: 35px;
    }
}

@media (max-width: 1300px) {
    #header {
        width: 100%;

        .el-row {
            width: 100%;

            .grid-content {
                .update {
                    .acd {
                        display: block;
                    }
                }
            }
        }

        .tbact {
            width: 100%;
        }
    }
}

@media (max-width: 1200px) {
    #header {
        width: 100%;

        .el-row {
            width: 100%;
        }

        .grid-content {
            .update {
                width: 100%;

                .acd {
                    display: block;
                }
            }
        }

        .tbact {
            width: 100%;
        }
    }
}

@media (max-width:1100px) {
    #header {
        width: 100%;

        .el-row {
            width: 100%;

            .grid-content {
                .update {
                    #tlice {
                        display: block;
                    }
                }
            }
        }

        .grid-content {
            .update {
                width: 100%;

                .acd {
                    display: block;
                }
            }
        }

        .tbact {
            width: 100%;
        }
    }
}

@media (max-width:520px) {
    #header {
        .el-row {
            .grid-content {
                .update {
                    .update-l {
                        width: 80px;
                    }
                }
            }
        }
    }
}

@media (max-width:500px) {
    #header {
        .el-row {
            .grid-content {
                .update {
                    .update-l {
                        width: 70px;
                    }

                    .yuyan {
                        font-size: 18px;
                    }

                    .xiazai {
                        font-size: 18px !important;
                    }

                    .xiazai-p {
                        font-size: 18px !important;
                    }

                    .bg-red {
                        background-color: #ccc;
                    }

                    .long {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
</style>