const config = {
    path: '/menu',
    name: 'Menu',
    component: () => import('@/views/Menu/index.vue'),
    meta: {
      title: '天津物流集团',
      keepAlive:true
    }
  }
  
  export  {config}