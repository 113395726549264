const config = {
  path: '/transact',
  name: 'Transact',
  component: () => import('@/views/Transact/index.vue'),
  meta: {
    title: '天津物流集团-论点办理',
    keepAlive: true
  }
}
const data = {
  token: '',
  queryStr: '',
  structid: '',
  carId: '',
  resultStateFlag: '',
  availableflag: '',
  urgentflag: '',
  issuestateflag: '',
  processflag: '',
  startDate: '',
  endDate: ''
}
const date = {
 
}
export {
  config,
  data,
  date
}