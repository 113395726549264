import Vue from 'vue'
import App from './App.vue'
import Meta from 'vue-meta';
// 使用vue-meta
Vue.use(Meta);
/* vue-router */
import router from './router'
/* vuex */
import store from './store'
/*vue-jsonp*/
import {
  VueJsonp
} from 'vue-jsonp'
Vue.use(VueJsonp)
/* element-ui */
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/css/swiper.css';
import Swiper from 'swiper';
import Bootstrap from 'bootstrap'
/*bootstrap-vue*/
import {
  BootstrapVue,
  BootstrapVueIcons
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
/* axios */
import axios from 'axios'
//请求拦截器
axios.defaults.baseURL = '/api' // api地址
axios.defaults.timeout = 5000 // 请求超时时间
// vue-cookies配置
import cookies from 'vue-cookies'
Vue.prototype.$cookies = cookies;
// 请求拦截器
// 引入Vant组件
import Vant from 'vant';
import 'vant/lib/index.css';
import {Toast} from 'vant';
// 响应拦截器
let lastTime = Date.now();
function resetIdleTimer() {
  lastTime = Date.now();
}
import vueTouch from 'kim-vue-touch'
// 监听鼠标移动和键盘输入事件
document.addEventListener("mousemove", resetIdleTimer);
document.addEventListener("keydown", resetIdleTimer);
import 'bootstrap/dist/css/bootstrap.css';
// 去掉标签
Vue.filter('stripTags', function (value) {
  return value.replace(/<[^>]+>/g, '');
});
/*vue-i18n*/
import i18n from './assets/language/idnex'
/*swiper*/
import VueQrcodeReader from 'vue-qrcode-reader'
import Qrcode from 'qrcodejs2'
Vue.use(VueQrcodeReader)
Vue.prototype.$qrcode = Qrcode
Vue.prototype.$axios = axios
Vue.use(ElementUI);
new Vue({
  router,
  store,
  i18n,
  Vant,
  Swiper,
  Toast,
  Bootstrap,
  vueTouch,
  el: '#app',
  render: h => h(App),
     created() {
     const locale = this.$i18n.locale; // 这里假设你有一种方式来获取当前语言
     document.documentElement.lang = locale; // 设置html标签的lang属性
     } 
     })