const config = {
    path: '/2',
    name: '2',
    component: () => import('@/views/2/index.vue'),
    meta: {
      title: '天津物流集团',
      keepAlive:true
    }
  }
  
  export { config}