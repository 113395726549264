const config = {
    path: '/6',
    name: '6',
    component: () => import('@/views/6/index.vue'),
    meta: {
      title: '天津物流集团',
      keepAlive:true
    }
  }
  
  export { config}