const config = {
    path: '/recording',
    name: 'Recording',
    component: () => import('@/views/Recording/index.vue'),
    meta: {
      title: '天津物流集团-记录办理',
      keepAlive:true
    }
  }
  const data={
    token:'',
    queryStr:'',
    structid:'',
    carId:'',
    resultStateFlag:'',
    availableflag:'',
    urgentflag:'',
    issuestateflag:'',
    processflag:'',
    startDate:'',
    endDate:''
  }

  export {config,data}