const config = {
    path: '/Jiesoxiangqing',
    name: 'Jiesoxiangqing',
    component: () => import('@/views/Jiesoxiangqing/index.vue'),
    meta: {
      title: '天津物流集团-接受论点详情',
      keepAlive:true
    }
  }
  const data={
    token:'',
    strQuery:'',
    parentId:''
  }
  export {config,data}