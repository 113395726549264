const config = {
    path: '/1',
    name: '1',
    component: () => import('@/views/1/index.vue'),
    meta: {
      title: '天津物流集团',
      keepAlive:true
    }
  }
  
  export { config}