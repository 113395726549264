//Secondary
const config = {
    path: '/secondary',
    name: 'Secondary',
    component: () => import('@/views/Secondary/index.vue'),
    meta: {
      title: '天津物流集团-登录',
      keepAlive:true
    }
  }

  export {config}