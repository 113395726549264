<template>
    <div id="foot">
        <b-container fluid class="bv-example-row">
            <b-row>
                <b-col md="10" style="color: #fff; margin: 0 auto; ">
                    <div class="xiao-bta " style="width: 40%;float: right;">
                        <p class="xiao-ttat">
                            <span class="xiao-btaeet" v-text="$t('foot.xiazhaispin')"></span>
                            <span class="input-ban" :key="xialakiang" style="width: 70px; margin-left: 20px;">
                                <select class="input-baned" v-model="selectedOption" @change="getSelectedOption">
                                    <option value="SQ">LQ  44MB</option>
                                    <option value="MQ">MQ  116MB</option>
                                    <option value="HQ">HQ  364MB</option>
                                </select>
                            </span>
                            <span style="margin-left: 20px;display: inline-block; background-color: #409EFF; border-radius: 10px;text-align: center; padding: 0px 8px;" class="bditta" @click="xiazaishrpin">
                                <i class="el-icon-download"></i>{{$t('foot.tbat')  }}</span>
                        </p>
                        <!-- <p class="xiao-ttat">
                            <span class="xiao-btaeet" v-text="$t('foot.xiazhaispin')"></span>
                            <span class="input-ban" :key="xialakiang" style="width: 70px; margin-left: 20px;">
                                <select class="input-baned" v-model="selectedOption" @change="getSelectedOption">
                                    <option value="CN">CN</option>
                                    <option value="KO">KO</option>
                                    <option value="JA">JA</option>
                                    <option value="US">US</option>
                                </select>
                            </span>
                                 <span style="margin-left: 20px;display: inline-block;width: 30px;" @click="xiazaishrpin">
                                 <i class="el-icon-download"></i></span>
                                 </p> -->
                    </div>      
                    <p class="xiao-bta" style="width: 40%;" v-text="$t('message.data[7].label')"></p>
                    <p class="xiao-bta" style="width: 40%;"><i class="el-icon-location-outline"></i>
                        <span v-text="$t('message.data[8].label')"></span>
                    </p>
                    <p class="xiao-bta" style="width: 40%;"><i class="el-icon-phone-outline"></i>
                        <span v-text="$t('message.data[9].label')"></span>
                    </p>
                    <p class="xiao-bta" style="width: 40%;">
                        <i class="el-icon-message"></i>
                        <span v-text="$t('message.data[10].label')"></span>
                    </p>
                </b-col>
                <b-col md="10" style="margin: 0 auto;">
                    <div class="grid-content bg-purple-dark">
                        <footer class="tm-footer">
                            <div class="container-fluid-footer">
                                <div style="font-size: 18px;height:40px;line-height: 40px;">
                                    <a arget="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="display:inline-block;text-decoration:none;">
                                        <span style="height:20px;line-height:20px;margin: 0px 10px 0px 5px; color:#fff;" v-html="$t('foot.bananhap')"></span>
                                    </a>
                                    <img v-if="$i18n.locale === 'cn'" style="width: 20px;height: 20px;" :src="$t('foot.tupian')" alt="">

                                         <a arget="_blank"
                                         href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010302001429"
                                         style="display:inline-block;text-decoration:none;">
                                         <span style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;"
                                            v-html="$t('foot.textdiz')"></span>
                                         </a>
                                         </div>
                            </div>
                        </footer>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lang: '',
            selectedOption: 'MQ',  // 用于存储选中的值
            xialakiang: 0
        }
    },
    created() {
        this.$watch(
            function () {
                return sessionStorage.getItem('lang');
            },
            function (newValue, oldValue) {
                // 处理获取到的最新值
                console.log(newValue, 1111);
            },
            { immediate: true }
        );
    },
    methods: {
        getSelectedOption() {
            console.log(this.selectedOption); // 打印选中的值
        },
        xiazaishrpin() {
            if (this.selectedOption == 'SQ') {
                this.isClick = !this.isClick; // 点击时切换状态
                let downloadUrl = require('../assets/image/movieLQ.mp4') // 视频地址
                let fileName = 'video.mp4' // 文件名
                let link = document.createElement('a')
                link.href = downloadUrl
                link.download = fileName
                link.click()
            }
            if (this.selectedOption == 'MQ') {
                this.isClick = !this.isClick; // 点击时切换状态
                let downloadUrl = require('../assets/image/movieMQ.mp4') // 视频地址
                let fileName = 'video.mp4' // 文件名
                let link = document.createElement('a')
                link.href = downloadUrl
                link.download = fileName
                link.click()
            }
            if (this.selectedOption == 'HQ') {
                this.isClick = !this.isClick; // 点击时切换状态
                let downloadUrl = require('../assets/image/movieHQ_20240109_18520816_20240109_19003094.mp4') // 视频地址
                let fileName = 'video.mp4' // 文件名
                let link = document.createElement('a')
                link.href = downloadUrl
                link.download = fileName
                link.click()
            }
        },
        xiazaiwenjian() {
        },
    }
};
</script>
 <style> 
#foot {
    padding: 30px;
    width: 100%;
    background: url(../assets/image/network02.jpg);
    background-repeat: repeat;
    background-size: cover;
}
.xiao-btaeet {
    text-align: center;
}
.bditta{
    cursor: pointer;  
}
.bditta:hover{
    cursor: pointer;  
}
.xiao-ttat {
    text-align: center;
}

@media (max-width:1000px) {
    .xiao-bta {
        text-align: left;
        width: 100% !important;
        float: left !important;
    }

    .xiao-btaeet {
        text-align: left;
    }

    .xiao-ttat {
        text-align: left;
    }
}
</style>