const config = {
    path: '/argument',
    name: 'Argument',
    component: () => import('@/views/Argument/index.vue'),
    meta: {
      title: '天津物流集团-三级菜单',
      keepAlive:true
    }
  }
  const data={ 
                          
  }                        
  
  export {config,data} 