const config = {
    path: '/target',
    name: 'Target',
    component: () => import('@/views/Target/index.vue'),
    meta: {
        title: '天津物流集团-目标',
        keepAlive: true
    }
}
const data = {
    token: '',
    queryStr: '',
    refId: '',
    TransactionTypeCode: '',
    CategoryLargeCode: '',
    CategoryMiddleCode: '',
    SubdivisionCode: '',
    MeaasurableTypeCode: '',
    DifficultyCode: '',
    FrequencyTypeCode: '',
    RecordTypeCode: '',
    //判断
    checkFlag: '',
    successFlag: '',
    attachmentFlag: '',
    timeLineFlag: '',
}
export {
    config,
    data,
}