export default {
    message: {
        title:'DSL',
        keywords:'DSL,DSL USA,Import and Export of Shipping,Domestic and International Air Freight,Inland Freight,Warehouse Logistics,Project Logistics,Freight of Dangerous Goods,Air,Sea,Dangerous,Import,Export,Shipping,Domestic,International',
        description:'We provide a one-stop logistics service plan to customers with a global logistics network infrastructure.We continue to grow into a comprehensive logistics company based on a global network strengthened by continuous overseas investments.',
        data: [{
            label: 'HOME',
            id: 0,
            xia: [{
                label: 'DAESHIN logistics is'
            }, {
                label: 'SERVICE'
            }, {
                label: 'Advantage'
            }, {
                label: 'HISTORY'
            }, {
                label: 'QUOTATION INQUIRY'
            }, {
                label: '我们的承诺'
            }, {
                label: 'BUSINESS PHILOSOPHY'
            }, {
                label: 'SERVICE'
            }, {
                label: 'DSL GROUP RESOURCES'
            }, {
                label: 'HISTORY'
            }, {
                label: 'QUOTATION INQUIRY'
            }, {
                label: 'OUR PROMISES'
            }]
        }, {
            label: 'COMPANY',
            id: 1,
            xia: [{
                label: 'CEO MESSAGE'
            }, {
                label: '5S STRATEGIES'
            }, {
                label: 'SINCERITY&CREDIT'
            }, {
                label: 'CUSTOMER'
            }, {
                label: 'CERTIFICATES'
            }, {
                label: 'APPRECIATION PLAQUE'
            }]
        }, {
            label: 'SUSINESS',
            route: 'Outlets',
            id: 2,
            xia: [{
                label: '国际国内海运业务'
            }, {
                label: '国际国内空运业务'
            }, {
                label: '仓储管理'
            }, {
                label: '陆运业务'
            }, {
                label: '危险品业务'
            }, {
                label: '项目运输业务'
            }]
        }, {
            label: 'NETWORK',
            route: 'Network',
            id: 3,
            xia: [{
                label: 'CHINA'
            }, {
                label: 'THE UNITED STATES OF AMERICA'
            }, {
                label: 'JAPAN'
            }, {
                label: 'SOUTH KOREA'
            }]
        }, {
            label: 'Propaganda',
            route: 'Advocacy',
            id: 4,
        }, {
            label: 'Current location',
            id: 5,
        }, {
            label: 'LOGON',
            id: 6,
        }, {
            label: 'Address'
        }, {
            label: '12710 Research Blvd., Suite #380, Austin, Texas'
        }, {
            label: '+1 512 638 0799'
        }, {
            label: 'sales_usa@i-daeshin.com'
        }, {
            label: 'PAGE',
        }, {
            xia: [{
                label: 'CHINA'
            }, {
                label: 'THE UNITED STATES OF AMERICA'
            }, {
                label: 'SOUTH KOREA'
            }, {
                label: 'JAPAN'
            }, ]
        }],
        swiper: [{
            titlebta: 'TRANSPORTATION & LOGISTICS SOLUTIONS',
            url: require("@/assets/image/sl1.jpg"),
            textp: '<span style="color: red;">Air&Sea Transport</span> Solution',
            title: 'Choose the global logistics company, "DAESHIN Logistics," to get the best "One-stop" shipping, "Door to Door" services anytime, anywhere including e-commerce services.',
            text: ''
        }, {
            url: 'require("@/assets/image/sl2.jpg") ',
            textp: '<span style="color: red;">International Logistics</span> Solution',
            title: 'Promptly lead to global air transport via DAESHIN global networks.',
            text: 'We will realize customer satisfaction with reasonable price,reliability,and differentiated services for "high efficiency,speed,safety",<br>various air transportation,air delivery,express delivery,and baggage consignment services.'
        }, {
            url: require("@/assets/image/sl3.jpg"),
            textp: '<span style="color: red;">Warehouse</span> Solution',
            title: 'We operate a comprehensive logistics warehouses with a total area of more than 77.500 ㎡ throughout China',
            text: 'It has a dock,large space,and complete fire infrastructure that is suitable for up and down cars,and ensures product safety through 365 days of safety patrol.<br>All managemnt of the warehouse is system-driven and delivered to the 24-hour real-time service customer.'
        }, {
            url: require("@/assets/image/sl4.jpg"),
            textp: '<span style="color: red;">Transport</span> Solution',
            title: 'We offer the vehicles optimizd for your business and cargo characteristics.',
            text: 'We have normal trucks, ISO tanks, wing body cars, refrigerated/freezed cars and provide quality transportation services to customers throughout China and throughout the world.'
        }, {
            textp: '<span style="color: red;">Dangerous Goods Transport</span> Solution',
            title: 'We ensure safe delivery of dangerous goods transportation and accurate in-time delivery.<br>ZERO-RISK management by our dangerous goods specialist team ensures safe arrival of inland and import/export  transportation for dangerous goods.',
            text: ' The work gets started by our experienced workers in safe protective gears.<br>We have detailed transportation route management, safety prevention mechanisms, and professional know-how, so you trust us and entrust expensive cargo, too.'
        }, {
            title: 'READ MORE',
            txt: 'QUOTATION INQUIRY',
        }],
        swiper2: [{
            title: 'SERVICE',
            subheading: 'LOGISTICS SOLUTIONS',
            swiper: [{
                url: require("@/assets/image/banner-1.jpg"),
                image: require('@/assets/image/海运4web.jpg'),
                p: '海运出口',
                title: '国际海运进出口',
                subheading: '根据货物特点，',
                subheading1: '提供优秀的港口装卸与运输服务，',
                text: '依托全球各个港口的物流基础设施和符合客户货件特性的直营设备，为客户提供量身定制的港口装卸和海运服务。同时，将业务范围扩大到报关、报检、清关等事业领域，力争实现协同效应优质化',
            }, {
                url: require("@/assets/image/banner-2.jpg"),
                image: require('@/assets/image/空运2web.jpg'),
                p: '国际空运',
                title: '国际国内空运',
                subheading: '为顾客设计更优化、更快捷的运输方案，减少转机环节',
                text: '通过货件的货盘化运输，实现迅速的通关及运输服务。除了进出口货件的空运、通关等服务外，还提供与全球电子商务相匹配的专业化国际快递服务',
            }, {
                url: require("@/assets/image/banner-3.jpg"),
                image: require('@/assets/image/仓库3web.jpg'),
                p: '仓储物流',
                title: '仓储物流',
                subheading: '大面积的综合仓库，',
                subheading1: '提供优化的运营支援和物流服务，',
                text: '总面积达77,500㎡的物流仓库，遍布国内主要城市。根据货物类别特征，提供定制型的储存方案。卓越的专业人力，提供高效、安全稳定的服务支持',
            }, {
                url: require("@/assets/image/banner-4.jpg"),
                image: require('@/assets/image/陆运2web.jpg'),
                p: '内陆运输',
                title: '国内道路运输',
                subheading: '拥有380辆运输车辆,',
                subheading1: '韩国的物流企业',
                text: '拥有箱式车、槽罐车、展翼车、冷藏车、冷冻车等运输车辆，可以迅速运输各个产业群所需的多种货物。遍布全国的物流网络，对物流配送提供365天全方位支持',

            }, {
                url: require("@/assets/image/banner-5.jpg"),
                // image: require('@/assets/image/危险2web.jpg'),
                p: '危险品运输',
                title: '危险品运输',
                subheading: '超过1年危险品运输经验,',
                subheading1: '一家韩国的物流企业，',
                text: '危险品货物可以按照客户要求添加各种药剂，全程视频监控，提供货物的实时位置，运输状态随时反馈给客户。从原材料供应物流到逆向物流，保证货物安全到达目的地',
            }],
        }],
        swiper3: [{
            name: ' Tae Kyung Lee',
            gongs: 'DSL KOR - Agent ',
            title: 'Well begun is half done.',
            text: 'A good start is to get halfway done and get started. The road to success is long, but if your first resolation is strong, you will have inevitable passion.',
        }, {
            name: 'YOUNGHOON LEE',
            gongs: 'DSL USA - Corporate Leader',
            title: 'It’s never over until the end.',
            text: 'There\'s no end in life, there is only beginning in every stage.',
        }, {
            name: 'Li Zhenxu',
            gongs: 'DSL CHN - Tianjin International Department',
            title: '商卽人 人卽商',
            text: 'Business is more about making people than making profits.',
        }, {
            name: '藤井知己',
            gongs: 'DSL JPN - Suzhou International Department ',
            title: 'Dig deep riverbeds and build bank high.',
            text: 'Constantly explore inner potential, reduce operating costs, improve work efficiency, control greed, and achieve win-win cooperation',
        }, {
            name: 'CaiZaiXun',
            gongs: 'DSL CHN - Shanghai Executive ',
            title: 'Effort never betrays.',
            text: 'Effort will not betray you. Only through continuous effort we can achieve our goals. Work and life require constant effort.',
        }, {
            name: 'Han Ying',
            gongs: 'DSL CHN - Hong Kong  International Department ',
            title: 'Walk every day, don\'t be afraid of thousands of miles, do it steadily, don\'t be afraid of thousands of things.',
            text: 'Everything you do requires passion and decisions made with all your heart. If you persist with passion, you will definitely succeed.',
        }, {
            name: 'Cha Yeongju',
            gongs: 'DaeShin Logistics Group CEO',
            title: 'If you bravely rush toward your will, you will overcome difficulties and become stronger.',
            text: 'If a person has a will, it can overcome difficulties without stopping moving forward no matter the difficulties or obstacles.',
        }, {
            name: 'Shao Xiaoqun',
            gongs: 'DSL CHN - Suzhou 3PL Department',
            title: 'Progress a little each day, and success is not far away',
            text: 'Each step of progress you make, it’s all the accumulation of success. Each hurdle you cross, it’s all the goal of reaching the finish line.',
        }, {
            name: ' Yusil Kang',
            gongs: 'DSL USA -  International Department',
            title: 'Life is Joy.',
            text: 'Life is wonderful, be optimistic and there will be surprises. Life is joy, be attentive and there will be laughter. Days are beautiful, be content and there will be happiness!',
        }, {
            name: 'Li Wenjing',
            gongs: 'DSL CHN - XiAn  International Department ',
            title: 'Where there is a will, there is a way.',
            text: 'It’s easy to give up but it\'s definitely cool to persist. Even when there are no stars in the night sky, they always shine in the same spo',
        }, {
            name: ' Mark Meredith',
            gongs: 'DSL USA  - Security Management',
            title: 'Safety first, Safety is the beginning and end of work.',
            text: 'It’s better to be extra cautious than to regret accidents. Follow the law and reduce risks.',
        }, {
            name: 'Huang Lijun',
            gongs: 'Guangzhou  International Department',
            title: 'Gentleman\'s dignity and dedicated service.',
            text: 'A gentleman\'s dignity should be like a thick enough floor to hold everything, I\'ll serve the customers with my all heart.',
        }],
        baojia: [{
            title: 'CONTACT',
            title2: 'QUOTATION INQUIRY',
            subheading: 'Telephone counseling and inquiries',
            subheading2: '+1 512 638 0799',
            text: 'Do you need any help with sea freight, air freight, warehouse management, inland freight, and dangerous goods? <br>Please do not hesitate to contact me.<br> I ll give you a quick answer.',
            input: 'Corporate Name',
            input1: 'Your Name',
            input2: 'Your Email',
            input3: 'Your Mobile',
            input4: 'Select Service Type',
            input5: 'Air Freight',
            input6: 'Sea Freight',
            input7: 'Warehouse',
            input8: 'Inland Freight',
            input9: 'Hazmat Freight',
            input10: 'Special Note',
            button: 'Submit',
            sj: 'Your email address is incorrect',
            yjan: 'Your cell phone number is incorrect',

        }],
        slide: [{
            title: 'HISTORY',
            title2: 'DAESHIN LOGISTICS GROUP',
            icondiqu1: 'CHINA,',
            icondiqu2: 'USA',
            icondiqu3: 'Korea',
            icondiqu4: 'JAPAN',
            zhongbu: 'Head office',
            fengongs: ' Branch office',
            fengongs1: 'CORP',
            huwulexing1: 'Hazmat/Inland',
            huwulexing2: 'International',
            huwulexing3: 'Hazmat',
            huwulexing4: 'International / Inland / Hazmat',
            huwulexing5: 'secondary battery copper foil&copper ',
            diqu1: 'TianJin',
            diqu2: 'XiAn',
            diqu3: 'ChangZhou',
            diqu4: 'ShanXi',
            diqu5: 'WeiHai',
            diqu6: 'ChongQing',
            diqu7: 'Hong Kong',
            diqu8: 'Guangzhou',
            diqu9: 'Suzhou',
            diqu10: 'Shanghai',
            diqu11: 'Seoul',
            diqu12: 'Austin',
            diqu13: 'Savannah Office',
            diqu14: 'Tokyo',
            swiper: [{
                    text: 'DAESHIN Logistics Group',
                    text2: 'TianJin Head office Set up',
                },
                {
                    text: 'TianJin DAESHIN International Shipping Agency',
                    text2: 'TianJin Head office Set up',
                },
                {
                    text: 'TianJin I-DAESHIN Logistics',
                    text2: 'TianJin Head office Set up',
                },
                {
                    text: 'TianJin T-DAESHIN Logistics',
                    text2: 'TianJin Head office Set up',
                },
                {
                    text: 'TianJin DAESHIN International Shipping Agency',
                    text2: 'XiAn Branch office Set up',
                },
                {
                    text: 'TianJin T-DAESHIN Logistics',
                    text2: 'ChangZhou Branch office Set up',
                },
                {
                    text: 'ShanXi T-DAESHIN Logistics',
                    text2: 'ShanXi Head office Set up',
                },
                {
                    text: 'TianJin T-DAESHIN Logistics',
                    text2: 'WeiHai Branch office Set up',
                },
                {
                    text: 'TianJin T-DAESHIN Logistics',
                    text2: 'ChongQing Branch office Set up',
                },
                {
                    text: 'Hong Kong DAESHIN Logistics',
                    text2: 'Hong Kong Head office Set up',
                },
                {
                    text: 'TianJin T-DAESHIN Logistics',
                    text2: 'Guangzhou Branch office Set up',
                },
                {
                    text: 'TianJin T-DAESHIN Logistics',
                    text2: 'Suzhou Branch office Set up',
                },
                {
                    text: 'TianJin T-DAESHIN Logistics',
                    text2: 'Shanghai Branch office Set up',
                },
                {
                    text: 'DSL Korea',
                    text2: 'Seoul Head office Set up',
                },
                {
                    text: 'DSL USA',
                    text2: 'Austin (TX) Head office Set up',
                },
                {
                    text: 'DSL USA',
                    text2: 'Savannah office Set Up',
                },
                {
                    text: 'DSL JAPAN',
                    text2: 'Tokyo Head office Set up',
                }, {
                    text: 'P&D Xi\'An',
                    text2: 'PNT&DaeShin Logistics Joint Venture',
                }
            ]
        }],
        about: [{
            tetlet: 'ABOUT US',
            tetlyt: 'YOUR LOGISTICS PARTNER',
            title: 'ABOUT US',
            subheading: 'MANAGEMENT IDEOLOGY',
            text: '<span style="color: red;">Customer First</span> Management : We provide <span style="color: red;">safe</span>,<span style="color: red;">  efficient, fast</span> and <span style="color: red;">accurate services</span>   to our customers.',
            image: [{
                url: '@/assets/image/关于我们小图3.jpg'
            }, {
                url: '@/assets/image/关于我们小图4.jpg'
            }, {
                url: '@/assets/image/关于我们小图5.jpg'
            }, {
                url: '@/assets/image/关于我们小图6.jpg'
            }],
            description: [{
                text: 'Daishin Logistics was established in 2007 in Tianjin, one of the Chinas four major municipalities.'
            }, {
                text: 'The 17 years of continuous growth are based on the operation of an international transportation management system, the constant efforts and innovation of all executives and employees, and the management philosophy of putting customers first.'
            }, {
                text: 'We established three corporations in Tianjin, China, and have branch offices in mid-to-large cities such as Xian, Changzhou, Weihai, Chongqing, Beijing, Shanghai, Gwangju, Xian, and Suzhou. We are continuing to grow globally by establishing subsidiaries in Hong Kong, Korea, the United States, and Japan.'
            }, {
                text: 'We own more than 380 vehicles in China and operate a comprehensive logistics warehouses of 77,500 m2. We provide one-stop services to our customers, including international shipping import and export, international air export and import, warehouse logistics, cold chain logistics, dangerous goods transportation, delivery, and project transportation.'
            }, {
                text: 'Not only have we accumulated know-how based on over 17 years of logistics experience, but also we have many professional personnel and maintain long-term and friendly cooperative relationships with domestic and foreign customers. Daeshin Logistics provides stable and competitive services to large corporations in Korea and Japan and has developed into a specialized logistics company related to land, sea, and air imports and exports and transportation of dangerous goods. Through continuous efforts, Daishin Logistics utilizes its unique strengths and infrastructure to provide customer satisfaction. We will increase freight competitiveness and provide quality services.'
            }]
        }],
        advantage: [{
            title: 'OUR STRENGTH',
            subheading: 'DAESHIN LOGISTICS GROUP RESOURCES',
            text: 'We provide a one-stop logistics service plan to customers with a global logistics network infrastructure.',
            text2: 'We continue to grow into a comprehensive logistics company based on a global network strengthened by continuous overseas investments.',
            description: [{
                url: '@/assets/image/homeyewu1.webp',
                text: 'Country'
            }, {
                url: '@/assets/image/homeyewu2.webp',
                text: 'Site'
            }, {
                url: '@/assets/image/homeyewu3.webp',
                text: 'Staff'
            }, {
                url: '@/assets/image/homeyewu4.webp',
                text: 'Warehouse'
            }, {
                url: '@/assets/image/homeyewu5.webp',
                text: 'Hazmat Truck'
            }, {
                url: '@/assets/image/homeyewu6.webp',
                text: 'Normal Truck'
            }, {
                url: '@/assets/image/homeyewu7.webp',
                text: 'Cold Chain Truck'
            }, {
                url: '@/assets/image/homeyewu8.webp',
                text: 'Packing Equipment'
            }]
        }],
        image: [{
            title: '大信物流的目标舞台是整个世界。',
            subheading: '细节决定成败，我们将为客户提供细致的全球服务。',
            url: '@/assets/image/home-background2.webp'
        }],
        spin: [{
            text: 'Details determine the success and the attitude presents everything.',
        }, {
            text: 'CUSTOMER',
        }, {
            text: 'QUALIFICATION',
        }],
        chengn: [{
            text: 'PROMISE',
        }, {
            text: 'OUR PROMISES',
        }]
    },
    information: {
        data: [{
            'val': 'Summary',
        }, {
            'val': 'CEO MESSAGE',
        }, {
            'val': 'Manage',
        }, {
            'val': 'Vision',
        }, {
            'val': 'SINCERITY&CREDIT',
        }, {
            'val': 'CERTIFIED ENTERPRISE CERTIFICATE',
        }, {
            'val': 'APPRECIATION PLAQUE',
        }, {
            'val': 'CUSTOMER',
        }, {
            'val': 'Company Vision and Corporate Culture',
        }],
        image: [{
            text1: 'TianJin DaeShin International',
            text2: 'China Customs AEO Advanced',
        }, {
            text1: 'TianJin DaeShin',
            text2: 'QMS-ISO9001',
        }, {
            text1: 'TianJin DaeShin',
            text2: 'QMS-ISO 14001',
        }, {
            text1: 'TianJin DaeShin International',
            text2: 'NVOCC',
        }, {
            text1: 'TianJin DaeShin',
            text2: 'Reputation Rating AAA',
        }, {
            text1: 'ShanXi DaeShin',
            text2: 'Reputation Rating AAA',
        }, {
            text1: 'SAMSUNG SDS',
            text2: 'Operational Support Appreciation',
        }, {
            text1: 'MOTTROL Hydraulic Machinery',
            text2: 'Logistics Quality Service Provider',
        }, {
            text1: 'ZhuHua Electronic(ChangZhou) Co.Ltd',
            text2: 'Logistics Quality Service Provider',
        }, {
            text1: 'WONIK (Xi\'An) Semiconductor',
            text2: 'Support contribution thanks',
        }, {
            text1: 'ShanXi DaeShin',
            text2: '2018 Advanced Dangerous Goods Transport',
        }, {
            text1: 'ShanXi DaeShin',
            text2: '2017 Advanced Dangerous Goods Transport',
        }, {
            text1: 'ILJIN GROUP',
            text2: 'Support contribution thanks',
        }, {
            text1: 'ZhuHua Electronic(Xi\'An) Co.Ltd',
            text2: 'Logistics Quality Service Provider',
        }, ],
        bejing: [{
            title: 'DAESHIN LOGISTICS IS...',
            text: 'Company Vision and Corporate Culture',
        }],
        idea: [{
            url: '@/assets/image/linian1.jpg',
            title: 'Attitude decides everything, details determine success or failure.',
            title3: 'DASHIN Logistics Group / CEO - CHA YOUNG JU',
            text: 'DAESHIN logistics puts "social responsibility" first, and all members strive to fulfill it in accordance with the ideology of "social contribution. Daishin Logistics communicates with customers as an essential strategy to coexist with customers, aiming to become a top-class logistics company, and gains customer trust based on service quality, low logistics costs, global network, and advanced logistics systems. We will continue to grow as a global logistics company with the goal of providing “first-class customer satisfaction services” based on our global network and professional staffs.'
        }],
        manage: [{
            title: 'CSV Management',
            subheading: 'The goal of CSV (Creating Shared Value) management is to realize creative management, win-win management, shared management based on compliance, credit management, safe management, and to fulfill the obligations of our partner companies to return to society and create social values. Instead, the logistics group contributes to social development through various efforts by actively utilizing our work capabilities based on CSV management ideology.',
            li: [{
                text: 'Creative Management',
                textx: 'We promise to contribute to the country and society by realizing the worlds advanced industrial model and promoting corporate development.'
            }, {
                text: 'Win-Win management',
                textx: 'We guarantee the continuous development of customers by creating a win-win industrial ecosystem and realizing active win-win.'
            }, {
                text: 'Shared management',
                textx: 'Based on the core work competencies, we will carry out systematic social service activities with interest in the socially disadvantaged.'
            }],
            url: '@/assets/image/xiangqing2.jpg',
            bootom: [{
                url: '@/assets/image/xiangqing3.jpg',
                text: 'Lawful Management',
                tceit: '법률을 준수하다'
            }, {
                url: '@/assets/image/xiangqing4.jpg',
                text: 'Credit Management',
                tceit: '약속을 중시하다'
            }, {
                url: '@/assets/image/xiangqing5.jpg',
                text: 'Safe Management',
                tceit: '안전을 보증하다'
            }]
        }],
        vision: [{
            box: [{
                title: 'Strategy Direction',
                strong: 'Discover new business opportunities',
                strongzong: 'Ensuring cost competitiveness',
                strongxia: 'Global Network & Partnerships',
                strongxiaz: 'Development of professional logistics talent',
                textp1: 'Continuous attraction of new customers',
                textp2: 'Active new businesses and overseas logistics businesses',
                textp3: 'Goal of strengthening customer logistics competitiveness',
                textp4: 'Provide quality logistics services with optimized logistics systems.',
                textp5: 'Establishing a network of partnerships with leading companies',
                textp6: 'Building win-win partnerships with customers',
                textp8: 'Systematic development of key personnel (logistics consultants, processes)',
            }, {
                title: 'Core competencies',
                strong: 'Realize a Global Network',
                strongzong: 'Ability to build and operate IT systems',
                strongxia: 'CCounseling Ability',
                textp1: 'Custom Services Build a wide range of locations where you can quickly and cost-effectively',
                textp2: 'transport your cargo to any location.',
                textp3: 'Real-time logistics information provision system and network construction',
                textp4: 'Various logistics information sharing systems',
                textp5: 'Provide a comprehensive, systematic and',
                textp6: 'competitive approach to the customer. Propose and consult with professionals for a feasible transport plan.',
            }, {
                title: 'Value sharing',
                strong: 'Trust, partner spiritt',
                strongzong: 'Active mind, Family Spirit',
                strongxia: 'Passion, Pioneer Spirit',
                textp1: 'Trust between customers and businesses, trust between companies and employees',
                textp2: ' enable a win-win relationship between employees and employees.Active mind, Family Sp',
                textp3: 'Crossing ones own or departmental interests and standing from the perspective of the entire company',
                textp4: 'Thinking and handling business with an open mindset and a proactive mindset',
                textp5: 'Create corporate value with more differentiated details based on pride of advanced logistics companies',
                textp6: 'Create corporate value through clear vision, aspiration, and confidence.',
            }],
            url: '@/assets/image/yuanjing1.jpg'
        }],
        zhanluei: [{
            tltle: '5S Strategies',
            s1: {
                text: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Operating 5 coporations according to business characteristics in China </p>',
                text1: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Becoming a global company by securing bases in Korea, Hong Kong, and the United States</p>',
                text2: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">ISO 9001, 14001 and AEO certification</p>',
                text3: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Operating Safety Management System</p>',
            },
            s2: {
                text: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">The first Korean compnay that obtained a HAZMAT License in China</p>',
                text1: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Asset based logistics company with over 380, our own vehicles</p>',
                text2: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Capable of operating approximately 8,000 vehicles through network of partner logistics companies</p>',
                text3: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p>Warehouse',
                text4: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p>Normal Truck',
                text5: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p>Hazmat Truck',
                text6: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p>Cold Chain Truck',
            },
            s3: {
                text: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Vehicle tracking system (GPS, CCTV)</p>',
                text1: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Monthly safety training (system ) of the transportation bureau</p>',
                text2: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Optained the highest rating (AAA) in the transportation departments safety evaluation</p>',
                text3: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Daily health check for the drivers (temperature, blood pressure, alcohol)</p>',
                text4: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Conduct drivers occupational disease and MMPI (Minnesota Multiphasic Personality Inventory) tests</p>',
                text5: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Conduct vehicle safety inspections twice a month</p>',
                text6: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Conduct fire safety emergency drills twice a year</p>',
                text7: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Samsung selected DSL as the representative logistics company among all and conducted fire safety emergency drills at Samsung.</p>',
            },
            s4: {
                text: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Our own vehicles throughout the country which can be arranged in a timely manner</p>',
                text1: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Purchase new vehicles meeting the customers’ requirements to customize and deploy them promptly.</p>',
                text2: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">24-hour GPS management , quickly and accurately track the location of each vehicle and share the data with customers.</p>',
                text3: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Rapid customs clearance and delivery</p>',
                text4: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Expedite customs clearance and delivery</p>',
            },
            s5: {
                text: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p> <p style="float: left;width: 95%;margin-bottom: 0;">When everyone says NO, DSL says YES.</p>',
                text1: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Make the impossible possible</p>',
                text2: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">The working mind for employees “Details determine success or failure”</p>',
            },
        }],
        opnert: [{
            opttbe: 'Corporate mission',
            title: 'The two letters " sincerity 诚" combined with "credit 信" are the fundamentals of companys development, meaning that we keep trust with sincerity.',
            text1: 'Commitment to protecting employees, shareholders, customers, society and the environment.',
            text2: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Vision is the direction of a companys development and its future.</p>',
            text3: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">A company must continuously increase its value so that shareholders can feel proud of the company, which leads to continuous investment and the support necessary for corporate development.</p>',
            text4: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">A company must provide faith, trust and service to itscustomers in order to create more opportunities, attract new customers, and have the opportunity to increase profits.</p>',
            text5: '<div style="display: none;">Companies can win more opportunities, attract new customers, and simultaneously, companies have the opportunity to increase profits only when they provide trust and services to customers.</div>',
            text6: '<p style="margin-right: 5px;display: inline-block;height: 100%;float: left;margin-bottom: 0;">✓</p><p style="float: left;width: 95%;margin-bottom: 0;">Companies should have an interest in society and environmental protection so that society can give more feedback to companies. Companies can create a good environment for development.</p>',
            text7: 'Only in this way can have the company realize development and growth for the future, and only by faithfully managing for the future can makes long-term development.',
            url: '@/assets/image/chengxi1.jpg'
        }],
        image1: require('@/assets/image/yuanjingen1.jpg'),
        image2: require('@/assets/image/chengxien1.jpg'),
        image3: require('@/assets/image/kehuoen.png'),
        image4: require('@/assets/image/xiangqingen2.png'),
        image5: require('@/assets/image/货运en.jpg'),
        image6: require('@/assets/image/中国en.jpg'),
        image7: require('@/assets/image/美国en.jpg'),
        image8: require('@/assets/image/韩国en.jpg'),
        image9: require('@/assets/image/日本en.jpg'),
        image10: require('@/assets/image/sl5en.jpg'),
        image11: require('@/assets/image/top1ko.jpg'),
        image12: require('@/assets/image/top3.jpg'),
        image13: require('@/assets/image/top2.jpg'),
    },
    outlets: {
        datalist: [{
            'wedic': 'Import and Export of Shipping',
        }, {
            'wedic': 'Domestic and International Air Freight',
        }, {
            'wedic': 'Inland Freight',
        }, {
            'wedic': 'Warehouse Logistics',
        }, {
            'wedic': 'Project Logistics',
        }, {
            'wedic': 'Freight of Dangerous Goods',
        }, {
            'wedic': 'READ MORE',
        }],
        mari: [{
            card: [{
                text1: 'Sea transport',
                text2: 'A high-quality professional team and comprehensive global network services, with a worldwide agency network',
                text3: 'Provide comprehensive customs clearance and door-to-door delivery services, from logistics solution design to cargo tracking, to meet the shipping requirements of various import and export goods',
                text4: 'Undertake charter business for miscellaneous and bulk cargo, and provide international shipping agency services for both liner and non liner services',
                text5: 'Undertake diversified businesses such as project goods, engineering goods, and special goods, and provide customers with professional supply chain management services and other comprehensive high-quality services',
                text6: 'Establishing long-term partnerships with multiple shipping companies to provide very favorable sea freight prices',
                url: '@/assets/image/hiashangyshu1.jpg'
            }, {
                title1: 'Port ports are selected according to cargo characteristics, and unloading management and transportation services are provided.',
                title2: 'We will design the best transportation route for you and provide you with the shortest distance and shortest time service.',
                title3: 'We are the only Korean logistics company in China that operates more than 380 vehicles',
                title4: 'We provide high-quality warehouse services with comprehensive warehouse operation.',
                title5: 'We offer a variety of complex transport services, including "Door to Door" and "Door to Port & Place".',
                title6: 'We are a Korean logistics company specializing in the transportation of dangerous goods with over 17 years of experience in direct transportation of dangerous goods in China.',
            }],
            text: [{
                title: 'SERVICE',
                text: [{
                    url: '@/assets/image/hiashangyshu2.jpg',
                    titlex: 'Container Service',
                    textp: 'Faster speed, lower price, and systematic management during sea transportation processes such as packaging, transportation, unloading, and storage.',
                }, {
                    url: '@/assets/image/hiashangyshu3.jpg',
                    titlex: 'Bulk Service',
                    textp: 'Win more ocean freight volume with discounted freight rates, high-quality service, and fewer loading and unloading times, thereby gaining more value. Entrust reliable services and centralized shipping services to meet customers transportation requirements.',

                }, {
                    url: '@/assets/image/hiashangyshu4.jpg',
                    titlex: 'Service Advantage',
                    textp: 'Daxin Logistics has a close cooperation relationship with world-class shipping companies, utilizing the professional services of various shipping companies to provide safer and faster customized transportation services for customers.',
                    textp2: 'Signing strategic preferential service contracts with major shipping companies also holds a strong advantage in saving logistics costs.',
                    textp3: 'Ensure diversified shipping company channels at home and abroad, as well as the majority of freight company outlets, to achieve freight competitiveness based on large cargo volumes and provide substantial value to customers.'
                }]
            }]
        }],
        avintion: [{
            card: [{
                text1: 'Air transport',
                text2: 'Specializing in domestic air cargo transportation and air express delivery, express delivery, air luggage check-in, port to port, and door-to-door services',
                text3: 'Combining the advantages of major airlines, our service network covers major, small, and medium-sized cities across the country',
                text4: 'The air cargo transportation business is complete, and we undertake the import and export business of air cargo from major airlines',
                text5: 'Collaborating with multiple air freight companies to establish an air transportation network, providing customers with high-quality and satisfactory comprehensive air transportation services',
                text6: 'With reasonable prices, honest services, and good reputation, we can meet the various needs of customers at different levels',
                url: '@/assets/image/air-1.jpg'
            }],
            text: [{
                title: 'SERVICE',
                text: [{
                    url: '@/assets/image/air-2.jpg',
                    titlex: 'One-Stop Transporting Logistics Service',
                    textp: 'Establish strategic partnerships with excellent airlines to ensure reasonable freight rates that satisfy customers and stable cargo transportation speeds throughout the year. Own transportation vehicles to ensure the convenience of air transportation services.',
                }, {
                    url: '@/assets/image/air-3.jpg',
                    titlex: 'Continued Transport Service',
                    textp: 'Continuation is the service of reloading unloaded goods onto an aircraft during air transportation and transporting them to their destination or desired location. In the continuation of transportation services, a wide range of transfer points and rich transfer experience determine the quality of service.',

                }, {
                    url: '@/assets/image/air-4.jpg',
                    titlex: 'Joint Services Of Sea And Air',
                    textp: 'Sea air intermodal transportation service is a service that combines the advantages of low-cost sea transportation and rapid air transportation. After sea transportation from South Korea to coastal ports in China, air transportation is used to inland areas; Or from South Korea to northern China',
                    textp2: 'After the port is transported by air, sea freight will be used to reach the southern port. Compared to using only sea transportation, it shortens transportation time and saves freight costs compared to using only air transportation. ',
                }, {
                    titlex: 'Service Advantage',
                    textp: 'Daxin Logistics provides high-quality customized logistics services such as air exports, air imports, international express delivery, sea and air intermodal transportation, customs clearance, and customs declaration.',
                    textp2: 'As a comprehensive logistics enterprise, we have rich information, knowledge, and experience to provide customers with real-time air cargo information and confirm cargo transportation information before the plane departs.',
                    textp3: 'Taking into account all aviation conditions, air freight, transportation time and distance, airline shipping routes, and the time spent on transshipment, we provide customers with suitable continuation services.',
                    textp4: 'We have overseas agents around the world, and even in rapidly changing transportation environments, we can quickly collect and respond to information on departure, transit, and arrival points, providing safe and reliable sea air intermodal services.'
                }]
            }]
        }],
        landlocked: [{
            card: [{
                text1: 'Inland transport',
                text2: 'Daxin Logistics provides various road transportation services with its extensive network in China.',
                text3: 'With years of accumulated experience, we have comprehensive services and competitive prices to provide higher level services.',
                url: '@/assets/image/land-1.jpg'
            }],
            text: [{
                title: 'SERVICE',
                text: [{
                    url: '@/assets/image/land-2.jpg',
                    img: '@/assets/image/land-3.jpg',
                    titlex: 'Inland highway transportation',
                    textp: 'Owning over 300 transportation vehicles on its own, including box trucks, tank trucks, wing trucks, refrigerated trucks, and refrigerated trucks.',
                    textp2: 'The Korean logistics company among the transportation enterprises in Chinese Mainland has accumulated rich experience in third-party logistics services, trained a large number of professional talents, and has an efficient management team.'
                }, {
                    url: '@/assets/image/land-4.jpg',
                    titlex: 'Service Advantage',
                    textp: 'Real time supervision of vehicles across Chinas network',
                }]
            }]
        }],
        warehousing: [{
            card: [{
                text1: 'Warehouse logistics',
                text2: 'The total area exceeds 77500 square meters, with a cargo loading and unloading platform of 10000 square meters.',
                text3: 'The warehouse is equipped with wide lanes, convenient unloading platforms, and offices adjacent to the warehouse.',
                text4: '24-hour monitoring and patrols, as well as advanced firefighting facilities, ensure the safe storage of goods.',
                text5: 'Provide comprehensive logistics operation services such as goods storage, sorting, repackaging, measuring, weighing, barcode scanning, labeling, palletizing, film wrapping, etc.',
                url: '@/assets/image/warehouse-3 (2).jpg'
            }],
            text: [{
                title: 'SERVICE',
                text: [{
                    url: '@/assets/image/warehouse-3 (3).jpg',
                    titlex: 'Warehouse distribution',
                    textp: 'Logistics warehouses are located throughout Beijing Shunyi, Beijing Tongzhou, Tianjin Prolos Logistics Park, Chongqing Liangjiang New Area Industrial Development Zone, Cangzhou Economic Development Zone, Weihai Lingang Economic and Technological Development Zone, Suzhou and other areas.',
                }, {
                    titlex: 'Service Advantage',
                    url: '@/assets/image/warehouse-3 (1).jpg',
                    img: '@/assets/image/warehouse-4.jpg',
                    textp: 'Daxin Logistics achieves higher customer satisfaction through persistent challenges, based on cutting-edge facilities, well-equipped equipment, and cutting-edge IT systems, to provide more optimized solutions for customer cargo storage.',
                    textp2: 'The high-quality personnel, stable team, and comprehensive personnel protection measures and equipment reflect the concept of people-oriented and long-term development of the enterprise.',
                }]
            }]
        }],
        project: [{
            card: [{
                text1: 'PROJECT LOGISTICS',
                text2: 'Diversified international trade requires more comprehensive and professional logistics services.',
                text3: 'Daxin Logistics, with its strong strength and strong agency network, provides a variety of professional international multimodal transportation services.',
                text4: 'Design better intermodal routes for customers, saving transportation costs and time',
                text5: 'Provide customers with various intermodal services such as "door to door", "door to port", "yard to station to door", etc; Issue a bill of lading or multimodal transport bill of lading.',
                text6: 'As long as the customer entrusts once, the goods can be shipped directly to the entire journey.',
                url: '@/assets/image/Project-1.jpg'
            }],
            text: [{
                title: 'SERVICE',
                text: [{
                    url: '@/assets/image/Project-2.jpg',
                    textp: 'Project Cargo Service',
                }, {
                    titlex: 'Service Advantage',
                    url: '@/assets/image/Project-3.jpg',
                    textp: 'Select various machinery, equipment, materials, etc. that need to be used in the transportation of the project, and the transportation technology to be adopted',
                    textp2: 'Choose better ship compartments and aircraft models based on the type of cargo to ensure timely/safe transportation',
                    textp3: 'Prior environmental investigation, exploration routes, obstacle investigation, transportation simulation, arrangement of special vehicles, and other situations involving inland transportation',
                    textp4: 'Propose a safe transportation plan by conducting a prior investigation of customs clearance, laws, and practices in the destination country',
                    textp5: 'Implement local tax exemption and reduction customs clearance business and provide comprehensive consulting services for trade/logistics services such as L/C, C/O, embassy certification, etc',
                    textp6: 'Through overseas agents, we provide systematic and comprehensive logistics services from transportation planning to subsequent management from the time of shipment from various countries until the arrival of the goods at the job site',
                }]
            }]
        }],
        international: [{
            card: [{
                text1: 'Transport of dangerous goods',
                text2: 'Any substance that has corrosive, natural, flammable, toxic, explosive properties, etc,',
                text3: 'Items that are prone to causing personal injury or property damage during transportation, loading, unloading, and storage, and require special protection, are all classified as dangerous goods.',
                text4: 'Dangerous goods transportation is a type of special transportation, where specialized organizations or technical personnel use special vehicles to transport unconventional items.',
                text5: 'After strict review by relevant national functional departments, it is necessary to have corresponding facilities and equipment that can ensure the safe transportation of dangerous goods in order to be qualified for the transportation of dangerous goods.',
                url: '@/assets/image/weixianxiao.jpg"'
            }],
            text: [{
                title: 'SERVICE',
                text: [{
                    url: '@/assets/image/weixianpintext1.jpg',
                    titlex: 'Road transportation of dangerous goods',
                    textp: 'A professional dangerous goods transportation team conducts inland transportation and import and export dangerous goods business, providing timely and accurate information feedback, tracking transportation, monitoring the entire process, and accurately providing transportation information to every customer at any time, ensuring zero risk for customers and safe arrival of goods at their destination.                    ',
                }, {
                    url: '@/assets/image/weixianpintext2.jpg',
                    img: '@/assets/image/weixianpintext3.jpg',
                    titlex: 'Service Advantage',
                    textp: 'Equipped with complete protective equipment and skilled operators, various chemicals can be added according to customer needs.',
                    textp2: 'Fine line management, reliable process design, networked emergency mechanisms, diverse communication methods, and centralized tracking by customer service call centers ensure the safe, timely, and accurate delivery of goods.'
                }]
            }]
        }],
        imaget: require('@/assets/image/warehouse-3 (3)en.jpg'),
        imaget1: require('@/assets/image/warehouse-3 (1)en.jpg'),
    },
    advocacy: {
        title: [{
            'title1': 'News Announcement',
            'title2': 'Industry knowledge',
        }],
        box: [{
            title: '2022年物流行业发展趋势与工业物流崛起',
            url: '@/assets/image/宣传中心1.webp',
            text: '在过去两年中、物流行业呈加速发展态势。2022年、这一趋势将随新技术的应用而进一步发展、并将在操作流程、运输、仓储、安全性',
            time: '2022-10-10'
        }, {
            title: '2022年世界国际海运公司',
            url: '@/assets/image/宣传中心2.webp',
            text: '据Alphainer最新运力数据显示,截至2022年7月10日,全球班轮公司运力前10名与上月比保持不变,目前全球在运营集装箱船总数为6406艘,总运力增加到2579.3291万TEU',
            time: '2022-09-13'
        }, {
            title: '物流机械设备的发展趋势',
            url: '@/assets/image/宣传中心3.webp',
            text: '随着生产和物流规模的扩大,以及物流自动化程度的提高,物流机械设备在现代化生产和物流中应用越来越广,作用也越来越大;而现代化生产和现代化物流又...',
            time: '2019-03-12'
        }, {
            title: '物流快递行业年度期盼的新机遇',
            url: '@/assets/image/宣传中心4.webp',
            text: '26日,京广高铁正式开通运营。羊城晚报记者获悉,武广高铁运营后已有广东快递企业试水广州长沙间的...',
            time: '2019-03-12'
        }, {
            title: '进军国际市场开拓物流发展新机遇',
            url: '@/assets/image/宣传中心5.webp',
            text: '5月11日,全国首列“南亚国际货运列车”在兰启程,10天后,这列满载着“中国制造”的货运...',
            time: '2019-03-12'
        }, {
            title: '物流从业必备知识：货车分类',
            url: '@/assets/image/货车分类.jpg',
            text: '目前从交通运输行业统计来讲，吧运营货车分成四类，普通货车、专用货车、牵引车和挂车，2020年共有营运载货汽车1110.28万辆，其普通货车414.14万辆，专用货车50.67万辆，牵引车310.8万辆',
            time: '2022-10-10'
        }, {
            title: '易燃易爆危险品存放原则',
            url: '@/assets/image/危险品储存.jpg',
            text: '易燃易爆化学危险品的整装存储，往往存放的品种多，性质危险而复杂，比较难于管理。而散装存储量比较大，设备多，技术条件复杂，一旦发生事故难以施救。',
            time: '2022-09-13'
        }, {
            title: ' 集装箱知识',
            url: '@/assets/image/集装箱种类.jpg',
            text: '集装箱运输是国际贸易货物多试联运过程中的重要运输方式，实现全球范围内的船舶、港口、航线、公路、中转站、桥梁、隧道多式联运相配套的物流系统。',
            time: '2022-09-13'
        }, {
            title: ' 危险品分类',
            url: '@/assets/image/危险品种类.jpg',
            text: '凡具有爆炸、易燃、毒害、腐蚀、放射性等物质、在运输、装卸和贮存保管过程中，容易造成人事伤亡和财产损毁而需要特别防护的货物，均属危险品货物。',
            time: '2019-03-12'
        }, ],
        'title1': '新闻公告',
        'title2': '行业知识',
        'title3': '2022年物流行业发展趋势与工业物流崛起',
        'title4': '2022年世界国际海运公司',
        'title5': '物流机械设备的发展趋势',
        'title6': '物流快递行业年度期盼的新机遇',
        'title7': '进军国际市场开拓物流发展新机遇',
        'title8': '物流从业必备知识：货车分类',
        'title9': '易燃易爆危险品存放原则',
        'title10': '集装箱知识',
        'title11': '危险品分类',
        'title12': '新闻公告',
        'title13': '行业知识',
        'title14': '海运公司排行前十：',
        'textp1': '在过去两年中、物流行业呈加速发展态势。2022年、这一趋势将随新技术的应用而进一步发展、并将在操作流程、运输、仓储、安全性',
        'textp2': '据Alphainer最新运力数据显示,截至2022年7月10日,全球班轮公司运力前10名与上月比保持不变,目前全球在运营集装箱船总数为6406艘,总运力增加到2579.3291万TEU',
        'textp3': '随着生产和物流规模的扩大,以及物流自动化程度的提高,物流机械设备在现代化生产和物流中应用越来越广,作用也越来越大;而现代化生产和现代化物流又...',
        'textp4': '26日,京广高铁正式开通运营。羊城晚报记者获悉,武广高铁运营后已有广东快递企业试水广州长沙间的...',
        'textp5': '5月11日,全国首列“南亚国际货运列车”在兰启程,10天后,这列满载着“中国制造”的货运...',
        'textp6': '目前从交通运输行业统计来讲，吧运营货车分成四类，普通货车、专用货车、牵引车和挂车，2020年共有营运载货汽车1110.28万辆，其普通货车414.14万辆，专用货车50.67万辆，牵引车310.8万辆',
        'textp7': '易燃易爆化学危险品的整装存储，往往存放的品种多，性质危险而复杂，比较难于管理。而散装存储量比较大，设备多，技术条件复杂，一旦发生事故难以施救。',
        'textp8': '集装箱运输是国际贸易货物多试联运过程中的重要运输方式，实现全球范围内的船舶、港口、航线、公路、中转站、桥梁、隧道多式联运相配套的物流系统。',
        'textp9': '凡具有爆炸、易燃、毒害、腐蚀、放射性等物质、在运输、装卸和贮存保管过程中，容易造成人事伤亡和财产损毁而需要特别防护的货物，均属危险品货物。',
        'time1': '2022-10-10',
        'time2': '2022-09-13',
        'time3': '2019-03-12',
        'time4': '2019-03-12',
        'time5': '2019-03-12',
        'time6': '2022-10-10',
        'time7': '2022-09-13',
        'time8': '2022-09-13',
        'time9': '2019-03-12',
        'textp1': '在过去两年中，物流行业呈加速发展态势。2022年，这一趋势将随这新技术的应用而进一步发展，并将在操作流程、运输、仓储、安全性，以及供应链稳定性等诸多方面产生持续而广泛的影响。数字化和可持续性将深刻改变物流行业的工作方式。',
        'textp2': '物流4.0开启物流数字化时代新篇章',
        'textp3': '一段时间以来，我们越来越多地谈到物流4.0，这一概念源自工业4.0。数字化将成为新时代物流发展的根基，更准确地说，将基于流程自动化、设备网络化和企业间的联合。物流4.0旨在简化流程、提升效率，同时维护全球供应链的稳定，尤其是在充满挑战的时期，及时发现并消除供应链风险至关重要。',
        'textp4': '与物流4.0息息相关的大数据、机器使用和自动化，以及网络化将对物流的未来发展产生重要影响。数字化在道路交通中也将发挥巨大作用。一方面，自动驾驶技术的发展将有助于提高道路安全，另一方便也有助于缓解交通拥堵。',
        'textp5': '物流自动化的大数据和网络',
        'textp6': '数据是物流的关键组成部分，随着数据量的不断提升，其作用愈加重要。智能设备的使用使越来越多的数据可以被收集和交换。物流企业可根据不同的数据流进行分析、预测或判断发展趋势。',
        'textp7': '有效部署大数据和市场参与者之间的智能网络，有利于维护整个供应链的稳定，预测及避免潜在“断链”风险，并及时制定替代计划，以保证物流时效。同时，大数据也可用于优化运输路径，提升效率。此外，网络化不仅有助于优化风险管理，还能够提高供应链的透明度，帮助相关企业了解运输过程中的具体信息。',
        'textp8': '机器人化加速物流行业发展',
        'textp9': '在很多科幻电影中都出现了人类与机器人协同的场景。虽然目前机器人还没有那么全能，但已经可以执行简单的任务，并在工作中发挥作用。机器人被广泛试运行使用，特别是在电子商务和消费品仓储领域，主要用来减少人工工作量。机器人可为新订单拣选货物，并在库区内运输重货，通过预先计算规划最优路径，高效把货物送到交接点，再由另外的机器人继续完成后面的流程。传感器的使用可有效避免机器人在行进过程中的碰撞。',
        'textp10': '得益于电子商务的蓬勃发展，以及由此带来物流需求的快速增长，更多机器人将会投入试运营，其数字化能力也将得到进一步提升。',
        'textp11': '多式联运物流运输将成主流‍',
        'textp12': '为了防范和避免供应链中断的风险，多样化的物流运输方式可有效防范、降低供应链中断的风险。去年苏伊士运河堵塞给全球海运带来很大影响，凸显出单一运输方式的局限性。多式联运覆盖路运、汽运、海运和空运，一旦一种运输方式出现问题，可迅速切换至另一种方式，从而有效避免运输延误，维持供应链稳定。多元化物流渠道是实现多式联运的先决条件。',
        'textp13': '可持续物流进一步发展',
        'textp14': '可持续发展是未来几年乃至几十年的大趋势。物流行业将为温室气体减排做出重大贡献。据世界经济论坛研究统计，物流运输占全球碳排放总量的5.5%。因此，为未来的物流发展提供环保解决方案势在必行。',
        'textp15': '过去，速度和成本是选择运输方式的决定性因素。如今，可持续性变得越来越重要。除了车辆电气化，清洁燃料的使用和相关技术的研发对于减少二氧化碳和其他排放至关重要。此外，仓储物流也是物流可持续发展的重要领域，如包材的选择，以及物流公司如何获取能源途径等方面。',
        'textp16': '碳排放报告也是进一步减排的有效工具，未来也将更加普及。报告可提供供应链透明度，供企业深入了解其二氧化碳和污染物的排放情况。此外，可持续发展也将成为企业形象和竞争力的重要组成部分，促使企业设法减少排放量，将对环境的影响降到最低。',
        'textp17': '总结与展望',
        'textp18': '今年，数字化将在物流行业进一步发力，将有更多技术创新应用于简化流程，提高全球供应链稳定性。在可持续发展的大趋势下，整个物流行业将在2022年开启发展新格局，在注重环保的同时，保持盈利能力。我们已处于数字化和气候变化的大变局之中，及早适应并积极引入新技术，顺势而为的市场参与者将乘势而上。',
        'li-p1': '1、马士基航运有限公司（全球最大的航运公司，总部设于丹麦歌本哈根）',
        'li-p2': '2、地中海航运公司（世界第二大航运公司）',
        'li-p3': '3、法国达飞海运集团（世界10大航运公司第三名）',
        'li-p4': '4、中国远洋运输（集团）公司',
        'li-p5': '5、赫伯罗特船舶公司',
        'li-p6': '6、中国长荣海运',
        'li-p7': '7、中国香港东方海外货柜航运公司',
        'li-p8': '8、中国台湾阳明海运集团',
        'li-p9': '9、日本商船三井MOL',
        'li-p10': '10、日本邮船株式会社（NYK）',
        'h3-p1': '第十名、日本邮船株式会社（NYK）',
        'h3-p2': '第九名、日本商船三井MOL',
        'h3-p3': '第八名、中国台湾阳明海运集团',
        'h3-p4': '第七名、中国香港东方海外货柜航运公司',
        'h3-p5': '第六名、中国长荣海运',
        'h3-p6': '第五名、赫伯罗特船舶公司',
        'h3-p7': '第四名、中国远洋运输（集团）公司',
        'h3-p8': '第三名、法国达飞海运集团',
        'h3-p9': '第二名、地中海航运公司',
        'h3-p10': '第一名、马士基航运有限公司',
        'p-text1': '日本三大船务公司之一，也是世界上历史最悠久和规模最大的航运公司之一，由日本在早期的三菱财阀所创立。',
        'p-text2': '该公司总部位于日本千代地区，拥有大约800艘船只，包含集装箱船、邮轮、游轮等等。',
        'p-text3': '世界10大航运公司中第二个日本船运公司，是日本经连的一部分，它们家的鳄鱼标志可以在世界各地大港口的集装箱上看到',
        'p-text4': '位于中国台湾基隆的元阳运输公司，成立于1972年，如果追溯历史还可以清朝期间。',
        'p-text5': '该公司拥有85艘船舶，拥有420万载重吨位34.6万标准箱吞吐量，该公司服务于全球70多个国家',
        'p-text6': '世界最大的综合国际集装箱运输公司之一，在全球70多个国家设立有320个办事处。该公司拥有57艘不同级别的船只，包括极端天气下的冰级船。',
        'p-text7': '是一家集装箱运输公司，总部设立于中国台湾桃园市，其主要线路为远东至北美洲、中美洲以及加勒比地区。该公司在全球80个国家拥有240个港口。',
        'p-text8': '德国跨国船运公司，成立与1970年，由德国两家运输公司合并而成，拥有175艘集装箱船',
        'p-text9': '中国最大的航运企业，截至2011年就覆盖了全球160多个国家1600多个港口。截止2016年总船队规模稳居全球第一',
        'p-text10': '成立于1978年，总部位于马赛，是法国第一，世界10大航运公司第三名。该公司在全球150多个国家420个港口拥有200多条航线',
        'p-text11': '成立于1970年的瑞士航运公司，是世界第二大航运公司，在全球拥有350个机构，员工超过2.8万人，拥有471艘集装箱船。',
        'p-text12': '丹麦最大的航运商业集团，也是世界最大的航运公司，总部设立于丹麦歌本哈根，在全球130个国家设有办事处，员工超过8',
        'infor1': '随着生产和物流规模的扩大，以及物流自动化程度的提高，物流机械设备在现代化生产和物流中应用越来越广，作用也越来越大',
        'infor2': '纵观物流机械设备发展现状可以看出，为适应现代物流的需要，物流机械设备有如下发展趋势',
        'infor3': '1.大型化和高速化',
        'infor4': '　　大型化是指设备的容量、规模、能力越来越大，高速化是指设备的运转速度、运行速度、识别速度、运算速度大大加快。现代社会经济快速发展，使得生产和物流规模不断扩大，为了提高作业效率和规模效益，大型、高速的物流机械需求量不断增长。物流机械设备的起重量、载重量、生产率、作业能力越来越大，工作速度越来越快',
        'infor5': '2.实用化和轻型化',
        'infor6': '　　物流机械设备是现代化、自动化物流的重要物质技术基础。物流机械设备要求使用方便，容易维护、操作，具有优异的耐久性、无故障性和良好的经济性，以及较高的安全性、可靠性。因此，今后会更加注重开发使用性能好、成本低、可靠性高的物流机械设备。',
        'infor7': '3.专用化和通用化',
        'infor8': '　　物流是社会经济发展的产物，必然随着社会经济的发展而呈现多样化的特征。多样化的特征反映了对物流机械设备需求的多样化，从而使物流机械设备具有多种多样的品种且',
        'infor9': '4.自动化和智能化',
        'infor10': '　　将机械技术和电子技术相结合，将先进的微电子技术、电力电子技术、光缆技术、液压技术、模糊控制技术应用到机械的驱动和控制系统中，实现物流机械设备的自动化和智能化将是今后的发展方向。例如，大型起重机的新一代电气控制装置已发展为全电子数字化控制系统，由全数字化控制驱动装置、可编程序控制器、故障诊断及数据管理系统、数字化操纵给定检测等装置组成。先进技术的应用使起重机具有更高的柔性，从而提高了单机综合自动化水平。运输设备的操作更多的转向依靠仪表、信号和辅助驾驶系统智能化程度越来越高。海运、空运中的自动驾驶系统、自动化立体仓库中的送取货小车、公路运输智能交通系系统',
        'infor11': '　　智能式搬运车AHV( AutonomousHandlingVehicle)也将会得到广泛应用。AHV的形状类似于现在使用的AGV，但装有两只通用的机械手，在工作时依靠起视觉作用的工业摄像机，对货物的位置和大小进行判断，如同人一样可以用机械手自由地搬运重达200--300千克的货物。AHV具有协同作业的功能，搬运的货物过长、过重时，可以由两台以上的ARV协同作业进行搬运，这样会大大减少ARV的规格型号，便于管理。数台同一规格AHV的联合运作，其作业能力可提高很多。',
        'infor12': '5.成套化和系统化',
        'infor13': '　　在物流机械设备单机自动化的基础上，通过计算机把各种物流机械设备组成一个物流机械设备集成系统，通过控制室的控制，与物流系统协调配合。这类物流机械设备自动化程度较高，具有信息处理功能，可将传感器检测出来的各种信息实施存储、运算、逻辑判断、变换等处理加工，进而向执行机构发出控制指令。这类物流机械设备还具有较好的信息输人输出接口，实现信息全部、准确、可靠地在整个物流机械集成系统中的传输。物流机械设备通过系统集成，能形成不同机种的优先匹配和组合，取长补短，发挥出优先功效。以后将发展的有工厂生产搬运自动化系统、货物配送集散系统、集装箱装卸搬运系统、货物的自动分拣与搬运系统等。',
        'infoe-text1': '26日，京广高铁正式开通运营。羊城晚报记者获悉，武广高铁运营后已有广东快递企业试水广州长沙间的高铁快件运输，如今京广高铁的开通更将给我国物流、快递业带来新机遇。',
        'infoe-text2': '“此前武广高铁开通，广州长沙间就有高铁快件运输。”广州一家知名民营快递企业负责人告诉记者，目前快递走公路运输的占了80%左右，走航空的约占15%。其实无论从时效和成本来看，高铁比公路都有优势。京广高铁开通运营后，中间很多节点城市都是快递服务需求旺盛的地区，空间很大。',
        'infoe-text3': '据了解，12月中旬，国家邮政局、国家发改委综合运输研究所、中国快递协会就组成联合调研组，赴广东省对快递企业利用高铁运输快件等开展实地调研。',
        'infoe-text4': '除了快递业，大宗商品运输等传统物流业预计也将从京广高铁开通、京广线货运能力提升中受益。数据显示，仅武广高铁开通后，京广线货运能力就提升了10.9%，使长期运能受限制的煤炭、石油、粮食等物资及港澳外贸货运的运输需求得到改善。',
        'infoe-text5': '5月11日，全国首列“南亚国际货运列车”在兰启程，10天后，这列满载着“中国制造”的货运列车，经过铁路、公路运输，将到达尼泊尔首都加德满都。这标志着兰州在“一带一路”建设中，在开启“兰州号”中亚、欧洲货运专列之后，又一次迈出了向西向南阿开放的重要一步',
        'infoe-text6': ' 　　“一带一路”重大战略构想提出两年多来，沿线国家纷纷响应。去年，中国跟30多个国家签订了共建“一带一路”谅解备忘录，一些主要的“一带一路”骨架已经开始搭建，比如中蒙俄经济走廊、中国—中南半岛的经济走廊、新亚欧大陆桥经济走廊、中国—中亚—西亚走廊、中巴经济走廊、孟中印缅经济走廊等，有的已取得喜人成果。先就中欧来说，以往，中欧之间的货运主要靠空运、海运以及公路运输，空运和公路运输成本高，海运耗时长。中欧班列以其运距短、速度快、安全性高的特征，以及安全快捷、绿色环保、受自然环境影响小的优势，已成为国际物流中陆路运输的骨干方式。',
        'infoe-text7': ' 　　国际货运的前景十分广阔，具有极高的性价比。众所周知，过去我们面向南亚和东南亚的物流贸易主要通过海运完成，此次开通的铁路货运专列可谓开拓了陆路运输的途径，将充分发挥铁路运输的优势。可以想象，今后由铁路带动各个国家和城市之间的经济贸易、货物往来、人员流动、文化交流与技术支持，必将强化各国间的“城市”效应，“一带一路”的发展格局也必将在互帮、互助、互进中建立起世界各国共同发展的合作体系。随着科技的互补、资源的互助，铁路也会在发展中迈开步伐，终铺就一条世界铁路运输线路图。 ',
        'infoe-text8': ' 　　现在“一带一路”已成为一种趋势，每一条国际铁路线的开通都对沿线国家与地区带来新一轮的发展机遇，各产业应以全新的发展思路来迎接这一崭新的时代，携手创造共赢。随着和南亚铁路携手合作，我们的铁路也在不断“升级”提速，我们相信，“南亚国际货运列车”必将成为“一带一路”大战略上国际货运的“明星产品”，也将拉动共同的经济发展从而惠及人民。',
        'textd-p1': '目前从交通运输行业统计来讲，把营运货车分成四类，普通货车、专用货车、牵引车和挂车，2020年共有营运载货汽车1110.28万辆，其中普通货车414.14万辆，专用货车50.67万辆，牵引车310.84万辆，挂车334.63万辆。其中的专用货车，包括了集装箱车辆、冷藏车辆、罐式车辆以及大件和危货运输车。',
        'textd-p2': '就一般货车而言，可以从载重、厢体结构、驾驶室、驱动方式、车长、用能结构、排放标准等去进行划分。专用车辆也可以放到厢体结构中，属于特殊厢体结构。挂车本身也是一个系列，根据《货运挂车系列型谱》（GB/T 6420-2017），挂车包括了栏板式挂车、仓栅式挂车、厢式、低平板式、车辆运输车挂车（又分为中置轴和半挂）、液体运输车、颗粒物料运输半挂车、集装箱半挂车、冷藏和保温运输半挂车。与一般货车按照厢体结构分类基本相同。因此，我们可以从载重、厢体结构、驾驶室形态、车辆驱动轴、车长、用能结构以及车辆排放7个维度，对货车类型进行细分',
        'textd-p3': '一、按载重分',
        'textd-p4': '目前常用的，一般分为微卡、轻卡、中卡、重卡四种：',
        'textd-p5': '微卡：总质量＜1.8吨。',
        'textd-p6': '轻卡：1.8吨＜总质量≤6吨。',
        'textd-p7': '中卡：6吨＜总质量≤14吨 。',
        'textd-p8': '重卡：总质量＞14吨。',
        'textd-p9': '2019年轻型民用载货汽车拥有量为1901万辆，占民用载货汽车的68.3%；重型民用载货汽车拥有量为762万辆，占民用载货汽车的27.37%；中型民用载货汽车拥有量为116万辆，占民用载货汽车的4.18%；微型民用载货汽车拥有量为4万辆，占民用载货汽车的0.15%。以轻、重型货车为主。',
        'textd-p10': '对于车长小于 6000mm 且总质量小于 4500kg 的轻型载货汽车，上蓝牌，允许在城市内行驶，就是我们常听到的蓝牌轻卡。根据新的《收费公路车辆通行费车型分类》，4.2米蓝牌轻卡成为1、2类车型的分界标，降低了蓝牌轻卡的收费。',
        'textd-p11': '二、按车厢结构分',
        'textd-p12': '由于运输货物的特性不同，货车（挂车）车箱厢体差异较大，一般来讲，可以分为仓栅式、栏板式、（低）平板式、自卸车、厢式、集装箱车、罐式以及中置轴。',
        'textd-p13': '其中，平板式包括普通平板和低平板专用车。普通平板半挂车，车长最长13米。新出台的《低平板半挂车技术规范》（JT/T 1264-2020），明确无论是低平板专用半挂车还是低平板增强半挂车，车长都不能超过13.75米。',
        'textd-p14': '低平板车也是轿运车的主要车型。2018年7月1日起开始只允许以下三种车运输商品车：平头铰接列车车长不超过17.1m、长头不超过18.1米，车宽不超过2.55m，载货后车高不超过4.2m；中置轴整车车长不超22米，车宽不超过2.55m，载货后车高不超过4.2m。',
        'textd-p15': '三种车型的转载情况如下图所示：',
        'textd-p16': '中置轴列车',
        'textd-p17': '此外，对于17.5米大板车，发改委、工信部曾于2007年12月21日发布公告，对17.5米长低平板半挂车等车型予以撤销，并自2007年12月31日起不再作为办理车辆注册登记的依据。而低平板半挂车的使用年限最长是15年，因此目前业内有了2022年17.5米大板将全面淘汰的说法。',
        'textd-p18': '中置轴属于挂车的一种特殊类型（挂车分为全挂、半挂、中置轴）。2016年颁布的《GT/T 1589-2016汽车、挂车及汽车列车外廓尺寸、轴荷及质量限值》标准中新增了中置轴货运列车的选项，中置轴货运列车的宽度为2.55米，最大长度达到了史无前例的22米，也就是前面看到的轿运车车型。部分品牌的中置轴挂车货箱容积可以达到130立方米，对于未来的快递甩挂运输绝对是一大优势。',
        'textd-p19': '三、按驾驶室分',
        'textd-p20': '可以分为长头、短头、平头和偏置四类。平头车发动机就在驾驶室下方，短头车发动机有一半在驾驶室下方，长头车发动机就位于驾驶室前方。',
        'textd-p21': '在我国，目前大家在路面上看到的绝大部分都是平头车，零星可能会看到几辆长头车。但是时间倒退几十年的话，结果是反着的。新中国生产的第一辆卡车，解放CA10就是长头车。随着汽车保有量的提升和提高道路安全的考虑，国家出台相关标准对汽车的外廓界限进行了明确要求，车头越长、货箱就越短，拉的货就越少，由此促使平头车开始盛行。',
        'textd-p22': '平头卡车',
        'textd-p23': '偏置式卡车',
        'textd-p24': '四、按驱动轴分',
        'textd-p25': '我们经常听见货车有4x2、6x2、6x4之类的说法，这就是按照驱动方式对卡车进行分类。前一个数字是车轮总数，后一个数字是驱动轮数。例如4x2，表示货车有4个车轮（两根轮轴即双桥），其中有2个驱动车轮（一根驱动轴即单桥）。同理，6*4的货车有3根轮轴，其中有2个是驱动轴。',
        'textd-p26': '我们常常听到前四后八、前四后四。所谓“前四后八”就是四轴车，前四是双桥4个轮，后八也是双桥，每个桥的左右各2个轮，每个桥4个轮，就是8个轮子驱动。“前四后四”是三轴车，前桥是双桥4轮，后面是单桥，每个桥的左右各2个轮，一共4轮。',
        'textd-p27': '五、按车厢长分',
        'textd-p28': '我们常听到的几款车型，如4米2、9米6、13米5等，都指的是车厢长度，不是《GT/T 1589-2016汽车、挂车及汽车列车外廓尺寸、轴荷及质量限值》标准中车长的概念。',
        'textd-p29': '常见几款车型规格及载重如下：',
        'textd-p30': '六、按用能及排放分',
        'textd-p31': '目前货车仍以柴油引擎作为动力来源，同时有部分轻型货车使用汽油、石油气、天然气以及电力等新能源。根据《中国机动车环境管理年报》，2017年货车保有量2341万辆，其中柴油车保有量1690.9万辆，占比72.2%。按排放标准阶段划分，国Ⅱ及之前排放标准的柴油货车35万辆，Ⅲ标准的柴油货车841.2万辆，国Ⅳ标准的柴油货车716.8万辆，国Ⅴ及以上排放标准的柴油货车97.9万辆。',
        'textd-p32': '2019年生态环境部等11部委联合印发《柴油货车污染治理攻坚战行动计划》，明确提出自2019年7月1日起，重点区域、珠三角地区、成渝地区提前实施机动车国六排放标准。2020年年底前，京津冀及周边地区、汾渭平原加快淘汰国三及以下排放标准营运柴油货车100万辆以上。各地也相继出台了国Ⅲ货车限行和淘汰补贴的相关政策，加速国Ⅲ货车淘汰。',
        'tretal1': '（八）腐蚀性物品',
        'tretal2': '腐蚀性物品，一般与其他种类的物品之间和腐蚀性物品中的有机与无机腐蚀品之间，酸性与碱性物品之间，可燃体固体之间，都应单独仓间存放，不可混储。',
        'tretal3': '1．腐蚀性物品之间',
        'tretal4': '（1）溴与硝酸、硫酸等混合，能加强其腐蚀性或燃烧，应隔离后存放。',
        'tretal5': '（2）过氧化氢易与硝酸起反应放出大量气体，遇三氯化磷等会起脱水作用，产生高温，甚至发生爆炸。',
        'tretal6': '2．腐蚀性物品与可燃液体之间',
        'tretal7': '有机酸性腐蚀品与乙类可燃液体之间要隔离后储存，有机碱性腐蚀品与可燃液体之间可同库储存，但堆垛须间隔2米以上。',
        'tretal8': '3．腐蚀性物品与可燃固体之间，无机碱性腐蚀品与乙类可燃固体之间可隔离存放。',
        'tretal9': '4．其他碱性腐蚀品均应单独仓间存放，不可混储。',
        'tretal10': '（七）毒害品',
        'tretal11': '1．无机毒害品与无机氧化剂之间和有机毒害品的固体与硝酸的有机衍生物之间应隔离存放',
        'tretal12': '2．无机毒害品与氧化（助燃）气体，应隔离存放，与不燃气体可同库存放；有机毒害品与不燃气体应隔离存放；',
        'tretal13': '3．液体的有机毒害品与可燃液体可隔离后存放；',
        'tretal14': '4．有机毒害品的固体与乙类燃烧固体可同储，但与甲类易燃固体应隔离后存放，无机毒害品与乙类易燃固体可隔离后存放。',
        'tretal15': '5．有机毒害品的固体与液体之间，以及与无机毒害品之间，均应隔离后存放；有机毒害品的液体与液体，固体与固体之间，无机的剧毒品与有毒品之间均可同库储存。。',
        'tretal16': '6．其他种类物品均不可同库存放',
        'tretal17': '（六）氧化剂和有机过氧化物',
        'tretal18': '1．氧化剂和有机过氧化物之间',
        'tretal19': '甲类无机氧化剂与有机氧化剂特别是有机过氧化物不能同库储存。',
        'tretal20': '2．氧化剂与压缩气体和液化气体',
        'tretal21': '甲类氧化剂与易燃或剧毒气体不可同库储存，因为甲类氧化剂的氧化能力强，与剧毒气体或易燃气体接触容易引起燃烧或钢瓶爆炸。',
        'tretal22': '乙类氧化剂与压缩和液化气体可隔离储存，即保持2米以上的间距，与惰性气体可同库存放。',
        'tretal23': '3．氧化剂与自燃、易燃，遇湿易燃物品，一般不同库储存。',
        'tretal24': '4．氧化剂与毒害品',
        'tretal25': '无机氧化剂与毒害品应隔离储存，有机氧化剂毒害品可以同库隔离储存，但与有可燃性的毒害品不可同库储存。',
        'tretal26': '5．氧化剂与腐蚀性物品',
        'tretal27': '有机过氧化物不得与溴和硫酸等氧化性腐蚀品同库储存。',
        'tretal28': '6．氧化剂（指无机氧化剂）不得与松软的粉状物同库储存。',
        'tretal29': '（五）遇湿易燃物品',
        'tretal30': '1．遇湿易燃品不得与自燃物品同库存放。因为自燃物品危险性大，见空气即着火，且黄磷、651除氧催化剂等，包装用水作稳定剂，一旦包装破损或渗透都有引起着火的危险。',
        'tretal31': '2．遇湿易燃物品与氧化剂不可同库存放。因为遇湿易燃物品是还原剂，遇氧化剂会剧烈反应，发生着火和爆炸。',
        'tretal32': '3．遇湿易燃物品与腐蚀性物品之间：因为溴、过氧化氢、硝酸、硫酸等强酸，都具有较强的氧化性，与遇水燃烧物品接触会立即着火或爆炸。且过氧化氢还含有水，也会引起着火爆炸，所以不得同库存放。与盐酸、甲酸、醋酸和含水碱性腐蚀品如液碱等，亦应隔离存放。',
        'tretal33': '4．遇湿易燃物品与含水的易燃液体和稳定剂是水的易燃液体，如己酸、二硫化碳等，均不得同库存放。',
        'tretal34': '5．遇湿易燃物品之间；活泼金属及其氢化物可同库存放；电石受潮后产生大量乙炔气，其包装易发生爆破，应单独存放。磷化钙、硫化钠、硅化镁等受潮后能产生大量易燃的毒气和易自燃的毒气，因此，亦应单独存放。',
        'tretal35': '（四）自燃物品',
        'tretal36': '1．甲类自燃物品，不得与爆炸品、氧化剂、氧化性气体（助燃）、易燃液体、易燃固体同库存放。',
        'tretal37': '2．黄磷、651除氧催化剂，不得与遇湿易燃物品同库存放。硼、锌、铝、锑、碳氢化合物类自燃物品与遇湿易燃物品可隔离储存。',
        'tretal38': '3．自燃物品之间：甲类自燃物品与乙类自燃物品与黄磷、651除氧催化剂不得同库放。',
        'tretal39': '4．腐蚀性物品，溴、硝酸、过氧化氢（40%的双氧水）具有较强的氧化性，自燃物品与之不可同库存放。与盐酸、甲酸、醋酸和碱性腐蚀品亦不准同库存放或隔离存放。',
        'tretal40': '（三）易燃固体',
        'tretal40': '1．与自燃物品：因为甲类自燃物品性质不稳定，可以自行氧化燃烧，会引起易燃固体的燃烧，所以不能同库储存。与乙类自燃物品亦应隔离储存。 ',
        'tretal42': '2．与遇湿易燃物品：因和遇湿易燃物品灭火方法不同，且有的性质相互抵触，不能同库储存。',
        'tretal43': '3．与氧化剂：因为易燃固体都有很强的还原性，与氧化剂接触或混合有引起着火爆炸的危险，所以都不能同库存放。',
        'tretal44': '4．与腐蚀性物品：与具有氧化性的腐蚀性物品如：溴、过氧化氢、硝酸等不可同库储存，与其他酸性腐蚀性物品可同库隔离存放，但发孔剂H（71011）与某些酸作用能引起燃烧，所以不宜同库存放。',
        'tretal45': '5．易燃固体之间：金属氨基化合物类、金属粉末、磷的化合物类等与其他易燃固体不宜同库储存。因为他们灭火方法和储存保养措施不同，硝化棉、赤磷、赛璐珞、火柴等均宜专库储存。樟脑、萘、赛璐珞制品，虽属乙类易燃固体，但挥发出来的蒸气和空气可形成爆炸性的混合气体，遇着火源容易引起燃烧爆炸，储存条件要求高，也宜专库储存。',
        'tretal46': '（二）易燃液体',
        'tretal47': '易燃液体，不仅本身易燃，而且大都具有一定的毒性。如甲醇、苯、二硫化碳等，原则上应单独存放。但因各种条件的限制，不得不与其他种类危险品同储时，应遵守如下原则：',
        'tretal48': '1．与甲类自燃物品不能同储，与乙类自燃物品也应隔离存放。因为自燃物品可自行燃烧。',
        'tretal49': '2．与腐蚀性物品溴、过氧化氢，硝酸等强酸不可同储，如量甚少时，也应隔离存放，并保持2米以上的间距。因为溴、过氧化氢、硝酸等强酸都有较强的氧化性。',
        'tretal50': '3．含水的易燃液体和需要加水存放或运输的易燃液体、不得与遇湿易燃物品同储',
        'tretal51': '易燃易爆化学危险品的整装储存，往往存放的品种多，性质危险而复杂，比较难于管理。而散装储存量比较大，设备多，技术条件复杂，一旦发生事故难以施救。故无论何种储存方式都潜在有很大的火灾危险，所以必须用科学的态度从严管理，万万不可马虎从事',
        'tretal52': '分类存放的一般原则',
        'tretal53': '（一）压缩、液化气体',
        'tretal54': '1．压缩、液化气体之间：可燃气体与氧化性（助燃）气体混合，遇着火源易着火甚至爆炸，应隔离存放。',
        'tretal55': '2．压缩、液化气体与自然、遇湿易燃等易燃物品之间：剧毒、可燃、氧化性（助燃）气体均不得与甲类自然物品物品同储和配装；与乙类自燃物品，遇水易燃物品（灭火方法不同）应隔离存放和配装；可燃液体、固体与剧毒、氧化性气体不得同储配装。',
        'tretal56': '3．压缩、液化气体与腐性物品：剧毒气体、可燃气体不得与硝酸、硫酸等强酸配装和同储，与氧化性（助燃）气体不燃气体应隔离储存放和配装。',
        'tretal57': '4．氧气瓶及氧气空瓶不得与油脂及含油物质、易燃物同储和配装。',
        'text1': '1.集装箱（container）所谓集装箱，是指具有一定强度、刚度和规格专供周转使用的大型装货容器。使用集装箱转运货物，可直接在发货人的仓库装货，运到收货人的仓库卸货，中途更换车、船时，无须将货物从箱内取出换装。按所装货物种类分，有杂货集装箱、散货集装箱、液体货集装箱、冷藏箱集装箱等；按制造材料分，有木集装箱、钢集装箱、铝合金集装箱、玻璃钢集装箱、不锈钢集装箱等；按结构分，有折叠式集装箱、固定式集装箱等，在固定式集装箱中还可分密闭集装箱、开顶集装箱、板架集装箱等；按总重分，有30吨集装箱、20吨集装箱、10吨集装箱、5吨集装箱、2.5吨集装箱等。',
        'text2': '2.集装箱外尺寸（containers overall external dimensions） 包括集装箱永久性附件在内的集装箱外部最大的长、宽、高尺寸。它是确定集装箱能否在船舶、底盘车、货车、铁路车辆之间进行换装的主要参数。是各运输部门必须掌握的一项重要技术资料。',
        'text3': '3.集装箱内尺寸（containers internal dimensions）集装箱内部的最大长、宽、高尺寸。高度为箱底板面至箱顶板最下面的距离，宽度为两内侧衬板之间的距离，长度为箱门内侧板量至端壁内衬板之间的距离。它决定集装箱内容积和箱内货物的最大尺寸。',
        'text4': '4.集装箱内容积（containers unobstructed capacity）按集装箱内尺寸计算的装货容积。同一规格的集装箱，由于结构和制造材料的不同，其内容积略有差异。集装箱内容积是物资部门或其它装箱人必须掌握的重要技术资料。',
        'text5': '5.集装箱计算单位（twenty-feet equivalent units 简称：TEU) 又称20英尺换算单位，是计算集装箱箱数的换算单位。目前各国大部分集装箱运输，都采用20英尺和40英尺长的两种集装箱。为使集装箱箱数计算统一化，把20英尺集装箱作为一个计算单位，40尺集装箱作为两个计算单位，以利统一计算集装箱的营运量。',
        'text6': '6.集装箱租赁 （container leasing) 即所有人将空箱租给使用人的一项业务。集装箱所有人为出租的一方集装箱，与使用人，一般是船公司或货主，为承租的一方，双方签订租赁合同。由出租人提供合格的集装箱交由承租人在约定范围内使用。装箱，每航次编制配载图；办理有关货运单证的编签； 编制并签验集装箱运用运载工具的出入及流转的有关单证； 办理集装箱及运载工具、装卸工具的情况检查、维修，以及空箱的清扫、熏蒸等工作； 空箱的收发、存贮和保管；安排空箱和重箱在堆场的堆码，及编制场地分配计划； 其它有关业务工作。 集装箱装卸区一般由专用码头、前沿、堆场、货运站、指挥塔、修理部门、大门和办公室组成。有时堆场或货运站等可延伸到市区内部事5-15公里的中转站。',
        'text7': '7.集装箱装卸区（container terminal) 是集装箱运输中，箱或货装卸交换保管的具体经办部门。它受承运人或其代理人的委托，进行下列各项业务：对整箱货运的交换、保管； 设有集装箱货运站者，办理拼箱货的交接； 安排集装箱船的靠泊，装卸集装箱。',
        'text8': '8.集装箱前方堆场（marshalling yard） 是指在集装箱码头前方，为加速船舶装卸作业，暂时堆放集装箱的场地。其作用是：当集装箱船到港前，有计划有次序地按积载要求将出口集装箱整齐地集中堆放，卸船时将进口集装箱暂时堆放在码头前方，以加速船舶装卸作业。',
        'text9': '9.集装箱后方堆场（container yard） 集装箱重箱或空箱进行交接、保管和堆存的场所。有些国家对集装箱堆场并不分前方堆场或后方堆场，统称为堆场。集装箱后方堆场是集装箱装卸区的组成部分。是集装箱运输“场到场”交接方式的整箱货办理交接的场所（实际上是在集装箱卸区“大门口”进行交接的）。',
        'text10': '10.空箱堆场（van pool） 专门办理空箱收集、保管、堆存或交接的场地。它是专为集装箱装卸区或转运站堆场不足时才予设立。这种堆场不办理重箱或货物交接。它可以单独经营，也可以由集装箱装卸区在区外另设。有些国家，经营这种空箱堆场，须向航运公会声明。',
        'text11': '11.中转站或内路站（container depot or inland depot）海港以外的集装箱运输的中转站或集散地。它的作用除了没有集装箱专用船的装卸作业外，其余均与集装箱装卸区业务相同。中转站或内陆站的度量，包括集装箱装卸港的市区中转站、内陆城市、内河港口的内陆站均在内。',
        'text12': '12.集装箱货运站（container freight station 简称：CFS） 为拼箱货装箱和拆箱的船、货双方办理交接的场所。承运人在一个港口或内陆城市只能委托一个集装箱货运站的经营者。由它代表承运人办理下列主要业务：拼箱货的理货和交接； 对货物外表检验如有异状时，就办理批注； 拼箱货的配箱积载和装箱； 进口拆箱货的拆箱和保管； 代承运人加铅封并签发站收据； 办理各项单证和编制等。',
        'text13': '13.托运人责任（shippers liabilities） 托运人在集装箱运输中应有的责任，这种责任是不完全同于传统海运方面的。拼箱货托运人的责任与传统海运相同。整箱货托运人的责任不同于传统运输的有：应保证所报货运资料的正确和完整； 承运人有权核对箱内所装货物，因核对而发生的费用，有托运人承担； 海关或其它权力机关开箱检查，其费用和由此发生货损货差，由托运人承担；如集装箱货不满，或是垫衬不良，积载不当，或是装了不适于集装箱运输的货物，因而引起货损、货差，概由托运人负责； 如使用了托运人自有的不适航的集装箱，所引起的货损事故，应由托运人负责；在使用承运人集装箱及设备其间造成第三者财产或生命的损害，应由托运人负责赔偿。集装箱的租赁，国际上有多种不同的方式，总括起来有：程租、期租、活期租用和航区内阻赁等。',
        'text14': '14.责任限制（limits of liability） 集装箱运输中发生货损货差，承运人应承担的最高赔偿额。拼箱货的责任限制与传统运输相同。整箱货的赔偿按照目前国际上的一些判例：如果提单上没有列明箱内所装货物的件数，每箱作为一个理赔计算单位；如提单上列明箱内载货件数的，仍按件数计算；如果货物的损坏和灭失，不属海运，而是在内陆运输中发生的，则按陆上运输最高赔偿额办理；如集装箱是由托运人所有或提供时，遇有灭失或损坏，其责任确属承运人应承担者，亦应视作一个理赔计算单位。',
        'goods1': '1.集装箱（container）所谓集装箱，是指具有一定强度、刚度和规格专供周转使用的大型装货容器。使用集装箱转运货物，可直接在发货人的仓库装货，运到收货人的仓库卸货，中途更换车、船时，无须将货物从箱内取出换装。按所装货物种类分，有杂货集装箱、散货集装箱、液体货集装箱、冷藏箱集装箱等；按制造材料分，有木集装箱、钢集装箱、铝合金集装箱、玻璃钢集装箱、不锈钢集装箱等；按结构分，有折叠式集装箱、固定式集装箱等，在固定式集装箱中还可分密闭集装箱、开顶集装箱、板架集装箱等；按总重分，有30吨集装箱、20吨集装箱、10吨集装箱、5吨集装箱、2.5吨集装箱等。',
        'goods2': '2.集装箱外尺寸（containers overall external dimensions） 包括集装箱永久性附件在内的集装箱外部最大的长、宽、高尺寸。它是确定集装箱能否在船舶、底盘车、货车、铁路车辆之间进行换装的主要参数。是各运输部门必须掌握的一项重要技术资料。',
        'goods3': '3.集装箱内尺寸（containers internal dimensions）集装箱内部的最大长、宽、高尺寸。高度为箱底板面至箱顶板最下面的距离，宽度为两内侧衬板之间的距离，长度为箱门内侧板量至端壁内衬板之间的距离。它决定集装箱内容积和箱内货物的最大尺寸。',
        'goods4': '4.集装箱内容积（containers unobstructed capacity）按集装箱内尺寸计算的装货容积。同一规格的集装箱，由于结构和制造材料的不同，其内容积略有差异。集装箱内容积是物资部门或其它装箱人必须掌握的重要技术资料。',
        'goods5': '5.集装箱计算单位（twenty-feet equivalent units 简称：TEU) 又称20英尺换算单位，是计算集装箱箱数的换算单位。目前各国大部分集装箱运输，都采用20英尺和40英尺长的两种集装箱。为使集装箱箱数计算统一化，把20英尺集装箱作为一个计算单位，40尺集装箱作为两个计算单位，以利统一计算集装箱的营运量。',
        'goods6': '6.集装箱租赁 （container leasing) 即所有人将空箱租给使用人的一项业务。集装箱所有人为出租的一方集装箱，与使用人，一般是船公司或货主，为承租的一方，双方签订租赁合同。由出租人提供合格的集装箱交由承租人在约定范围内使用。装箱，每航次编制配载图；办理有关货运单证的编签； 编制并签验集装箱运用运载工具的出入及流转的有关单证； 办理集装箱及运载工具、装卸工具的情况检查、维修，以及空箱的清扫、熏蒸等工作； 空箱的收发、存贮和保管；安排空箱和重箱在堆场的堆码，及编制场地分配计划； 其它有关业务工作。 集装箱装卸区一般由专用码头、前沿、堆场、货运站、指挥塔、修理部门、大门和办公室组成。有时堆场或货运站等可延伸到市区内部事5-15公里的中转站。',
        'goods7': '7.集装箱装卸区（container terminal) 是集装箱运输中，箱或货装卸交换保管的具体经办部门。它受承运人或其代理人的委托，进行下列各项业务：对整箱货运的交换、保管； 设有集装箱货运站者，办理拼箱货的交接； 安排集装箱船的靠泊，装卸集装箱。',
        'goods8': '8.集装箱前方堆场（marshalling yard） 是指在集装箱码头前方，为加速船舶装卸作业，暂时堆放集装箱的场地。其作用是：当集装箱船到港前，有计划有次序地按积载要求将出口集装箱整齐地集中堆放，卸船时将进口集装箱暂时堆放在码头前方，以加速船舶装卸作业。',
        'goods9': '9.集装箱后方堆场（container yard） 集装箱重箱或空箱进行交接、保管和堆存的场所。有些国家对集装箱堆场并不分前方堆场或后方堆场，统称为堆场。集装箱后方堆场是集装箱装卸区的组成部分。是集装箱运输“场到场”交接方式的整箱货办理交接的场所（实际上是在集装箱卸区“大门口”进行交接的）。',
        'goods10': '10.空箱堆场（van pool） 专门办理空箱收集、保管、堆存或交接的场地。它是专为集装箱装卸区或转运站堆场不足时才予设立。这种堆场不办理重箱或货物交接。它可以单独经营，也可以由集装箱装卸区在区外另设。有些国家，经营这种空箱堆场，须向航运公会声明。',
        'goods11': '11.中转站或内路站（container depot or inland depot）海港以外的集装箱运输的中转站或集散地。它的作用除了没有集装箱专用船的装卸作业外，其余均与集装箱装卸区业务相同。中转站或内陆站的度量，包括集装箱装卸港的市区中转站、内陆城市、内河港口的内陆站均在内。',
        'goods12': '12.集装箱货运站（container freight station 简称：CFS） 为拼箱货装箱和拆箱的船、货双方办理交接的场所。承运人在一个港口或内陆城市只能委托一个集装箱货运站的经营者。由它代表承运人办理下列主要业务：拼箱货的理货和交接； 对货物外表检验如有异状时，就办理批注； 拼箱货的配箱积载和装箱； 进口拆箱货的拆箱和保管； 代承运人加铅封并签发站收据； 办理各项单证和编制等。',
        'goods13': '13.托运人责任（shippers liabilities） 托运人在集装箱运输中应有的责任，这种责任是不完全同于传统海运方面的。拼箱货托运人的责任与传统海运相同。整箱货托运人的责任不同于传统运输的有：应保证所报货运资料的正确和完整； 承运人有权核对箱内所装货物，因核对而发生的费用，有托运人承担； 海关或其它权力机关开箱检查，其费用和由此发生货损货差，由托运人承担；如集装箱货不满，或是垫衬不良，积载不当，或是装了不适于集装箱运输的货物，因而引起货损、货差，概由托运人负责； 如使用了托运人自有的不适航的集装箱，所引起的货损事故，应由托运人负责；在使用承运人集装箱及设备其间造成第三者财产或生命的损害，应由托运人负责赔偿。集装箱的租赁，国际上有多种不同的方式，总括起来有：程租、期租、活期租用和航区内阻赁等。',
        'goods14': '14.责任限制（limits of liability） 集装箱运输中发生货损货差，承运人应承担的最高赔偿额。拼箱货的责任限制与传统运输相同。整箱货的赔偿按照目前国际上的一些判例：如果提单上没有列明箱内所装货物的件数，每箱作为一个理赔计算单位；如提单上列明箱内载货件数的，仍按件数计算；如果货物的损坏和灭失，不属海运，而是在内陆运输中发生的，则按陆上运输最高赔偿额办理；如集装箱是由托运人所有或提供时，遇有灭失或损坏，其责任确属承运人应承担者，亦应视作一个理赔计算单位。',
    },
    foot: {
        xiazhaispin: 'Company Introduction Movie',
        tbat:'Download',
        textdiz: '',
        tupian:'',
        bananhap:'',
        title: '联系我们 ',
        subheading: '联系我们，选择我们就是选择放心、选择安心',
        data: [{
            text: '天津总部热线'
        }, {
            text: '空运业务合作'
        }, {
            text: '空运业务'
        }, {
            text: '陆运仓储'
        }, {
            text: '投诉建议'
        }]
    },
    network: [{
        title: 'DSL GLOBAL NETWORK',
        subheading: 'DAESHIN LOGISTICS NETWORK'
    }, {
        title: 'DAESHIN TianJin Head Office',
        subheading: 'Floor 22, Block B, Huashengguangchang, No. 146, Weididao, Hexi District, Tianjin City',
        sjhaoma: ' 022-88239781',
        dianhuahaoma: '022-88239785',
        emoe: 'sales_china@i-daeshin.com',
    }, {
        title: 'DAESHIN Beijing Branch Office',
        subheading: 'No. 1, Caixiangxi Road, Nancai Town, Shunyi District, Beijing City'
    }, {
        title: 'DAESHIN ShangHai Branch Office',
        subheading: 'Room 2109, Block B, Yuandongguojidasha, No. 317, Xianxia Road, Changning District, Shanghai City'
    }, {
        title: 'DAESHIN WeiHai Branch Office',
        subheading: 'No. 6-2, Zhengqi Road, Qiongshan Town, Lingangjingjijishukaifa District, Weihai City, Shandong Province'
    }, {
        title: 'DAESHIN Cangzhou Branch Office',
        subheading: 'Building 5, No. 35, Donghai Road, Cangzhoujingjikaifa District'
    }, {
        title: 'DAESHIN ShanXi Head Office',
        subheading: 'Group 1, Liuwan Village, Yintai District, Tongchuan City, Shanxi Province'
    }, {
        title: 'DAESHIN Xian Branch Office',
        subheading: '2301/2302, Unit 2, Building 8, Yicuishangfubei District, Jinyeer Road, Gaoxin District, Xian City'
    }, {
        title: 'DAESHIN ChongQing Branch Office',
        subheading: '  Fengshuwuliuyuan, No. 19, Yinglong Avenue, Longxing Town, Yubei District, Zhongqing City, Longxing Town, Zhongqingyubei District'
    }, {
        title: 'DAESHIN ChengDu Office',
        subheading: 'Binjiangfeicuicheng Nandamen, Pengshan District, Meishan City, Sichuan Province'
    }, {
        title: 'DAESHIN HuiZhou Office',
        subheading: ' 3108, Unit 2, Building 2, Qianshuiwan, Xiayong Street, Dayawan District, Huiyang District, Huizhou City, Guangdong Province'
    }, {
        title: 'DAESHIN FuZhou Office',
        subheading: ' 705, Block 2, Building 6, Quarter, Pansheng Garden, Xincuo Town, Fuqing City, Fujian Province'
    }, {
        title: 'DAESHIN QuZhou Office',
        subheading: 'No. 12, Xiaoxingxincailiao Co., Ltd., Xiaoxing Avenue, Gaoxinyuan District, Kecheng District, Quzhou City, Zhejiang Province'
    }, {
        title: 'DAESHIN SuZhou Branch Office',
        subheading: 'Room 1404, Gaorongdasha, No. 77, Gaotiexinchengnantiancheng Road, Xiangcheng District, Suzhou City'
    }, {
        title: 'DAESHIN ChangZhou Office',
        subheading: 'Zhuhuadianzi, No. 1, Zhong Road, Gang District, Xinbei District, ChangZhou City, Jiangsu Province'
    }, {
        title: 'DSL US LA(CA) INC',
        // subheading: '11 Sago Palm Way, Bloomingdale, GA 31302 ',
        // emoe: 'yhlee1019@i-daeshin.com',
    }, {
        title: 'DSL USA Head Office',
        subheading: '12710 Research Blvd., Suite #380, Austin, Texas, 78759',
        sjhaoma: '+1 512 638 0799',
        emoe: 'mhsung@i-daeshin.com',
    }, {
        title: 'DSL Korea Seoul Head Office',
        subheading: '#1102-1, 70 SEONYU-RO, YEONGDEUNGPO-GU, SEOUL 07294, KOREA{br}(MULLAE-DONG 3-GA, WOORI VENTURE TOWN 2)',
        sjhaoma: ' +82-02-2088-0681',
        dianhuahaoma: ' +82-2-2088-0685',
        emoe: ' op@i-daeshin.com',
    }, {
        title: 'DSL JAPAN / Tokyo Head Office',
        subheading: ' 101 Yokochi Building, 5-33-3 Nishinippori, Arakawa Tokyo',
        sjhaoma: ' +81-3-6806-7927',
        emoe: ' kazunobu_iwamoto@i-daeshin.com',
    }, {
        title: 'DAESHIN Hong Kong Office',
        subheading: '',
    }, {
        title: 'DAESHIN GuangZhou Branch Office',
        subheading: 'Unit 1302, Building A2, Zhitai Commercial Plaza, 2395 Kaichuang Avenue, Huangpu District, Guangzhou',
    }]
}