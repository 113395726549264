import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = []
/* 预读文件(require.context)：返回一个读取函数，可以通过读取函数直接拿到预读文件的参数 */
const context = require.context(
  '@/views/', // 预读文件地址
  true, // 是否读取子文件    
  /\.js/ // 预读文件类型           
)
context.keys().forEach((res) => {
  console.log(context(res).config)
  routes.push(context(res).config)
});
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  
})
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;
  
    if (route.path !== '*') {
      array.push(path);
    }
  
    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }
    return array;
  }, []);
}
router.beforeEach((to, from, next) => {
  window.scrollTo(0,0); // 将滚动条回到页面顶部（动无滚动画）
  try{
    // 判断缓存是否有语言，如果没有根据浏览器语言设置
    let lang=sessionStorage.getItem('lang');
    if(!lang){
      let curLan=navigator.language.replace(/-(\s*)/,'');
      console.log(curLan,111);
      if(curLan=='zhCN'){
        //中文要特殊处理下
        to.query.lang = 'cn'; 
        sessionStorage.setItem('lang','cn');
      }else if (curLan=='koKR') {
        to.query.lang = 'ko'; 
        sessionStorage.setItem('lang','ko');
      }else if (curLan=='enUS') {
        to.query.lang = 'en'; 
        sessionStorage.setItem('lang','en');
      }else{
        to.query.lang = curLan; 
        sessionStorage.setItem('lang',curLan);
      }
      router.push({ path: to.path, query: to.query })
    }
    next();
  }catch(err){
    next(); // 继续导航页面
  }
 
 
});

export default router

// function getRoutesXML() {
//   const list = getRoutesList(router.options.routes, 'https://www.i-daeshin.com')
//     .map(route => `<url><loc>${route}</loc><changefreq>daily</changefreq> <priority>1.0</priority></url>`)
//     .join('\r\n');
//   return `<urlset xmlns="http://.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`;
// }
// getRoutesXML();