const config = {
    path: '/problems',
    name: 'Problems',
    component: () => import('@/views/Problems/index.vue'),
    meta: {
        title: '天津物流集团-完成',
        keepAlive: true
    }
}
const data = {
    token: '',
    queryStr: '',
    refId: '',
    TransactionTypeCode: '',
    CategoryLargeCode: '',
    CategoryMiddleCode: '',
    SubdivisionCode: '',
    MeaasurableTypeCode: '',
    DifficultyCode: '',
    FrequencyTypeCode: '',
    RecordTypeCode: '',
    //判断
    checkFlag: '',
    successFlag: '',
    attachmentFlag: '',
    timeLineFlag: '',
}
export {
    config,
    data,
}