const config = {
    path: '/4',
    name: '4',
    component: () => import('@/views/4/index.vue'),
    meta: {
      title: '天津物流集团',
      keepAlive:true
    }
  }
  const data={
    token:'',
    strQuery:'',
    parentId:'',
  }
  
  export { config,data}