const config = {
    path: '/matters',
    name: 'Matters',
    component: () => import('@/views/Matters/index.vue'),
meta:{
    title:'天津物流集团-业务',
    keepAlive:true
}
}
const data={
    token:'',
    queryStr:'',
    userId:'',
    workTypeCode:'',
    priorityTypeCode:'',
    difficultyTypecode:'',
    dateType:'',
    startDate:'',
    endDate:'',
    // 判断
    teamFlag:'',        
    orderFlag:'',       
    shoutAnOrderFlag:'',
    acceptFlag:'',      
    proceedFlag:'',     
    holdFlag:'',        
    submitFlag:'',      
    approveFlag:'',     
    closeFlag:'',       
    successFlag:'',     
    delayFlag:'',       
    lastNodeFlag:'',    
}   
                   
export{config,data}          