const config = {
  path: '*',
  name: 'Error',
  redirect: '/',
  component: () => import('@/views/404/index.vue'),
  meta: {
    title: '天津物流集团',
    keepAlive:true
  }
}

export  {config}