const config = {
    path: '/imageteb',
    name: 'Imageteb',
    component: () => import('@/views/Imageteb/index.vue'),
    meta: {
        title: '天津物流集团-图片'
    }
}
const data = {               
                             
}

export {
    config,
    data
}