<template>
  <div id="app">
    <!-- <button class="tap" @click="toTop()" v-show="tabshow"><i class="el-icon-top"></i></button> -->
    <button v-show="tabshow" class="scroll-top el-icon-top" ref="scrollTopBtn" @click="scrollToTop"></button>
    <Header :class="{ 'scrolled-header': isScrolled }" v-show="isHeaderVisible" @showChange="handleShowChange"
      v-if="$route.meta.header">
    </header>
    <router-view />
    <Foot class="foot" v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Foot from '@/components/Foot.vue'
export default {
  name: "App",

  components: {
    Header,
    Foot,
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      scrollTopNum: "",
      tabshow: false,
      path: '',
      isScrolled: false,
      isClosing: false, //  是否正在关闭页面的标记
      isRouterAlive: true,
      isHeaderVisible: true,
      lastScrollPosition: 0,
    }
  },
  watch: {
    $route: {
      handler(e) {
        window.document.title = e.meta.title;
      },
      immediate: true,
    },
  },
  
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('scroll', this.handl);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handl);
  },
  methods: {

    handleShowChange(visible) {
      // console.log(visible); // 实时输出 v-show 的内容（true 或 false）
      visible = this.isHeaderVisible
    },
    handleScroll() {
      let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      this.scrollTopNum = top;
    },
    handl() {
      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition < 800) {
        this.tabshow = false
      } else {
        this.tabshow = true
      }
    },
    //解决页面刷新，重新渲染数据
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    handle() {
      if (window.pageYOffset > 200) {
        this.$refs.scrollTopBtn.style.display = 'block';
        this.tabshow = true
      } else {
        this.$refs.scrollTopBtn.style.display = 'none';
        this.tabshow = false
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    handleScroll() {
      if (window.pageYOffset > 200) {
        this.isScrolled = true;
        this.tabshow = true;
      } else {
        this.isScrolled = false;
        this.tabshow = false;
      }
    }
  },
};
</script>
<style lang="scss">
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.scrolled-header {
  background-color: #fff !important;
  color: #000 !important;

  .el-row {
    .grid-content {
      color: #000 !important;

      .update {
        .yuyan {
          a {
            color: #000 !important;
          }
        }
      }
    }
  }
}

.scroll-top {
  width: 100px;
  height: 100px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  padding: 10px;
  background-color: #36880e;
  color: #ffff;
  border: none;
  // display: none;
  cursor: pointer;
  z-index: 1000;
  font-size: 50px;
}

.scroll-top:hover {
  background-color: #256703;
}

/* 水平滚动条 */
::-webkit-scrollbar {
  width: 14px;
  /* 滚动条的宽度 */
  background-color: #36880e;
  /* 滚动条背景色 */
}

::-webkit-scrollbar-thumb {
  background-color: #256703;
  /* 滚动条的颜色 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #256703;
  /* 滚动条的颜色（鼠标悬停时） */
}

/* 垂直滚动条 */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  /* 滚动 条轨 道的 背景 色 */
}

::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0;
  /* 滚动条轨道的背景色（鼠标悬停时） */
}

body {
  width: 100%;
  position: absolute;
  padding: 0;
  margin: 0;

  .tap {
    text-align: center;
    cursor: pointer;
    position: fixed;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    bottom: 150px;
    right: 100px;
    border: #FF3E41;
    line-height: 100px;
    font-size: 60px;
    background-color: #FF3E41;
    color: #fff;
    z-index: 200;
  }

  .tap:hover {
    border: #e45d60;
    background-color: #e45d60;
    color: #000;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  .foot {
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media (max-width: 1050px) {
  body {
    .top {
      width: 30px;
      height: 30px;
      bottom: 30px;
      font-size: 20px;
      right: 15px;
    }
  }
}
</style>