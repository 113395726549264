const config = {
    path: '/3',
    name: '3',
    component: () => import('@/views/3/index.vue'),
    meta: {
      title: '天津物流集团',
      keepAlive:true
    }
  }
  
  export { config}