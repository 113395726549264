const config = {
    path: '/chulijieguoxiangqing',
    name: 'chulijieguoxiangqing',
    component: () => import('@/views/chulijieguoxiangqing/index.vue'),
    meta: {
      title: '天津物流集团-处理结果详情',
      keepAlive:true
    }
  }
  const data={
  }
  export {config,data}