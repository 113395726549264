const config = {
    path: '/network',
    name: 'Network',
    component: () => import('@/views/Network/index.vue'),
    meta: {
      title: '大信物流集团',
      header:true
    }
  }
  
  export  {config}