const config = {
    path: '/trentter',
    name: 'Trentter',
    component: () => import('@/views/Trentter/index.vue'),
    meta: {
        title: '天津物流集团-处理内容',
        keepAlive: true
    }
}
const data = {
    token: "",
    queryStr: "",
    parentId:"",
    measurableRefId:"",
}
export {
    config,
    data,
}