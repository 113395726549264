import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './language.EN'
import usLocal from './language-CN.js'
import jaLocal from './language-JA'
import koLocal from './language-KO'
Vue.use(VueI18n);
const messages = {
    en: {
        ...enLocale
    },
    cn: {
        ...usLocal
    },
    ja:{
        ...jaLocal
    },
    ko:{
        ...koLocal
    }
}
const information = {
    en: {
        ...enLocale
    },
    cn: {
        ...usLocal
    },
    ja:{
        ...jaLocal
    },
    ko:{
        ...koLocal
    }
}
const  outlets = {
    en: {
        ...enLocale
    },
    cn: {
        ...usLocal
    },
    ja:{
        ...jaLocal
    },
    ko:{
        ...koLocal
    }
}
const  advocacy = {
    en: {
        ...enLocale
    },
    cn: {
        ...usLocal
    },
    ja:{
        ...jaLocal
    },
    ko:{
        ...koLocal
    },

}
const  foot = {
    en: {
        ...enLocale
    },
    cn: {
        ...usLocal
    },
    ja:{
        ...jaLocal
    },
    ko:{
        ...koLocal
    },

}
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'cn' || 'ja' || 'ko', // 通过this.$i18n.locale的值实现语言切换
    messages,
    information,
    outlets,
    advocacy,
    foot,
    silentTranslationWarn: true
});
 
export default i18n;
