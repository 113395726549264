//Vehicle
const config = {
    path: '/vehicle',
    name: 'Vehicle',
    component: () => import('@/views/Vehicle/index.vue'),
    meta: {
      title: '天津物流集团-车辆详情',
      keepAlive:true
    }
  }
  const data={
    token:'',
    structid:'',
    carNo:'',
    carType:'',
    cargoType:'',
    AreasId:'',
  }
  export {config,data}